import React from "react";
import createReactClass from "create-react-class";
import * as UrlHelpers from "../../libs/UrlHelpers";

const SelectionPageClose = createReactClass({
  render: function () {
    return (
      <div className="my-selection-page-header__close-btn" onClick={UrlHelpers.navigateBackOrToResult}>
        <span className="ficon ficon__close-thin my-selection-page-header__close-icon" />
      </div>
    );
  },
});

export default SelectionPageClose;
