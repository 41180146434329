import React from "react";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";

const VisualMessage = ({ children }) => {
  return (
    <div style={{ margin: 20 }}>
      <span style={{ textAlign: "centred" }}>{children}</span>
    </div>
  );
};

const DetailPisysTableMessage = ({ isLoading, children }) => {
  if (isLoading) {
    return <VisualMessage>{ElstrLangStore.text("DETAIL_TEXT_LOADING")}</VisualMessage>;
  }

  return <VisualMessage>{children}</VisualMessage>;
};

export default DetailPisysTableMessage;
