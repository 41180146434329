import React from "react";
import createReactClass from "create-react-class";
import ContainerGrid from "./ContainerGrid";
import ContainerList from "./ContainerList";
import ContainerCompare from "./ContainerCompare";
import Footer from "../footer/Footer";
import AppsStore from "../../stores/AppsStore";
import StaticCacheVersionsStore from "../../stores/StaticCacheVersionsStore";
import MaterialsStore from "../../stores/MaterialsStore";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";
import ElstrConfigStore from "elstr-jslib/src/scripts/stores/ElstrConfigStore";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";

function _setState() {
  let materialsToRender = MaterialsStore.getFilteredMaterialsResult();
  let currentLanguage = ElstrLangStore.getCurrentLanguage();
  let resultMultip = parseInt(ElstrUrlHashStore.get("resultMultip"));
  if (isNaN(resultMultip)) resultMultip = 1; // set default
  let successCounter = StaticCacheVersionsStore.getSuccessCounter();

  let resultView = ElstrUrlHashStore.get("resultView") || "grid";

  return {
    materialsToRender,
    resultView,
    resultMultip,
    currentLanguage,
    successCounter,
  };
}

const ContainerResultMaterials = createReactClass({
  mixins: [
    AppsStore.mixin,
    MaterialsStore.mixin,
    ElstrUrlHashStore.mixin,
    ElstrLangStore.mixin,
    StaticCacheVersionsStore.mixin,
  ],

  getInitialState: function () {
    return _setState();
  },

  storeDidChange: function () {
    this.setState(_setState());
  },

  shouldComponentUpdate: function (nextProps, nextState) {
    return (
      this.state.materialsToRender !== nextState.materialsToRender ||
      this.state.currentLanguage !== nextState.currentLanguage ||
      this.state.resultMultip !== nextState.resultMultip ||
      this.state.successCounter !== nextState.successCounter
    );
  },

  /**
   * Render
   */
  render: function () {
    let containerComponent = null;
    let classNameMainBody = "main-body__center";
    let resultView = this.state.resultView;
    let materialsToRender = this.state.materialsToRender;
    let displayMore = true; // will show the show more field in the view

    let resultMultip = this.state.resultMultip;
    let limitFirstRendering = ElstrConfigStore.option("limitFirstRendering");

    // set the materials to render with the config (limitFirstRendering) and the result Multiplier
    let numberToRender = resultMultip * limitFirstRendering;

    // if it happens that the multiplication is greater than the actual number of materials
    // the materials to render will be decreased and the displayMore field is set to false so it won't be rendered
    if (numberToRender > materialsToRender.length) {
      numberToRender = materialsToRender.length;
      displayMore = false;
    }

    // reduce to number of results shown and which will be rendered
    // https://stackoverflow.com/a/6824966
    materialsToRender.splice(numberToRender);

    if (resultView === "grid") {
      containerComponent = (
        <ContainerGrid materialsToRender={materialsToRender} displayMore={displayMore} scrollActive={true} />
      );
      classNameMainBody += " main-content--grid-view";
    } else if (resultView === "list") {
      containerComponent = (
        <ContainerList materialsToRender={materialsToRender} displayMore={displayMore} scrollActive={true} />
      );
      classNameMainBody += " main-content--list-view";
    } else if (resultView === "compare") {
      containerComponent = (
        <ContainerCompare materialsToRender={materialsToRender} displayMore={displayMore} scrollActive={true} />
      );
      classNameMainBody += " main-content--compare-view";
    }

    return (
      <div key="ContainerResultMaterials" className={classNameMainBody} id="main-content" tabIndex="-1">
        {containerComponent}
        <Footer />
      </div>
    );
  },
});

export default ContainerResultMaterials;
