import React from "react";
import createReactClass from "create-react-class";
import * as UrlHelpers from "../../libs/UrlHelpers";

const SelectionPageStack = createReactClass({
  render: () => {
    // decide if the stacking page should appear
    let stacked = UrlHelpers.getStacked();

    if (stacked) {
      return <div className="my-selection-page__stack" key="stacking-selection" />;
    } else {
      return <i key="no stacking selection" />;
    }
  },
});

export default SelectionPageStack;
