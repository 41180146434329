import React from "react";
import createReactClass from "create-react-class";
import BackToResult from "../router/BackToResult";
import SelectionStore from "../../stores/SelectionStore";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import ElstrConfigStore from "elstr-jslib/src/scripts/stores/ElstrConfigStore";
import ElstrUrlHashActions from "elstr-jslib/src/scripts/actions/ElstrUrlHashActions";
import { setDocTitle } from "../../libs/Meta";
import Header from "../common/view/Header";
import PhoneNav from "../common/view/PhoneNav";
import { getTitle } from "../../libs/Title";
import Body from "../print_label/Body";
import PrintLabelStore from "../../stores/PrintLabelStore";

const PrintLabel = createReactClass({
  mixins: [SelectionStore.mixin, ElstrUrlHashStore.mixin, ElstrLangStore.mixin, PrintLabelStore.mixin],

  statics: {
    getDerivedStateFromProps(newProps) {
      ElstrUrlHashActions.setRouteParams(newProps.location.pathname, newProps.match.params);
      return null;
    },
  },

  getInitialState: function () {
    return {};
  },

  storeDidChange: function () {
    this.setState({});
  },

  componentDidMount: function () {
    window.scrollTo(0, 0);
    // set to one mount
    PrintLabelStore.setDefaultNrOfLabels(1);
  },

  render: function () {
    let isPisysSite = ElstrConfigStore.option("isPisysSite");

    // Set title for selection
    let preTitle = ElstrLangStore.text("TITLE__EKATALOG");
    if (isPisysSite) preTitle = ElstrLangStore.text("TITLE__PISYS");
    setDocTitle(`${preTitle} ${getTitle()}`);

    // this means the user entered the print label view via the detail page
    // and wants to print label only one article
    const sMatHdrMatnrInHash = ElstrUrlHashStore.get("sMatHdrMatnr");

    return (
      <React.Fragment>
        <BackToResult />
        <div className="common-page">
          <div className="common-page__container">
            <Header />
            <PhoneNav />
            <Body sMatHdrMatnrInHash={sMatHdrMatnrInHash} />
          </div>
        </div>
        <div className="subpage-modal-overlay" />
      </React.Fragment>
    );
  },
});

export default PrintLabel;
