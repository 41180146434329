import React from "react";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import AppsStore from "../../stores/AppsStore";

const DetailContact = createReactClass({
  propTypes: {
    material: PropTypes.object.isRequired,
  },

  handleOnClickLink: function (value) {
    window._paq.push(["trackEvent", "conversion", "click", value]);
  },

  render: function () {
    // format first the source object where the phone number and email addresses are taken
    let navigation = VETROPACK_STATIC_CACHE.navigation;
    let catObj = {};
    navigation.forEach(elem => {
      let keys = Object.keys(elem["catalogue"]);
      catObj[keys[0]] = elem.catalogue[keys[0]];
    });

    // start getting all the contents
    let contentPhone = [];
    let contentMail = [];
    let contentMailExport = [];
    let emailsExportAlreadyPushed = [];
    let material = this.props.material;

    if (material["sMatAtvKatalog"] !== null && material["sMatAtvKatalog"] !== "null") {
      material["sMatAtvKatalog"].forEach((catalogue, i) => {
        const catalogConfig = catObj[catalogue];
        if (!catalogConfig) return;

        /** push phone numbers **/
        let cataloguePhone = catalogConfig["cataloguePhone"] || "";
        let cataloguePhoneFormated = AppsStore.getFormatedTelNr(cataloguePhone);
        contentPhone.push(
          <div className="contact-info-block__tel" key={cataloguePhone}>
            <a
              href={`tel:${cataloguePhoneFormated}`}
              onClick={this.handleOnClickLink.bind(this, `tel:${cataloguePhoneFormated}`)}
            >
              <span className="ficon ficon__tel contact-info-block__tel-icon" />
              {cataloguePhone}
            </a>
          </div>,
        );

        /** push normal emails **/
        let catalogueMail = catalogConfig["catalogueMail"] || "";
        contentMail.push(
          <div className="contact-info-block__mail" key={catalogueMail}>
            <a href={`mailto:${catalogueMail}`} onClick={this.handleOnClickLink.bind(this, `mailto:${catalogueMail}`)}>
              <span className="ficon ficon__mail contact-info-block__mail-icon" />
              {catalogueMail}
            </a>
          </div>,
        );

        /** push export emails **/
        let catalogueMailExport = catalogConfig["catalogueMailExport"] || "";
        // the next check is there for two reasons
        // 1) the catalogueMailExport can be empty
        // 2) catalogueMailExport is not unique und should be pushed only once
        if (catalogueMailExport.length > 1 && !emailsExportAlreadyPushed.includes(catalogueMailExport)) {
          contentMailExport.push(
            <div className="contact-info-block__mail" key={`${catalogueMailExport}${i}`}>
              <a
                href={`mailto:${catalogueMailExport}`}
                onClick={this.handleOnClickLink.bind(this, `mailto:${catalogueMailExport}`)}
              >
                <span className="ficon ficon__mail contact-info-block__mail-icon" />
                {catalogueMailExport}
              </a>
            </div>,
          );
        }
        emailsExportAlreadyPushed.push(catalogueMailExport);
      });
    }

    return (
      <React.Fragment>
        {contentPhone}
        {contentMail}
        {contentMailExport}
      </React.Fragment>
    );
  },
});

export default DetailContact;
