import { isPisysSite } from "./Controllers";
import FilterStore from "../stores/FilterStore";
import MaterialsStore from "../stores/MaterialsStore";
import ElstrUrlHashActions from "elstr-jslib/src/scripts/actions/ElstrUrlHashActions";
import ElstrConfigStore from "elstr-jslib/src/scripts/stores/ElstrConfigStore";
import ElstrLog from "elstr-jslib/src/scripts/ElstrLog";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import ElstrUserStore from "elstr-jslib/src/scripts/stores/ElstrUserStore";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";
import ElstrCache from "elstr-jslib/src/scripts/ElstrCache";
import SelectionActions from "../actions/SelectionActions";
import ElstrId from "elstr-jslib/src/scripts/ElstrId";
import * as UrlHelpers from "./UrlHelpers";
import ElstrScrollStore from "elstr-jslib/src/scripts/stores/ElstrScrollStore";
import AppsActions from "../actions/AppsActions";
import * as Attributes from "./Attributes";
import * as Filters from "./Filters";
// vetro styles from eyekon
import "../assets/sass/styles.scss";
// styles from intelliact or other libraries req.
import "../assets/css/shared.css";
import "./../assets/css/rc-tooltip-overwrite.css";
import * as Sentry from "@sentry/browser";
// init sentry client, see https://docs.sentry.io/platforms/javascript/#integrating-the-sdk
let dsn;
let environment = ELSTR.applicationEnv;
if (environment === "development") {
  // local-dev
  dsn = "https://3d2f18355e6645628f7dfc59c9ee0377@sentry.io/1273531";
} else {
  // client
  dsn = "https://93bf0cfa285548428ccd94e57b19cc70@sentry.io/306159";
}

Sentry.init({
  dsn,
  environment,
});

ElstrUrlHashActions.init(true);

ElstrConfigStore.init();

// Elstr init
ElstrLog.init({
  justAConsoleAlias: ElstrConfigStore.option("ElstrLog", "consoleAlias"),
  enabled: ElstrConfigStore.option("ElstrLog", "enabled"),
  serverLevel: ElstrConfigStore.option("ElstrLog", "serverLevel"),
  traceEnabled: ElstrConfigStore.option("ElstrLog", "traceEnabled"),
});

ElstrLangStore.init();

let authReq = false; // disabled for default (ex: ekatalog)
if (ElstrConfigStore.option("isPisysSite")) authReq = true; // enable for pisys
ElstrUserStore.init({
  forceAuthentication: authReq,
});

ElstrUrlHashStore.initWithReactRouter();
// Possible to override the name for fixing wrong unchangeable input saved on local storage
let localStorageName = ElstrConfigStore.simpleOptionOrDefault("localStorageName", "AppMeta");
ElstrCache.init("LOCAL_STORAGE", ElstrConfigStore.option("ElstrCache", "timeToExpire"), localStorageName);

/**
 * Set the mySelectionId if undefined
 * This also means, that the user is a new one and other meta data
 * may be initialized as well
 */

let mySelectionId = ElstrCache.get("mySelectionId");
if (mySelectionId === undefined) {
  mySelectionId = ElstrId.create();
  ElstrCache.set("mySelectionId", mySelectionId);
}

/**
 * Set the eCatId if undefined
 * This id will be used in eCat to identify a user
 */
let eCatId = ElstrCache.get("eCatId");
if (eCatId === undefined) {
  eCatId = ElstrId.create();

  // overwrite with an empty string for pisys since not used
  if (isPisysSite()) {
    eCatId = "";
  }

  ElstrCache.set("eCatId", eCatId);
}

/**
 * get and store the my selection items here when no auth is req. or the user is already logged in
 * otherwise the selection materials will be triggered inside the SelectionStore when a user logs in
 */
if (!authReq || ElstrUserStore.isAuth()) {
  let mySelectionId = ElstrCache.get("mySelectionId");
  // let selectionSource = UrlHelpers.getSelectionSourceForServerRequest();
  let payload = { selectionId: mySelectionId, selectionSource: "local" };
  SelectionActions.getMaterials({ payload });

  // when initializing the page, there might be a SelectionId in the URL which differs from the own SelectionId
  UrlHelpers.requestSelectionMaterialsWhenSelectionIdInUrl();
}

if (LIBS.appName === "catalogue") {
  /**
   * update filter store for new user or returning user with their settings
   * update attributes for new user or returning user with their settings
   */
  FilterStore.updateUserFilters();

  MaterialsStore.init();

  ElstrScrollStore.disableThrottle(); // disable throttle in elstrScrollStore

  /**
   * for static version number
   */
  let intervalIdGetStaticCacheVersions = window.setInterval(function () {
    AppsActions.getStaticCacheVersions({});
  }, ElstrConfigStore.simpleOptionOrDefault("getStaticCacheVersionsInterval", 100000));

  ElstrLog.log("intervalIdGetStaticCacheVersions", intervalIdGetStaticCacheVersions);
}

Attributes.init();
Filters.init();
