import React from "react";
import Translations from "../../../libs/Translations";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";

const DeliveryHeader = () => {
  const aufnr = Translations.getLabelDetailColumn("aufnr")
    ? Translations.getLabelDetailColumn("aufnr")
    : ElstrLangStore.text("DETAIL_TAB_AUFNR");

  return (
    <thead>
      <tr>
        <th>{Translations.getLabelDetailColumn("exidv")}</th>
        <th>{Translations.getLabelDetailColumn("erdat")}</th>
        <th>{Translations.getLabelDetailColumn("eruhr")}</th>
        <th>{Translations.getLabelDetailColumn("palno")}</th>
        <th>{Translations.getLabelDetailColumn("proddat")}</th>
        <th>{Translations.getLabelDetailColumn("linename")}</th>
        <th>{aufnr}</th>
        <th>{Translations.getLabelDetailColumn("chargD")}</th>
      </tr>
    </thead>
  );
};

export default DeliveryHeader;
