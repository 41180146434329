let keyMirror = require("keymirror");

module.exports = keyMirror({
  SELECTION_GET_MATERIALS_WILL_GET: null,
  SELECTION_GET_MATERIALS_DID_GET: null,

  SELECTION_FOREIGN_GET_MATERIALS_WILL_GET: null,
  SELECTION_FOREIGN_GET_MATERIALS_DID_GET: null,

  SELECTION_ADD_MATERIALS_WILL_ADD: null,
  SELECTION_ADD_MATERIALS_DID_ADD: null,

  SELECTION_REMOVE_MATERIALS_WILL_REMOVE: null,
  SELECTION_REMOVE_MATERIALS_DID_REMOVE: null,
});
