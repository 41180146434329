import { Link } from "react-router-dom";
import Translations from "../../libs/Translations";
import React from "react";
import createReactClass from "create-react-class";
import AppsStore from "../../stores/AppsStore";
import * as UrlHelpers from "../../libs/UrlHelpers";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";

const MetaNavCatalogue = createReactClass({
  mixins: [ElstrUrlHashStore.mixin],

  storeDidChange: function () {
    this.forceUpdate();
  },

  changeCatalogue: function (catalogue, e) {
    AppsStore.setCloseAllMenus(e);
    window._paq.push(["trackEvent", "interface", "change", "catalogue: " + catalogue]);
  },

  render: function () {
    let selected = "";
    // for desktop
    let classNameNavItem = "meta-nav-list__item";
    let classNameSelected = "meta-nav-list__item--selected for selected";
    // mobile and phone
    if (AppsStore.getLayoutView() !== "desktop") {
      classNameNavItem = "sub-layer-list__item";
      classNameSelected = "sub-layer-list__item--selected";
    }

    // catalogueGlobal is created static
    let currentCat = ElstrUrlHashStore.getRouteParam("catalogue") || "global";
    if (currentCat === undefined) currentCat = "global"; // set default

    if (currentCat === "global") selected = classNameSelected;

    // prepare path
    let hashesToRemain = UrlHelpers.getAllHashesExcept(["mainView", "catalogue", "mainGroup", "switched"], false);
    let mainGroup = ElstrUrlHashStore.getRouteParam("mainGroup") || "all";
    let path = `/catalogue/result/global/${mainGroup}/#ph=1${hashesToRemain}`;

    let catalogueGlobal = (
      <Link to={path} onClick={this.changeCatalogue.bind(this, "global")}>
        <li className={`${classNameNavItem} ${selected}`}>{ElstrLangStore.text("GLOBAL_CATALOGUE")}</li>
      </Link>
    );

    // catalogueList is created dynamically
    let catalogueKeys = Object.keys(Translations.getValues("sMatAtvKatalog"));

    // create and fill catalogueList
    let catalogueList = [];
    catalogueKeys.forEach(catalogue => {
      selected = "";
      if (catalogue === currentCat) selected = classNameSelected;

      let path = `/catalogue/result/${catalogue}/${mainGroup}#${hashesToRemain}`;

      catalogueList.push(
        <Link key={catalogue} to={path} onClick={this.changeCatalogue.bind(this, catalogue)}>
          <li className={`${classNameNavItem} ${selected}`}>{Translations.getValue("sMatAtvKatalog", catalogue)}</li>
        </Link>,
      );
    });

    return (
      <span>
        {catalogueGlobal}
        {catalogueList}
      </span>
    );
  },
});

export default MetaNavCatalogue;
