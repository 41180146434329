import ElstrFlux from "elstr-jslib/src/scripts/libs/ElstrFlux.js";
import AppsConstants from "../constants/actions/UserConstants";

const AppsActions = ElstrFlux.createActions(
  [
    {
      className: "VETROPACK_WidgetServer_JSON_User",
      methodName: "setLang",
      constantWill: AppsConstants.USER_SET_LANG_WILL_SET,
      constantDid: AppsConstants.USER_SET_LANG_DID_SET,
    },
  ],
  {},
);

export default AppsActions;
