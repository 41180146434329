import mcFly from "elstr-jslib/src/scripts/libs/mcFly.js";
import ElstrEditingStates from "elstr-jslib/src/scripts/constants/ElstrEditingStates";
import ElstrLoadingStates from "elstr-jslib/src/scripts/constants/ElstrLoadingStates";

const PrintLabelStore = mcFly.createStore(
  {
    defaultNrOfLabels: 1,

    error: null,
    loadingState: ElstrLoadingStates.EMPTY,
    editingState: ElstrEditingStates.NORMAL,

    /** GETTERS **/
    getLoadingState: function () {
      return this.loadingState;
    },

    getEditingState: function () {
      return this.editingState;
    },

    getDefaultNrOfLabels: function () {
      return PrintLabelStore.defaultNrOfLabels;
    },

    /** SETTERS **/
    setDefaultNrOfLabels: function (defaultNrOfLabels) {
      PrintLabelStore.defaultNrOfLabels = defaultNrOfLabels;
      PrintLabelStore.emitChange();
    },
  },
  function (payload) {
    switch (payload.actionType) {
    }
    return true;
  },
);

export default PrintLabelStore;
