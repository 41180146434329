import React from "react";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import ScrollPositionsStore from "../../stores/ScrollPositionsStore";
import ElstrScrollStore from "elstr-jslib/src/scripts/stores/ElstrScrollStore";

const ItemCompareScroll = createReactClass({
  mixins: [ElstrScrollStore.mixin, ScrollPositionsStore.mixin],

  propTypes: {
    scrollActive: PropTypes.bool.isRequired,
  },

  getInitialState: function () {
    let pos = ScrollPositionsStore.getCompareScroll();

    return {
      xPos: pos.xPos,
      yPos: pos.yPos,
    };
  },

  storeDidChange: function () {
    if (this.props.scrollActive) {
      let yPos = ElstrScrollStore.getCurrentScroll();

      this.setState({ yPos });
    }
  },

  componentDidMount: function () {
    if (this.props.scrollActive) {
      let pos = ScrollPositionsStore.getCompareScroll();

      window.scrollTo(0, pos.yPos);
    }

    // horizontal scrolling is in the 'item-compare-frame' for the compare view
    let elem = document.getElementsByClassName("item-compare-frame")[0];
    elem.onscroll = this.eventHandlerCompare;

    elem.scrollLeft = this.state.xPos;
  },

  componentWillUnmount: function () {
    if (this.props.scrollActive) {
      let xPos = this.state.xPos;
      let yPos = this.state.yPos;

      ScrollPositionsStore.setCompareScroll({ xPos, yPos });
    }
  },

  eventHandlerCompare: function (e) {
    let elem = document.getElementsByClassName("item-compare-frame")[0];

    this.setState({ xPos: elem.scrollLeft });
  },

  render: function () {
    return <i />;
  },
});

export default ItemCompareScroll;
