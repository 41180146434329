import * as TT from "../../libs/Tooltip";
import { MAIN_VIEW as MV } from "../../constants/MainView";
import React from "react";
import createReactClass from "create-react-class";
import AppsStore from "../../stores/AppsStore";
import * as UrlHelpers from "../../libs/UrlHelpers";
import TouchStore from "../../stores/TouchStore";
import ElstrScrollStore from "elstr-jslib/src/scripts/stores/ElstrScrollStore";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";
import { TYPES } from "../../constants/Tooltip";

/**
 * returns true when a click happened outside of the DOM element
 * @param elemClassName
 * @param e
 * @returns {boolean}
 */
function clickOutside(elemClassName, e) {
  const el = document.getElementsByClassName(elemClassName)[0];
  // condition true when outside of the div
  return !el.contains(e.target);
}

const ClassChangerOnScrollApp = createReactClass({
  mixins: [AppsStore.mixin, ElstrScrollStore.mixin],

  storeDidChange: function () {
    this.forceUpdate();
  },

  closeOpenOverlaysAndTooltips: function (e) {
    /** handle overlay settings **/
    // only do something when settings are open
    if (AppsStore.getOverlaySettingsOpen()) {
      if (clickOutside("item-list__setting-layer", e)) {
        AppsStore.setOverlaySettings(false);
      }
    }

    // only do something when settings are open
    if (AppsStore.getOverlaySettingsOpenPackagingDossier()) {
      if (clickOutside("item-list__setting-layer-packaging-dossier", e)) {
        AppsStore.setOverlaySettingsPackagingDossier(false);
      }
    }

    /** handle catalogue settings **/
    // only do something when getCatalogueSelectionOpen are open
    if (AppsStore.getCatalogueSelectionOpen()) {
      AppsStore.setCatalogueSettings(false);
    }

    /** handle language settings **/
    // only do something when getLanguageSelectionOpen are open
    if (AppsStore.getLanguageSelectionOpen()) {
      AppsStore.setLanguageSettings(false);
    }

    if (clickOutside(TYPES.leftSticky, e)) {
      TT.removeTooltip(TYPES.leftSticky);
    }

    if (clickOutside(TYPES.centerSticky, e)) {
      TT.removeTooltip(TYPES.centerSticky);
    }
  },

  closeOpenTouchOverlays: function (e) {
    /** handle mobile color overlay open **/
    // only do something when getShowColorsMobileOverlay is allowed
    if (TouchStore.getShowColorsOverlayForMaterial() !== null) {
      // RESETS the overlay
      TouchStore.setShowColorsOverlayForMaterial(null);
    }
  },

  render: function () {
    /**
     * Handle all main app changes
     * - layout
     * - overlay
     */
    let classNameContainer = ""; // def always for parent className
    if (LIBS.appName === "intro") classNameContainer += "homepage ";

    /** layout view (desktop / tablet / phone). the last two are for mobile **/
    let layoutView = AppsStore.getLayoutView();
    classNameContainer += ` ${layoutView}`;

    /** find out if the device is a touch device and add according class to classNameContainer **/
    let touch =
      "ontouchstart" in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

    if (touch) {
      classNameContainer += ` touch-active—style`;
    } else {
      classNameContainer += ` touch-inactive—style`;
    }

    /** orientation from mobile devices **/
    let mql = window.matchMedia("(orientation: landscape)");
    let _orientation = mql.matches ? "landscape" : "portrait";

    classNameContainer += ` ${_orientation}`;

    /** Menu overlay changes **/
    // overlay menu changes
    if (AppsStore.getOverlayMenuOpen()) {
      classNameContainer += " menu-overlay--open";
    }
    // overlay settings changes for result and mainDossier
    if (AppsStore.getOverlaySettingsOpen()) {
      classNameContainer += " label-setting-overlay--open";
    }
    // overlay settings changes for packagingDossier
    if (AppsStore.getOverlaySettingsOpenPackagingDossier()) {
      classNameContainer += " label-setting-overlay--open-packaging-dossier";
    }
    // overlay filter changes
    if (AppsStore.getOverlayFiltersOpen()) {
      classNameContainer += " filter-overlay--open";
    }
    // overlay detail contat
    if (AppsStore.getOverlayDetailContactOpen()) {
      classNameContainer += " detail-contact-overlay--open";
    }
    // Overlay Infobox changes
    if (AppsStore.getOverlayInfoboxOpen()) {
      classNameContainer += " infobox-overlay--open";
    }

    //  All Scrollport changes - They are depending on layoutView and selectionView and resultView
    let scrollTop = ElstrScrollStore.getCurrentScroll();
    let selectionView = ElstrUrlHashStore.get("selectionView");
    let resultView = ElstrUrlHashStore.get("resultView");
    let mainView = UrlHelpers.getMainView();

    // sticky header for result mainView
    // sticky header for layoutView desktop
    if (mainView === MV.RESULT) {
      let headerWrapHeight = 212; // 212px is the value from the clickDummy
      if (layoutView === "desktop" && scrollTop > headerWrapHeight) {
        classNameContainer += " header--sticky";
      }
      // floating filter icon and sticky header for layoutView phone and tablet
      if (LIBS.appName === "catalogue" && layoutView !== "desktop" && scrollTop > 160) {
        classNameContainer += " filter-open-icon--visible";
        if (resultView === "list") {
          classNameContainer += " item-list-labels-row--sticky";
        }
      }
      /** sticky header for selection mainView **/
    } else if (mainView === MV.SELECTION) {
      if (selectionView === "list") {
        if (layoutView !== "phone" && scrollTop > 200) {
          classNameContainer += " myselection__item-list-labels-sticky-top";
        }
        if (layoutView === "phone" && scrollTop > 70) {
          classNameContainer += " myselection__item-list-labels-sticky-top";
        }
      }
    }

    return (
      <div
        id="container"
        key="container"
        className={classNameContainer}
        onClick={this.closeOpenOverlaysAndTooltips}
        onTouchStart={this.closeOpenTouchOverlays}
      >
        {this.props.children}
      </div>
    );
  },
});

export default ClassChangerOnScrollApp;
