import * as ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";

export function getCopyCustomerCache() {
  if (!VETROPACK_STATIC_CACHE.customers) return [];
  return [...VETROPACK_STATIC_CACHE.customers];
}

export function getFilterValuesForCustomer() {
  // the filter value in the url of customer might include more than one
  // data point which are joined by the char " - " and therefore split by this char
  const urlHashValue = ElstrUrlHashStore.get("filter_customer");
  return urlHashValue.split(" - ");
}

export function getFilterValueForCustomer() {
  // only the value of the first index will be used for filtering
  return getFilterValuesForCustomer()[0];
}

export function checkIfCustomerInsideValue(customer, value) {
  value = value.toLowerCase();

  // enable the user to have multiple search params separated with a blank
  value = value.split(" ");

  let addCustomer = false;
  value.every(val => {
    // reset
    addCustomer = false;

    if (!isNaN(val)) {
      if (customer.kunnr.toLowerCase().includes(val)) addCustomer = true;
    }

    if (!addCustomer && customer.name1.toLowerCase().includes(val)) addCustomer = true;
    if (!addCustomer && customer.ort01.toLowerCase().includes(val)) addCustomer = true;
    if (!addCustomer && customer.land1.toLowerCase().includes(val)) addCustomer = true;

    return addCustomer;
  });

  return addCustomer;
}

export function getAllCustomerNrsInsideFilterVal() {
  const filterVal = getFilterValueForCustomer();
  const customers = getCopyCustomerCache();
  const filteredCustomerNrs = [];

  customers.forEach(customer => {
    if (checkIfCustomerInsideValue(customer, filterVal)) {
      filteredCustomerNrs.push(customer.kunnr);
    }
  });

  return filteredCustomerNrs;
}
