import React from "react";
import { ScrollToHOC } from "react-scroll-to";

function getYPos(dataAttributeKey) {
  // we scroll to the filter block
  const filterBlock = document.getElementById(`filterBlock${dataAttributeKey}`);
  if (filterBlock === null) return null;

  let y = filterBlock.getBoundingClientRect().top;

  // we have to move up back to height of the header
  const header = document.getElementsByClassName("main__header")[0];
  y -= header.clientHeight;

  // we have to add the page offset for the right scroll y position
  y += window.pageYOffset;

  window._paq.push(["trackEvent", "Filter", "scroll to", dataAttributeKey]);

  return y;
}

export default ScrollToHOC(function (props) {
  return (
    <span onClick={() => props.scrollTo({ y: getYPos(props.dataAttributeKey), smooth: true })}>{props.children}</span>
  );
});
