import React from "react";
import createReactClass from "create-react-class";
import MaterialsStore from "../../stores/MaterialsStore";
import ContainerCompare from "../article/ContainerCompare";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";
import ElstrConfigStore from "elstr-jslib/src/scripts/stores/ElstrConfigStore";
import * as UrlHelpers from "../../libs/UrlHelpers";
import { getFirstKeyNameOfJSON } from "../../libs/Utils";
import Translations from "../../libs/Translations";

const DetailAlternative = createReactClass({
  selectChange: function (e) {
    let value = e.target.value;
    if (value !== "-") {
      UrlHelpers.setHashNoHistory({ alternativeTab: value });

      window._paq.push(["trackEvent", "Detail", "alternativeTab", value]);
    }
  },

  tabChange: function (alternativeOption) {
    UrlHelpers.setHashNoHistory({ alternativeTab: alternativeOption });
    window._paq.push(["trackEvent", "Detail", "alternativeTab", alternativeOption]);
  },

  render: function () {
    const sMatHdrMatnr = this.props.sMatHdrMatnr;
    const alternativeMaterialsObj = MaterialsStore.filterAlternativeMaterials(sMatHdrMatnr);
    const keys = Object.keys(alternativeMaterialsObj);

    // return no alternative page
    if (keys.length < 1) {
      return (
        <div className="detail-related">
          <h2 className="detail-related__title">{ElstrLangStore.text("DETAIL_ALTERNATIVE_NOALTERNATIVE")}</h2>
        </div>
      );
    }

    // contains all possible alternative options and also represents the order of the tabs
    const alternativeTabsOptions = ElstrConfigStore.option("alternative");

    /** this part is to find out which tab should be the active tab
     * there might be an entry in the URL for the hash: "alternativeTab" but it might not be a possible tab
     * in this case, the first alternative from the results in alternativeMaterialsObj will be assigned
     * while the url will still remain unchanged
     */
    let alternativeTabURL = ElstrUrlHashStore.get("alternativeTab");
    let activeTab = alternativeTabURL; // default

    if (alternativeTabURL === undefined || !alternativeMaterialsObj[alternativeTabURL]) {
      activeTab = getFirstKeyNameOfJSON(alternativeMaterialsObj); // reassign the activeTab
    }

    const tabDesktop = [];
    const tabMobile = [];

    for (let alternativeOption in alternativeTabsOptions) {
      if (alternativeMaterialsObj[alternativeOption]) {
        let alternativeResult = alternativeMaterialsObj[alternativeOption];

        let classNameDesktop = "detail-related-nav-tab__item";
        let desktopSelected = " detail-related-nav-tab__item--current";
        let classNameMobile = "";

        // apply selected class
        if (activeTab === alternativeOption) {
          classNameDesktop += desktopSelected;
          classNameMobile = "detail-related-nav-selectbox__item--current";
        }

        // id is in translation in upper case
        let translationId = `DETAIL_ALTERNATIVE_${alternativeOption.toUpperCase()}`;
        let tabCategory = ElstrLangStore.text(translationId);

        // see FSP VIII
        if (alternativeOption.includes("MATKL")) {
          const matkl = alternativeOption.split("_")[1];
          tabCategory = Translations.getValue("sMatHdrMatkl", matkl);
        }

        const numbOfResults = `(${alternativeResult.length})`;
        const completeTabTitle = `${tabCategory} ${numbOfResults}`;

        // nav tab desktop - tablet
        tabDesktop.push(
          <li
            className={classNameDesktop}
            key={`${alternativeOption} not-phone`}
            onClick={this.tabChange.bind(this, alternativeOption)}
          >
            {completeTabTitle}
          </li>,
        );

        // nav for phone view
        tabMobile.push(
          <option className={classNameMobile} key={`${alternativeOption} phone`} value={alternativeOption}>
            {completeTabTitle}
          </option>,
        );
      }
    }

    const materialsToRender = alternativeMaterialsObj[activeTab];

    return (
      <div className="detail-related">
        <h2 className="detail-related__title">{ElstrLangStore.text("DETAIL_ALTERNATIVE")}</h2>
        <ul className="detail-related__nav--tab phone-tablet--hidden">{tabDesktop}</ul>
        <div className="detail-related__nav--selectbox desktop--hidden">
          <select
            className="selectbox detail-related-nav__selectbox"
            defaultValue={activeTab}
            onChange={this.selectChange}
          >
            {tabMobile}
          </select>
        </div>
        <div className="detail-related__content-container">
          <div className="detail-related__content">
            <ContainerCompare materialsToRender={materialsToRender} scrollActive={false} />
          </div>
        </div>
      </div>
    );
  },
});

export default DetailAlternative;
