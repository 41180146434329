import React from "react";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import ScrollPositionsStore from "../../stores/ScrollPositionsStore";
import ElstrScrollStore from "elstr-jslib/src/scripts/stores/ElstrScrollStore";

const ItemGridScroll = createReactClass({
  mixins: [ElstrScrollStore.mixin, ScrollPositionsStore.mixin],

  propTypes: {
    scrollActive: PropTypes.bool.isRequired,
  },

  getInitialState: function () {
    let yPos = ScrollPositionsStore.getGridScroll();

    return { yPos };
  },

  storeDidChange: function () {
    if (this.props.scrollActive) {
      let yPos = ElstrScrollStore.getCurrentScroll();

      this.setState({ yPos });
    }
  },

  componentDidMount: function () {
    if (this.props.scrollActive) {
      let yPos = ScrollPositionsStore.getGridScroll();

      if (yPos !== null) {
        window.scrollTo(0, yPos);
      }
    }
  },

  componentWillUnmount: function () {
    if (this.props.scrollActive) {
      let yPos = this.state.yPos;

      ScrollPositionsStore.setGridScroll(yPos);
    }
  },

  render: function () {
    return <i />;
  },
});

export default ItemGridScroll;
