import React from "react";
import BottomStep1 from "./BottomStep1";
import BottomStep2 from "./BottomStep2";
import DossierStore from "../../stores/DossierStore";

export default function DossierCarrierBottom({ step }) {
  function getContent() {
    if (step === 1) {
      return <BottomStep1 />;
    }

    if (step === 2) {
      return <BottomStep2 />;
    }

    return null;
  }

  function getClassName() {
    if (DossierStore.getActiveStep() < step) {
      return "hide-step";
    }

    // for the active step 2 we assign a class which has :hover definitions
    if (DossierStore.getActiveStep() === 2 && step === 2) {
      return "container-common-carrier";
    }

    return "";
  }

  return (
    <div className={getClassName()}>
      <div className="container-common container-common__bottom">
        <div className="container-common-content">
          <div className="container-common-content-placer">{getContent()}</div>
        </div>
      </div>
    </div>
  );
}
