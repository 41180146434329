import ElstrConfigStore from "elstr-jslib/src/scripts/stores/ElstrConfigStore";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import MaterialsStore from "../stores/MaterialsStore";

export const REPORT_FORMAT = {
  EXCEL: "excel",
  HTML: "html",
};

export function getPathProdInfoProdReport(dataRow, reportFormat = REPORT_FORMAT.HTML) {
  if (!ElstrConfigStore.option("zafis")["prodInfo"]["prodReport"]) {
    return "";
  }
  if (!ElstrConfigStore.option("zafis")["werk"][dataRow["werks"]]) {
    return "";
  }
  if (!ElstrConfigStore.option("zafis")["werk"][dataRow["werks"]]["path"]) {
    return "";
  }

  const config = ElstrConfigStore.option("zafis")["prodInfo"]["prodReport"];
  const query = config["query"];
  const path = config["path"];

  return `${path}?${query}&lang=${ElstrLangStore.getCurrentLanguage()}&werk=${dataRow["werks"]}&vonfauf=${
    dataRow["aufnr"]
  }&format=${reportFormat}`;
}

export function getPathProdInfoQualityReport(dataRow, type = REPORT_FORMAT.HTML) {
  if (!ElstrConfigStore.option("zafis")["prodInfo"]["qualityReport"]) {
    return "";
  }
  if (!ElstrConfigStore.option("zafis")["werk"][dataRow["werks"]]["path"]) {
    return "";
  }

  const config = ElstrConfigStore.option("zafis")["prodInfo"]["qualityReport"];
  const query = config["query"];
  const path = ElstrConfigStore.option("zafis")["werk"][dataRow["werks"]]["path"];

  return `${path}?${query}&lang=${ElstrLangStore.getCurrentLanguage()}&werk=${dataRow["werks"]}&vonfauf=${
    dataRow["aufnr"]
  }&format=${type}`;
}

export function getPathProdInfoInspectionPlan(dataRow) {
  if (!ElstrConfigStore.option("zafis")["prodInfo"]["inspectionPlan"]) {
    return "";
  }
  if (!ElstrConfigStore.option("zafis")["werk"][dataRow["werks"]]["path"]) {
    return "";
  }

  const mat = MaterialsStore.getMaterialByNumberOrDefault(dataRow["matnr"]);
  const techNo = mat["sMatHdrZeinr"] ? mat["sMatHdrZeinr"] : "undefinedTechNo";

  const config = ElstrConfigStore.option("zafis")["prodInfo"]["inspectionPlan"];
  const query = config["query"];
  const path = ElstrConfigStore.option("zafis")["werk"][dataRow["werks"]]["path"];

  return (
    `${path}?` +
    `${query}&lang=${ElstrLangStore.getCurrentLanguage()}` +
    `&werk=${dataRow["werks"]}` +
    `&vonfauf=${dataRow["aufnr"]}` +
    `&vonart=${techNo}` +
    `&vonvfer=${dataRow["matnr"]}`
  );
}

export function getPathSalesOrderQualityReport(dataRow, reportFormat = REPORT_FORMAT.HTML) {
  if (!ElstrConfigStore.option("zafis")["salesOrder"]["qualityReport"]) {
    return "";
  }
  if (!ElstrConfigStore.option("zafis")["werk"][dataRow["werks"]]["path"]) {
    return "";
  }

  const config = ElstrConfigStore.option("zafis")["salesOrder"]["qualityReport"];
  const query = config["query"];
  const path = ElstrConfigStore.option("zafis")["werk"][dataRow["werks"]]["path"];

  return `${path}?${query}&lang=${ElstrLangStore.getCurrentLanguage()}&werk=${dataRow["werks"]}&delivsap=${
    dataRow["vbelv"]
  }&vonvfer=${dataRow["matnr"]}&format=${reportFormat}`;
}

export function werkHasServer(dataRow) {
  if (!ElstrConfigStore.option("zafis")["werk"][dataRow["werks"]]) {
    return false;
  }

  if (!ElstrConfigStore.option("zafis")["werk"][dataRow["werks"]]["hasServer"]) {
    return false;
  }

  return ElstrConfigStore.option("zafis")["werk"][dataRow["werks"]]["hasServer"];
}
