import * as Attributes from "../libs/Attributes";
import Translations from "../libs/Translations";
import mcFly from "elstr-jslib/src/scripts/libs/mcFly.js";
import ElstrLog from "elstr-jslib/src/scripts/ElstrLog";
import ElstrEditingStates from "elstr-jslib/src/scripts/constants/ElstrEditingStates";
import ElstrLoadingStates from "elstr-jslib/src/scripts/constants/ElstrLoadingStates";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import ElstrCache from "elstr-jslib/src/scripts/ElstrCache";
import * as UrlHelpers from "../libs/UrlHelpers";
import ElstrUrlHashConstants from "elstr-jslib/src/scripts/constants/ElstrUrlHashConstants";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";
import { isPisysSite } from "../libs/Controllers";
import * as Filters from "../libs/Filters";
import FilterActions from "../actions/FilterActions";
import FilterConstants from "../constants/actions/FilterConstants";
import { diff } from "../libs/Utils";
import MaterialsStore from "./MaterialsStore";
import * as Customer from "../libs/Customer";
import { FILTER, FILTER_TYPE } from "../constants/Filter";

const FilterStore = mcFly.createStore(
  {
    filters: null,
    error: null,
    loadingState: ElstrLoadingStates.EMPTY,
    editingState: ElstrEditingStates.NORMAL,
    autocompleteOpen: null, // will contain the dataAttributeKey of the current active autocomplete box
    counterForUpdate: 0, // updates every time a user changes attributes order or de/activates one (only relevant in list view)

    filteredFromServer: {},

    // getters
    getFilters: function () {
      return this.filters;
    },

    getError: function () {
      return this.error;
    },

    getLoadingState: function () {
      if (this.loadingState === ElstrLoadingStates.EMPTY) {
        let cachedData = this.getFilters();
        if (cachedData) {
          return ElstrLoadingStates.CACHED;
        }
      }
      return this.loadingState;
    },

    getEditingState: function () {
      return this.editingState;
    },

    // used for displaying in filter text, dropdown menus, selected text
    getFilterText: function (key, dataAttributeKey, step) {
      const multilangType = Attributes.getMultilangType(dataAttributeKey);
      const filterType = Filters.getFilterType(dataAttributeKey);
      const valuesFrom = Filters.getValuesFrom(dataAttributeKey);

      // multilangType 4 are special made filters and will not contain their translation in the
      // translations file which is created by the backend
      if (multilangType === 4) {
        return ElstrLangStore.text(`LABEL_${dataAttributeKey}_${key}`);
      }

      let text = Translations.getValue(dataAttributeKey, key) || key;

      // multilangType has they SAP key in the text before the translated text starts
      // key = SO text = Planned product/Dummy -> text = SO Planned product/Dummy
      if (multilangType === 3) {
        text = `${key} ${text}`;
      }

      if (dataAttributeKey === FILTER.DEFECT_TYPE) {
        // this means they are one of the fegrp
        if (key === "100" || key === "200" || key === "300") {
          text = `${text} (${ElstrLangStore.text("HEADER_MAINGROUP_ALL")} ${key})`;
        }
      }

      if (filterType === FILTER_TYPE.LISTBOX_TWO_STEP) {
        text = Filters.getTextTwoStep(dataAttributeKey, key, step);
      }

      if (valuesFrom) {
        text = FilterStore.getFilterText(key, valuesFrom);
      }

      return text;
    },

    getAutocompleteOpen: function () {
      return FilterStore.autocompleteOpen;
    },

    /**
     * Sorts the filter by the current active language in the language specific alphabetic order
     * @return {*[]}
     */
    getFilterSortedByLanguage: function () {
      let currentLanguage = ElstrLangStore.getCurrentLanguage();

      // transform filters to add the translated text
      let _filters = [...this.filters];

      // distinct handling for ecat and pisys filter
      let _onlyPisysFilters = _filters.filter(filter => filter.isPisys);
      let _onlyEcatFilters = _filters.filter(filter => !filter.isPisys);

      // we get the translation text for the pisys labels
      _onlyPisysFilters.forEach((filter, i) => {
        try {
          _onlyPisysFilters[i].transText = Translations.getLabel(filter.dataAttributeKey).toLowerCase() || "";
        } catch (e) {
          _onlyPisysFilters[i].transText = `no label for ${filter.dataAttributeKey} defined`;
        }
      });

      // we sort the pisys filters
      // older browser might now have Intl and therefore won't be sorted
      if (global.Intl) {
        let collator = new Intl.Collator(currentLanguage, { sensitivity: "base" });
        _onlyPisysFilters.sort((a, b) => collator.compare(a.transText, b.transText));
      }

      // now we merge ecat filters (no sorting by label) with sorted pisys filters (according to language)
      let mergedFilter = _onlyEcatFilters.concat(_onlyPisysFilters);
      return mergedFilter;
    },

    getFilterIsLoading: function (dataAttributeKey) {
      if (
        FilterStore.filteredFromServer[dataAttributeKey] &&
        FilterStore.filteredFromServer[dataAttributeKey].loadingState &&
        FilterStore.filteredFromServer[dataAttributeKey].loadingState === ElstrLoadingStates.EMPTY
      ) {
        return true;
      }
      return false;
    },

    // toggles
    toggleDisplayFilter: function (dataAttributeKey) {
      let filtersToDisplay = ElstrCache.get("filtersToDisplay");

      // toggle
      filtersToDisplay[dataAttributeKey] = !filtersToDisplay[dataAttributeKey];

      // update cache
      ElstrCache.set("filtersToDisplay", filtersToDisplay);

      // remove filter url if not displayed anymore
      if (!filtersToDisplay[dataAttributeKey]) UrlHelpers.clearSingleFilter(dataAttributeKey); // triggers emitChange

      FilterStore.emitChange();
    },
    displayAllFilters: function (isPisys) {
      let filtersToDisplay = ElstrCache.get("filtersToDisplay");
      let filtersFromVSC = VETROPACK_STATIC_CACHE.filters;

      // set all to true when filterfromVSC.isPisys equals isPisys
      filtersFromVSC.forEach(filterfromVSC => {
        let dataAttributeKey = filterfromVSC.dataAttributeKey;
        if (filterfromVSC["isPisys"] === isPisys) {
          filtersToDisplay[dataAttributeKey] = true;
        }
      });

      // update cache
      ElstrCache.set("filtersToDisplay", filtersToDisplay);

      FilterStore.emitChange();
    },

    // initialize or upgrade which filters a user will see when he loads the app
    updateUserFilters: function () {
      let filtersToDisplay = ElstrCache.get("filtersToDisplay");
      let filtersFromVSC = VETROPACK_STATIC_CACHE.filters;

      // initialize
      if (filtersToDisplay === undefined) {
        let filtersToDisplay = {};

        filtersFromVSC.forEach(filter => {
          let isDisplayed = true;
          if (filter.isAdditional) isDisplayed = false;
          // in order to show some filters when init the app for the first time (standard ecat filters)
          if (filter.isAdditional && !filter.isPisys) isDisplayed = true;
          filtersToDisplay[filter.dataAttributeKey] = isDisplayed;
        });

        ElstrCache.set("filtersToDisplay", filtersToDisplay);

        // update (contains delete and add)
      } else {
        //
        // will be an obj that contains all dataAttributeKeysFromVSC as key and value
        // ex:
        // {
        // sMatAtvEcatSubgroup: "sMatAtvEcatSubgroup",
        // sMatAtvEinsatzart: "sMatAtvEinsatzart"
        // ... }
        //
        let dataAttributeKeysFromVSC = {};
        filtersFromVSC.forEach(filter => {
          dataAttributeKeysFromVSC[filter.dataAttributeKey] = filter.dataAttributeKey;
        });

        // add filters that are new
        for (let filterKey in dataAttributeKeysFromVSC) {
          // add a new entry when a key from dataAttributeKeysFromVSC which comes from VSC is not in the localStorage filtersToDisplay
          if (filtersToDisplay[filterKey] === undefined) {
            ElstrLog.log("new key: will be added", filterKey);

            filtersFromVSC.forEach(filter => {
              if (filter.dataAttributeKey === filterKey) {
                let isDisplayed = true;
                if (filter.isAdditional) isDisplayed = false;
                // in order to show some filters when init the app for the first time (standard ecat filters)
                if (filter.isAdditional && !filter.isPisys) isDisplayed = true;
                filtersToDisplay[filterKey] = isDisplayed;
              }
            });
          }
        }

        // delete entries that are not existent anymore
        for (let filterKey in filtersToDisplay) {
          if (filterKey !== dataAttributeKeysFromVSC[filterKey]) {
            ElstrLog.log("deleting filter key:", filterKey);
            delete filtersToDisplay[filterKey];
          }
        }

        // update
        ElstrCache.set("filtersToDisplay", filtersToDisplay);
      }

      filtersToDisplay = ElstrCache.get("filtersToDisplay");
      filtersFromVSC.forEach(filter => {
        filter.isDisplayed = filtersToDisplay[filter.dataAttributeKey].isAdditional;
      });

      FilterStore.filters = filtersFromVSC;
    },

    // set which autocomplete window is open
    setAutocompleteOpen: function (dataAttributeKey) {
      let openedItem = FilterStore.getAutocompleteOpen();
      if (openedItem !== dataAttributeKey) {
        FilterStore.autocompleteOpen = dataAttributeKey;
        FilterStore.emitChange();
      }
    },

    resetFilteredFromServer(dataAttributeKey) {
      ElstrLog.info(`resetting: ${dataAttributeKey}`);

      const { filteredFromServer } = FilterStore;
      delete filteredFromServer[dataAttributeKey];
    },

    filterInputsNoChange(dataAttributeKey, filterArrVals, filter_dateFrom, filter_dateTo) {
      const { filteredFromServer } = FilterStore;

      // when it doesn't exist we want to proceed with the RPC
      if (!filteredFromServer[dataAttributeKey]) {
        return false;
      }

      const storedInput = filteredFromServer[dataAttributeKey];

      // check for undefined
      if (!storedInput.filterArrVals) {
        return false;
      }

      if (!storedInput.filterArrVals) {
        return false;
      }

      // compare of the two array inputs
      // when having the same input both length of diff response will be an empty array []
      const compareStoreToCurrentInput = diff(storedInput.filterArrVals, filterArrVals);
      const compareCurrentInputToStore = diff(filterArrVals, storedInput.filterArrVals);

      if (compareStoreToCurrentInput.length !== 0) {
        return false;
      }

      if (compareCurrentInputToStore.length !== 0) {
        return false;
      }

      // check if date inputs have changed
      if (storedInput.filter_dateFrom !== filter_dateFrom) {
        return false;
      }

      if (storedInput.filter_dateTo !== filter_dateTo) {
        return false;
      }

      // nothing has changed and therefore RPC can be aborted
      return true;
    },

    resetByUrl() {
      // get all stored keys
      const dataAttributeKeys = Object.keys(FilterStore.filteredFromServer);

      dataAttributeKeys.forEach(dataAttributeKey => {
        const hashKey = `filter_${dataAttributeKey}`;
        const hashValue = ElstrUrlHashStore.get(hashKey);

        // in case there is no actual value anymore in the url store we therefore reset this stored data here
        if (!hashValue) {
          FilterStore.resetFilteredFromServer(dataAttributeKey);
        }
      });
    },

    createKeyIfMissing: function (dataAttributeKey) {
      const { filteredFromServer } = FilterStore;

      // create empty obj if not exist
      if (!filteredFromServer[dataAttributeKey]) {
        filteredFromServer[dataAttributeKey] = {};
      }
      return filteredFromServer;
    },

    setFilterData(dataAttributeKey, data, timestamp) {
      const filteredFromServer = FilterStore.createKeyIfMissing(dataAttributeKey);

      filteredFromServer[dataAttributeKey].data = data;
      filteredFromServer[dataAttributeKey].timestamp = timestamp;
    },

    setFilterIsLoading(dataAttributeKey) {
      const filteredFromServer = FilterStore.createKeyIfMissing(dataAttributeKey);
      filteredFromServer[dataAttributeKey].loadingState = ElstrLoadingStates.EMPTY;
    },

    setFilterHasLoaded(dataAttributeKey) {
      const filteredFromServer = FilterStore.createKeyIfMissing(dataAttributeKey);
      filteredFromServer[dataAttributeKey].loadingState = ElstrLoadingStates.LOADED;
    },

    setFilterInput(dataAttributeKey, filterArrVals, filter_dateFrom, filter_dateTo) {
      const filteredFromServer = FilterStore.createKeyIfMissing(dataAttributeKey);

      filteredFromServer[dataAttributeKey].filterArrVals = filterArrVals;
      filteredFromServer[dataAttributeKey].filter_dateFrom = filter_dateFrom;
      filteredFromServer[dataAttributeKey].filter_dateTo = filter_dateTo;
      FilterStore.setFilterIsLoading(dataAttributeKey);
    },

    parseDateToISO8601(date) {
      let dd = date.getDate();
      let mm = date.getMonth() + 1; //January is 0!

      let yyyy = date.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }

      if (mm < 10) {
        mm = "0" + mm;
      }

      // ISO 8601 syntax (YYYY-MM-DD)
      return `${yyyy}-${mm}-${dd}`;
    },

    filterByUrl: function () {
      // filters the data in the result view according to the user provided data

      const currentHash = ElstrUrlHashStore.get();
      let { filter_dateFrom, filter_dateTo } = currentHash;

      for (let key in currentHash) {
        if (key.startsWith("filter_")) {
          // remove the filter_ text from they key
          let dataAttributeKey = key.slice(7);

          if (dataAttributeKey === FILTER.CUSTOMER) {
            if (!filter_dateFrom && !filter_dateTo) {
              // fallback case to gather customer response without date as it was before
              // initiating phase v/b

              FilterStore.resetFilteredFromServer(dataAttributeKey);
              FilterStore.setFilterHasLoaded(dataAttributeKey);
              continue;
            }
          }

          let attributeSource = Filters.getAttributeSource(dataAttributeKey);

          // ATTRIBUTE_SOURCE.MATERIALS are all filtered from VSC only
          if (attributeSource !== Filters.ATTRIBUTE_SOURCE.MATERIALS) {
            let filterArrVals;

            // get values according to source
            if (attributeSource === Filters.ATTRIBUTE_SOURCE.CUSTOMERS) {
              filterArrVals = Customer.getFilterValuesForCustomer();
            }

            if (attributeSource === Filters.ATTRIBUTE_SOURCE.TRANSLATIONS) {
              filterArrVals = currentHash[key].split("¦");
            }

            // check here for changed input data, if so, start a new request otherwise leave loop here
            if (FilterStore.filterInputsNoChange(dataAttributeKey, filterArrVals, filter_dateFrom, filter_dateTo)) {
              continue;
            }

            FilterStore.setFilterInput(dataAttributeKey, filterArrVals, filter_dateFrom, filter_dateTo);

            // adjust date values for server request
            // we allow dateFrom or dateTo to be not selected by an user
            if (!filter_dateFrom) {
              filter_dateFrom = null;
            }

            if (!filter_dateTo) {
              filter_dateTo = null;
            }

            // for customers we send all the customer numbers fulfilling the filter criteria
            if (attributeSource === Filters.ATTRIBUTE_SOURCE.CUSTOMERS) {
              filterArrVals = Customer.getAllCustomerNrsInsideFilterVal();
            }

            const filterBy = FilterStore.getFilterByForRequest(dataAttributeKey, filterArrVals);

            // we call the actions to request the filtered results from the server
            // dataAttributeKey is provided so the response can be identified in payload.actionType -> FilterConstants.FILTER_GET_MATERIALS_FROM_SERVER_DID
            const reqName = getMappedRequestName(dataAttributeKey);
            const timestamp = Date.now();

            FilterActions[reqName]({
              filterBy,
              filter_dateFrom,
              filter_dateTo,
              dataAttributeKey,
              timestamp,
            });
          }
        }
      }
    },

    getFilterByForRequest(dataAttributeKey, filterArrVals) {
      if (dataAttributeKey === FILTER.DEFECT_TYPE) {
        const filterBy = {
          fegrp: [],
          fecod: [],
        };

        const fegrp = Translations.getValues("fegrp");
        const fecod = Translations.getValues("fecod");
        const keysFegrp = Object.keys(fegrp);
        const keysFecod = Object.keys(fecod);

        filterArrVals.forEach(val => {
          if (keysFegrp.includes(val)) {
            filterBy.fegrp.push(val);
          }
          if (keysFecod.includes(val)) {
            filterBy.fecod.push(val);
          }
        });

        return filterBy;
      }

      if (dataAttributeKey === FILTER.PRODUCTION) {
        let filterValues = UrlHelpers.getHashArray(`filter_${FILTER.PRODUCTION}`);
        const filterBy = {
          werks: "",
          arbpl: [],
        };

        filterValues.forEach((val, i) => {
          // the first one is the werk, all following are arpl
          if (i === 0) {
            filterBy.werks = val;
          } else {
            filterBy.arbpl.push(val);
          }
        });

        return filterBy;
      }

      return filterArrVals;
    },

    // responsible for firing actions that are filtered from the server
    initiateFilteredFromServer: function () {
      // only needed in pisys
      if (!isPisysSite()) return;

      FilterStore.filterByUrl();
      FilterStore.resetByUrl();
    },
  },
  function (payload) {
    switch (payload.actionType) {
      case ElstrUrlHashConstants.URL_HASH_CHANGE: {
        mcFly.dispatcher.waitFor([ElstrUrlHashStore.dispatcherID]);

        FilterStore.initiateFilteredFromServer();

        break;
      }

      case ElstrUrlHashConstants.URL_ROUTE_CHANGE: {
        mcFly.dispatcher.waitFor([ElstrUrlHashStore.dispatcherID]);

        FilterStore.initiateFilteredFromServer();

        break;
      }

      case FilterConstants.FILTER_GET_MATERIALS_FROM_SERVER_WILL: {
        mcFly.dispatcher.waitFor([ElstrUrlHashStore.dispatcherID]);

        break;
      }

      case FilterConstants.FILTER_GET_MATERIALS_FROM_SERVER_DID: {
        mcFly.dispatcher.waitFor([ElstrUrlHashStore.dispatcherID]);

        // handle standard errors
        const { error, messages } = payload;
        if (error && error !== "") {
          FilterStore.error = error;
          break;
        }

        if (messages && messages !== "" && messages.length > 0) {
          FilterStore.error = messages;
          break;
        }

        const { dataAttributeKey, timestamp } = payload.params;
        const { filteredFromServer } = FilterStore;

        // we received an old response and quit here
        if (
          filteredFromServer[dataAttributeKey] &&
          filteredFromServer[dataAttributeKey].timestamp &&
          filteredFromServer[dataAttributeKey].timestamp > timestamp
        ) {
          break;
        }

        // all ok
        if (payload.data && payload.data.data) {
          const { data } = payload.data;

          // set data according to response
          FilterStore.setFilterData(dataAttributeKey, data, timestamp);
          // start the standard material filter behaviour
          MaterialsStore.startFilterMaterials();

          FilterStore.error = null;
        }

        FilterStore.setFilterHasLoaded(dataAttributeKey);
        FilterStore.emitChange();

        break;
      }
    }

    return true;
  },
);

function getMappedRequestName(key) {
  if (key === FILTER.DEFECT_PRIORITY) {
    return "complaintsPriority";
  }

  if (key === FILTER.DEFECT_TYPE) {
    return "complaintsDefectType";
  }

  if (key === FILTER.DEFECT_LOCATION) {
    return "complaintsDefectLocation";
  }

  if (key === FILTER.PRODUCTION) {
    return "production";
  }

  return key;
}

export default FilterStore;
