import React, { useState } from "react";
import PrintLabelStore from "../../stores/PrintLabelStore";
import { preventNonNumeric } from "../../libs/Numbers";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";

export default function Top({ sMatHdrMatnrInHash }) {
  const [valueOfNrOfLabels, setValueOfNrOfLabels] = useState(0);
  const minDefaultNrOfLabels = 1;

  // not rendering the top component when entering from detail page
  if (sMatHdrMatnrInHash) {
    return null;
  }

  function onChange(e) {
    const { value } = e.target;
    const parsedValue = Number.parseInt(value);

    if (isNaN(parsedValue)) {
      setValueOfNrOfLabels(0);
      PrintLabelStore.setDefaultNrOfLabels(minDefaultNrOfLabels);
      return;
    }

    if (parsedValue < minDefaultNrOfLabels) {
      setValueOfNrOfLabels(minDefaultNrOfLabels);
      PrintLabelStore.setDefaultNrOfLabels(minDefaultNrOfLabels);
      return;
    }

    setValueOfNrOfLabels(parsedValue);
    PrintLabelStore.setDefaultNrOfLabels(parsedValue);
  }

  const label = `${ElstrLangStore.text("PRINT_LABEL_DEFAULT_NUMBER_OF_LABELS")}:`;

  return (
    <div className="container-common container-common__top">
      <div className="container-common-content">
        <div className="container-common-content-placer">
          <span>
            <label className="label-print-labels">{label}</label>
            <input
              type="number"
              className="input-default-number-labels"
              value={valueOfNrOfLabels || ""}
              placeholder={1}
              step={1}
              onKeyPress={preventNonNumeric}
              onChange={onChange}
            />
          </span>
        </div>
      </div>
    </div>
  );
}
