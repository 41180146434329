import * as Attributes from "../libs/Attributes";
import Translations from "../libs/Translations";
import Controllers from "../libs/Controllers";
import * as Filters from "../libs/Filters";
import { isViewDossier, updateHashFromFilterUpdate } from "../libs/UrlHelpers";
import mcFly from "elstr-jslib/src/scripts/libs/mcFly.js";
import ElstrConfigStore from "elstr-jslib/src/scripts/stores/ElstrConfigStore";
import query from "array-query";
import ElstrUrlHashConstants from "elstr-jslib/src/scripts/constants/ElstrUrlHashConstants";
import ElstrLangConstants from "elstr-jslib/src/scripts/constants/ElstrLangConstants";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import ElstrEditingStates from "elstr-jslib/src/scripts/constants/ElstrEditingStates";
import ElstrLoadingStates from "elstr-jslib/src/scripts/constants/ElstrLoadingStates";
import FilterStore from "./FilterStore";
import * as Customer from "../libs/Customer";

const MaterialsStore = mcFly.createStore(
  {
    materialsByMatnr: {},

    filteredMaterialsResult: [],
    // TODO: filteredMaterialsResultNotSpliced can probably be removed by not using .splice() in the render method and instead using .slice()
    filteredMaterialsResultNotSpliced: [], // copy of filteredMaterialsResult before it gets spliced, which removes results in ContainerResultMaterials to limit the number of rendered items
    filteredMaterialsCatAndMain: [], // materials filtered with criteria catalogue and mainGroup
    filteredMaterialsRandomIntro: [], // materials filtered with criteria catalogue and mainGroup

    filteredMaterialsCatAndMainSums: {},
    filteredMaterialsCatAndMainRanges: {},

    alternativeMaterials: [], // filtered with the criteria for alternative (see config.ini and FSP)
    numberOfResults: 0,
    avoidInfiniteLoop: 0,

    cataloguePrevious: null,
    mainGroupPrevious: null,

    error: null,
    loadingState: ElstrLoadingStates.EMPTY,
    editingState: ElstrEditingStates.NORMAL,

    init: () => {
      // Filter the materials for criteria catalogue and mainGroup
      MaterialsStore.filterCatalogueAndMain();

      // Sort by key Value pair for matnr
      const materials = MaterialsStore.getMaterials();
      materials.forEach(material => {
        MaterialsStore.materialsByMatnr[material.sMatHdrMatnr] = material;
      });
    },

    // GETTERS
    getMaterials: () => {
      return VETROPACK_STATIC_CACHE.materials;
    },

    getMaterialByMatnr: sMatHdrMatnr => {
      return MaterialsStore.materialsByMatnr[sMatHdrMatnr];
    },

    getEditingState: function () {
      return this.editingState;
    },

    getFilteredMaterialsResult: function () {
      return this.filteredMaterialsResult;
    },

    getFilteredMaterialsResultNotSpliced: function () {
      return this.filteredMaterialsResultNotSpliced;
    },

    getFilteredMaterialResultNumbers: () => {
      let allFilteredMaterials = MaterialsStore.getFilteredMaterialsResultNotSpliced();
      return allFilteredMaterials.map(mat => mat.sMatHdrMatnr);
    },

    getAlternativeMaterials: function () {
      return this.alternativeMaterials;
    },

    getMaterialByNumberOrDefault: function (number) {
      let sMatHdrMatnr = number || ElstrUrlHashStore.getRouteParam("sMatHdrMatnr");
      return MaterialsStore.getMaterialByMatnr(sMatHdrMatnr) || {};
    },

    // filtered materials for catalogue and mainGroup
    getFilteredMainMaterials: function () {
      return MaterialsStore.filteredMaterialsCatAndMain;
    },

    getNumberOfResults: function () {
      return this.numberOfResults;
    },

    getImagePath: function (imgKey, resultView) {
      // path and name for the fallback / placeholder image
      let imgPath = LIBS.appVersion + "/assets/img/";
      let imgName = `placeholder-${resultView}.jpg`;

      // if there is an imgNumber for the selected article, the path and name will be overwritten
      let imgNumber = VETROPACK_STATIC_CACHE.images[imgKey];
      if (imgNumber) {
        let cacheFolder = ElstrConfigStore.option("staticCacheFolder");
        imgName = `${imgKey}-${resultView}_${imgNumber}.jpg`;
        imgPath = `${cacheFolder}/img/`;
      }

      // return the full path together with path/artilcenum.jpg
      return `${imgPath}${imgName}`;
    },

    getMinMaxRange: function (dataAttributeKey) {
      // The value might be cached
      if (!MaterialsStore.filteredMaterialsCatAndMainRanges[dataAttributeKey]) {
        // http://stackoverflow.com/a/8864464
        // let min = Number.POSITIVE_INFINITY
        // let max = Number.NEGATIVE_INFINITY
        let min = 1000000;
        let max = -1000000;

        let filteredMainMaterials = MaterialsStore.getFilteredMainMaterials();

        // find min and max from all
        filteredMainMaterials.forEach(material => {
          let val = material[dataAttributeKey];
          if (val !== null && !isNaN(val)) {
            // null values shall be ignored
            if (val < min) min = val;
            if (val > max) max = val;
          }
        });

        MaterialsStore.filteredMaterialsCatAndMainRanges[dataAttributeKey] = [min, max];
      }

      return MaterialsStore.filteredMaterialsCatAndMainRanges[dataAttributeKey];
    },

    /**
     *
     * @param arrOfObj the array of obj responsible for getting the sum
     * @param key a dataAttributeKe
     * @returns {{}}
     */
    getSumsOfAttributeValueFiltersInnerLogic: (arrOfObj, key) => {
      // First we classify the objects into groups
      let clasifiedObjects = {};

      for (let i = 0; i < arrOfObj.length; i++) {
        let obj = arrOfObj[i];
        let content = obj[key];

        if (content !== "null" && content !== null) {
          // special handling for array and if the array has length > 1
          if (Object.prototype.toString.call(content) === "[object Array]" && content.length > 1) {
            content.forEach(value => {
              if (!clasifiedObjects[value]) clasifiedObjects[value] = [];
              clasifiedObjects[value].push(obj);
            });
          } else {
            if (!clasifiedObjects[content]) clasifiedObjects[content] = [];
            clasifiedObjects[content].push(obj);
          }
        }
      }

      // If Pisys we remove the duplicates
      let isPisysSite = ElstrConfigStore.option("isPisysSite");
      if (!isPisysSite) {
        for (let category in clasifiedObjects) {
          clasifiedObjects[category] = MaterialsStore.filterSameZVGB(clasifiedObjects[category]);
        }
      }

      // We return the totals
      let sum = {};
      for (let category in clasifiedObjects) {
        sum[category] = clasifiedObjects[category].length;
      }

      return sum;
    },

    /**
     * returns an object with the sum of the how many times the provided key has occourded in the the array of main materials
     *
     * this is helpful for a key like sMatAtvMatnrZvgb, where we want to know if there is more than one obj with this key
     * and how many times. sMatAtvMatnrZvgb with the sum of only 1 can be rendered every time while
     * sMatAtvMatnrZvgb > 1 shouldn't be posted everytime in catalogue
     *
     * ex: sumsOfKey('sMatAtvMatnrZvgb', allMaterials) returns
     * {"1020302": 1, "239292": 2, "123232": 1, ...}
     * where the key here is "sMatAtvMatnrZvgb" and the value the sum
     * http://stackoverflow.com/questions/6857468/converting-a-js-object-to-an-array
     * @param key
     * @returns {*}
     */
    getSumsOfAttributeValueFilters: function (key) {
      // No sums in this special case
      if (Controllers.caseEcatSubgroupMainGroupAll(key)) return { NONE: 0 };

      // The value might be cached
      if (!MaterialsStore.filteredMaterialsCatAndMainSums[key]) {
        let arrOfObj = this.getFilteredMainMaterials();
        let sum = this.getSumsOfAttributeValueFiltersInnerLogic(arrOfObj, key);
        MaterialsStore.filteredMaterialsCatAndMainSums[key] = sum;
      }

      return MaterialsStore.filteredMaterialsCatAndMainSums[key];
    },

    /**
     * when representing the number in the filter check box
     * the empty filter settings might are applied
     * and therefore then length of the materials will be different
     *
     * @param key
     * @returns {*}
     */
    getSumsOfAttributeValueFiltersForShowingAsNumberInCheckbox: function (key) {
      let arrOfObj = this.getFilteredMainMaterials();
      arrOfObj = this.filterEmptyFilterSettings(arrOfObj);
      return this.getSumsOfAttributeValueFiltersInnerLogic(arrOfObj, key);
    },

    getFilteredMaterialsRandomIntro: function () {
      return MaterialsStore.filteredMaterialsRandomIntro;
    },

    getHeaderTextWithUnit: function (dataAttributeKey, unit) {
      let headerName = Translations.getLabel(dataAttributeKey) || "";
      if (unit && unit !== "") {
        // try to translate the unit. it might exist a translation for it
        unit = ElstrLangStore.text(unit);
        headerName = `${headerName} [${unit}]`;
      }

      return headerName;
    },

    /** Returns the content for a valueColumn in list / compare / detail
     *
     * @param dataAttributeKey ex: sMatAtvFuellvoll
     * @param materialValue ex: EINWEG
     * @param useUnit boolean ex: in XlsxExport we won't show the unit in the cell
     * @returns {string}
     */
    getValueColumn: function (dataAttributeKey, materialValue, useUnit = true) {
      const multilangType = Attributes.getMultilangType(dataAttributeKey);

      const unitFromAttributes = Attributes.getUnit(dataAttributeKey);
      // try to translate the unit. it might exist a translation for it
      const unit = useUnit ? ElstrLangStore.text(unitFromAttributes) : "";

      // fill valueColumn
      let valueColumn = "";

      // set to def value when materialValue !== null
      if (materialValue !== null) {
        if (unit !== "") {
          valueColumn = `${materialValue} ${unit}`; // default valueColumn
        } else {
          valueColumn = materialValue; // default valueColumn
        }
      }

      // set valueColumn to date when the matieralDataAttributeKey is a ISO_8601 date
      let re = /(\d{4})-(\d{2})-(\d{2})T(\d{2})\:(\d{2})\:(\d{2})[+-](\d{2})\:(\d{2})/;
      if (re.test(materialValue)) {
        let d = new Date(materialValue);
        // valueColumn = `${d.getDate()} . ${(d.getMonth() + 1)} . ${d.getFullYear()}`;
        valueColumn = `${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}`;
      }

      // in case of multilangTyp is 1 or 3 and the key exists in the translation file, the content will be overwritten
      if (multilangType === 1 || multilangType === 3) {
        valueColumn = "";

        // multilang type 3 will set the materialValue in forefront ex: 00004 SGF2 - Beer for Product Hierarchy Level 1
        if (multilangType === 3) {
          valueColumn = `${materialValue} `;
        }

        // special case for array
        if (Object.prototype.toString.call(materialValue) === "[object Array]") {
          materialValue.forEach((keyValue, i) => {
            if (i !== 0) valueColumn += `, `; // add coma and space if more than one value in the array
            valueColumn += `${Translations.getValue(dataAttributeKey, keyValue) || ""}`;
          });

          // default case if not array
        } else {
          valueColumn += `${Translations.getValue(dataAttributeKey, materialValue) || ""}`;
        }
        // add unit in the end
        valueColumn = `${valueColumn} ${unit}`;
      }

      if (multilangType === 4) {
        valueColumn = ElstrLangStore.text(`LABEL_${dataAttributeKey}_${materialValue}`);
      }

      return valueColumn;
    },

    /**
     * To find out that no matter what sortByKey and orderByDirection is the ZVGBs are next to each other
     * @param dossierSort
     * @returns {boolean}
     * @constructor
     */
    ZVGB_notNextToEachOther: function (dossierSort) {
      let lastZVGB = "";
      let listOfZVGB = [];
      let result = false;

      // perf opt to skip for huge materials
      if (dossierSort.length > 800) {
        return false;
      }

      // make sure that app doesn't break
      if (MaterialsStore.avoidInfiniteLoop > 10000) {
        return false;
      }

      dossierSort.some(material => {
        let sMatAtvMatnrZvgb = material.sMatAtvMatnrZvgb;

        // if test passes it means the sMatAtvMatnrZvgb are not next to each other
        if (listOfZVGB.includes(sMatAtvMatnrZvgb)) {
          if (lastZVGB !== sMatAtvMatnrZvgb) {
            result = true;
            return true;
          }
        }

        // if it is the first time in the list we push it and assign lastZVGB to compare next time
        if (!listOfZVGB.includes(sMatAtvMatnrZvgb)) {
          listOfZVGB.push(sMatAtvMatnrZvgb);
        }
        lastZVGB = sMatAtvMatnrZvgb;

        return false;
      });

      MaterialsStore.avoidInfiniteLoop++;

      return result;
    },

    /**
     * To place ZVGB that are the same next to each other
     * @param dossierSort
     * @returns {*}
     */
    switchSameZVGB: function (dossierSort) {
      let lastZVGB = "";
      let listOfZVGB = [];
      let indexOFZVGB = {};
      let indexOFZVGBToSwitchFrom = 0;
      let indexOFZVGBToSwitchTo = 0;
      let needToSwitch = false;

      dossierSort.some((material, i) => {
        let sMatAtvMatnrZvgb = material.sMatAtvMatnrZvgb;

        // if test passes it means the sMatAtvMatnrZvgb are not next to each other
        if (listOfZVGB.includes(sMatAtvMatnrZvgb)) {
          if (lastZVGB !== sMatAtvMatnrZvgb) {
            needToSwitch = true;
            indexOFZVGBToSwitchFrom = i;
            // push to one after (+ 1) the same ZVGB position
            indexOFZVGBToSwitchTo = indexOFZVGB[sMatAtvMatnrZvgb] + 1;
            return true;
          }
        }

        // if it is the first time in the list we push it and assign lastZVGB to compare next time
        if (!listOfZVGB.includes(sMatAtvMatnrZvgb)) {
          listOfZVGB.push(sMatAtvMatnrZvgb);
        }
        lastZVGB = sMatAtvMatnrZvgb;
        indexOFZVGB[sMatAtvMatnrZvgb] = i;

        return false;
      });

      if (needToSwitch) {
        var tmp = dossierSort[indexOFZVGBToSwitchTo];
        dossierSort[indexOFZVGBToSwitchTo] = dossierSort[indexOFZVGBToSwitchFrom];
        dossierSort[indexOFZVGBToSwitchFrom] = tmp;
      }

      return dossierSort;
    },

    // SORT AND FILTER METHODS
    sortAndOrderMaterials: function (materialsToSortAndOrder) {
      let sortAndOrderQuery = query();

      // sort bottles according to options
      let sortByKey = ElstrUrlHashStore.get("sortByKey");
      let orderByDirection = ElstrUrlHashStore.get("orderByDirection") || "ASC";

      // if there exist a sortByKey, this sorting value will be applied first
      if (sortByKey) {
        sortAndOrderQuery.sort(sortByKey);
        if (orderByDirection === "ASC") {
          sortAndOrderQuery.asc();
        } else if (orderByDirection === "DESC") {
          sortAndOrderQuery.desc();
        }
      }

      // following sort options are applied only if they are not explicit sorted by the user
      // the order and attribute type is defined by the FSP
      if (sortByKey !== "sMatAtvEcatSubgroup") {
        sortAndOrderQuery.sort("sMatAtvEcatSubgroup");
      }

      if (sortByKey !== "sMatAtvUmriss") {
        sortAndOrderQuery.sort("sMatAtvUmriss");
      }

      if (sortByKey !== "sMatAtvFuellvoll") {
        sortAndOrderQuery.sort("sMatAtvFuellvoll");
      }

      if (sortByKey !== "sMatAtvMuendungsgruppe") {
        sortAndOrderQuery.sort("sMatAtvMuendungsgruppe");
      }

      if (sortByKey !== "sMatAtvFarbe") {
        sortAndOrderQuery.sort("sMatAtvFarbe");
      }

      if (sortByKey !== "sMatHdrMatnr") {
        sortAndOrderQuery.sort("sMatHdrMatnr");
      }

      // overwrite for dossier view
      // we want to apply the above sortByKey and orderByDirection
      // but also we need to group each ZVGB next to each other
      // depending on which sortByKey is used (ex MATNR) the ZVGB next to each other is not guaranteed
      // therefore we will further sort / switch materials until we have the best of the sortByKey
      // and ZVGB next to each other
      if (isViewDossier()) {
        let dossierSort = sortAndOrderQuery.on(materialsToSortAndOrder);

        // as long as not all ZVGB are next to each other we will switchZVGBs
        while (MaterialsStore.ZVGB_notNextToEachOther(dossierSort)) {
          dossierSort = MaterialsStore.switchSameZVGB(dossierSort);
        }

        MaterialsStore.avoidInfiniteLoop = 0;

        return dossierSort;
      }

      return sortAndOrderQuery.on(materialsToSortAndOrder);
    },

    filterCatalogueAndMain: function (catalogue = "global", mainGroup = "all") {
      let allMaterials = VETROPACK_STATIC_CACHE.materials;

      let filterQuery = query.select(allMaterials).where("sMatAtvKatalog");

      // catalogue filter
      if (catalogue !== "global") {
        filterQuery.has(catalogue);
      } else {
        filterQuery.not();
        filterQuery.is(undefined);
      }

      let addQuery = query("sMatAtvEcatMaingroup");

      if (mainGroup !== "all") {
        addQuery.is(mainGroup);
      } else {
        addQuery.not();
        addQuery.is(undefined);
      }
      filterQuery.and(addQuery);

      let filteredMaterialsMain = filterQuery.end();

      MaterialsStore.filteredMaterialsCatAndMain = filteredMaterialsMain;
      MaterialsStore.filteredMaterialsCatAndMainSums = {};
      MaterialsStore.filteredMaterialsCatAndMainRanges = {};
    },

    // returns the bottles filtered with the sameZVGB criteria
    filterSameZVGB: function (materialsToFilter) {
      // can be different for intro and catalogue (typically catalogue = lowestZfer and intro = newestMstae)
      let uniteSameZvgb = ElstrConfigStore.option("uniteSameZvgb");

      // 1) sort according to uniteSameZvgb criteria
      if (uniteSameZvgb === "lowestZfer") {
        // sort sMatHdrMatnr asc (which is default in the library)
        materialsToFilter = query().sort("sMatHdrMatnr").asc().on(materialsToFilter);
      } else if (uniteSameZvgb === "newestMstae") {
        // sort with the date desc
        materialsToFilter = query().sort("sMatHdrMstde").desc().on(materialsToFilter);
      }

      // 2) get sumsOfAttributeZvgb
      let duplicatedAttributeZvgb = {};

      let uniqueZvgbMaterials = [];
      materialsToFilter.forEach(material => {
        let sMatAtvMatnrZvgb = material["sMatAtvMatnrZvgb"];

        if (!duplicatedAttributeZvgb[sMatAtvMatnrZvgb]) {
          duplicatedAttributeZvgb[sMatAtvMatnrZvgb] = true;
          uniqueZvgbMaterials.push(material);
        }
      });
      return uniqueZvgbMaterials;
    },

    filterAlternativeMaterials: function () {
      // get the current material object according to the url
      let sMatHdrMatnr = ElstrUrlHashStore.getRouteParam("sMatHdrMatnr");
      let compareMaterial = MaterialsStore.getMaterialByNumberOrDefault(sMatHdrMatnr);

      // load config from config.ini
      let alternativeTabsConfig = ElstrConfigStore.option("alternative");

      let results = {};
      for (let alternative in alternativeTabsConfig) {
        let alternativeFilterOptions = alternativeTabsConfig[alternative];

        // assign the equals and not equals arrays
        let equalOptions = alternativeFilterOptions["eq"];
        let notEqualOptions = alternativeFilterOptions["ne"];

        // start query
        let allMaterials = VETROPACK_STATIC_CACHE.materials;
        let queryAlternative = query.select(allMaterials);

        // start equals options
        equalOptions.forEach((option, i) => {
          if (i === 0) {
            // special handling for the first one
            queryAlternative.where(option).is(compareMaterial[option]);
          } else {
            queryAlternative.and(option).is(compareMaterial[option]);
          }
        });

        // start not equals options
        notEqualOptions.forEach(option => {
          queryAlternative.and(option).not().is(compareMaterial[option]);
        });

        // see FSP VIII
        if (alternative.includes("BASED_ON")) {
          // abort based on when already on a detail of a based on material
          if (compareMaterial["sMatHdrMatkl"] === "FER-PRODU") {
            continue;
          }
          queryAlternative.and("sMatHdrMatkl").is("FER-PRODU");
        }

        // see FSP VIII
        // matkl could be for example FER-DECOR, FER-VIPG and maybe more in the future
        if (alternative.includes("MATKL")) {
          const matkl = alternative.split("_")[1];
          // abort when tab option and compareMaterial are the same
          if (compareMaterial["sMatHdrMatkl"] === matkl) {
            continue;
          }
          queryAlternative.and("sMatHdrMatkl").is(matkl);
        }

        // apply the query filter
        let alternativeItems = queryAlternative.end();

        // in case the alternate tab is not PACKAGING and it's not the pisys site (so it's eCat)
        // the filterSameZVGB has not to be applied
        // according to FSP 4.9.9
        let isPisysSite = ElstrConfigStore.option("isPisysSite");
        if (!isPisysSite && alternative !== "PACKAGING") {
          alternativeItems = MaterialsStore.filterSameZVGB(alternativeItems);
        }

        // sort according to applied criterias
        alternativeItems = MaterialsStore.sortAndOrderMaterials(alternativeItems);

        // only push to results, when there are alternativeItems found
        if (alternativeItems.length > 0) {
          // push this object to the results
          results[alternative] = alternativeItems;
        }
      }

      // Store
      MaterialsStore.alternativeMaterials = results;

      // apply filter return
      return results;
    },

    /**
     * currently a filter can only be inactive in ecat and not in pisys site
     * in the case a user switched from pisys site to ecat site
     * while having active pisys filters
     * the active pisys filters shouldn't not be counted
     * @param dataAttributeKey
     * @returns {boolean}
     */
    isFilterIsInactive: function (dataAttributeKey) {
      let isPisysSite = ElstrConfigStore.option("isPisysSite");
      if (!isPisysSite) {
        return Filters.getFilterType(dataAttributeKey) === "inactive";
      } else {
        return false;
      }
    },

    filterFromServerResponse: function (materialsToFilter) {
      for (let key in FilterStore.filteredFromServer) {
        if (FilterStore.filteredFromServer.hasOwnProperty(key) && FilterStore.filteredFromServer[key].data) {
          let _filterResults = FilterStore.filteredFromServer[key].data;

          // overwrite with only included materials from the filter results
          materialsToFilter = materialsToFilter.filter(material => _filterResults.includes(material.sMatHdrMatnr));
        }
      }
      return materialsToFilter;
    },

    filterFromUserFilters: function (materialsToFilter) {
      // filters the data in the result view according to the user provided data
      let currentHash = ElstrUrlHashStore.get();

      // in certain cases we want to remove hashes from the url so the filter values also don't apply
      // ex1) when on mainGroup Beer and having filter filter_sMatAtvEcatSubgroup=BE_LONG_NECK and switching to Wine we want to remove it
      // ex2) when having a range filter from 1-6000 and then switching the mainGroup where the maximum is 4000 we remove the 6000
      let anyFilterToUpdate = false;

      for (let key in currentHash) {
        // only for attributes who contain  the 'filter_' string **/
        if (key.startsWith("filter_")) {
          // remove the filter_ text from they key **/
          let dataAttributeKey = key.slice(7);
          let filterType = Filters.getFilterType(dataAttributeKey);
          let attributeSource = Filters.getAttributeSource(dataAttributeKey);

          // now the filter criteria are getting applied according to the filterType of the filter
          // attributeSource was added in phase V and Filters.ATTRIBUTE_SOURCE.MATERIALS
          // is the classical way filtering with data from VSC.MATERIALS
          // typeof undefined as fallback mechanism
          if (attributeSource === Filters.ATTRIBUTE_SOURCE.MATERIALS || typeof attributeSource === "undefined") {
            // case for an array filterType
            if (
              filterType === "color" ||
              filterType === "subgroup" ||
              filterType === "listbox" ||
              filterType === "checkbox"
            ) {
              // begin of the additional query to apply
              let addQuery = query(dataAttributeKey);

              let filterArrVals = currentHash[key].split("¦");
              // the query that gets added at the end

              filterArrVals.forEach((filterVal, i) => {
                filterVal = MaterialsStore.convertValueIfNecessary(dataAttributeKey, filterVal);

                if (i === 0) {
                  /** the first one has to be added this way for the lib array-query to work **/
                  addQuery.has(filterVal);
                } else {
                  /** other values are added this way **/
                  addQuery.or(dataAttributeKey);
                  addQuery.has(filterVal);
                }
              });
              // apply filter
              materialsToFilter = addQuery.on(materialsToFilter);

              let sumsOfAttribute = MaterialsStore.getSumsOfAttributeValueFilters(dataAttributeKey);
              for (let i in filterArrVals) {
                let filterName = filterArrVals[i];

                if (!(sumsOfAttribute[filterName] > 0)) {
                  filterArrVals.splice(i, 1);

                  if (filterArrVals.length > 0) {
                    currentHash["filter_" + dataAttributeKey] = filterArrVals.join("¦");
                  } else {
                    delete currentHash["filter_" + dataAttributeKey];
                  }

                  anyFilterToUpdate = true;
                }
              }
            }

            // case for an single value
            if (filterType === "radiobox") {
              // begin of the additional query to apply
              let addQuery = query(dataAttributeKey);

              let filterVal = currentHash[key];
              filterVal = MaterialsStore.convertValueIfNecessary(dataAttributeKey, filterVal);
              addQuery.is(filterVal);
              materialsToFilter = addQuery.on(materialsToFilter);

              let sumsOfAttribute = MaterialsStore.getSumsOfAttributeValueFilters(dataAttributeKey);

              if (!(sumsOfAttribute[filterVal] > 0)) {
                delete currentHash[key];
                anyFilterToUpdate = true;
              }
            }

            // case for an slider/range
            if (filterType === "slider") {
              let filterSliderVals = currentHash[key].split("-");

              let minSlider = filterSliderVals[0];
              let maxSlider = filterSliderVals[1];

              let addQuery = query(dataAttributeKey);
              if (minSlider !== "" && maxSlider !== "") {
                // begin of the additional query to apply
                addQuery.gte(parseFloat(minSlider));
                addQuery.and(dataAttributeKey);
                addQuery.lte(parseFloat(maxSlider));
              } else if (minSlider !== "") {
                addQuery.gte(parseFloat(minSlider));
              } else if (maxSlider !== "") {
                addQuery.lte(parseFloat(maxSlider));
              } else {
                addQuery = null;
              }

              // the query that gets added at the end

              let range = MaterialsStore.getMinMaxRange(dataAttributeKey);
              let min = range[0];
              let max = range[1];
              let toUpdate = false;

              if (parseFloat(minSlider) < min) {
                minSlider = "";
                toUpdate = true;
              }
              if (parseFloat(maxSlider) > max) {
                maxSlider = "";
                toUpdate = true;
              }
              if (toUpdate) {
                if (minSlider !== "" || maxSlider !== "") {
                  currentHash[key] = minSlider + "-" + maxSlider;
                } else {
                  delete currentHash[key];
                }
                anyFilterToUpdate = true;
              }

              // apply filter (If any)
              if (addQuery !== null) {
                materialsToFilter = addQuery.on(materialsToFilter);
              }
            }

            // case for an searchbox
            if (filterType === "searchbox") {
              let filterVal = currentHash[key];

              materialsToFilter = MaterialsStore.filterSearchBox(filterVal, dataAttributeKey, materialsToFilter);
            }

            // case for the searchbox at the top (which searches for art numbers or names)
            if (filterType === "searchFor") {
              let searchVal = currentHash[key];

              materialsToFilter = MaterialsStore.filterSearchFor(searchVal, materialsToFilter);
            }
          }

          // The attribute data source comes customers and the filtered results
          // will be defined from the response of a server request or from cache
          if (attributeSource === Filters.ATTRIBUTE_SOURCE.CUSTOMERS) {
            if (filterType === "searchbox") {
              materialsToFilter = MaterialsStore.filterSearchBoxCustomers(materialsToFilter);
            }
          }
        }
      }

      if (anyFilterToUpdate) {
        updateHashFromFilterUpdate(currentHash);
      }

      materialsToFilter = MaterialsStore.filterFromServerResponse(materialsToFilter);

      return materialsToFilter;
    },

    // this function should be only called from the intro app

    filterIntroPage: function () {
      // will contain already all new materials since intro.app loads a different file (see app initialization)
      let allNewMaterials = VETROPACK_STATIC_CACHE.materials;

      let isPisysSite = ElstrConfigStore.option("isPisysSite");
      if (!isPisysSite) {
        allNewMaterials = MaterialsStore.filterSameZVGB(allNewMaterials);
      }

      let materialsIntroPage = [];
      let neededElements = 3;

      // overwrite the number of random items to get if there less than 3 new materials
      if (allNewMaterials.length < 3) neededElements = allNewMaterials.length;

      let pushedPos = []; // will include numbers that have already been pushed
      let randomNum;
      for (let i = 0; i < neededElements; i++) {
        let noNewNumber = true;
        while (noNewNumber) {
          // get a random num
          // http://stackoverflow.com/a/19269682
          randomNum = Math.floor(Math.random() * allNewMaterials.length);

          // if the number has not been pushed
          if (!pushedPos.includes(randomNum)) {
            // push to already selected random numbers
            pushedPos.push(randomNum);
            // push to returning materialsIntroPage
            materialsIntroPage.push(allNewMaterials[randomNum]);
            // to break the while loop
            noNewNumber = false;
          }
        }
      }

      MaterialsStore.filteredMaterialsRandomIntro = materialsIntroPage;
    },

    escapeRegExp(searchVal) {
      // https://stackoverflow.com/a/9310752/6138965
      // without escaping the "star"-char * sine it is used as a wildcard
      return searchVal.replace(/[-[\]{}()+?.,\\^$|#\s]/g, "\\$&");
    },

    replaceWildcardChar(searchVal) {
      // by replacing * with . a user might use the * as a wildcard char to match any
      return searchVal.replace(/[*]/g, ".");
    },

    filterWithRegexPattern: function (filterVal, dataAttributeKey, materialsToFilter) {
      let searchValToLowerCase = filterVal.toLowerCase();

      // some characters ex: "." needs to be escaped for the regex statement
      // ex regex: search for "burg." since article 10409 contains a point "."
      // put searchVal to lower case
      searchValToLowerCase = MaterialsStore.escapeRegExp(searchValToLowerCase);
      searchValToLowerCase = MaterialsStore.replaceWildcardChar(searchValToLowerCase);

      // ex regex: search for burg. ne regex -> (burg\. ne)
      let regexQuery = `${searchValToLowerCase}`;
      regexQuery = new RegExp(regexQuery);

      dataAttributeKey = Attributes.getRealDataAttributeKey(dataAttributeKey);

      // set the materials to search in lower case for comparing
      // this step is done this way to not alter the original data
      let materialsToFilterInLowercase = [];
      materialsToFilter.forEach(material => {
        // for undefined handling
        if (material[dataAttributeKey]) {
          let valueOfDataAttributeKey = material[dataAttributeKey];
          if (isNaN(material[dataAttributeKey])) {
            valueOfDataAttributeKey = material[dataAttributeKey].toLowerCase();
          }

          materialsToFilterInLowercase.push({
            [dataAttributeKey]: valueOfDataAttributeKey,
            sMatHdrMatnr: material["sMatHdrMatnr"],
          });
        }
      });

      // filter the Materials
      let filteredMaterials = query(dataAttributeKey).regex(regexQuery).on(materialsToFilterInLowercase);
      // push array with every sMatHdrMatnr
      let sMatHdrMatnr = filteredMaterials.map(material => {
        return material.sMatHdrMatnr;
      });

      // get from the filteredMaterials the ones that are in the sMatHdrMatnr array
      materialsToFilter = query("sMatHdrMatnr").within(sMatHdrMatnr).on(materialsToFilter);

      return materialsToFilter;
    },

    filterMultipleZfer: function (searchVal, materials) {
      let valuesToCheck = searchVal.split(" ");
      // provide only unique numbers
      let numbers = valuesToCheck.map(number => number);
      numbers = [...new Set([...numbers])];

      // begin of the additional query to apply
      let addQuery = query("sMatHdrMatnr");
      numbers.forEach((number, i) => {
        if (i === 0) {
          // the first one has to be added this way for the lib array-query to work
          addQuery.is(number);
        } else {
          // other values are added this way
          addQuery.or("sMatHdrMatnr");
          addQuery.is(number);
        }
      });
      materials = addQuery.on(materials);
      return materials;
    },

    isSequenceOfMatnr(searchVal) {
      // In case a user entered a sequence of numbers separated by space we will provide him these
      // They must be all numbers each and are separated by an empty space ex: 19437 22092 319390
      let valuesToCheck = searchVal.split(" ");
      if (valuesToCheck.length > 1) {
        let allChecksPassed = true;
        valuesToCheck.forEach(value => {
          if (isNaN(Number(value))) allChecksPassed = false;
        });

        return allChecksPassed;
      }
    },

    filterSearchFor: function (searchVal, materials) {
      if (this.isSequenceOfMatnr(searchVal)) {
        return this.filterMultipleZfer(searchVal, materials);
      }

      // In case the above pattern doesn't apply we'll filter with the below pattern
      // NOTICE: At the moment hardcoded to only search in sMatHdrTdline, sMatHdrMatnr, and sMatHdrZeinr according to FSP
      let materialsToFilter1 = MaterialsStore.filterWithRegexPattern(searchVal, "sMatHdrTdline", materials);
      let materialsToFilter2 = MaterialsStore.filterWithRegexPattern(searchVal, "sMatHdrMatnr", materials);

      let materialsToFilter3 = [];
      // for pisys the attribute 'sMatHdrZeinr' gets filtered additional. for ecat not
      if (ElstrConfigStore.option("isPisysSite")) {
        materialsToFilter3 = MaterialsStore.filterWithRegexPattern(searchVal, "sMatHdrZeinr", materials);
      }

      // https://stackoverflow.com/a/38940354
      // Set is to have only unique entries in the array
      materials = [...new Set([...materialsToFilter1, ...materialsToFilter2, ...materialsToFilter3])];
      return materials;
    },

    /**
     *
     * @param filterVal
     * @param materialsToFilter
     * @return {Array}
     */
    getCustomersFromFilterValueForAutocomplete: function (filterVal, materialsToFilter) {
      let customers = Customer.getCopyCustomerCache();
      let _customersAlsoInMaterialsToFilter = [];

      customers.every(customer => {
        if (Customer.checkIfCustomerInsideValue(customer, filterVal)) {
          materialsToFilter.forEach(material => {
            // here we not only find out if the customer is in the filterVal
            // but also if he includes in the materialsToFilter
            if (customer.materials.includes(material.sMatHdrMatnr)) {
              _customersAlsoInMaterialsToFilter.push(customer);
              _customersAlsoInMaterialsToFilter = [...new Set(_customersAlsoInMaterialsToFilter)];
            }
          });
        }
        // since we only show maximum 10 items in the autocomplete window, we stop after 10
        return _customersAlsoInMaterialsToFilter.length <= 10;
      });

      return _customersAlsoInMaterialsToFilter;
    },

    /**
     *
     * @param filterVal
     * @param materialsToFilter
     * @return {Array}
     */
    filterSearchBoxCustomers: function (materialsToFilter) {
      const customers = Customer.getCopyCustomerCache();
      let _availableMaterials = [];

      const filterVal = Customer.getFilterValueForCustomer();

      customers.forEach(customer => {
        if (Customer.checkIfCustomerInsideValue(customer, filterVal)) {
          customer.materials.forEach(material => _availableMaterials.push(material));
        }
      });

      // return distinct
      _availableMaterials = [...new Set([..._availableMaterials])];

      let materialsFiltered = [];
      materialsToFilter.forEach(material => {
        if (_availableMaterials.includes(material.sMatHdrMatnr)) {
          materialsFiltered.push(material);
        }
      });

      return materialsFiltered;
    },

    filterSearchBox: function (filterVal, dataAttributeKey, materialsToFilter) {
      materialsToFilter = MaterialsStore.filterWithRegexPattern(filterVal, dataAttributeKey, materialsToFilter);

      return materialsToFilter;
    },

    filterEmptyFilterSettings: function (materialsToFilter) {
      let filters = VETROPACK_STATIC_CACHE.filters;

      // app intro has no VSC.filters
      if (!filters) {
        return materialsToFilter;
      }

      filters.forEach(filter => {
        // first check if there is empty filtering for this filter
        if (filter.emptyFilter !== "") {
          let dataAttributeKey = filter.dataAttributeKey;
          let filterAlreadyApplied = ElstrUrlHashStore.get()[`filter_${dataAttributeKey}`];

          // only do something when the filter hasn't be applied. Otherwise the applied filter overwrites the empty filter settings
          if (!filterAlreadyApplied) {
            let addQuery = query(dataAttributeKey);
            let valuesToFilter = filter.emptyFilter.split(",");
            valuesToFilter.forEach((filterVal, i) => {
              // begin of the additional query to apply

              filterVal = MaterialsStore.convertValueIfNecessary(dataAttributeKey, filterVal);

              if (i === 0) {
                // the first one has to be added this way for the lib array-query to work
                addQuery.is(filterVal);
              } else {
                // other values are added this way
                addQuery.or(dataAttributeKey);
                addQuery.is(filterVal);
              }
            });
            // apply filter
            materialsToFilter = addQuery.on(materialsToFilter);
          }
        }
      });
      return materialsToFilter;
    },

    startFilterMaterials: function () {
      let routeParams = ElstrUrlHashStore.getRouteParams();
      let catalogue = routeParams.catalogue || "global";
      let mainGroup = routeParams.mainGroup || "all";

      // call MaterialsStore.filterCatalogueAndMain() only when catalogue or mainGroup has changed
      if (catalogue !== this.cataloguePrevious || mainGroup !== this.mainGroupPrevious) {
        // OVERWRITE
        this.mainGroupPrevious = mainGroup;
        this.cataloguePrevious = catalogue;

        MaterialsStore.filterCatalogueAndMain(catalogue, mainGroup);
      }

      // start with filteredMaterialsResult from catalogue and mainGroup criteria
      let filteredMaterials = MaterialsStore.getFilteredMainMaterials();

      // filter when empty filters are applied
      if (Controllers.applyEmptyFilter()) {
        filteredMaterials = MaterialsStore.filterEmptyFilterSettings(filteredMaterials);
      }

      // filter from user input filter (search or every other filter block) (left side of mainView = result)
      filteredMaterials = MaterialsStore.filterFromUserFilters(filteredMaterials);

      // in case it's not a Pisys Site, the materials with same ZVGB are getting filtered out
      let isPisysSite = ElstrConfigStore.option("isPisysSite");
      if (!isPisysSite) {
        filteredMaterials = MaterialsStore.filterSameZVGB(filteredMaterials);
      }

      // sort according to applied criteria
      filteredMaterials = MaterialsStore.sortAndOrderMaterials(filteredMaterials);

      // store the number of results before they get spliced
      MaterialsStore.numberOfResults = filteredMaterials.length;

      // copy of filteredMaterialsResult before it gets spliced, which removes results in ContainerResultMaterials
      // https://stackoverflow.com/a/23481096
      MaterialsStore.filteredMaterialsResultNotSpliced = [...filteredMaterials];

      MaterialsStore.filteredMaterialsResult = filteredMaterials;

      MaterialsStore.emitChange();
    },

    getError: function () {
      return MaterialsStore.error;
    },

    convertValueIfNecessary: (dataAttributeKey, value) => {
      let dataType = Attributes.getDataType(dataAttributeKey);
      if (dataType === "integer") {
        return parseInt(value);
      }

      if (dataType === "float") {
        return parseFloat(value);
      }

      return value;
    },
  },

  function (payload) {
    switch (payload.actionType) {
      case ElstrUrlHashConstants.URL_HASH_CHANGE: {
        mcFly.dispatcher.waitFor([ElstrUrlHashStore.dispatcherID]);

        MaterialsStore.startFilterMaterials();

        break;
      }

      case ElstrUrlHashConstants.URL_ROUTE_CHANGE: {
        mcFly.dispatcher.waitFor([ElstrUrlHashStore.dispatcherID]);

        MaterialsStore.startFilterMaterials();

        break;
      }

      case ElstrLangConstants.ELSTR_LANG_DID_LOAD: {
        MaterialsStore.startFilterMaterials();

        break;
      }
    }

    return true;
  },
);

export default MaterialsStore;
