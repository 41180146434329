import ElstrFlux from "elstr-jslib/src/scripts/libs/ElstrFlux.js";
import DossierConstants from "../constants/actions/DossierConstants";
import ElstrIo from "elstr-jslib/src/scripts/ElstrIo.js";

const elstrIo = new ElstrIo({
  abortStaleRequests: true,
  maxTimeout: 1000 * 60 * 60 * 24, // 24h
});

const DossierActions = ElstrFlux.createActions(
  [
    {
      className: "VETROPACK_WidgetServer_JSON_Dossier",
      methodName: "setDossier", // "payload" {"selectionId": "69306BD0-E781-4B04-9369-3AD718EC49F3", "sourceSelection" : "local", "meta": {"title": ""}}
      constantWill: DossierConstants.DOSSIER_SET_DOSSIER_DATA_WILL,
      constantDid: DossierConstants.DOSSIER_SET_DOSSIER_DATA_DID,
    },
    {
      className: "VETROPACK_WidgetServer_JSON_Dossier",
      methodName: "setDocs", // "payload" {"selectionId": "69306BD0-E781-4B04-9369-3AD718EC49F3", "sourceSelection" : "local", "docs": [{10000: [{},{}]}, 19437: [{},{}] }]}
      constantWill: DossierConstants.DOSSIER_SET_DOCS_WILL,
      constantDid: DossierConstants.DOSSIER_SET_DOCS_DID,
    },
    {
      className: "VETROPACK_WidgetServer_JSON_Dossier",
      methodName: "setAttributes", // "payload" {"selectionId": "69306BD0-E781-4B04-9369-3AD718EC49F3", "sourceSelection" : "local", "attributes": [{},{}]}
      constantWill: DossierConstants.DOSSIER_SET_ATTRIBUTES_WILL,
      constantDid: DossierConstants.DOSSIER_SET_ATTRIBUTES_DID,
    },
    {
      className: "VETROPACK_WidgetServer_JSON_Dossier",
      methodName: "getDocumentsFromNumbers", // "payload" {"materialNumbers": ["10000", "197822"]}
      constantWill: DossierConstants.DOSSIER_GET_DOCUMENTS_FROM_NUMBERS_WILL,
      constantDid: DossierConstants.DOSSIER_GET_DOCUMENTS_FROM_NUMBERS_DID,
    },
  ],
  {
    initOnDb: function () {
      return {
        actionType: DossierConstants.DOSSIER_INIT_ON_DB,
      };
    },
  },
  elstrIo,
);

export default DossierActions;
