import React from "react";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import MaterialsStore from "../../stores/MaterialsStore";
import FilterStore from "../../stores/FilterStore";
import * as UrlHelpers from "../../libs/UrlHelpers";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";

const FilterCheckbox = createReactClass({
  mixins: [MaterialsStore.mixin],

  propTypes: {
    filter: PropTypes.object, // for every filter type
  },

  getInitialState: function () {
    return {
      filter: this.props.filter,
      hash: `filter_${this.props.filter.dataAttributeKey}`,
    };
  },

  storeDidChange: function () {
    this.forceUpdate();
  },

  render: function () {
    let dataAttributeKey = this.state.filter.dataAttributeKey;
    let mainGroup = ElstrUrlHashStore.getRouteParam("mainGroup");

    // in case the dataAttributeKey is sMatAtvEcatSubgroup, an additional check is required
    let addCheck = false;
    if (dataAttributeKey === "sMatAtvEcatSubgroup") addCheck = true;

    // get an array of the hash
    let filterValueArr = UrlHelpers.getHashArray(this.state.hash);

    let sumsOfAttribute = MaterialsStore.getSumsOfAttributeValueFilters(dataAttributeKey);

    // sort attributes alphabetic
    let sumsOfAttributeSorted = Object.keys(sumsOfAttribute).sort();

    // In case the _OTHER key appears, it will be put to the end of the sorted array
    // for example in BE_OTHER or WI_OTHER
    sumsOfAttributeSorted.forEach(function (key, index, originArray) {
      if (key.endsWith("_OTHER")) {
        let lastPos = originArray.length - 1;
        let tmp = originArray[lastPos];
        originArray[lastPos] = key; // put _OTHER to last pos
        originArray[index] = tmp; // overwrite _OTHER with the last pos of before
      }
    });

    let sumsForShowingAsNumber =
      MaterialsStore.getSumsOfAttributeValueFiltersForShowingAsNumberInCheckbox(dataAttributeKey);

    // fill only content that exists, because of sumsOfAttributes
    let content = [];

    sumsOfAttributeSorted.forEach(key => {
      let pushToContent = true; // default always push the content
      // ! THIS IS ONLY FOR THE SPECIAL CASE WHEN THE FILTER IS: s_mat_atv-ecat_subgroup
      //
      // if addCheck is true and the key doesn't start with the according maingrouop,
      // the content should not be pushed
      // this checks to only push content thats correlated
      //
      // ex:
      // mainGroup = BE
      // dataAttributeKey = BE_SHORT_NECK -> content will be pushed
      // dataAttributeKey = WI_BORDEAUX -> content should not be pushed
      //
      if (addCheck && !key.startsWith(mainGroup)) pushToContent = false;

      if (pushToContent) {
        let sum = sumsForShowingAsNumber[key];
        let text = FilterStore.getFilterText(key, dataAttributeKey);

        content.push(
          <div key={key}>
            <dt>
              <input
                type="checkbox"
                checked={filterValueArr.includes(key)}
                onChange={UrlHelpers.setFilterArray.bind(this, this.state.hash, key, filterValueArr)}
                id={key}
              />{" "}
              <label htmlFor={key}>{text}</label>
            </dt>
            <dd>{sum}</dd>
          </div>,
        );
      }
    });

    return (
      <div className="filter-block__checkbox">
        <dl>{content}</dl>
      </div>
    );
  },
});

export default FilterCheckbox;
