import React from "react";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import ScrollPositionsStore from "../../stores/ScrollPositionsStore";
import ElstrScrollStore from "elstr-jslib/src/scripts/stores/ElstrScrollStore";

const ItemListScroll = createReactClass({
  mixins: [ElstrScrollStore.mixin, ScrollPositionsStore.mixin],

  propTypes: {
    scrollActive: PropTypes.bool.isRequired,
  },

  getInitialState: function () {
    let pos = ScrollPositionsStore.getListScroll();

    if (pos.xPos === null) pos.xPos = 0;
    if (pos.yPos === null) pos.yPos = 0;

    return {
      xPos: pos.xPos,
      yPos: pos.yPos,
    };
  },

  storeDidChange: function () {
    if (this.props.scrollActive) {
      let xPos = ElstrScrollStore.getCurrentHorizontalScroll();
      let yPos = ElstrScrollStore.getCurrentScroll();

      this.setState({ xPos, yPos });
    }
  },

  componentDidMount: function () {
    if (this.props.scrollActive) {
      let pos = ScrollPositionsStore.getListScroll();

      if (pos.xPos === null) pos.xPos = 0;
      if (pos.yPos === null) pos.yPos = 0;

      window.scrollTo(pos.xPos, pos.yPos);
    }
  },

  componentWillUnmount: function () {
    if (this.props.scrollActive) {
      let xPos = this.state.xPos;
      let yPos = this.state.yPos;

      ScrollPositionsStore.setListScroll({ xPos, yPos });
    }
  },

  render: function () {
    return <i />;
  },
});

export default ItemListScroll;
