import React from "react";
import createReactClass from "create-react-class";
import EmptyPage from "../view/EmptyPage";
import EnvInfoDisplay from "../common/EnvInfoDisplay";
import VetroUserHandler from "./VetroUserHandler";

const VetroUserLogin = createReactClass({
  render: () => {
    // the div might have to inherit className from ClassChangerOnScrollApp
    return (
      <React.Fragment key="container">
        <EnvInfoDisplay key="EnvInfoDisplay" />
        <EmptyPage />
        <VetroUserHandler />
      </React.Fragment>
    );
  },
});

export default VetroUserLogin;
