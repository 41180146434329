import React from "react";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import DetailsActions from "../../actions/DetailsActions";
import * as UrlHelpers from "../../libs/UrlHelpers";
import DetailPhoneAndMail from "./DetailPhoneAndMail";
import DetailControllers from "../../libs/DetailControllers";
import DetailsStore from "../../stores/DetailsStore";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";
import ElstrConfigStore from "elstr-jslib/src/scripts/stores/ElstrConfigStore";
import technicalDrawing from "../../assets/img/technical_drawing.png";

const DetailAside = createReactClass({
  getInitialState() {
    return {};
  },

  propTypes: {
    material: PropTypes.object.isRequired,
  },

  statics: {
    getDerivedStateFromProps(newProps, prevState) {
      const sMatHdrMatnrURL = ElstrUrlHashStore.getRouteParam("sMatHdrMatnr");
      if (sMatHdrMatnrURL !== prevState.sMatHdrMatnr) {
        DetailsActions.getDocuments({
          materialNumber: sMatHdrMatnrURL,
        });
        return { sMatHdrMatnr: newProps.material.sMatHdrMatnr };
      }
      return null;
    },
  },

  render: function () {
    let documentItems = [];
    let materialDocuments = DetailsStore.getDocuments();
    let sMatHdrMatnr = this.props.material.sMatHdrMatnr;
    let href = UrlHelpers.getHrefMaterialDocument();

    materialDocuments.forEach(matDoc => {
      if (DetailControllers.allowedToPostDocument(matDoc)) {
        let textOverImage = `${ElstrLangStore.text("DETAIL_ASIDE_ARTICLEDRAWING")}`;

        let doknr = matDoc["doknr"];
        documentItems.push(
          <a
            key={doknr}
            rel="noopener noreferrer"
            target="_blank"
            href={`${href}&doknr=${doknr}&materialNumber=${sMatHdrMatnr}`}
          >
            <li className="thumbnail-list__item">
              <span className="thumbnail-list-item__title" style={{ backgroundImage: "url(" + technicalDrawing + ")" }}>
                {textOverImage}
              </span>
            </li>
          </a>,
        );
      }
    });

    /** the contanct block will be hidden for pisys **/
    let contactUsIsVisible = ElstrConfigStore.option("isPisysSite") ? "hidden" : "visible";

    let material = this.props.material;

    return (
      <div className="detail-main-body__aside phone-tablet--hidden">
        <div className="contact-info-block">
          <div className="contact-info-block__title" style={{ visibility: contactUsIsVisible }}>
            {ElstrLangStore.text("DETAIL_CONTACT_US")}
          </div>
          <DetailPhoneAndMail material={material} />
        </div>
        <ul className="thumbnail-list detail-main-body-aside__thumbnail-list">{documentItems}</ul>
      </div>
    );
  },
});

export default DetailAside;
