import ElstrConfigStore from "elstr-jslib/src/scripts/stores/ElstrConfigStore";
import { PREFIX_SECOND_FILTER } from "../components/detail/PisysHeaderMenu";

const DetailControllers = {
  // returns true when the document is allowed to be posted at that specific place
  allowedToPostDocument: matDoc => {
    let dokst = ElstrConfigStore.option("dokstCatalogue"); // this category is allowed to be posted in the detail aside
    let dokar = ElstrConfigStore.option("dokarCatalogue"); // this category is allowed to be posted in the detail aside

    return dokst.includes(matDoc["dokst"]) && dokar.includes(matDoc["dokar"]);
  },

  shouldPerformSecondFilter: filters => {
    const allFiltersKeys = Object.keys(filters);

    return allFiltersKeys.some(key => {
      return key.startsWith(PREFIX_SECOND_FILTER);
    });
  },
};

export default DetailControllers;
