import { DossierContainerTop } from "../dossier/DossierContainerTop";
import DossierCarrierBottom from "../dossier/DossierCarrierBottom";
import React from "react";
import createReactClass from "create-react-class";
import ContainerCompare from "../article/ContainerCompare";
import SelectionStore from "../../stores/SelectionStore";
import DossierStore from "../../stores/DossierStore";

const DossierOutputSettings = createReactClass({
  isHiddenContainer: function () {
    return DossierStore.getActiveStep() !== 2;
  },

  render: function () {
    const materialsToRender = SelectionStore.getSortedRenderedMaterials();

    return (
      <React.Fragment>
        <div className="container-common-carrier__on-compare-frame">
          <DossierContainerTop step={2} />
        </div>
        {this.isHiddenContainer() ? null : (
          <ContainerCompare materialsToRender={materialsToRender} scrollActive={true} />
        )}
        <DossierCarrierBottom step={2} />
      </React.Fragment>
    );
  },
});

export default DossierOutputSettings;
