import React from "react";
import Controllers from "../../libs/Controllers";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import FilterBlock from "./FilterBlock";
import * as UrlHelpers from "../../libs/UrlHelpers";
import FilterStore from "../../stores/FilterStore";
import AppsStore from "../../stores/AppsStore";
import ElstrConfigStore from "elstr-jslib/src/scripts/stores/ElstrConfigStore";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import ElstrCache from "elstr-jslib/src/scripts/ElstrCache";

const ContainerFilters = createReactClass({
  mixins: [FilterStore.mixin, ElstrUrlHashStore.mixin, AppsStore.mixin],

  propTypes: {
    renderNotFor: PropTypes.array.isRequired, // can be ['desktop', 'tablet', 'phone']
  },

  storeDidChange: function () {
    this.forceUpdate();
  },

  render: function () {
    // check if component should rerender or return empty <i/>
    let layoutView = AppsStore.getLayoutView();
    let renderNotFor = this.props.renderNotFor;

    // dont render component if renderNotFor includes the layoutView
    if (renderNotFor.includes(layoutView)) {
      return <i />;
    } else {
      let filters = FilterStore.getFilters();
      let filtersSortedByLanguage = FilterStore.getFilterSortedByLanguage();
      let isPisysSite = ElstrConfigStore.option("isPisysSite");
      let filtersToDisplay = ElstrCache.get("filtersToDisplay");

      // determine if the additional content boxes (ecat and / or pisys) should be displayed
      // if the number remains 0, the box won't be displayed
      let additionalContentEcat = 0;
      let additionalContentPisys = 0;

      // start adding this numbers
      if (filtersToDisplay) {
        filters.forEach(filter => {
          let isDisplay = filtersToDisplay[filter.dataAttributeKey];
          let filterIsInUrl = false;
          if (ElstrUrlHashStore.get(`filter_${filter.dataAttributeKey}`)) filterIsInUrl = true;

          if (filter.isAdditional && !isDisplay && !filterIsInUrl) {
            if (filter.isPisys === false) {
              additionalContentEcat += 1;
            } else {
              additionalContentPisys += 1;
            }
          }
        });
      }

      let filtersToRender = [];
      let overlayFiltersIsOpen = AppsStore.getOverlayFiltersOpen();
      // only push if filtersToDisplay is initialized
      if (filtersToDisplay) {
        filters.forEach(filter => {
          if (filter.isAdditional) return;

          // for not pisys page and pisys filter is true, addCondition will be wrong and filter won't be pushed
          if (!isPisysSite && filter.isPisys) return;

          if (Controllers.caseEcatSubgroupMainGroupAll(filter.dataAttributeKey)) return;

          filtersToRender.push(
            <FilterBlock key={filter.dataAttributeKey} filter={filter} overlayFiltersIsOpen={overlayFiltersIsOpen} />,
          );
        });
      }

      // handles additional filters
      if (filtersToDisplay) {
        filtersSortedByLanguage.forEach(filter => {
          if (!filter.isAdditional) return;

          // for not pisys page and pisys filter is true, addCondition will be wrong and filter won't be pushed
          if (!isPisysSite && filter.isPisys) return;

          // we don't want to show the filter when its not in the filtersToDisplay
          // BUT if someone shared a link with that active filter to a user in the url, we will show that filter
          let filterIsInUrl = false;
          let dataAttributeKey = filter.dataAttributeKey;
          if (ElstrUrlHashStore.get(`filter_${dataAttributeKey}`)) filterIsInUrl = true;
          if (filtersToDisplay[filter.dataAttributeKey] === false && !filterIsInUrl) return;

          if (Controllers.caseEcatSubgroupMainGroupAll(filter.dataAttributeKey)) return;

          filtersToRender.push(
            <FilterBlock key={dataAttributeKey} filter={filter} overlayFiltersIsOpen={overlayFiltersIsOpen} />,
          );
        });
      }

      // only push if there is actual content to show
      if (additionalContentEcat > 0) {
        // additional container filters for ecat
        filtersToRender.push(<FilterBlock key="additional ecat" additional="ecat" />);
      }

      // only push if there is actual content to show
      if (additionalContentPisys > 0) {
        // additional container filters for pisys only if isPisysSite
        if (isPisysSite) {
          filtersToRender.push(<FilterBlock key="additional pisys" additional="pisys" />);
        }
      }

      const isMobileLayout = AppsStore.getIsMobileLayoutView();

      return (
        <div className={`filters-col ${isMobileLayout ? "filter-overlay--phone-tablet" : ""}`}>
          <div className="filter-overlay__nav-bar desktop--hidden">
            <span className="filter-overlay-nav-bar__left" onClick={UrlHelpers.clearAllFilters.bind(this)}>
              {ElstrLangStore.text("FILTER_TEXT_CLEAR")}
            </span>
            <span className="filter-overlay-nav-bar__title">{ElstrLangStore.text("FILTER_TEXT_FILTERS")}</span>
            <span
              className="filter-overlay-nav-bar__right close-filter-overlay"
              onClick={AppsStore.toggleOverlayFiltersOpen}
            >
              {ElstrLangStore.text("LANG_TEXT_DONE")}
            </span>
          </div>
          {filtersToRender}
        </div>
      );
    }
  },
});

export default ContainerFilters;
