import React from "react";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import AppsStore from "../../stores/AppsStore";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import ElstrConfigStore from "elstr-jslib/src/scripts/stores/ElstrConfigStore";
import DetailPhoneAndMail from "./DetailPhoneAndMail";

const DetailContact = createReactClass({
  propTypes: {
    material: PropTypes.object.isRequired,
  },
  /**
   * Render
   */

  render: function () {
    /** the contanct block will be hidden for pisys **/
    let contactUsIsVisible = ElstrConfigStore.option("isPisysSite") ? "none" : "block";

    let material = this.props.material;

    return (
      <div className="contact-overlay desktop--hidden">
        <div className="overlay__nav-bar">
          <span className="ficon ficon__contact overlay-nav__contact-icon" />
          <span className="overlay-nav-bar__title">{ElstrLangStore.text("DETAIL_CONTACT")}</span>
          <span
            className="overlay-nav-bar__right close-contact-overlay"
            onClick={AppsStore.toggleOverlayDetailContactOpen}
          >
            {ElstrLangStore.text("LANG_TEXT_DONE")}
          </span>
        </div>
        <div className="contact-overlay__content">
          <div className="contact-info-block">
            <div className="contact-overlay__title" style={{ display: contactUsIsVisible }}>
              {ElstrLangStore.text("DETAIL_CONTACT_US")}
            </div>
            <DetailPhoneAndMail material={material} />
          </div>
        </div>
      </div>
    );
  },
});

export default DetailContact;
