import { Link } from "react-router-dom";
import { MAIN_VIEW as MV } from "../../constants/MainView";
import React from "react";
import createReactClass from "create-react-class";
import RouteHistoryStore from "../../stores/RouteHistoryStore";
import * as UrlHelpers from "../../libs/UrlHelpers";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";

const BackToResult = createReactClass({
  render: () => {
    // display only when stacked
    if (!UrlHelpers.getStacked()) {
      return <div />;
    }

    let mainView = UrlHelpers.getMainView();

    let className = "";

    if (mainView === MV.DETAIL) {
      className = "detail-page-to-results";
    }

    if (mainView === MV.SELECTION || MV.DOSSIER || MV.PRINT_LABEL) {
      className = "page-to-results";
    }

    return (
      <div className={className}>
        <Link to={RouteHistoryStore.getBackToResultPath()}>
          <span className="ficon ficon__arrow-back detail-page-back-to-list__icon" />
          {ElstrLangStore.text("BACK_TO_RESULTS")}
        </Link>
      </div>
    );
  },
});

export default BackToResult;
