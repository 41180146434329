import { Route } from "react-router-dom";
import React from "react";
import createReactClass from "create-react-class";
import RefreshPage from "./RefreshPage";
import App from "../../catalogue/App";
import Redirects from "./Redirects";

const ContainerRouter = createReactClass({
  render: function () {
    return (
      <React.Fragment>
        <Redirects />
        <Route path="/intro" component={RefreshPage} />
        <Route path="/catalogue" component={App} />
      </React.Fragment>
    );
  },
});

export default ContainerRouter;
