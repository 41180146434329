import React from "react";
import Tooltip from "rc-tooltip";

const TextArea = props => (
  <Tooltip trigger={["click"]} align={{ offset: [0, -8] }} overlay={getOverlay(props.textList)} placement="topLeft">
    {props.children}
  </Tooltip>
);

function getOverlay(textList) {
  // join texts via with line breaks
  const fullText = textList.join("\n");

  return (
    <div className="tooltip__text">
      <textarea style={{ resize: "none" }} readOnly rows="6" cols="75" value={fullText} />
    </div>
  );
}

export default TextArea;
