import Translations from "../../libs/Translations";
import React from "react";
import createReactClass from "create-react-class";
import FilterStore from "../../stores/FilterStore";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import ElstrCache from "elstr-jslib/src/scripts/ElstrCache";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";

const FilterAdditional = createReactClass({
  mixins: [FilterStore.mixin],

  storeDidChange: function () {
    this.forceUpdate();
  },

  render: function () {
    // settings for ecat
    let classNameAdd = "filter-block__additional-filters";
    let classNameBlock = "additional-filter";
    let pisys = false; // compare variable

    // settings for pisys
    if (this.props.additional === "pisys") {
      classNameAdd = "filter-block__additional-filters--pisys";
      classNameBlock = "additional-filter--pisys";
      pisys = true; // compare variable
    }

    let filters = FilterStore.getFilterSortedByLanguage();

    let filtersToDisplay = ElstrCache.get("filtersToDisplay");

    let additionalContent = [];

    // only push if filtersToDisplay is initialized
    if (filtersToDisplay) {
      filters.forEach((filter, i) => {
        let isDisplay = filtersToDisplay[filter.dataAttributeKey];

        let filterIsInUrl = false;
        if (ElstrUrlHashStore.get(`filter_${filter.dataAttributeKey}`)) filterIsInUrl = true;

        if (filter.isAdditional && !isDisplay && filter.isPisys === pisys && !filterIsInUrl) {
          let text =
            Translations.getLabel(filter.dataAttributeKey) || Translations.getNoLabelFor(filter.dataAttributeKey);

          additionalContent.push(
            <div
              key={i}
              onClick={FilterStore.toggleDisplayFilter.bind(this, filter.dataAttributeKey)}
              className={classNameBlock}
            >
              {text}
            </div>,
          );
        }
      });
    }

    return (
      <React.Fragment>
        <div className="filter-block__select-all">
          <span className="pointer" onClick={FilterStore.displayAllFilters.bind(this, pisys)}>
            {ElstrLangStore.text("FILTER_SELECT_ALL")}
          </span>
        </div>
        <div className={classNameAdd}>{additionalContent}</div>
      </React.Fragment>
    );
  },
});

export default FilterAdditional;
