import * as TT from "../../libs/Tooltip";
import LinkToDetail from "../router/LinkToDetail";
import Translations from "../../libs/Translations";
import React from "react";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import ItemColors from "./ItemColors";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import MaterialsStore from "../../stores/MaterialsStore";
import TouchStore from "../../stores/TouchStore";
import Controllers from "../../libs/Controllers";
import { TYPES } from "../../constants/Tooltip";

const ItemListField = createReactClass({
  mixins: [ElstrLangStore.mixin, TouchStore.mixin],

  propTypes: {
    material: PropTypes.object.isRequired,
    dataAttributeKey: PropTypes.string.isRequired,
    listDisplaySize: PropTypes.number.isRequired,
  },

  getInitialState: function () {
    return this._setState();
  },

  storeDidChange: function () {
    this.setState(this._setState());
  },

  _setState: function () {
    let showColorsOverlaySelf = TouchStore.getShowColorsOverlayForMaterial() === this.props.material.sMatHdrMatnr;

    let currentLanguage = ElstrLangStore.getCurrentLanguage();

    return {
      currentLanguage,
      showColorsOverlaySelf,
    };
  },

  shouldComponentUpdate: function (nextProps, nextState) {
    return (
      this.props.dataAttributeKey !== nextProps.dataAttributeKey ||
      this.state.currentLanguage !== nextState.currentLanguage ||
      this.state.showColorsOverlaySelf !== nextState.showColorsOverlaySelf
    );
  },

  render: function () {
    let material = this.props.material;
    let dataAttributeKey = this.props.dataAttributeKey;

    // fill valueColumn content
    let valueColumn = MaterialsStore.getValueColumn(dataAttributeKey, material[dataAttributeKey]);

    // in case the dataAttributeKey === 'sMatAtvKatalog', the content will be rewritten
    // this is because sMatAtvKatalog needs much space and is also an array
    if (dataAttributeKey === "sMatAtvKatalog") {
      if (Object.prototype.toString.call(this.props.material[dataAttributeKey]) === "[object Array]") {
        let textShown = "";
        this.props.material[dataAttributeKey].forEach((value, i) => {
          // value ex: VPI
          if (i !== 0) textShown += `, `; // add coma and space if more than one value in the array
          textShown += `${value}`;
        });

        // rewrite the valueColumn
        valueColumn = (
          <span
            onMouseOver={TT.showTooltip.bind(this, valueColumn, TYPES.left, 12)}
            onMouseOut={TT.removeTooltip.bind(this, TYPES.left)}
            onMouseDown={TT.removeTooltip.bind(this, TYPES.left)}
          >
            {textShown}
          </span>
        );
      }
    }

    /**
     * begin returning content for
     * - color (sMatAtvFarbe)
     * - text line (sMatHdrTdline)
     * - everything else
     */
    let sMatHdrMatnr = material.sMatHdrMatnr;

    // colors (sMatAtvFarbe) has a own style
    if (dataAttributeKey === "sMatAtvFarbe") {
      let sMatAtvFarbe = material.sMatAtvFarbe;
      let alternativeColors = material.alternativeColors;

      let colorsOverlay = null;
      if (this.state.showColorsOverlaySelf) {
        colorsOverlay = (
          <ItemColors
            colorSelf={material.sMatAtvFarbe}
            colorsAlternate={material.alternativeColors}
            sMatHdrMatnr={material.sMatHdrMatnr}
            isOverlay={true}
          />
        );
      }

      return (
        <div className="item-list__col-colors" key={`${dataAttributeKey} ${sMatHdrMatnr}`}>
          <ItemColors
            colorSelf={sMatAtvFarbe}
            colorsAlternate={alternativeColors}
            sMatHdrMatnr={sMatHdrMatnr}
            isOverlay={false}
            limit={8}
          />
          {colorsOverlay}
        </div>
      );
      // Title (sMatHdrTdline) has a own style
    } else if (dataAttributeKey.startsWith("sMatHdrTdline")) {
      let inactiveClass = "";
      if (Controllers.isMsteaInactive(material["sMatHdrMstae"])) inactiveClass = "blackAndDashed";

      return (
        <div className="item-list__link" key={`${dataAttributeKey} ${sMatHdrMatnr}`}>
          <LinkToDetail renderedFrom="List" material={this.props.material}>
            <h3 className={`item-list__title ${inactiveClass}`}>{Translations.getItemTitle(material)}</h3>
          </LinkToDetail>
        </div>
      );
    } else {
      let classNameStandard = "item-list__col";
      if (this.props["listDisplaySize"] === 2) {
        classNameStandard += " double-width";
      }

      return (
        <div className={classNameStandard} key={`${dataAttributeKey} ${sMatHdrMatnr}`}>
          <span className="text-wrap">{valueColumn}</span>
        </div>
      );
    }
  },
});

export default ItemListField;
