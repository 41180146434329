import { DOWNLOAD_TYPES, FlexibleDataTable } from "./FlexibleDataTable";
import React from "react";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import DetailsActions from "../../actions/DetailsActions";
import DetailsStore from "../../stores/DetailsStore";
import ElstrLoadingStates from "elstr-jslib/src/scripts/constants/ElstrLoadingStates";
import DetailPisysTableMessage from "./DetailPisysTableMessage";

function filterDuplicates(allMaterials) {
  return allMaterials.filter((obj, index, arr) => {
    // Check if the index of the current object is the same as the first occurrence of the aufnr number
    return index === arr.findIndex(item => item.aufnr === obj.aufnr);
  });
}

const DetailPisysProductionOrder = createReactClass({
  propTypes: {
    material: PropTypes.object.isRequired,
  },

  getInitialState() {
    const { material } = this.props;
    return { material };
  },

  statics: {
    getDerivedStateFromProps(newProps, prevState) {
      if (newProps.material.sMatHdrMatnr !== prevState.renderedMaterialNumber) {
        // Start two requests for live data (loadMore = false) and cache data (loadMore = true)
        DetailsActions.getProductionOrder({
          materialNumber: newProps.material.sMatHdrMatnr,
          othersZFERfromZVGB: true,
          loadMore: false,
        });
        DetailsActions.getProductionOrder({
          materialNumber: newProps.material.sMatHdrMatnr,
          othersZFERfromZVGB: true,
          loadMore: true,
        });

        return {
          renderedMaterialNumber: newProps.material.sMatHdrMatnr,
        };
      }
      return null;
    },
  },

  render: function () {
    const materials = DetailsStore.getProductionOrder();
    const materialsLoadMore = DetailsStore.getProductionOrderLoadMore();

    // show the user the loading state of the app
    const loadingState = DetailsStore.getLoadingStateFlexibleTable();
    const loadingStateLoadMore = DetailsStore.getLoadingStateFlexibleDataLoadMore();
    const isLoading = loadingState !== ElstrLoadingStates.LOADED || loadingStateLoadMore !== ElstrLoadingStates.LOADED;
    let error = DetailsStore.getError();
    let infoText = "";

    if (error == null && !isLoading && materialsLoadMore.data.length === 0) {
      infoText = ElstrLangStore.text("DETAIL_TEXT_NODATA");
    }

    const combinedMaterials = [];
    if (!isLoading && materials.data && materialsLoadMore.data) {
      combinedMaterials.push(...filterDuplicates([...materials.data, ...materialsLoadMore.data]));
    }

    if (isLoading) {
      return <DetailPisysTableMessage isLoading />;
    } else if (error !== null) {
      return <DetailPisysTableMessage>{error}</DetailPisysTableMessage>;
    } else if (infoText) {
      return <DetailPisysTableMessage>{infoText}</DetailPisysTableMessage>;
    } else {
      return (
        <FlexibleDataTable
          dataDefintions={materials.dataDefintions}
          data={combinedMaterials}
          downloadType={DOWNLOAD_TYPES.PRODUCTION_ORDER}
        />
      );
    }
  },
});

export default DetailPisysProductionOrder;
