import React from "react";
import PropTypes from "prop-types";

const Dossier = props => (
  <svg
    id="dossier_svg__Layer_1"
    x={0}
    y={0}
    viewBox="0 0 27 35"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <style>{".dossier_svg__st1{fill:none}"}</style>
    <g id="dossier_svg__Group_4" transform="translate(1)" fill={props.color} opacity={1}>
      <g id="dossier_svg__Combined_Shape">
        <path className="dossier_svg__st1" d="M22 31H0V1h14l8 8v22z" id="dossier_svg__Combined_Shape-2" />
        <path d="M23 32H-1V0h15.4L23 8.6V32zM1 30h20V9.4L13.6 2H1v28z" id="dossier_svg__Combined_Shape-3" />
      </g>
      <g id="dossier_svg__Rectangle_3">
        <path className="dossier_svg__st1" d="M25 11v23H1" id="dossier_svg__Rectangle_3-2" />
        <path d="M26 35H1v-2h23V11h2z" id="dossier_svg__Rectangle_3-3" />
      </g>
      <path d="M12 0h2v9h-2z" id="dossier_svg__Rectangle_5" />
      <path d="M12 9h11v2H12z" id="dossier_svg__Rectangle_5-2" />
    </g>
  </svg>
);

export default Dossier;

Dossier.propTypes = {
  color: PropTypes.string.isRequired,
};
