import LinkToDetail from "../router/LinkToDetail";
import Translations from "../../libs/Translations";
import React from "react";
import ItemCompareRows from "./ItemCompareRows";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import MaterialsStore from "../../stores/MaterialsStore";
import Controllers from "../../libs/Controllers";
import ItemLabelTop from "./ItemLabelTop";
import ItemToggleSelection from "./ItemToggleSelection";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";

const ItemCompare = createReactClass({
  mixins: [ElstrLangStore.mixin],

  propTypes: {
    material: PropTypes.object.isRequired,
    attributesSorted: PropTypes.array.isRequired,
    counterForUpdate: PropTypes.number, // increases when a user changes attributes settings (displaying / order)
  },

  storeDidChange: function () {
    this.forceUpdate();
  },

  shouldComponentUpdate: function (nextProps) {
    return (
      this.props.material.sMatHdrMatnr !== nextProps.material.sMatHdrMatnr ||
      this.props.counterForUpdate !== nextProps.counterForUpdate
    );
  },

  render: function () {
    let { material, attributesSorted } = this.props;

    // compare item uses the grid img
    let imgKey = `${material.sMatAtvMatnrZvgb}`;
    let ImagePath = MaterialsStore.getImagePath(imgKey, "grid");

    let textArtNrAndMatnr = Translations.getTextArtNrAndMatnr(material["sMatHdrMatnr"]);

    let inactiveClass = "";
    if (Controllers.isMsteaInactive(material["sMatHdrMstae"])) inactiveClass = "blackAndDashed";

    return (
      <article className="item-compare">
        <ItemToggleSelection sMatHdrMatnr={material["sMatHdrMatnr"]} material={material} />
        <LinkToDetail renderedFrom="Compare" material={this.props.material}>
          <ItemLabelTop material={material} />
        </LinkToDetail>
        <div className="item-compare__top-box">
          <div className="item-compare-top-box__content">
            <LinkToDetail renderedFrom="Compare" material={this.props.material}>
              <h3 className={`item-compare__title ${inactiveClass}`}> {Translations.getItemTitle(material)}</h3>
            </LinkToDetail>
            <div className="item-compare__key-info">
              <span className="art-nr">{textArtNrAndMatnr}</span>
              {Translations.getFuellVollAndMuendung(material)}
            </div>
            <LinkToDetail renderedFrom="Compare" material={this.props.material}>
              <img src={ImagePath} alt="glas img" className="item-compare__img" />
            </LinkToDetail>
          </div>
        </div>
        <ItemCompareRows material={material} attributesSorted={attributesSorted} />
      </article>
    );
  },
});

export default ItemCompare;
