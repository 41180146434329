import mcFly from "elstr-jslib/src/scripts/libs/mcFly.js";
import ElstrEditingStates from "elstr-jslib/src/scripts/constants/ElstrEditingStates";
import ElstrLoadingStates from "elstr-jslib/src/scripts/constants/ElstrLoadingStates";
import SettingsConstants from "../constants/actions/SettingsConstants";
import * as Sentry from "@sentry/browser";

const keys = {
  newZvgb: "newZvgb",
};

const SettingsStore = mcFly.createStore(
  {
    settings: {}, // see const keys for possible keys
    error: null,
    loadingState: ElstrLoadingStates.EMPTY,
    editingState: ElstrEditingStates.NORMAL,

    /** getters **/

    getSettings: function () {
      return this.settings;
    },

    getError: function () {
      return this.error;
    },

    getLoadingState: function () {
      return this.loadingState;
    },

    getEditingState: function () {
      return this.editingState;
    },

    getSetting: function (key) {
      if (this.settings && this.settings[key]) {
        return this.settings[key];
      }
      return [];
    },

    handlePayload: function (payload, key) {
      let error = payload.error;

      if (error && error !== "") {
        SettingsStore.error = error;
        Sentry.captureException(
          new Error(
            `err in SettingsStore.handlePayload with payload:
            ${JSON.stringify(payload)} on key ${key} and err ${error}`,
          ),
        );
      } else {
        if (payload.data && payload.data.results) {
          SettingsStore.error = "";
          SettingsStore.setSetting(key, payload.data.results);
        } else {
          SettingsStore.error = "NO DATA";
        }
      }
    },

    setSetting: function (key, setting) {
      this.settings[key] = setting;
      SettingsStore.emitChange();
    },
  },

  function (payload) {
    switch (payload.actionType) {
      case SettingsConstants.SUBSCRIPTION_NEW_ZVGB_WILL_GET: {
        break;
      }

      case SettingsConstants.SUBSCRIPTION_NEW_ZVGB_DID_GET: {
        SettingsStore.handlePayload(payload, keys.newZvgb);
        break;
      }

      case SettingsConstants.SUBSCRIPTION_NEW_ZVGB_WILL_ADD: {
        break;
      }

      case SettingsConstants.SUBSCRIPTION_NEW_ZVGB_DID_ADD: {
        SettingsStore.handlePayload(payload, keys.newZvgb);
        break;
      }

      case SettingsConstants.SUBSCRIPTION_NEW_ZVGB_WILL_REMOVE: {
        break;
      }

      case SettingsConstants.SUBSCRIPTION_NEW_ZVGB_DID_REMOVE: {
        SettingsStore.handlePayload(payload, keys.newZvgb);
        break;
      }
    }
    return true;
  },
);

export default SettingsStore;
