import React from "react";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import * as UrlHelpers from "../../libs/UrlHelpers";
import DetailsStore from "../../stores/DetailsStore";
import ElstrLoadingStates from "elstr-jslib/src/scripts/constants/ElstrLoadingStates";
import DetailPisysTableMessage from "./DetailPisysTableMessage";

const DetailPisysAddDocs = createReactClass({
  propTypes: {
    sMatHdrMatnr: PropTypes.string.isRequired,
  },

  render: function () {
    let materialDocuments = DetailsStore.getDocuments();

    let currentLanguage = ElstrLangStore.getCurrentLanguage();

    let bodyItems = [];

    let sMatHdrMatnr = this.props.sMatHdrMatnr;
    let href = UrlHelpers.getHrefMaterialDocument();

    materialDocuments.forEach(matDoc => {
      let doknr = matDoc["doknr"];
      const rowClass = matDoc["dokst"] === "S9" ? "inactive-row" : "";

      bodyItems.push(
        <tr className={rowClass} key={`${matDoc["doknr"]}-${matDoc["dokvr"]}}`}>
          <td>
            <a
              rel="noopener noreferrer"
              target="_blank"
              className="link--download"
              href={`${href}&doknr=${doknr}&materialNumber=${sMatHdrMatnr}`}
            >
              <span className="ficon ficon__download" />
            </a>
          </td>
          <td>{matDoc["dokar"]}</td>
          <td>{matDoc["doknr"]}</td>
          <td>{matDoc["doktl_d"]}</td>
          <td>{matDoc["dokvr"]}</td>
          <td>{matDoc["dokst"]}</td>
          <td>{matDoc["dostx"][currentLanguage]}</td>
          <td>{matDoc["dktxt"][currentLanguage]}</td>
        </tr>,
      );
    });

    // show the user the loading state of the app
    let loadingState = DetailsStore.getLoadingStateAddDocs();

    if (loadingState === ElstrLoadingStates.EMPTY) {
      return <DetailPisysTableMessage isLoading />;
    } else {
      return (
        <table className="detail-pisys-info__product" style={{ marginLeft: "10px" }}>
          <thead>
            <tr>
              <th>{ElstrLangStore.text("DETAIL_TAB_DOWNLOAD")}</th>
              <th>{ElstrLangStore.text("DETAIL_TAB_ADDDOCS_TYPE")}</th>
              <th>{ElstrLangStore.text("DETAIL_TAB_ADDDOCS_NO")}</th>
              <th>{ElstrLangStore.text("DETAIL_TAB_ADDDOCS_PARTNO")}</th>
              <th>{ElstrLangStore.text("DETAIL_TAB_ADDDOCS_VERS")}</th>
              <th>{ElstrLangStore.text("DETAIL_TAB_ADDDOCS_STATE")}</th>
              <th>{ElstrLangStore.text("DETAIL_TAB_ADDDOCS_STATE")}</th>
              <th>{ElstrLangStore.text("DETAIL_TAB_ADDDOCS_SHORTTEXT")}</th>
            </tr>
          </thead>
          <tbody>{bodyItems}</tbody>
        </table>
      );
    }
  },
});

export default DetailPisysAddDocs;
