import pako from "pako";

export function fromJSON(json) {
  const dataString = JSON.stringify(json);
  const plain = new TextEncoder().encode(dataString);
  const compressed = pako.gzip(plain);
  const str = bin2string(compressed);

  if (typeof window !== "undefined") {
    return window.btoa(str);
  }

  // mostly for test runner, we're also using node instead of jsdom
  // see also https://github.com/jsdom/jsdom/issues/2524;
  return _btoa(str);
}

const bin2string = function (array) {
  return String.fromCharCode.apply(String, array);
};

const _btoa = string => {
  let b64 = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

  string = String(string);
  let bitmap,
    a,
    b,
    c,
    result = "",
    i = 0,
    rest = string.length % 3; // To determine the final padding

  for (; i < string.length; ) {
    if ((a = string.charCodeAt(i++)) > 255 || (b = string.charCodeAt(i++)) > 255 || (c = string.charCodeAt(i++)) > 255)
      throw new TypeError(
        "Failed to execute 'btoa' on 'Window': The string to be encoded contains characters outside of the Latin1 range.",
      );

    bitmap = (a << 16) | (b << 8) | c;
    result +=
      b64.charAt((bitmap >> 18) & 63) +
      b64.charAt((bitmap >> 12) & 63) +
      b64.charAt((bitmap >> 6) & 63) +
      b64.charAt(bitmap & 63);
  }

  // If there's need of padding, replace the last 'A's with equal signs
  return rest ? result.slice(0, rest - 3) + "===".substring(rest) : result;
};
