import { useState } from "react";

export function useSvgColor() {
  const [color, setColor] = useState("#0061ad");

  function onMouseOver() {
    setColor("#4a494a");
  }

  function onMouseOut() {
    setColor("#0061ad");
  }

  return [color, onMouseOver, onMouseOut];
}
