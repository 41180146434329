import { Link } from "react-router-dom";
import Translations from "../../libs/Translations";
import React from "react";
import createReactClass from "create-react-class";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import AppsStore from "../../stores/AppsStore";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";
import * as UrlHelpers from "../../libs/UrlHelpers";

const HeaderMaingroupDesktop = createReactClass({
  mixins: [ElstrLangStore.mixin, ElstrUrlHashStore.mixin],

  storeDidChange: function () {
    this.forceUpdate();
  },

  setCloseAllMenus: function (myMainGroup, e) {
    // toggle only when the layout is not desktop (so for tablet and phone)
    let layoutView = AppsStore.getLayoutView();
    let overlayMenuOpen = AppsStore.getOverlayMenuOpen();

    if (layoutView !== "desktop" && overlayMenuOpen) {
      AppsStore.setCloseAllMenus(e);
    }

    window._paq.push(["trackEvent", "Primary Nav", "select", myMainGroup]);
  },

  render: function () {
    // desktop
    let classNameUnlisted = "primary-nav__list";
    let classNameItem = "primary-nav__item";
    let classNameSpan = "";
    let classNameSelected = "primary-nav__item--active";
    let selected = "";

    // other views
    if (AppsStore.getLayoutView() !== "desktop") {
      classNameUnlisted = "menu-overlay-primary-nav__list";
      classNameItem = "menu-overlay-primary-nav__item";
      classNameSpan = "ficon ficon__arrow-primary-nav menu-overlay-primary-nav__icon";
      classNameSelected = "menu-overlay-primary-nav__item--active";
    }

    // the content of the mainGroup bar
    let content = [];

    // apply selected class if mainGroup = all
    let mainGroup = ElstrUrlHashStore.getRouteParam("mainGroup");
    if (mainGroup === undefined && LIBS["appName"] === "catalogue") mainGroup = "all"; // set default
    if (LIBS["appName"] === "intro") mainGroup = ""; // overwrite if in the intro app and there is some value in the url

    if (mainGroup === "all") {
      selected = classNameSelected;
    }

    // prepare path
    let hashesToRemain = UrlHelpers.getAllHashesExcept(
      ["mainView", "mainGroup", "resultMultip", "switched", "ph"],
      false,
    );

    let catalogue = ElstrUrlHashStore.getRouteParam("catalogue") || "global";

    // add mainGroup all to content
    let path = `/catalogue/result/${catalogue}/all#ph=1${hashesToRemain}`;

    content.push(
      <Link key="all mainGroup" to={path} onClick={this.setCloseAllMenus.bind(this, "all")}>
        <li key="all mainGroup li" className={`${classNameItem} ${selected}`}>
          <span className={classNameSpan} />
          {ElstrLangStore.text("HEADER_MAINGROUP_ALL")}
        </li>
      </Link>,
    );

    //add all other maingroups VETROPACK_STATIC_CACHE.navigation[0].maingroups
    let maingroups = VETROPACK_STATIC_CACHE.navigation[0]["maingroups"];
    maingroups.forEach(myMainGroup => {
      selected = "";
      if (mainGroup === myMainGroup) {
        selected = classNameSelected;
      }

      let path = `/catalogue/result/${catalogue}/${myMainGroup}#ph=1${hashesToRemain}`;

      content.push(
        <Link key={myMainGroup} to={path} onClick={this.setCloseAllMenus.bind(this, myMainGroup)}>
          <li key={`li ${myMainGroup}`} className={`${classNameItem} ${selected}`}>
            <span className={classNameSpan} />
            {Translations.getValue("sMatAtvEcatMaingroup", myMainGroup)}
          </li>
        </Link>,
      );
    });

    return <ul className={classNameUnlisted}>{content}</ul>;
  },
});

export default HeaderMaingroupDesktop;
