import AppsStore from "../stores/AppsStore";
import { TYPES } from "../constants/Tooltip";

/**
 * won't show for mobile devices
 * @param textToDisplay string
 * @param type TYPES
 * @param offset number
 * @param e Event
 * @returns {null}
 */
export function showTooltip(textToDisplay, type, offset, e) {
  e.preventDefault();
  e.stopPropagation();
  if (AppsStore.getIsMobileClient()) return null;
  handleShowTooltip(textToDisplay, type, offset, e);
}

/**
 * will show as well for mobile devices
 * @param textToDisplay string
 * @param type TYPES
 * @param offset number
 * @param e Event
 */
export function showTooltipAll(textToDisplay, type, offset, e) {
  e.preventDefault();
  e.stopPropagation();
  handleShowTooltip(textToDisplay, type, offset, e);
}

/**
 *
 * @param type TYPES
 */
export function removeTooltip(type) {
  /**
   * Get the tooltip DOM element
   * @type {NodeList}
   */
  let elementTooltip = document.getElementsByClassName(type);
  elementTooltip = elementTooltip[0];

  let elementTooltipText = elementTooltip.getElementsByClassName("tooltip__text");
  elementTooltipText = elementTooltipText[0];

  // for consistency, if style didn't exist
  // after set the display to none so the tooltip disappears
  // and text string to empty
  if (!elementTooltip.style) elementTooltip.style = {};
  elementTooltip.style.display = "none";
  elementTooltipText.innerHTML = "";
}

/**
 * will handle the corresponding tooltip with text
 * @param textToDisplay string
 * @param type string
 * @param offset number
 * @param e Event
 */
export function handleShowTooltip(textToDisplay, type, offset, e) {
  // Get the coordinates of the DOM Element Position which invoked the event
  let targetDOM = e.target;
  let boundingTarget = targetDOM.getBoundingClientRect();

  /**
   * Get the tooltip DOM element and the DOM text element (first child)
   * @type {NodeList}
   */
  let elementTooltip = document.getElementsByClassName(type);
  elementTooltip = elementTooltip[0];

  let elementTooltipText = elementTooltip.getElementsByClassName("tooltip__text");
  elementTooltipText = elementTooltipText[0];

  const scrollTop = getScrollTop();
  const scrollLeft = getScrollLeft();

  // display the tooltip and fill the the text
  elementTooltipText.innerHTML = textToDisplay;
  elementTooltip.style.display = "block";

  // get bounding after block is displayed and text is filled
  let boundingText = elementTooltipText.getBoundingClientRect();

  // for consistency, if style didn't exist
  if (!elementTooltip.style) elementTooltip.style = {};

  // calculate the left position
  let newLeft = boundingTarget.left + scrollLeft - boundingText.width / 2 + offset + "px";
  if (type === TYPES.left || type === TYPES.leftSticky) {
    let offSetToCenterTheArrowInTheMiddle = 6.25;
    newLeft = boundingTarget.left - offSetToCenterTheArrowInTheMiddle + scrollLeft + "px";
  }
  // calculate the top position
  let newTop = scrollTop + boundingTarget.top - boundingText.height - offset + "px";

  elementTooltip.style.left = newLeft;
  elementTooltip.style.top = newTop;
}

/**
 * Get the offset Position
 * Notice: Browser can have difference
 * src of following code: http://stackoverflow.com/questions/2717252/document-body-scrolltop-is-always-0-in-ie-even-when-scrolling/11102215#11102215
 * @returns {number}
 */
function getScrollLeft() {
  return window.pageXOffset !== "undefined"
    ? window.pageXOffset
    : document.documentElement.scrollLeft
    ? document.documentElement.scrollLeft
    : document.body.scrollLeft
    ? document.body.scrollLeft
    : 0; // fallback if it's not ie11
}

/**
 * Get the offset Position
 * Notice: Browser can have difference
 * src of following code: http://stackoverflow.com/questions/2717252/document-body-scrolltop-is-always-0-in-ie-even-when-scrolling/11102215#11102215
 * @returns {number}
 */
function getScrollTop() {
  return typeof window.pageYOffset !== "undefined"
    ? window.pageYOffset
    : document.documentElement.scrollTop
    ? document.documentElement.scrollTop
    : document.body.scrollTop
    ? document.body.scrollTop
    : 0; // fallback if it's not ie11
}
