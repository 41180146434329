import React from "react";
import createReactClass from "create-react-class";
import HeaderWrap from "../header/HeaderWrap";

const EmptyPage = createReactClass({
  render: () => {
    return (
      <React.Fragment>
        <HeaderWrap emptypage={true} />
        <div className="emptypage-main" />
      </React.Fragment>
    );
  },
});

export default EmptyPage;
