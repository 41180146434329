import React from "react";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import * as UrlHelpers from "../../libs/UrlHelpers";
import MaterialsStore from "../../stores/MaterialsStore";

const ItemCompareMore = createReactClass({
  propTypes: {
    displayMore: PropTypes.bool.isRequired,
    numberOfResults: PropTypes.number.isRequired,
  },

  handleOnClickIncResultMultip: function (positionText) {
    UrlHelpers.incResultMultip();
    window._paq.push(["trackEvent", "product compare", "click", positionText]);
  },

  render: function () {
    if (this.props.displayMore) {
      let numbOfTotalResults = MaterialsStore.getNumberOfResults();
      let numberOfResults = this.props.numberOfResults;
      let positionText = `${ElstrLangStore.text("ITEM_SHOW_MORE_SHOWING")} ${numberOfResults} ${ElstrLangStore.text(
        "ITEM_SHOW_MORE_FROM",
      )} ${numbOfTotalResults}`;
      let loadMoreText = `${ElstrLangStore.text("ITEM_SHOW_MORE")}`;

      return (
        <article
          className="item-compare load-more"
          onClick={this.handleOnClickIncResultMultip.bind(this, positionText)}
        >
          <div className="btn-filter item-compare__load-more-btn">
            <span className="load-more__position">{positionText}</span>
            <span className="load-more__text">
              {loadMoreText}
              <span className="ficon ficon__close btn-filter__close load-more__ficon" />
            </span>
          </div>
        </article>
      );
    } else {
      return <i />;
    }
  },
});

export default ItemCompareMore;
