import { MAIN_VIEW as MV } from "../constants/MainView";
import mcFly from "elstr-jslib/src/scripts/libs/mcFly.js";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";
import ElstrConfigStore from "elstr-jslib/src/scripts/stores/ElstrConfigStore";
import ElstrCache from "elstr-jslib/src/scripts/ElstrCache";
import ElstrEditingStates from "elstr-jslib/src/scripts/constants/ElstrEditingStates";
import ElstrLoadingStates from "elstr-jslib/src/scripts/constants/ElstrLoadingStates";
import ElstrUserConstants from "elstr-jslib/src/scripts/constants/ElstrUserConstants";
import SelectionConstants from "../constants/actions/SelectionConstants";
import MaterialsStore from "./MaterialsStore";
import SelectionActions from "../actions/SelectionActions";
import * as UrlHelpers from "../libs/UrlHelpers";
import * as Sentry from "@sentry/browser";

const SelectionStore = mcFly.createStore(
  {
    mySelectedMaterials: [],
    foreignSelectedMaterials: [],
    editingSelectionId: "", // will be used as an indicator if a user is editing a selection, empty string means not, not an empty string will indicate editing
    editingSelectionSource: "",

    error: null,
    loadingState: ElstrLoadingStates.EMPTY,
    editingState: ElstrEditingStates.NORMAL,

    // GETTERS
    getLoadingState: function () {
      return this.loadingState;
    },

    getEditingState: function () {
      return this.editingState;
    },

    getMySelectedMaterials: function () {
      //
      // Is an Array of Obj and looks like this:
      // "results":[{
      // "materialNumber": "1003452"
      // }, {
      // "materialNumber": "1003451"
      // },{
      // "materialNumber": "1003455"
      // }],
      //
      return SelectionStore.mySelectedMaterials;
    },

    getRenderedMaterialNumbersAsList: function () {
      /**
       * Returns in any case the material numbers that are shown on the screen
       * Returns this format: ["1003452","1003451","1003455"]
       */

      let selectionIdURL = ElstrUrlHashStore.get("selectionId");
      let unsortedMaterials = null;

      // Either will be mySelection or Foreign Selection Materials
      if (SelectionStore.isSelectionUrlMySelection() || selectionIdURL === undefined) {
        // Result for Store for MySelection
        unsortedMaterials = SelectionStore.mySelectedMaterials;
      } else if (!SelectionStore.isSelectionUrlMySelection()) {
        // Result for Store Foreign Selection
        unsortedMaterials = SelectionStore.foreignSelectedMaterials;
      }

      if (SelectionStore.isEditing()) {
        unsortedMaterials = SelectionStore.foreignSelectedMaterials;
      }

      let selectedMaterialsAsArray = [];
      // Prepare as List
      unsortedMaterials.forEach(material => {
        selectedMaterialsAsArray.push(material["materialNumber"]);
      });

      return selectedMaterialsAsArray;
    },

    getSortedRenderedMaterialNumbersAsList: function () {
      let sortedMaterials = SelectionStore.getSortedRenderedMaterials();

      let sortedMaterialsNumbers = sortedMaterials.map(mat => {
        return mat.sMatHdrMatnr;
      });

      return sortedMaterialsNumbers;
    },

    getSortedRenderedMaterials: function () {
      let selectedMaterials = SelectionStore.getRenderedMaterialNumbersAsList();

      let materialsToRender = [];
      if (selectedMaterials) {
        materialsToRender = selectedMaterials.map(materialNumber => {
          return MaterialsStore.getMaterialByMatnr(materialNumber);
        });
      }

      // Sort the materialsToRender before providing to grid / list / compare view
      if (materialsToRender.length > 0) {
        materialsToRender = MaterialsStore.sortAndOrderMaterials(materialsToRender);
      }

      return materialsToRender;
    },

    getForeignSelectedMaterials: function () {
      //  see getMySelectedMaterials()
      return SelectionStore.foreignSelectedMaterials;
    },

    getEditingSelectionId: function () {
      return SelectionStore.editingSelectionId;
    },

    getEditingSelectionSource: function () {
      return SelectionStore.editingSelectionSource;
    },

    /**
     * Regards if a user is looking at his own selection or at a foreign selection
     * Will return the according materials for that sorted and ordered
     * @return {Array}
     */
    getMaterialsToRender: function () {
      let selectionIdURL = ElstrUrlHashStore.get().selectionId;

      let selectedMaterials = [];
      if (SelectionStore.isSelectionUrlMySelection() || selectionIdURL === undefined) {
        selectedMaterials = SelectionStore.getMySelectedMaterials();
      } else if (!SelectionStore.isSelectionUrlMySelection()) {
        selectedMaterials = SelectionStore.getForeignSelectedMaterials();
      }

      let materialsToRender = [];
      if (selectedMaterials) {
        materialsToRender = selectedMaterials.map(material => {
          return MaterialsStore.getMaterialByMatnr(material.materialNumber);
        });
      }

      // Sort the materialsToRender before providing to grid / list / compare view
      if (materialsToRender.length > 0) {
        materialsToRender = MaterialsStore.sortAndOrderMaterials(materialsToRender);
      }

      return materialsToRender;
    },

    getAffectedSelectionId: () => {
      let mySelectionId = ElstrCache.get("mySelectionId");
      let editingSelectionId = SelectionStore.getEditingSelectionId();

      // Either is my Selection or an foreign selection when editing
      let selectionId = mySelectionId;
      if (editingSelectionId !== "") {
        selectionId = editingSelectionId;
      }
      return selectionId;
    },

    // SETTERS
    setEditingSelectionId: value => {
      SelectionStore.editingSelectionId = value;
    },

    setEditingSelectionSource: value => {
      SelectionStore.editingSelectionSource = value;
    },

    /**
     *
     * @returns {boolean}
     */
    isEditing: () => {
      return SelectionStore.getEditingSelectionId() !== "";
    },

    toggleEditMode: function () {
      let selectionId = "";
      let selectionSource = "";

      // adding or remove hash moveBackToResults=true in the url
      // the reason is, that when editing a pisys selection, after activating the switch mode
      // the user might push on SelectionPageClose to return to the results page
      // the SelectionPageClose Component will by default use a page back instead of navigation
      // adding moveBackToResults will prevent page back and move to results page
      if (SelectionStore.isEditing()) {
        UrlHelpers.clearHash("moveBackToResults");
      } else {
        let hashObj = ElstrUrlHashStore.get();
        selectionId = hashObj["selectionId"];
        selectionSource = hashObj["selectionSource"];
        UrlHelpers.setHashNoHistory({ moveBackToResults: true });
      }

      SelectionStore.setEditingSelectionId(selectionId);
      SelectionStore.setEditingSelectionSource(selectionSource);
      SelectionStore.emitChange();
    },

    // CONTROLLERS
    checkIfEditingSelectionShouldBeKept: () => {
      if (SelectionStore.isEditing()) {
        if (SelectionStore.getEditingSelectionSource() === "catalogue") {
          return true;
        }
      }
      return false;
    },

    checkIfSwitchingFromMyEcatSelectionToPisys: () => {
      // first criteria is to be in eCat page and not in PISYS
      if (!ElstrConfigStore.option("isPisysSite")) {
        if (SelectionStore.isSelectionUrlMySelection()) {
          return true;
        }
      }
      return false;
    },

    toKeepSelectionIdAndSelectionSource: () => {
      let hashObj = ElstrUrlHashStore.get();
      let selectionSource = hashObj["selectionSource"];
      let mainView = UrlHelpers.getMainView();

      // the criteria is the selection source must be catalogue
      // and the mainView must be selection
      return selectionSource === "catalogue" && mainView === MV.SELECTION;
    },

    isSelectionUrlMySelection: () => {
      let selectionIdURL = ElstrUrlHashStore.get("selectionId");
      let mySelectionId = ElstrCache.get("mySelectionId");
      return selectionIdURL === mySelectionId;
    },

    getOnlyExistingMaterials: function (payload) {
      let results = [];

      // not in intro since intro a user might have materials in his selection that are not available there
      if (LIBS.appName !== "intro") {
        payload.data.results.forEach(function rmUndefinedNumbers(pos, i) {
          // will return undefined when the materialNumber is not in the VSC
          let check = MaterialsStore.getMaterialByMatnr(pos.materialNumber);
          // only add existing numbers from the selection
          if (check) {
            // push to results if it exists
            results.push(pos);
            // possibility to remove it from the db itself for the moment left out
          }
        });
      } else {
        results = payload.data.results;
      }
      return results;
    },

    // payload handlers
    selectionPayloadHandler: function (payload) {
      let error = payload.error;
      if (error && error !== "") {
        SelectionStore.error = error;
        Sentry.captureException(
          new Error(
            `err in SelectionStore.selectionPayloadHandler with payload:
             ${JSON.stringify(payload)} and err ${error}`,
          ),
        );
      }

      if (payload.data && payload.data.results) {
        let selectionIdFromResult = payload.data.selectionId;

        let results = SelectionStore.getOnlyExistingMaterials(payload);

        let selectionIdURL = ElstrUrlHashStore.get("selectionId");
        let mySelectionId = ElstrCache.get("mySelectionId");

        // Switching Rule 2)
        // Don't keep editing when switched from Pisys Foreign to eCat My Selection
        // Otherwise keep on editing
        // Indicator to test is if keepEditingAfterSwitch is in the url
        if (ElstrUrlHashStore.get()["keepEditingAfterSwitch"]) {
          // but only when it's not my selection
          if (!SelectionStore.isSelectionUrlMySelection()) {
            SelectionStore.toggleEditMode();
          }

          // remove the property from the url hash
          UrlHelpers.clearHash("keepEditingAfterSwitch");
        }

        if (
          SelectionStore.isSelectionUrlMySelection() ||
          (selectionIdURL === undefined && !SelectionStore.isEditing()) ||
          selectionIdFromResult === mySelectionId
        ) {
          // Result for Store for MySelection
          SelectionStore.mySelectedMaterials = results;
        } else if (!SelectionStore.isSelectionUrlMySelection()) {
          // Result for Store Foreign Selection
          SelectionStore.foreignSelectedMaterials = results;
        }

        SelectionStore.error = "";
      } else {
        error = "NO DATA";
        SelectionStore.error = error;
      }

      SelectionStore.emitChange();
    },
  },
  function (payload) {
    switch (payload.actionType) {
      case SelectionConstants.SELECTION_GET_MATERIALS_WILL_GET: {
        break;
      }

      case SelectionConstants.SELECTION_GET_MATERIALS_DID_GET: {
        mcFly.dispatcher.waitFor([ElstrUrlHashStore.dispatcherID]);

        SelectionStore.selectionPayloadHandler(payload);

        break;
      }

      case SelectionConstants.SELECTION_ADD_MATERIALS_WILL_ADD: {
        break;
      }

      case SelectionConstants.SELECTION_ADD_MATERIALS_DID_ADD: {
        mcFly.dispatcher.waitFor([ElstrUrlHashStore.dispatcherID]);

        SelectionStore.selectionPayloadHandler(payload);

        break;
      }

      case SelectionConstants.SELECTION_REMOVE_MATERIALS_WILL_REMOVE: {
        break;
      }

      case SelectionConstants.SELECTION_REMOVE_MATERIALS_DID_REMOVE: {
        mcFly.dispatcher.waitFor([ElstrUrlHashStore.dispatcherID]);

        SelectionStore.selectionPayloadHandler(payload);

        break;
      }

      case ElstrUserConstants.ELSTR_USER_DID_LOGIN_SUCCESS: {
        // load materials when user did login
        let mySelectionId = ElstrCache.get("mySelectionId");
        let selectionSource = UrlHelpers.getSelectionSourceForServerRequest();

        let payload = { selectionId: mySelectionId, selectionSource };
        SelectionActions.getMaterials({ payload });

        // after login, there might be a SelectionId in the URL which differs from the own SelectionId
        UrlHelpers.requestSelectionMaterialsWhenSelectionIdInUrl();

        break;
      }

      case ElstrUserConstants.ELSTR_USER_DID_LOGOUT: {
        // load materials when user did login
        SelectionStore.mySelectedMaterials = [];
        SelectionStore.foreignSelectedMaterials = [];

        break;
      }
    }

    return true;
  },
);

export default SelectionStore;
