import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import * as TT from "../../libs/Tooltip";
import { TYPES } from "../../constants/Tooltip";
import React from "react";

export const ExportToExcel = ({ exportPisysTable }) => {
  // list with tabs which shouldn't render the additional menu
  const blackListTabs = ["blowMoulds"];

  if (blackListTabs.includes(ElstrUrlHashStore.get("pisysTab"))) {
    return null;
  }

  const tooltip = ElstrLangStore.text("EXPORT_TO_EXCEL");

  return (
    <span
      className="ficon ficon__excel-export view-nav-item__icon pointer"
      onClick={exportPisysTable}
      onMouseOver={e => TT.showTooltip(tooltip, TYPES.left, 12, e)}
      onMouseOut={() => TT.removeTooltip(TYPES.left)}
      onMouseDown={() => TT.removeTooltip(TYPES.left)}
    />
  );
};
