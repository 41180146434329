import { Link } from "react-router-dom";
import Translations from "../../libs/Translations";
import React from "react";
import { MAIN_VIEW as MV } from "../../constants/MainView";
import SelectionStore from "../../stores/SelectionStore";
import createReactClass from "create-react-class";
import AppsStore from "../../stores/AppsStore";
import PropTypes from "prop-types";
import HeaderMaingroupDesktop from "./HeaderMaingroupDesktop";
import HeaderColRightSettingsDesktop from "./HeaderColRightSettingsDesktop";
import * as UrlHelpers from "../../libs/UrlHelpers";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";

const HeaderColRight = createReactClass({
  mixins: [SelectionStore.mixin],

  propTypes: {
    // emptypage is for pages like login / error where certain elements won't appear
    emptypage: PropTypes.bool,
  },

  storeDidChange: function () {
    this.forceUpdate();
  },

  handleOnClickHumburger: function () {
    AppsStore.toggleOverlayOpenMenu();
  },

  // Render
  render: function () {
    // return empty if restricted
    if (this.props.emptypage) {
      return <i />;
    }

    let quantityOfMySelection = SelectionStore.getMySelectedMaterials().length;
    // when editing, quantity of the foreign selection is shown
    if (SelectionStore.isEditing()) quantityOfMySelection = SelectionStore.getForeignSelectedMaterials().length;

    // prepare path
    let path = UrlHelpers.getLinkPathForSelection();

    let classNameEditing = "";
    if (SelectionStore.isEditing()) classNameEditing = "counter-editing";

    const mainViewSettings = UrlHelpers.getMainView() === MV.SETTINGS;

    const selectionSymbol = !mainViewSettings ? (
      <Link to={path}>
        <div className="header__my-selection">
          <span className={`my-selection__counter ${classNameEditing}`}>{quantityOfMySelection}</span>
          <span className="ficon ficon__my-selection my-selection__flag" />
        </div>
      </Link>
    ) : null;

    const headerTitle = !mainViewSettings ? Translations.getCurrentCatText() : ElstrLangStore.text("USER_SETTINGS");

    return (
      <div className="header__col--right">
        <h1 className="header__title">{headerTitle}</h1>
        {selectionSymbol}
        <div className="header__humburger-nav humburger-nav desktop--hidden" onClick={this.handleOnClickHumburger} />
        <nav className="header__primary-nav phone-tablet--hidden">
          <h2 className="visually-hidden">Main navigation</h2>
          {/* <!-- maingroup start -->*/}
          {this.renderHeaderMenu()}
          {/* <!-- maingroup end -->*/}
        </nav>
      </div>
    );
  },

  renderHeaderMenu: () => {
    const settings = UrlHelpers.getMainView() === MV.SETTINGS;
    return settings ? <HeaderColRightSettingsDesktop /> : <HeaderMaingroupDesktop />;
  },
});

export default HeaderColRight;
