let keyMirror = require("keymirror");

module.exports = keyMirror({
  DOSSIER_SET_DOSSIER_DATA_WILL: null,
  DOSSIER_SET_DOSSIER_DATA_DID: null,

  DOSSIER_SET_DOCS_WILL: null,
  DOSSIER_SET_DOCS_DID: null,

  DOSSIER_SET_ATTRIBUTES_WILL: null,
  DOSSIER_SET_ATTRIBUTES_DID: null,

  DOSSIER_GET_DOCUMENTS_FROM_NUMBERS_WILL: null,
  DOSSIER_GET_DOCUMENTS_FROM_NUMBERS_DID: null,

  DOSSIER_INIT_ON_DB: null,
});
