import ElstrFlux from "elstr-jslib/src/scripts/libs/ElstrFlux.js";
import ElstrIo from "elstr-jslib/src/scripts/ElstrIo.js";
import SelectionConstants from "../constants/actions/SelectionConstants";

const elstrIo = new ElstrIo({
  abortStaleRequests: true,
  maxTimeout: 60000 * 5, // 5 Min
});

const SelectionActions = ElstrFlux.createActions(
  [
    {
      className: "VETROPACK_WidgetServer_JSON_Selection",
      methodName: "getMaterials", // "payload" {"selectionId": "cf5d969c-bbb5-11e6-a4a6-cec0c932ce01", "sourceSelection" : "local"}
      constantWill: SelectionConstants.SELECTION_GET_MATERIALS_WILL_GET,
      constantDid: SelectionConstants.SELECTION_GET_MATERIALS_DID_GET,
    },
    {
      className: "VETROPACK_WidgetServer_JSON_Selection",
      methodName: "addMaterials", // "payload" {"selectionId": "cf5d969c-bbb5-11e6-a4a6-cec0c932ce01", "materialNumbers": ["1003455", "1003456"], "sourceSelection" : "local"}
      constantWill: SelectionConstants.SELECTION_ADD_MATERIALS_WILL_ADD,
      constantDid: SelectionConstants.SELECTION_ADD_MATERIALS_DID_ADD,
    },
    {
      className: "VETROPACK_WidgetServer_JSON_Selection",
      methodName: "removeMaterials", // "payload" {"selectionId": "cf5d969c-bbb5-11e6-a4a6-cec0c932ce01", "materialNumber": ["1003455", "1003456"], "sourceSelection" : "local"}
      constantWill: SelectionConstants.SELECTION_REMOVE_MATERIALS_WILL_REMOVE,
      constantDid: SelectionConstants.SELECTION_REMOVE_MATERIALS_DID_REMOVE,
    },
  ],
  {},
  elstrIo,
);

export default SelectionActions;
