import LinkToDetail from "../router/LinkToDetail";
import * as Attributes from "../../libs/Attributes";
import React from "react";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import ItemListField from "./ItemListField";
import ItemLabelTop from "./ItemLabelTop";
import ItemToggleSelection from "./ItemToggleSelection";
import ItemListWidthStore from "../../stores/ItemListWidthStore";
import MaterialsStore from "../../stores/MaterialsStore";
import AppsStore from "../../stores/AppsStore";

const ItemList = createReactClass({
  mixins: [AppsStore.mixin],

  propTypes: {
    material: PropTypes.object.isRequired,
    attributesSorted: PropTypes.array.isRequired,
    isFirstOne: PropTypes.bool, // the first component will store his width
    counterForUpdate: PropTypes.number, // increases when a user changes attributes settings (displaying / order)
  },

  updateItemListWidth: function () {
    // setTimeout necessary for css to load
    setTimeout(function () {
      let width = document.getElementsByClassName("item-list")[0].offsetWidth;
      ItemListWidthStore.setItemListWidth(width);
    }, 0);
  },

  componentDidMount: function () {
    if (this.props.isFirstOne) {
      this.updateItemListWidth();
    }
  },

  componentDidUpdate: function () {
    if (this.props.isFirstOne) {
      this.updateItemListWidth();
    }
  },

  storeDidChange: function () {
    // main purpose is to update when layoutView changes because then the number of the elem width is changed
    // and therefore a new ItemListWidthStore.setItemListWidth(width) must be set
    if (this.props.isFirstOne) {
      this.forceUpdate();
    }
  },

  shouldComponentUpdate: function (nextProps) {
    return (
      this.props.material.sMatHdrMatnr !== nextProps.material.sMatHdrMatnr ||
      this.props.isFirstOne !== nextProps.isFirstOne ||
      this.props.counterForUpdate !== nextProps.counterForUpdate
    );
  },

  render: function () {
    let material = this.props.material;

    let imgKey = `${material.sMatAtvMatnrZvgb}`;
    let ImagePath = MaterialsStore.getImagePath(imgKey, "list");
    let sMatHdrMatnr = material["sMatHdrMatnr"]; // 30040

    let itemListColContent = [];
    let attributesSorted = this.props.attributesSorted;
    attributesSorted.forEach(attribute => {
      // check if the attribute should be shown
      if (attribute.listDisplayDefault === true && attribute.listDisplayAllowed === true) {
        let dataAttributeKey = Attributes.getRealDataAttributeKey(attribute["dataAttributeKey"]);

        itemListColContent.push(
          <ItemListField
            key={dataAttributeKey}
            material={material}
            dataAttributeKey={dataAttributeKey}
            listDisplaySize={attribute["listDisplaySize"]}
          />,
        );
      }
    });

    return (
      <article className="item-list" key={sMatHdrMatnr}>
        <ItemToggleSelection sMatHdrMatnr={sMatHdrMatnr} material={material} />
        <ItemLabelTop material={material} />
        <div className="item-list__link">
          <LinkToDetail renderedFrom="List" material={this.props.material}>
            <div className="item-list__col-img">
              <img src={ImagePath} alt="glas img" className="item-list__img" />
            </div>
          </LinkToDetail>
        </div>
        {itemListColContent}
      </article>
    );
  },
});

export default ItemList;
