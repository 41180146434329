import Translations from "../../libs/Translations";
import React from "react";
import createReactClass from "create-react-class";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import AppsStore from "../../stores/AppsStore";
import SettingsStore from "../../stores/SettingsStore";
import SettingsActions from "../../actions/SettingsActions";

const ALL = "ALL";

function _setState() {
  const checkedMainGroups = SettingsStore.getSetting("newZvgb");

  return { checkedMainGroups };
}

const SettingsNewZvgb = createReactClass({
  mixins: [ElstrLangStore.mixin, AppsStore.mixin, SettingsStore.mixin],

  getInitialState: function () {
    return _setState();
  },

  storeDidChange: function () {
    this.setState(_setState());
  },

  /**
   * @param mainGroup string
   */
  toggleCheckbox: function (mainGroup) {
    const isChecked = this.state.checkedMainGroups.includes(mainGroup);

    if (isChecked) {
      SettingsActions.removeSubscriptionNewZvgb({ mainGroup });
    } else {
      SettingsActions.addSubscriptionNewZvgb({ mainGroup });
    }
  },

  /**
   * @param myMainGroup string
   * @return boolean
   */
  isChecked: function (myMainGroup) {
    const checkedMainGroups = this.state.checkedMainGroups;

    // always true for all
    if (this.state.checkedMainGroups.includes(ALL)) {
      return true;
    }

    return checkedMainGroups.includes(myMainGroup);
  },

  render: function () {
    let allTranslated = ElstrLangStore.text("HEADER_MAINGROUP_ALL");
    let content = [];

    // prepare content for the first checkbox -> ALL
    const checked = this.isChecked(ALL);
    content.push(
      <li className="item-setting-list__item" key={ALL}>
        <input
          className="item-setting-list__checkbox"
          type="checkbox"
          onChange={this.toggleCheckbox.bind(this, ALL)}
          checked={checked}
          name={ALL}
          value={ALL}
        />
        <label htmlFor="setting--ALL">{allTranslated}</label>
      </li>,
    );

    // prepare content for the other checkboxes -> maingroups
    let maingroups = VETROPACK_STATIC_CACHE.navigation[0]["maingroups"];
    maingroups.forEach(mainGroup => {
      const checked = this.isChecked(mainGroup);
      // we disable checkbox when the ALL is selected
      const disabled = this.isChecked(ALL);

      content.push(
        <li className="item-setting-list__item" key={mainGroup}>
          <input
            className="item-setting-list__checkbox"
            checked={checked}
            onChange={this.toggleCheckbox.bind(this, mainGroup)}
            type="checkbox"
            disabled={disabled}
            name={mainGroup}
            value={mainGroup}
          />
          <label htmlFor={`setting--${mainGroup}`}>{Translations.getValue("sMatAtvEcatMaingroup", mainGroup)}</label>
        </li>,
      );
    });

    return <ul className="settings-item-settings__list">{content}</ul>;
  },
});

export default SettingsNewZvgb;
