import React from "react";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import HeaderColRight from "./HeaderColRight";
import HeaderColLeftCenter from "./HeaderColLeftCenter";

const HeaderWrapContent = createReactClass({
  propTypes: {
    // emptypage is for pages like login / error where certain elements won't appear
    emptypage: PropTypes.bool,
  },

  render: function () {
    return (
      <header key="header" className="header">
        <h2 className="visually-hidden">Header</h2>
        <div className="header__container">
          <HeaderColLeftCenter emptypage={this.props.emptypage} />
          <HeaderColRight emptypage={this.props.emptypage} />
        </div>
      </header>
    );
  },
});

export default HeaderWrapContent;
