import React from "react";
import createReactClass from "create-react-class";
import HeaderWrap from "../header/HeaderWrap";
import HeaderSticky from "../header/HeaderSticky";
import ContainerFilters from "../filter/ContainerFilters";
import ContainerResultMaterials from "../article/ContainerResultMaterials";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";
import ElstrUrlHashActions from "elstr-jslib/src/scripts/actions/ElstrUrlHashActions";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import ElstrConfigStore from "elstr-jslib/src/scripts/stores/ElstrConfigStore";
import AppsStore from "../../stores/AppsStore";
import RouteHistoryStore from "../../stores/RouteHistoryStore";
import StaticCacheVersionsStore from "../../stores/StaticCacheVersionsStore";
import { setDocTitle } from "../../libs/Meta";

const Result = createReactClass({
  mixins: [ElstrLangStore.mixin, AppsStore.mixin, StaticCacheVersionsStore.mixin, ElstrUrlHashStore.mixin],

  statics: {
    getDerivedStateFromProps(newProps) {
      ElstrUrlHashActions.setRouteParams(newProps.location.pathname, newProps.match.params);

      RouteHistoryStore.setMaingroup(newProps.match.params.mainGroup);
      RouteHistoryStore.setCatalogue(newProps.match.params.catalogue);
      return null;
    },
  },

  _setState() {
    let currentLang = ElstrLangStore.getCurrentLanguage();
    let layoutView = AppsStore.getLayoutView();
    let successCounter = StaticCacheVersionsStore.getSuccessCounter();

    let resultView = ElstrUrlHashStore.get("resultView") || "grid";

    let match = { params: { mainGroup: "", catalogue: "" } };
    match.params.mainGroup = this.props.match.params.mainGroup;
    match.params.catalogue = this.props.match.params.catalogue;

    return { currentLang, layoutView, resultView, successCounter, match };
  },

  getInitialState: function () {
    return this._setState();
  },

  storeDidChange: function () {
    this.setState(this._setState());
  },

  componentDidMount: function () {
    // set title window title
    let isPisysSite = ElstrConfigStore.option("isPisysSite");
    let title = ElstrLangStore.text("TITLE__EKATALOG");
    if (isPisysSite) title = ElstrLangStore.text("TITLE__PISYS");
    setDocTitle(`${title}`);
  },

  shouldComponentUpdate: function (nextProps, nextState) {
    return (
      this.state.currentLang !== nextState.currentLang ||
      this.state.layoutView !== nextState.layoutView ||
      this.state.resultView !== nextState.resultView ||
      this.state.successCounter !== nextState.successCounter ||
      this.state.match.params.mainGroup !== this.props.match.params.mainGroup ||
      this.state.match.params.catalogue !== this.props.match.params.catalogue
    );
  },

  render: function () {
    // in case the resultView is compare an additional className is required
    let classNameCompare = this.state.resultView === "compare" ? "main__body--compare-view" : "";
    return (
      <React.Fragment>
        <HeaderWrap />
        <div className="main">
          <h2 className="visually-hidden">Main</h2>
          <HeaderSticky key="HeaderSticky" />
          <div className={`main__body ${classNameCompare}`}>
            <aside className="main-body__aside">
              {" "}
              {/* dont put aside in ContainerFilters, because in mobile view, ContainerFilters aren't aside */}
              <ContainerFilters key="ContainerFilters Desktop" renderNotFor={["phone", "tablet"]} />
            </aside>
            <ContainerResultMaterials />
          </div>
        </div>
      </React.Fragment>
    );
  },
});

export default Result;
