import React from "react";
import MaterialsStore from "../../stores/MaterialsStore";
import Top from "./Top";
import ContainerCompare from "../article/ContainerCompare";
import Bottom from "./Bottom";
import SelectionStore from "../../stores/SelectionStore";

export default function Body({ sMatHdrMatnrInHash }) {
  let materialsToRender = SelectionStore.getSortedRenderedMaterials();

  // this is the entry point from the Detail Page
  if (sMatHdrMatnrInHash) {
    materialsToRender = [MaterialsStore.getMaterialByMatnr(sMatHdrMatnrInHash)];
  }

  // Hide the body when there are no selectedMaterials and change the title
  let visibility = "visible";
  if (materialsToRender.length <= 0) {
    visibility = "hidden";
  }

  return (
    <div className="common-page__body">
      <div className="common-page-body__container">
        <div className="common-page-body__header phone--hidden" />
        <div style={{ visibility }}>
          <Top sMatHdrMatnrInHash={sMatHdrMatnrInHash} />
          <ContainerCompare materialsToRender={materialsToRender} scrollActive={true} />
          <Bottom />
        </div>
      </div>
    </div>
  );
}
