import ElstrFlux from "elstr-jslib/src/scripts/libs/ElstrFlux.js";
import AppsConstants from "../constants/actions/AppsConstants";

const AppsActions = ElstrFlux.createActions(
  [
    {
      className: "VETROPACK_WidgetServer_JSON_Cache",
      methodName: "getStaticCacheVersions",
      constantWill: AppsConstants.STATIC_CACHE_VERSIONS_WILL_GET,
      constantDid: AppsConstants.STATIC_CACHE_VERSIONS_DID_GET,
    },
  ],
  {},
);

export default AppsActions;
