import * as TT from "../../libs/Tooltip";
import ItemColor from "./ItemColor";
import Translations from "../../libs/Translations";
import * as Attributes from "../../libs/Attributes";
import React from "react";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import ElstrConfigStore from "elstr-jslib/src/scripts/stores/ElstrConfigStore";
import AppsStore from "../../stores/AppsStore";
import query from "array-query";
import * as UrlHelpers from "../../libs/UrlHelpers";

const ItemColors = createReactClass({
  mixins: [ElstrLangStore.mixin, AppsStore.mixin],

  propTypes: {
    colorSelf: PropTypes.string.isRequired,
    sMatHdrMatnr: PropTypes.string.isRequired,
    colorsAlternate: PropTypes.array.isRequired,
    isOverlay: PropTypes.bool.isRequired,
    renderedView: PropTypes.string,
    limit: PropTypes.number,
  },

  storeDidChange: function () {
    this.forceUpdate();
  },

  addColor: function (colorKey, colorStyle, selectedStyle, style, sMatHdrMatnr, multiLangType, mainSMatHdrMatnr) {
    //
    // create the class style:
    // ex: selected standard colour: 'product-color product-color--WE product-color--selected'
    // ex: non selected standard color: 'product-color product-color--WE'
    // ex: selected text color: 'product-color-text roduct-color-text--selected'
    // ex: non selected text color: 'product-color-text--selected'
    //
    let fullClassStyle = "";
    if (style === "color") {
      fullClassStyle = `${colorStyle}${colorKey} ${selectedStyle}`;
    } else if (style === "text") {
      fullClassStyle = `${colorStyle} ${selectedStyle}`;
    }

    let selectedStyleTextOverlay = "";
    if (selectedStyle !== "") selectedStyleTextOverlay = "overlay-colors__color-text--selected";

    let tooltip = "";
    if (multiLangType === 3) {
      tooltip += `${colorKey} `;
    }
    tooltip += Translations.getValue("sMatAtvFarbe", colorKey) || `${ElstrLangStore.text("ITEM_COLOR_UNDEFINED")}`;

    // this will remove the link to the same material as rendered in the detail top table
    let noLink = UrlHelpers.controlIfRenderedMaterialNumber(sMatHdrMatnr);

    let colorContent = null;
    // return the corresponding style to render

    if (this.props.isOverlay) {
      // FOR OVERLAY
      let classNameOverlay = `overlay-colors__color-text ${selectedStyleTextOverlay}`;
      colorContent = <span className={classNameOverlay}>{tooltip}</span>;
    } else if (style === "color") {
      // FOR STANDARD VIEW WITH COLOR ITEM
      colorContent = <span key={`${colorKey} ${sMatHdrMatnr}`} />;
    } else if (style === "text") {
      // FOR STANDARD VIEW COLOR TEXT
      colorContent = <span key={`${colorKey} ${sMatHdrMatnr}`}>{tooltip}</span>;
    }

    return (
      <ItemColor
        key={`${colorKey} ${sMatHdrMatnr}`}
        sMatHdrMatnr={sMatHdrMatnr}
        mainSMatHdrMatnr={mainSMatHdrMatnr}
        showTooltip={TT.showTooltip}
        removeTooltip={TT.removeTooltip}
        colorKey={colorKey}
        fullClassStyle={fullClassStyle}
        noLink={noLink}
        isOverlay={this.props.isOverlay}
        tooltip={tooltip}
      >
        {colorContent}
      </ItemColor>
    );
  },

  render: function () {
    let colorSelf = this.props.colorSelf;
    let colorsAlternate = this.props.colorsAlternate;

    let uniteConfig = ElstrConfigStore.option("uniteSameZvgb");

    // sort according to uniteConfig
    if (uniteConfig === "lowestZfer") {
      colorsAlternate = query().sort("sMatHdrMatnr").asc().on(colorsAlternate);
    } else if (uniteConfig === "newestMstae") {
      colorsAlternate = query().sort("sMatHdrMatnr").desc().on(colorsAlternate);
    }

    // styles
    let classNameColor = "product-color product-color--";
    let classNameColorSelected = " product-color--selected";

    let classNameText = "product-color-text";
    let classNameTextSelected = " product-color-text--selected";

    // REWRITE STYLES FOR OVERLAY
    if (this.props.isOverlay) {
      classNameColor = "overlay-color__item product-color product-color--";
      classNameColorSelected = " product-color--selected";
      classNameText = "overlay-color__item product-color product-color--none";
      classNameTextSelected = classNameColorSelected;
    }

    // an array of all standard colors according to config.ini
    let standardColours = ElstrConfigStore.option("standardColours");

    // distinct all colors from alternate colors data. alternate colors may have they'r colors more than once for
    // different artNum

    // return only the colors of the object colorsAlternate object
    let allAddColors = colorsAlternate.map(obj => obj.sMatAtvFarbe);

    // return distinct values: ex from: http://stackoverflow.com/a/14438954
    // uniqueCol:                   ["BU", "CE"]
    let uniqueCol = [...new Set(allAddColors)];
    uniqueCol.push(colorSelf);
    uniqueCol.sort();

    // assign array with corresponding sMatHdrMatnr to uniqueCol Array
    // ex: uniqueColsMatHdrMatnrs : ["232323","111111"]
    // uniqueCol:                   ["BU", "CE"]
    let uniqueColsMatHdrMatnrs = [];
    let sMatHdrMatnr = this.props.sMatHdrMatnr;

    uniqueCol.forEach(color => {
      if (color === colorSelf) {
        // own color gets his own sMatHdrMatnr
        uniqueColsMatHdrMatnrs.push(sMatHdrMatnr);
      } else {
        // alternate color gets his alternate color sMatHdrMatnr
        colorsAlternate.every(alternativeColArr => {
          if (alternativeColArr["sMatAtvFarbe"] === color) {
            uniqueColsMatHdrMatnrs.push(alternativeColArr["sMatHdrMatnr"]);
            return false; // quit the every loop
          }
          return true; // keep looping
        });
      }
    });

    // content for colors who are displayed with color
    let contentColors = [];
    // content for colors who are displayed with text
    let contentColorsText = [];

    // this is a counter for the grid and list view where the space to place the number is limited
    // here are a few rules for this counter:
    // counter is only relevant for list and grid so far
    // - grid has a limit of 10 units
    // - list has a limit of 8 units
    // - a standard colour counts as 1 unit
    // - a list text counts as 4 units
    // - before the counter is greater than the limit, the class 'product-color-more' should be rendered and no additional colors anymore
    let limit = this.props.limit;
    let counter = 0;

    // fill first the own color
    let standard = false;
    standardColours.forEach(colorStandard => {
      if (colorSelf === colorStandard) standard = true;
    });

    // push either to contentColors or contentColorsText
    let selectedStyle = "";
    let multiLangType = Attributes.getMultilangType("sMatAtvFarbe");
    if (standard) {
      counter += 1; // for standard col
      selectedStyle = classNameColorSelected;
      contentColors.push(
        this.addColor(
          colorSelf,
          classNameColor,
          selectedStyle,
          "color",
          sMatHdrMatnr,
          multiLangType,
          this.props.sMatHdrMatnr,
        ),
      );
    } else {
      counter += 4; // for text
      selectedStyle = classNameTextSelected;
      contentColorsText.push(
        this.addColor(
          colorSelf,
          classNameText,
          selectedStyle,
          "text",
          sMatHdrMatnr,
          multiLangType,
          this.props.sMatHdrMatnr,
        ),
      );
    }

    // fill every other color
    uniqueCol.every((uniqueCol, i) => {
      if (uniqueCol === colorSelf) return true; // leave loop to not push colorSelf again

      let standard = false;
      standardColours.forEach(colorStandard => {
        if (uniqueCol === colorStandard) standard = true;
      });

      // increase the counter but only if there is limit available
      if (standard && limit) {
        counter += 1; // for standard col
      } else {
        counter += 4; // for text
      }

      // only if there is a limit available and the counter is bigger than the limit
      if (limit && counter >= limit) {
        contentColorsText.push(
          <span className="product-color-more" key="product-color-more">
            ...
          </span>,
        );
        return false; // to exit the every function
      }

      let selectedStyle = "";
      // push either to contentColors or contentColorsText but only if it's not the own color
      if (standard) {
        contentColors.push(
          this.addColor(
            uniqueCol,
            classNameColor,
            selectedStyle,
            "color",
            uniqueColsMatHdrMatnrs[i],
            multiLangType,
            this.props.sMatHdrMatnr,
          ),
        );
      } else {
        contentColorsText.push(
          this.addColor(
            uniqueCol,
            classNameText,
            selectedStyle,
            "text",
            uniqueColsMatHdrMatnrs[i],
            multiLangType,
            this.props.sMatHdrMatnr,
          ),
        );
      }
      return true;
    });

    let classNameOuterDiv = "";
    if (this.props.isOverlay) classNameOuterDiv = "overlay-colors";
    if (this.props.renderedView === "list") classNameOuterDiv += "  overlay-colors--list-view";

    // DEFAULT RETURN
    return (
      <div className={classNameOuterDiv}>
        {contentColors}
        {contentColorsText}
      </div>
    );
  },
});

export default ItemColors;
