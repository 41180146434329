import { Link } from "react-router-dom";
import React from "react";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import vetroLogo from "../../assets/img/layout/vetropack-logo.svg";
import * as UrlHelpers from "../../libs/UrlHelpers";

const HeaderColLeftCenter = createReactClass({
  propTypes: {
    // emptypage is for pages like login / error where certain elements won't appear
    emptypage: PropTypes.bool,
  },

  render: function () {
    let classNameHeader = "header__col--left";

    /** empty page has centred view (when shown) **/
    let visibility = "visible";
    if (this.props.emptypage) {
      classNameHeader = "header__logo--center";
      visibility = "hidden";
    }

    return (
      <div className={classNameHeader} style={{ visibility }}>
        <div className="header__logo">
          <h2 className="visually-hidden">Vetropack Logo</h2>
          <Link
            to={UrlHelpers.getPathToStartOfCatalogue()}
            onClick={() => {
              UrlHelpers.setHashOverwrite({ ph: 1 });
              window.scrollTo(0, 0);
            }}
          >
            <img className="header-logo__img" src={vetroLogo} alt="Vetropack Logo" />
          </Link>
        </div>
      </div>
    );
  },
});

export default HeaderColLeftCenter;
