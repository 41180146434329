import LinkToDetail from "../router/LinkToDetail";
import Translations from "../../libs/Translations";
import React from "react";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import MaterialsStore from "../../stores/MaterialsStore";
import ItemColors from "./ItemColors";
import ItemToggleSelection from "./ItemToggleSelection";
import ItemLabelTop from "./ItemLabelTop";
import Controllers from "../../libs/Controllers";
import TouchStore from "../../stores/TouchStore";

const ItemGrid = createReactClass({
  mixins: [ElstrLangStore.mixin, TouchStore.mixin],

  propTypes: {
    material: PropTypes.object.isRequired,
  },

  getInitialState: function () {
    return { showColorsOverlaySelf: false };
  },

  storeDidChange: function () {
    // this will reset showing the overlay open for the component itself
    this.state.showColorsOverlaySelf =
      TouchStore.getShowColorsOverlayForMaterial() === this.props.material.sMatHdrMatnr;

    this.forceUpdate();
  },

  shouldComponentUpdate: function (nextProps) {
    return this.props.material.sMatHdrMatnr !== nextProps.material.sMatHdrMatnr;
  },

  render: function () {
    // defaults
    let material = this.props.material;

    let labelBottom = null;

    // get img path
    let sMatAtvMatnrZvgb = `${material.sMatAtvMatnrZvgb}`;
    let ImagePath = MaterialsStore.getImagePath(sMatAtvMatnrZvgb, "grid");

    let textArtNrAndMatnr = Translations.getTextArtNrAndMatnr(material["sMatHdrMatnr"]);

    // apply the label on bottom when the page is intro
    if (LIBS.appName === "intro") {
      labelBottom = (
        <div className="label-new--bottom">
          <span className="label-new--bottom__text">{ElstrLangStore.text("NEW_BOTTLE")}</span>
        </div>
      );
    }

    let colorsOverlay = null;

    if (this.state.showColorsOverlaySelf) {
      colorsOverlay = (
        <ItemColors
          colorSelf={material.sMatAtvFarbe}
          colorsAlternate={material.alternativeColors}
          sMatHdrMatnr={material.sMatHdrMatnr}
          isOverlay={true}
        />
      );
    }

    let inactiveClass = "";
    if (Controllers.isMsteaInactive(material["sMatHdrMstae"])) inactiveClass = "blackAndDashed";

    return (
      <article className="item-grid" key={material["sMatHdrMatnr"]}>
        <ItemToggleSelection sMatHdrMatnr={material["sMatHdrMatnr"]} material={material} />
        <LinkToDetail renderedFrom="Grid" material={this.props.material}>
          <ItemLabelTop material={material} />
        </LinkToDetail>
        <div className="item-grid__content">
          <h3 className={`item-grid__title ${inactiveClass}`}>
            <LinkToDetail renderedFrom="Grid" material={this.props.material}>
              {Translations.getItemTitle(material)}
            </LinkToDetail>
          </h3>
          <LinkToDetail renderedFrom="Grid" material={this.props.material}>
            <img src={ImagePath} alt="glas img" className="item-grid__img" />
            <div className="item-grid__key-info">
              <span className="art-nr">{textArtNrAndMatnr}</span>
              {Translations.getFuellVollAndMuendung(material)}
            </div>
          </LinkToDetail>
          <div className="item-grid__colors">
            <ItemColors
              colorSelf={material.sMatAtvFarbe}
              colorsAlternate={material.alternativeColors}
              sMatHdrMatnr={material.sMatHdrMatnr}
              isOverlay={false}
              showColorsOverlaySelf={this.state.showColorsOverlaySelf}
              limit={10}
            />
            {colorsOverlay}
          </div>
        </div>
        {labelBottom}
      </article>
    );
  },
});

export default ItemGrid;
