import * as TT from "../../libs/Tooltip";
import * as Zafis from "../../libs/Zafis";
import Translations from "../../libs/Translations";
import React from "react";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import * as UrlHelpers from "../../libs/UrlHelpers";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";
import Filesaver from "file-saver";
import { TYPES } from "../../constants/Tooltip";
import Tooltip from "rc-tooltip";
import TextArea from "../common/tooltip/rc-tooltip/TextArea";
import PisysTable from "../common/tooltip/rc-tooltip/overlay/PisysTable";
import ComplaintsBody from "./tableData/ComplaintsBody";
import ComplaintsHeader from "./tableData/ComplaintsHeader";
import DeliveryHeader from "./tableData/DeliveryHeader";
import DeliveryBody from "./tableData/DeliveryBody";
import PisysHeaderMenu, { FILTER_MECHANISM, PREFIX_SECOND_FILTER, SECOND_PARAMS_FILTER_META } from "./PisysHeaderMenu";
import DC from "../../libs/DetailControllers";
import ElstrConfigStore from "elstr-jslib/src/scripts/stores/ElstrConfigStore";

const align = TYPES.left;

const commonTooltipAlign = {
  offset: [-8, -12],
};

export const DOWNLOAD_TYPES = {
  PRODUCTION_ORDER: "PRODUCTION_ORDER",
  BLOW_MOULDS: "BLOW_MOULDS",
  COMPLAINTS: "COMPLAINTS",
  SALES_ORDER: "SALES_ORDER",
};

export const FlexibleDataTable = createReactClass({
  orderedData: [],

  propTypes: {
    data: PropTypes.array.isRequired,
    dataDefintions: PropTypes.object.isRequired,
    downloadType: PropTypes.string,
  },

  getInitialState: function () {
    return {
      filters: [],
      columnToGroup: null,
      orderBy: null,
      orderByDirection: null,
    };
  },

  setOrder: function (columnToGroup) {
    let orderByDirection = "DSC";

    if (this.state.orderBy === columnToGroup) {
      if (this.state.orderByDirection === "DSC") orderByDirection = "ASC";
      else if (this.state.orderByDirection === "ASC") orderByDirection = null;
      else orderByDirection = "DSC";
    }

    this.setState({
      orderBy: columnToGroup,
      orderByDirection: orderByDirection,
    });
  },

  upGroup: function () {
    this.setState({
      columnToGroup: null,
    });
  },

  setGroup: function (columnToGroup) {
    if (this.state.columnToGroup === columnToGroup || !this.isParameterGroupingEnabled(columnToGroup)) {
      columnToGroup = null;
    }

    this.setState({
      columnToGroup: columnToGroup,
    });
  },

  handleOnClick: function (parameterName, e) {
    this.setState({
      filters: this.state.filters,
    });

    // to prevent opening the url tab in mobile
    e.preventDefault();
  },

  handleOnKeyDown: function (parameterName, e) {
    if (e.keyCode === 9 || e.keyCode === 13 || (e.shiftKey && e.keyCode === 9)) {
      // 9 = Enter-Key / 13 = TAB-Key

      this.setState({
        filters: this.state.filters,
      });

      // to prevent opening the url tab in mobile
      e.preventDefault();
    }
  },

  updateFilter: function (parameterName, e) {
    if (this.isParameterFilterEnabled(parameterName)) {
      let filterValue = e.target.value;

      if (filterValue !== "") {
        this.state.filters[parameterName] = filterValue;
      } else {
        delete this.state.filters[parameterName];
      }
    }
  },

  removeAllSecondFilter: function () {
    const keys = Object.keys(Object.assign(this.state.filters));

    if (!keys) {
      return;
    }

    keys.forEach(key => {
      if (key.startsWith(PREFIX_SECOND_FILTER)) {
        delete this.state.filters[key];
      }
    });

    this.setState({
      filters: this.state.filters,
    });
  },

  getUnit: function (parameterName, rowData) {
    if (
      this.props.dataDefintions &&
      this.props.dataDefintions[parameterName] &&
      this.props.dataDefintions[parameterName].unitKey &&
      rowData &&
      rowData[this.props.dataDefintions[parameterName].unitKey]
    ) {
      return rowData[this.props.dataDefintions[parameterName].unitKey];
    } else return null;
  },

  orderByCommon: function (orderByDirection, valueA, valueB) {
    if (valueA === valueB) {
      return 0;
    } else if (valueA < valueB) {
      if (orderByDirection === "ASC") return -1;
      else return 1;
    } else {
      if (orderByDirection === "ASC") return 1;
      else return -1;
    }
  },

  parseDate: function (date) {
    if (!date) return "";

    // for date 2020-06-23T13:43:46+0000
    date = date.split("T")[0];

    const [year, month, day] = date.split("-");
    return new Date(year, month - 1, day);
  },

  dateToHuman: function (dateStr) {
    if (!dateStr) return "";

    let date = this.parseDate(dateStr);
    return [date.getFullYear(), this.pad(date.getMonth() + 1), this.pad(date.getDate())].join(".");
  },

  pad: function (s) {
    return s < 10 ? "0" + s : s;
  },

  shouldDownloadComplaintBeRendered: function (rowData) {
    // not render for: 2 TEUER
    if (rowData["priok"] === "2") {
      return false;
    }

    // not render for: 1 KRITISCH
    if (rowData["priok"] === "1") {
      return false;
    }

    // when t_gos_hdr is empty (no rowData available)
    return rowData["t_gos_hdr"].length !== 0;
  },

  orderByStr: function (orderBy, orderByDirection, a, b) {
    let valueA = a[orderBy];
    let valueB = b[orderBy];
    return this.orderByCommon(orderByDirection, valueA, valueB);
  },
  orderByNumber: function (orderBy, orderByDirection, a, b) {
    let valueA = parseFloat(a[orderBy]);
    let valueB = parseFloat(b[orderBy]);
    if (isNaN(valueA)) valueA = 0;
    if (isNaN(valueB)) valueB = 0;
    return this.orderByCommon(orderByDirection, valueA, valueB);
  },

  orderByDate: function (orderBy, orderByDirection, a, b) {
    let valueA = this.parseDate(a[orderBy]);
    let valueB = this.parseDate(b[orderBy]);
    if (isNaN(valueA)) valueA = 0;
    if (isNaN(valueB)) valueB = 0;
    return this.orderByCommon(orderByDirection, valueA, valueB);
  },

  orderByArrayToTranslate: function (orderBy, orderByDirection, a, b) {
    let valueA = this.arrayToTranslateToHuman(a[orderBy], orderBy);
    let valueB = this.arrayToTranslateToHuman(b[orderBy], orderBy);
    return this.orderByCommon(orderByDirection, valueA, valueB);
  },

  orderByObjectWithTranslations: function (orderBy, orderByDirection, a, b) {
    let valueA = a[orderBy][ElstrLangStore.getCurrentLanguage()];
    let valueB = b[orderBy][ElstrLangStore.getCurrentLanguage()];
    valueA = valueA !== null ? valueA : "";
    valueB = valueB !== null ? valueB : "";
    return this.orderByCommon(orderByDirection, valueA, valueB);
  },

  arrayToTranslateToHuman: function (array, parameterName) {
    let result = array.map(val => {
      const transl = Translations.getValue(parameterName, val);
      return transl || parameterName;
    });
    return result.join(", ");
  },

  orderByMultyStr: function (orderBy, orderByDirection, a, b) {
    const translA = Translations.getValue(orderBy, a[orderBy]);
    const valueA = translA || a[orderBy];

    const translB = Translations.getValue(orderBy, b[orderBy]);
    const valueB = translB || b[orderBy];

    return this.orderByCommon(orderByDirection, valueA, valueB);
  },

  getColumnType: function (parameterName) {
    if (this.props.dataDefintions && this.props.dataDefintions[parameterName]) {
      return this.props.dataDefintions[parameterName].type;
    } else return null;
  },

  getColumnTitleFromCache: function (parameterName) {
    if (this.props.dataDefintions && this.props.dataDefintions[parameterName]) {
      return this.props.dataDefintions[parameterName].columnTitleFromCache;
    } else return "";
  },

  isParameterMultilanguage: function (parameterName) {
    return this.getColumnType(parameterName) === "multiLanguage";
  },

  isParameterMultilanguageWithValue: function (parameterName) {
    return this.getColumnType(parameterName) === "multiLanguageWithValue";
  },

  isParameterArrayToTranslate: function (parameterName) {
    return this.getColumnType(parameterName) === "arrayToTranslate";
  },

  isParameterFilterEnabled: function (parameterName) {
    if (this.props.dataDefintions && this.props.dataDefintions[parameterName]) {
      return this.props.dataDefintions[parameterName].filterEnabled;
    } else return true;
  },

  isParameterGroupingEnabled: function (parameterName) {
    if (this.props.dataDefintions && this.props.dataDefintions[parameterName]) {
      return this.props.dataDefintions[parameterName].groupingEnabled;
    } else return true;
  },

  isParameterVisible: function (parameterName) {
    if (this.props.dataDefintions && this.props.dataDefintions[parameterName]) {
      return this.props.dataDefintions[parameterName].visible;
    } else return true;
  },
  downloadExists: function () {
    return this.props.downloadType !== null && this.props.downloadType;
  },

  getColumnTitleDisplayed: function (parameterName) {
    const columnTitleFromCache = this.getColumnTitleFromCache(parameterName);

    return columnTitleFromCache === ""
      ? ElstrLangStore.text("DETAIL_TAB_" + parameterName.toUpperCase())
      : Translations.getLabelDetailColumn(columnTitleFromCache);
  },

  getColumnTitleGrouped: function (parameterName) {
    return `${this.getColumnTitleDisplayed(parameterName)}: `;
  },

  getFiltersAndHeaders: function (rowNumber, dataRow, columns, headers, firstRow) {
    if (rowNumber === 0) {
      // the table can have a download link which will be added on the left side
      if (this.downloadExists()) {
        headers.push(
          <th className="th-filter" key={`header_download`}>
            <span className="col-title">{ElstrLangStore.text("DETAIL_TAB_DOWNLOAD")}</span>
          </th>,
        );
        firstRow.push(<td key={"column_filter_download"} />);
      }

      for (let parameterName in dataRow) {
        if (this.isParameterVisible(parameterName)) {
          if (this.state.columnToGroup === null || this.state.columnToGroup !== parameterName) {
            // We store the columns available
            columns.push(parameterName);

            let filterValue = this.state.filters[parameterName];
            if (typeof filterValue === "undefined") filterValue = "";

            let orderIcon = " ";
            if (this.state.orderBy === parameterName) {
              if (this.state.orderByDirection === "ASC") {
                orderIcon = <span className={"ficon ficon__arrow-up"} />;
              } else if (this.state.orderByDirection === "DSC") {
                orderIcon = <span className={"ficon ficon__arrow-down"} />;
              }
            }

            let grouping;
            if (this.isParameterGroupingEnabled(parameterName)) {
              const tooltip = ElstrLangStore.text("GROUPING_COLUMN");

              grouping = (
                <span
                  className="ficon ficon__group"
                  onMouseOver={TT.showTooltip.bind(this, tooltip, align, 12)}
                  onMouseOut={TT.removeTooltip.bind(this, align)}
                  onMouseDown={TT.removeTooltip.bind(this, align)}
                  onClick={this.setGroup.bind(this, parameterName)}
                />
              );
            }

            headers.push(
              <th className="th-filter" key={"header_" + parameterName}>
                {grouping}
                <span className="col-title pointer" onClick={this.setOrder.bind(this, parameterName)}>
                  {this.getColumnTitleDisplayed(parameterName)}
                  {orderIcon}
                </span>
              </th>,
            );

            if (this.isParameterFilterEnabled(parameterName)) {
              firstRow.push(
                <td key={"column_filter_" + parameterName}>
                  <span className="input-filter">
                    <input
                      type="text"
                      name={parameterName}
                      defaultValue={filterValue}
                      placeholder={ElstrLangStore.text("DETAIL_TEXT_FILTERING")}
                      onKeyDown={this.handleOnKeyDown.bind(this, parameterName)}
                      onChange={this.updateFilter.bind(this, parameterName)}
                    />
                    <span
                      className="ficon ficon__filter pointer"
                      onClick={this.handleOnClick.bind(this, parameterName)}
                    />
                  </span>
                </td>,
              );
            } else {
              firstRow.push(<td key={"column_filter_" + parameterName} />);
            }
          }
        }
      }
    }
  },

  getTableHeadBlowMoulds: function () {
    return `<th>${ElstrLangStore.text("DETAIL_TAB_ADDDOCS_TYPE")}</th>
              <th>${ElstrLangStore.text("DETAIL_TAB_ADDDOCS_NO")}</th>
              <th>${ElstrLangStore.text("DETAIL_TAB_ADDDOCS_PARTNO")}</th>
              <th>${ElstrLangStore.text("DETAIL_TAB_ADDDOCS_VERS")}</th>
              <th>${ElstrLangStore.text("DETAIL_TAB_ADDDOCS_STATE")}</th>
              <th>${ElstrLangStore.text("DETAIL_TAB_ADDDOCS_SHORTTEXT")}</th>
              <th>${ElstrLangStore.text("DETAIL_TAB_DOWNLOAD")}</th>`;
  },

  getTableBodyBlowMoulds: function (t_prdist_dcm) {
    let rows = "";
    let href = UrlHelpers.getHrefMaterialDocument();
    let currentLanguage = ElstrLangStore.getCurrentLanguage();
    let sMatHdrMatnr = ElstrUrlHashStore.getRouteParam("sMatHdrMatnr");

    t_prdist_dcm.forEach(matDoc => {
      let doknr = matDoc["doknr"];
      let source = matDoc["source"] || "BLOW_MOULD_DRAWING";
      const rowClass = matDoc["dokst"] === "S9" ? "inactive-row" : "";

      rows += `<tr class=${rowClass}>
        <td>${matDoc["dokar"]}</td>
        <td>${matDoc["doknr"]}</td>
        <td>${matDoc["doktl_d"]}</td>
        <td>${matDoc["dokvr"]}</td>
        <td>${matDoc["dokst"]}</td>
        <td>${matDoc["dktxt"][currentLanguage]}</td>
        <td>
          <a
            rel="noopener noreferrer"
            target="_blank"
            class="link--download"
            href=${href}&doknr=${doknr}&materialNumber=${sMatHdrMatnr}&source=${source}
          >
            <span class="ficon ficon__download" />
          </a>
        </td>
      </tr>`;
    });
    return rows;
  },

  getTooltipTextForIstat: function (value) {
    return Translations.getValue("istatLong", value) || "";
  },

  getCellValueWithoutUnit: function (parameterName, dataRow, columnType) {
    let isMultilanguage = this.isParameterMultilanguage(parameterName);
    let isMultilanguageWithValue = this.isParameterMultilanguageWithValue(parameterName);
    let isArrayToTranslate = this.isParameterArrayToTranslate(parameterName);

    let currentValue;

    let currentLanguage = ElstrLangStore.getCurrentLanguage();
    if (isMultilanguage) {
      const transl = Translations.getValue(parameterName, dataRow[parameterName]);
      currentValue = transl || dataRow[parameterName];
    } else if (isMultilanguageWithValue) {
      const transl = Translations.getValue(parameterName, dataRow[parameterName]);
      currentValue = transl ? `${dataRow[parameterName]} ${transl}` : "";
    } else if (isArrayToTranslate) {
      currentValue = this.arrayToTranslateToHuman(dataRow[parameterName], parameterName);
    } else if (columnType === "date") {
      currentValue = this.dateToHuman(dataRow[parameterName]);
    } else if (columnType === "email") {
      currentValue = dataRow[parameterName];
    } else if (columnType === "numberLarge") {
      // used to format large numbers to make them more readable
      currentValue = dataRow[parameterName].toLocaleString("de-CH");
    } else if (columnType === "objectWithTranslation") {
      currentValue = dataRow[parameterName][currentLanguage];
      currentValue = currentValue !== null ? currentValue : "";
    } else {
      currentValue = dataRow[parameterName];
    }
    return currentValue;
  },

  addContentRow: function (rowNumber, columns, dataRow, bodyItems) {
    let columnValues = [];
    const performSecondFilter = DC.shouldPerformSecondFilter(this.state.filters);

    let addRow = true;

    if (this.downloadExists()) {
      if (this.props.downloadType === DOWNLOAD_TYPES.PRODUCTION_ORDER) {
        let href = "";
        const aufnr = dataRow["aufnr"];
        let downloads = "";

        if (dataRow["articleSheet"]) {
          href = UrlHelpers.getHrefMaterialDocument();
          href = `${href}&aufnr=${aufnr}&source=PROD_ORDER_ARTICLE_SPEC`;

          downloads += `
            <li class="link--download--tooltip-nohover">
              <span class="download-options">
                <a class="ficon ficon__download hover" rel="noopener noreferrer" target="_blank" href=${href}>
                </a>
              </span>               
              <span class="row-download">${ElstrLangStore.text("PROD_ORDER_ARTICLE_SPEC")}</span>
            </li>`;
        }

        if (dataRow["setupSheet"]) {
          href = UrlHelpers.getHrefMaterialDocument();
          href = `${href}&aufnr=${aufnr}&source=PROD_ORDER_SETUP_SHEET`;

          downloads += `
            <li class="link--download--tooltip-nohover">
              <span class="download-options">
                <a class="ficon ficon__download hover" rel="noopener noreferrer" target="_blank" href=${href}>
                </a>
              </span>                       
              <span class="row-download">${ElstrLangStore.text("PROD_ORDER_SETUP_SHEET")}</span>
            </li>`;
        }

        // A planed production PO has nothing to download as
        // this is planed and not executed --> so no download icon
        // only PP orders have a download icon
        if (dataRow["auart"] === "PP") {
          let hrefHtml = Zafis.getPathProdInfoProdReport(dataRow, Zafis.REPORT_FORMAT.HTML);
          let hrefExcel = Zafis.getPathProdInfoProdReport(dataRow, Zafis.REPORT_FORMAT.EXCEL);

          downloads += `
            <li class="link--download--tooltip-nohover">
                <span class="download-options">
                  <a class="download-options hover" rel="noopener noreferrer" target="_blank" href=${hrefHtml}>
                    <span class="ficon ficon__external-link tooltip--download-item">
                    </span>
                    <span class="download-text">HTML</span>  
                  </a>
                  <a class="download-options hover" rel="noopener noreferrer" target="_blank" href=${hrefExcel}>
                    <span class="ficon ficon__excel-export tooltip--download-item">
                    </span>
                    <span class="download-text">Excel</span>  
                  </a>
                </span>
              <span class="row-download">${ElstrLangStore.text("ZAFIS_PRODUCTION_REPORT_PROD_ORDERS")}</span>  
            </li>`;

          if (Zafis.werkHasServer(dataRow)) {
            let hrefHtml = Zafis.getPathProdInfoQualityReport(dataRow, Zafis.REPORT_FORMAT.HTML);
            let hrefExcel = Zafis.getPathProdInfoQualityReport(dataRow, Zafis.REPORT_FORMAT.EXCEL);

            downloads += `
              <li class="link--download--tooltip-nohover">
                <span class="download-options">
                  <a class="download-options hover" rel="noopener noreferrer" target="_blank" href=${hrefHtml}>
                    <span class="ficon ficon__external-link tooltip--download-item">
                    </span>
                    <span class="download-text">HTML</span>  
                  </a>
                  <a class="download-options hover" rel="noopener noreferrer" target="_blank" href=${hrefExcel}>
                    <span class="ficon ficon__excel-export tooltip--download-item">
                    </span>
                    <span class="download-text">Excel</span>  
                  </a>
                </span>
                <span class="row-download">${ElstrLangStore.text("ZAFIS_QUALITY_REPORT_PROD_ORDERS")}</span>
              </li>`;

            href = Zafis.getPathProdInfoInspectionPlan(dataRow);

            downloads += `
              <li class="link--download--tooltip-nohover">
              <span class="download-options">
                <a class="ficon ficon__download hover" rel="noopener noreferrer" target="_blank" href=${href}>
                </a>
              </span>                       
              <span class="row-download">${ElstrLangStore.text("ZAFIS_INSPECTION_PLAN_PROD_ORDERS")}</span>
              </li>`;
          }
        }

        const innerText = `<ul class="list-download">${downloads}</ul>`;

        columnValues.push(
          <td key={"column_download"}>
            {downloads !== "" ? (
              <span className="link--download">
                <span
                  onClick={TT.showTooltipAll.bind(this, innerText, TYPES.leftSticky, 15)}
                  className="ficon ficon__download pointer"
                />
              </span>
            ) : null}
          </td>,
        );
      } else if (this.props.downloadType === DOWNLOAD_TYPES.BLOW_MOULDS) {
        const tableHead = this.getTableHeadBlowMoulds();
        const tableBody = this.getTableBodyBlowMoulds(this.orderedData[rowNumber]["t_prdist_dcm"]);
        const innerText = `<table class="detail-pisys-info__product"><thead><tr>${tableHead}</tr></thead><tbody>${tableBody}</tbody></table>`;

        columnValues.push(
          <td key={"column_download"}>
            <span className="link--download">
              <span
                onClick={TT.showTooltipAll.bind(this, innerText, TYPES.leftSticky, 15)}
                className="ficon ficon__download pointer"
              />
            </span>
          </td>,
        );
      } else if (this.props.downloadType === DOWNLOAD_TYPES.SALES_ORDER && Zafis.werkHasServer(dataRow)) {
        let tooltip = ElstrLangStore.text("ZAFIS_QUALITY_REPORT_SALES_ORDERS");
        let tooltipExcel = `${tooltip} Excel`;
        let tooltipHtml = `${tooltip} HTML`;
        let hrefHtml = Zafis.getPathSalesOrderQualityReport(dataRow, Zafis.REPORT_FORMAT.HTML);
        let hrefExcel = Zafis.getPathSalesOrderQualityReport(dataRow, Zafis.REPORT_FORMAT.EXCEL);

        columnValues.push(
          <td key={"column_download"}>
            <span className="download-options">
              <a rel="noopener noreferrer" target="_blank" className="link--download" href={hrefHtml}>
                <span
                  className="ficon ficon__external-link pad-right-after-dl-ficon"
                  onMouseOver={TT.showTooltip.bind(this, tooltipHtml, align, 12)}
                  onMouseOut={TT.removeTooltip.bind(this, align)}
                  onMouseDown={TT.removeTooltip.bind(this, align)}
                />
                HTML
              </a>
              <a rel="noopener noreferrer" target="_blank" className="link--download" href={hrefExcel}>
                <span
                  className="ficon ficon__excel-export pad-right-after-dl-ficon"
                  onMouseOver={TT.showTooltip.bind(this, tooltipExcel, align, 12)}
                  onMouseOut={TT.removeTooltip.bind(this, align)}
                  onMouseDown={TT.removeTooltip.bind(this, align)}
                />
                Excel
              </a>
            </span>
          </td>,
        );
      } else if (
        this.props.downloadType === DOWNLOAD_TYPES.COMPLAINTS &&
        this.shouldDownloadComplaintBeRendered(this.orderedData[rowNumber])
      ) {
        const thead = <ComplaintsHeader />;
        const tbody = <ComplaintsBody dataRow={this.orderedData[rowNumber]} />;
        const overlay = <PisysTable thead={thead} tbody={tbody} />;

        columnValues.push(
          <td key={"column_download"}>
            <span className="link--download">
              <Tooltip align={commonTooltipAlign} overlay={overlay} trigger={["click"]} placement="topLeft">
                <span className="ficon ficon__download pointer" />
              </Tooltip>
            </span>
          </td>,
        );
      } else {
        columnValues.push(<td key={"empty"} />);
      }
    }

    for (let j in columns) {
      // abort further handling when the row is already filtered out
      if (!addRow) {
        break;
      }

      let parameterName = columns[j];
      let columnType = this.getColumnType(parameterName);

      let currentValue = this.getCellValueWithoutUnit(parameterName, dataRow, columnType);

      // the standard filtering happens here
      if (typeof this.state.filters[parameterName] !== "undefined") {
        // stringify and lowercase the values for comparing
        let currentValueToCompare = (currentValue + "").toLowerCase();
        let filterVlaueToCompare = (this.state.filters[parameterName] + "").toLowerCase();
        //  using indexOf < 0 to as filtering mechanism
        if (currentValueToCompare.indexOf(filterVlaueToCompare) < 0) {
          addRow = false;
          break;
        }
      }

      if (this.state.columnToGroup === null || this.state.columnToGroup !== parameterName) {
        let unit = this.getUnit(parameterName, dataRow);
        if (unit !== null) currentValue += " " + unit;

        // make currentValue clickable email link for columnType "email"
        if (columnType === "email") {
          currentValue = (
            <a className="pisys-table-content-clickable" target="_top" href={`mailto:${currentValue}`}>
              {currentValue}
            </a>
          );
        }

        if (columnType === "tooltipText") {
          // currently tooltipText is only used in this one place, therefore hardcoded to take "t_ad_qnt_ltx"
          // otherwise the DataDefinition from BE can be extended to contain a new field "tooltipSourceId"
          const textList = this.orderedData[rowNumber]["t_ad_qnt_ltx"];
          const displayText = currentValue;

          currentValue = (
            <TextArea textList={textList}>
              <span className="pisys-table-content-clickable">{displayText}</span>
            </TextArea>
          );
        }

        if (columnType === "arrayToTranslate") {
          const allValues = currentValue.split(", ");
          const numOfElements = allValues.length;
          currentValue = [];
          allValues.forEach((value, i) => {
            const tooltipText = this.getTooltipTextForIstat(value);

            currentValue.push(
              <span
                className="help"
                key={value}
                onMouseOver={TT.showTooltip.bind(this, tooltipText, TYPES.center, 12)}
                onMouseOut={TT.removeTooltip.bind(this, TYPES.center)}
                onMouseDown={TT.removeTooltip.bind(this, TYPES.center)}
              >
                {value}
                {i + 1 !== numOfElements ? ", " : ""}
              </span>,
            );
          });
        }

        if (columnType === "tooltipTable") {
          // currently tooltipTable is only used in this one place, therefore hardcoded to take "t_at_ord_sscc"
          // otherwise the DataDefinition from BE can be extended to contain a new field "tooltipSourceId"
          const tableSource = this.orderedData[rowNumber]["t_at_ord_sscc"];

          // only handle when data is provided
          if (!tableSource) {
            addRow = false;
            return;
          }
          if (!Array.isArray(tableSource)) {
            addRow = false;
            return;
          }

          const displayText = currentValue;
          let _tableSource = tableSource;

          if (performSecondFilter) {
            const allFiltersKeys = Object.keys(this.state.filters);
            const secondFilterKeys = allFiltersKeys.filter(filterKey => filterKey.startsWith(PREFIX_SECOND_FILTER));

            // filter data
            _tableSource = tableSource.filter(row => {
              let includeRow = true;

              if (!displayText) {
                return false;
              }

              secondFilterKeys.forEach(filterKey => {
                const filterValueToCompare = (this.state.filters[filterKey] + "").toLowerCase();

                if (!filterValueToCompare) {
                  return;
                }

                const { param, filterMechanism } = SECOND_PARAMS_FILTER_META[filterKey];
                const valueToCompare = row[param];

                if (!valueToCompare) {
                  includeRow = false;
                  return;
                }

                if (valueToCompare.length < 0) {
                  includeRow = false;
                  return;
                }

                // indexOf case
                if (filterMechanism === FILTER_MECHANISM.INDEX_OF) {
                  if (valueToCompare.toLowerCase().indexOf(filterValueToCompare) < 0) {
                    includeRow = false;
                  }
                }

                // gte / from case
                if (filterMechanism === FILTER_MECHANISM.GREATER_THEN_EQUALS) {
                  if (Number(valueToCompare) < Number(filterValueToCompare)) {
                    includeRow = false;
                  }
                }

                // lte / to case
                if (filterMechanism === FILTER_MECHANISM.LESS_THEN_EQUALS) {
                  if (Number(valueToCompare) > Number(filterValueToCompare)) {
                    includeRow = false;
                  }
                }
              });

              return includeRow;
            });
          }

          if (_tableSource.length === 0 && performSecondFilter) {
            addRow = false;
            break;
          }

          const thead = <DeliveryHeader />;
          const tbody = <DeliveryBody tableSource={_tableSource} filters={this.state.filters} />;
          const overlay = <PisysTable thead={thead} tbody={tbody} />;

          currentValue = (
            <Tooltip align={commonTooltipAlign} overlay={overlay} trigger={["click"]} placement="topLeft">
              <span className="pisys-table-content-clickable">{displayText}</span>
            </Tooltip>
          );

          // in rare cases where the tableSource is empty and the user does not filter for second filters
          // we just display the value without the clickable span class
          if (_tableSource.length === 0 && !performSecondFilter) {
            currentValue = displayText;
          }
        }

        if (parameterName === "SubWork_Title") {
          const workid = dataRow["workid"];
          const subworkId = dataRow["subworkid"];

          let host = "https://dms.vetropack.com";
          if (ElstrConfigStore.option("opentext")["host"]) {
            host = ElstrConfigStore.option("opentext")["host"];
          }

          let link = `${host}/OTCS/cs.exe/open/FormStatusViewRedirect?&nexturl=&workid=${workid}&subworkid=${subworkId}&formid=2`;

          currentValue = (
            <a className="pisys-table-content-clickable" target="_blank" rel="noopener noreferrer" href={link}>
              {currentValue}
            </a>
          );
        }

        columnValues.push(<td key={"column_" + parameterName}>{currentValue}</td>);
      }
    }

    if (addRow) {
      bodyItems.push(<tr key={"dataRow_" + rowNumber}>{columnValues}</tr>);
    }
  },

  addTable: function (key, tables, headers, firstRow, bodyItems) {
    tables.push(
      <table key={key} className="detail-pisys-info__product" style={{ marginLeft: 10, display: "inline-block" }}>
        <thead>
          <tr>{headers}</tr>
        </thead>
        <tbody>
          <tr>{firstRow}</tr>
          {bodyItems}
        </tbody>
      </table>,
    );
  },

  orderBy: function (data, orderBy, orderByDirection) {
    let orderedData;

    if (orderBy !== null && orderByDirection !== null) {
      let columnType = this.getColumnType(orderBy);

      if (columnType === "number" || columnType === "numberLarge") {
        orderedData = data.sort(this.orderByNumber.bind(this, orderBy, orderByDirection));
      } else if (columnType === "date") {
        orderedData = data.sort(this.orderByDate.bind(this, orderBy, orderByDirection));
      } else if (columnType === "multiLanguage") {
        orderedData = data.sort(this.orderByMultyStr.bind(this, orderBy, orderByDirection));
      } else if (columnType === "arrayToTranslate") {
        orderedData = data.sort(this.orderByArrayToTranslate.bind(this, orderBy, orderByDirection));
      } else if (columnType === "objectWithTranslation") {
        orderedData = data.sort(this.orderByObjectWithTranslations.bind(this, orderBy, orderByDirection));
      } else {
        orderedData = data.sort(this.orderByStr.bind(this, orderBy, orderByDirection));
      }
    } else {
      orderedData = data;
    }
    return orderedData;
  },

  exportPisysTable: function () {
    let wb = {
      SheetNames: [],
      Sheets: {},
    };

    // CREATE THE WORKSHEET DATA ARRAY //
    let ws_data = [];

    let headers = [];
    let columns = [];

    let data = this.props.data;
    if (this.state.columnToGroup !== null) {
      // We always order the column to group:
      data = this.orderBy(data, this.state.columnToGroup, "ASC");
    }

    let { orderBy, orderByDirection } = this.state;

    this.orderedData = this.orderBy(data, orderBy, orderByDirection);
    // CREATE THE HEADER DATA
    this.orderedData.forEach((dataRow, rowNumber) => {
      // push header data for first row
      if (rowNumber === 0) {
        for (let parameterName in dataRow) {
          if (this.isParameterVisible(parameterName)) {
            columns.push(parameterName);
            let headerTitle = this.getColumnTitleDisplayed(parameterName);
            let unit = this.getUnit(parameterName, dataRow);
            if (unit !== null) headerTitle += " [" + unit + "]";
            headers.push(headerTitle);
          }
        }
        ws_data.push(headers);
      }

      // push row data
      let row = [];
      for (let j in columns) {
        let parameterName = columns[j];
        let columnType = this.getColumnType(parameterName);

        let currentValue = this.getCellValueWithoutUnit(parameterName, dataRow, columnType);

        row.push(currentValue);
      }

      ws_data.push(row);
    });

    let ws = XLSX.utils.aoa_to_sheet(ws_data);

    let mainView = UrlHelpers.getMainView();
    let sMatHdrMatnr = ElstrUrlHashStore.getRouteParam("sMatHdrMatnr");
    let tab = ElstrUrlHashStore.get("pisysTab");
    let translationId = `DETAIL_TAB_${tab.toUpperCase()}`;

    // we remove whitespace and join them by underscore
    // also transform to lowercase for consistency with rest of the data
    let tabName = ElstrLangStore.text(translationId).split(" ").join("_").toLocaleLowerCase();

    // Add the sheet name to the list
    let ws_name = `${mainView}_${sMatHdrMatnr}_${tabName}`;
    wb.SheetNames.push(ws_name);

    // Load the worksheet object
    wb.Sheets[ws_name] = ws;

    let wopts = { bookType: "xlsx", bookSST: false, type: "array" };

    let wbout = XLSX.write(wb, wopts);

    let filename = `${mainView}_${sMatHdrMatnr}_${tabName}_${new Date()}.xlsx`;

    // the saveAs call downloads a file on the local machine
    Filesaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), filename);
  },

  render: function () {
    let data = this.props.data || [];
    let columns = [];
    let bodyItems = [];
    let tables = [];
    let headers = [];
    let firstRow = [];

    if (this.state.columnToGroup !== null) {
      // We always order the column to group:
      data = this.orderBy(data, this.state.columnToGroup, "ASC");
    }

    let previusGroupValue = null;

    let orderBy = this.state.orderBy;
    let orderByDirection = this.state.orderByDirection;
    this.orderedData = this.orderBy(data, orderBy, orderByDirection);
    this.orderedData.forEach((dataRow, rowNumber) => {
      this.getFiltersAndHeaders(rowNumber, dataRow, columns, headers, firstRow);

      // No grouping
      if (this.state.columnToGroup === null) {
        this.addContentRow(rowNumber, columns, dataRow, bodyItems);
      } else {
        let parameterName = this.state.columnToGroup;
        let columnType = this.getColumnType(parameterName);

        let currentGroupValue = this.getCellValueWithoutUnit(parameterName, dataRow, columnType);

        const tooltip = ElstrLangStore.text("GROUPING_UNGROUP");
        let orderIcon;
        if (this.state.orderBy === this.state.columnToGroup) {
          if (this.state.orderByDirection === "ASC") {
            orderIcon = <span className={"ficon ficon__arrow-up"} />;
          } else if (this.state.orderByDirection === "DSC") {
            orderIcon = <span className={"ficon ficon__arrow-down"} />;
          }
        }

        if (previusGroupValue === null) {
          tables.push(
            <h3 key={"header" + rowNumber} className="grouped-table-title">
              <span
                className="ficon ficon__ungroup pointer"
                onClick={this.upGroup}
                onMouseOver={TT.showTooltip.bind(this, tooltip, align, 12)}
                onMouseOut={TT.removeTooltip.bind(this, align)}
                onMouseDown={TT.removeTooltip.bind(this, align)}
              />
              <span className="pointer" onClick={this.setOrder.bind(this, this.state.columnToGroup)}>
                {this.getColumnTitleGrouped(this.state.columnToGroup)}
                {currentGroupValue}
                {orderIcon}
              </span>
            </h3>,
          );
          this.addContentRow(rowNumber, columns, dataRow, bodyItems);
        } else if (currentGroupValue === previusGroupValue) {
          this.addContentRow(rowNumber, columns, dataRow, bodyItems);
        } else {
          let tableKey = "table_" + rowNumber;
          if (this.state.columnToGroup !== null) tableKey += "_" + this.state.columnToGroup;

          this.addTable(tableKey, tables, headers, firstRow, bodyItems);

          tables.push(
            <h3 key={"header" + rowNumber} className="grouped-table-title">
              <span
                className="ficon ficon__ungroup"
                onMouseOver={TT.showTooltip.bind(this, tooltip, align, 15)}
                onMouseOut={TT.removeTooltip.bind(this, align)}
                onMouseDown={TT.removeTooltip.bind(this, align)}
                onClick={this.upGroup}
              />
              <span className="pointer" onClick={this.setOrder.bind(this, this.state.columnToGroup)}>
                {this.getColumnTitleGrouped(this.state.columnToGroup)}
                {currentGroupValue}
                {orderIcon}
              </span>
            </h3>,
          );

          bodyItems = [];
          this.addContentRow(rowNumber, columns, dataRow, bodyItems);
        }

        previusGroupValue = currentGroupValue;
      }
    });

    this.addTable("table_end", tables, headers, firstRow, bodyItems);

    return (
      <>
        <PisysHeaderMenu
          exportPisysTable={this.exportPisysTable}
          filterProps={{
            handleOnKeyDown: this.handleOnKeyDown,
            updateFilter: this.updateFilter,
            handleOnClick: this.handleOnClick,
            removeAllSecondFilter: this.removeAllSecondFilter,
            filters: this.state.filters,
          }}
        />
        <div className="flexible-data-table">{tables}</div>
      </>
    );
  },
});
