export const FILTER = {
  CUSTOMER: "customer",
  DEFECT_PRIORITY: "priok",
  DEFECT_TYPE: "fegrpFecod",
  DEFECT_LOCATION: "oteil",
  PRODUCTION: "werksArbpl",
  PLANNED_ORDERS: "plannedOrders",
};

export const FILTER_TYPE = {
  LISTBOX_TWO_STEP: "listboxTwoStep",
};
