import "@babel/polyfill"; // This will emulate a full ES2015 environment and is intended to be used in an application rather than a library/tool.
// Initialize Parts that are the same for Intro and Catalogue
import "../libs/InitializeMain";
// Initialize Pusher For Event Notifications (at the moment for Selection changes)
import "../libs/InitializePusher";
import { BrowserRouter } from "react-router-dom";
import React from "react";
import ReactDom from "react-dom";
import * as UrlHelpers from "../libs/UrlHelpers";
import ContainerRouter from "../components/router/ContainerRouter";

const basename = UrlHelpers.getBasename();
const supportsHistory = "pushState" in window.history;

ReactDom.render(
  <BrowserRouter forceRefresh={!supportsHistory} basename={basename}>
    <ContainerRouter />
  </BrowserRouter>,
  document.getElementById("content"),
);
