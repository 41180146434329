function isNumeric(event) {
  // https://stackoverflow.com/a/39292388/6138965
  const charCode = event.which ? event.which : event.key || event.keyCode; // keyCode is deprecated but needed for some browsers

  // for browser compatibilities disable eslint
  // eslint-disable-next-line eqeqeq
  if (charCode == 101 || charCode == 69 || charCode == 45 || charCode == 43) {
    return false;
  }
  return true;
}

export function preventNonNumeric(event) {
  // https://stackoverflow.com/a/39292388/6138965
  if (!isNumeric(event)) {
    event.preventDefault();
  }
}
