import * as TT from "../../libs/Tooltip";
import React from "react";
import { isPisysSite } from "../../libs/Controllers";
import XlsxExport from "../../libs/XlsxExport";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import AppsStore from "../../stores/AppsStore";
import { TYPES } from "../../constants/Tooltip";

export default function HeaderAddExcel() {
  // not render for eCat and phone layout (the icon is overlapping with the dropdown menu otherwise)
  if (!isPisysSite() || AppsStore.getLayoutView() === "phone") return <i />;

  const tooltip = ElstrLangStore.text("EXPORT_TO_EXCEL");

  return (
    <li className="view-nav__item view-nav__item--add-item">
      <span
        className="ficon ficon__excel-export view-nav-item__icon"
        onClick={XlsxExport.exportMaterials}
        onMouseOver={TT.showTooltip.bind(this, tooltip, TYPES.center, 15)}
        onMouseOut={TT.removeTooltip.bind(this, TYPES.center)}
        onMouseDown={TT.removeTooltip.bind(this, TYPES.center)}
      />
    </li>
  );
}
