import { MAIN_VIEW as MV } from "../constants/MainView";
import mcFly from "elstr-jslib/src/scripts/libs/mcFly.js";
import ElstrEditingStates from "elstr-jslib/src/scripts/constants/ElstrEditingStates";
import ElstrLoadingStates from "elstr-jslib/src/scripts/constants/ElstrLoadingStates";
import ElstrUrlHashConstants from "elstr-jslib/src/scripts/constants/ElstrUrlHashConstants";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";
import * as UrlHelpers from "../libs/UrlHelpers";

const ScrollPositionsStore = mcFly.createStore(
  {
    filters: null,
    error: null,
    loadingState: ElstrLoadingStates.EMPTY,
    editingState: ElstrEditingStates.NORMAL,
    gridScroll: 0,
    listScroll: { xPos: 0, yPos: 0 },
    compareScroll: { xPos: 0, yPos: 0 },
    mainGroup: undefined,
    catalogue: undefined,

    /** getters **/
    getError: function () {
      return this.error;
    },

    getLoadingState: function () {
      return this.loadingState;
    },

    getEditingState: function () {
      return this.editingState;
    },

    getGridScroll: function () {
      return ScrollPositionsStore.gridScroll;
    },

    getListScroll: function () {
      return ScrollPositionsStore.listScroll;
    },

    getCompareScroll: function () {
      return ScrollPositionsStore.compareScroll;
    },

    /** setters **/
    setGridScroll: function (yPos) {
      ScrollPositionsStore.gridScroll = yPos;
    },

    setListScroll: function (positionObj) {
      ScrollPositionsStore.listScroll = positionObj;
    },

    setCompareScroll: function (positionObj) {
      ScrollPositionsStore.compareScroll = positionObj;
    },

    checkIfScrollPositionShouldBeUpgraded: () => {
      let mainView = UrlHelpers.getMainView();
      // only relevant when switching inside to result mainView
      if (mainView === MV.RESULT) {
        /** Reset Positions when the mainGroup or the catalogue has changed **/
        let mainGroup = ElstrUrlHashStore.getRouteParam("mainGroup");
        let catalogue = ElstrUrlHashStore.getRouteParam("catalogue");
        if (ScrollPositionsStore.mainGroup !== mainGroup || ScrollPositionsStore.catalogue !== catalogue) {
          // store new values
          ScrollPositionsStore.mainGroup = mainGroup;
          ScrollPositionsStore.catalogue = catalogue;

          // Reset all positions
          ScrollPositionsStore.listScroll = { xPos: 0, yPos: 0 };
          ScrollPositionsStore.compareScroll = { xPos: 0, yPos: 0 };
          ScrollPositionsStore.gridScroll = 0;

          // emit change for ItemGridScroll ItemListScroll and ItemCompareScroll
          ScrollPositionsStore.emitChange();
        }
      }
    },
  },
  function (payload) {
    switch (payload.actionType) {
      case ElstrUrlHashConstants.URL_HASH_CHANGE: {
        mcFly.dispatcher.waitFor([ElstrUrlHashStore.dispatcherID]);
        ScrollPositionsStore.checkIfScrollPositionShouldBeUpgraded();
        break;
      }

      case ElstrUrlHashConstants.URL_ROUTE_CHANGE: {
        mcFly.dispatcher.waitFor([ElstrUrlHashStore.dispatcherID]);
        ScrollPositionsStore.checkIfScrollPositionShouldBeUpgraded();
        break;
      }
    }
    return true;
  },
);

export default ScrollPositionsStore;
