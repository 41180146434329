import Translations from "../../libs/Translations";
import React from "react";
import * as Attributes from "../../libs/Attributes";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";
import ElstrConfigStore from "elstr-jslib/src/scripts/stores/ElstrConfigStore";
import * as UrlHelpers from "../../libs/UrlHelpers";
import AppsStore from "../../stores/AppsStore";
import ItemListOverlaySettings from "./ItemListOverlaySettings";

const ItemListHeader = createReactClass({
  propTypes: {
    attributesSorted: PropTypes.array.isRequired,
  },

  setSortingInUrl: function (dataAttributeKey) {
    // true when setting to ASC or DESC, but false when to remove
    let setHash = true;

    // get url information
    let orderByDirection = ElstrUrlHashStore.get("orderByDirection");
    let sortByKey = ElstrUrlHashStore.get("sortByKey");

    // gets called when the sortByKey is the same as in the URL
    if (sortByKey === dataAttributeKey) {
      if (orderByDirection === "ASC") {
        orderByDirection = "DESC";
      } else if (orderByDirection === "DESC") {
        // will remove the sorting from the url
        setHash = false;
        UrlHelpers.clearHashes(["sortByKey", "orderByDirection"]);
      }
    } else {
      // gets called when switching the sortByKey
      orderByDirection = "ASC";
    }

    // setting the new sorting order and key
    if (setHash) {
      UrlHelpers.setHashesNoHistory({
        sortByKey: dataAttributeKey,
        orderByDirection,
      });
    }
  },

  render: function () {
    let headerContent = [];

    let attributesSorted = this.props.attributesSorted;

    let classNameLabelActive = "item-list__label--active";
    let classNameFiconDown = "ficon__arrow-down";
    let classNameFiconUp = "ficon__arrow-up";

    // assign if should be arrow up or down
    let orderByDirection = ElstrUrlHashStore.get("orderByDirection");
    let classNameFicon = classNameFiconUp; // as default
    if (orderByDirection === "DESC") classNameFicon = classNameFiconDown;

    let sortByKey = ElstrUrlHashStore.get("sortByKey");
    attributesSorted.forEach((attribute, i) => {
      if (attribute.listDisplayDefault === true && attribute.listDisplayAllowed === true) {
        let dataAttributeKey = attribute["dataAttributeKey"];
        let activeLabel = "";

        // class name for the col can be with -title or -color
        let classNameCol = "item-list__label-col";
        if (attribute["listDisplaySize"] === 2) classNameCol += " double-width";

        // override for sMatHdrTdline and sMatAtvFarbe
        if (dataAttributeKey === "sMatHdrTdline") classNameCol = "item-list__label-col-title";
        if (dataAttributeKey === "sMatAtvFarbe") classNameCol = "item-list__label-col-color";

        let headerText = Translations.getLabel(dataAttributeKey) || "";

        dataAttributeKey = Attributes.getRealDataAttributeKey(dataAttributeKey);

        // check if to set label active
        if (dataAttributeKey === sortByKey) activeLabel = classNameLabelActive;

        headerContent.push(
          <div className={classNameCol} key={dataAttributeKey + i}>
            <span
              className={`item-list__label ${activeLabel}`}
              onClick={this.setSortingInUrl.bind(this, dataAttributeKey)}
            >
              {headerText}
              <span className={`ficon ${classNameFicon} item-list__label-arrow`} />
            </span>
          </div>,
        );
      }
    });

    let itemListSettingsIcon = null;
    // add the settings button for the pisys site
    if (ElstrConfigStore.option("isPisysSite")) {
      itemListSettingsIcon = (
        <span
          className="ficon ficon__setting item-list__setting-icon"
          key="overlay-options"
          onClick={AppsStore.toggleOverlaySettingsOpen}
        />
      );
    }

    return (
      <React.Fragment>
        <div className="item-list__label-col-setting">
          {itemListSettingsIcon}
          <ItemListOverlaySettings type={Attributes.TYPES.result} />
        </div>
        {headerContent}
      </React.Fragment>
    );
  },
});

export default ItemListHeader;
