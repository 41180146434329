import React from "react";
import Translations from "../../../libs/Translations";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";

const ComplaintsHeader = () => (
  <thead>
    <tr>
      <th>{Translations.getLabelDetailColumn("crName")}</th>
      <th>{Translations.getLabelDetailColumn("crDate")}</th>
      <th>{Translations.getLabelDetailColumn("crTime")}</th>
      <th>{Translations.getLabelDetailColumn("descr")}</th>
      <th>{Translations.getLabelDetailColumn("techType")}</th>
      <th>{Translations.getLabelDetailColumn("lang")}</th>
      <th>{ElstrLangStore.text("DETAIL_TAB_DOWNLOAD")}</th>
    </tr>
  </thead>
);

export default ComplaintsHeader;
