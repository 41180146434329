import * as TT from "../../libs/Tooltip";
import { MAIN_VIEW as MV } from "../../constants/MainView";
import Translations from "../../libs/Translations";
import React from "react";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";
import * as UrlHelpers from "../../libs/UrlHelpers";
import SelectionActions from "../../actions/SelectionActions";
import SelectionStore from "../../stores/SelectionStore";
import MaterialsStore from "../../stores/MaterialsStore";
import Controllers from "../../libs/Controllers";
import { TYPES } from "../../constants/Tooltip";

function returnSetStateObj(obj) {
  // flag will be default false
  let added = false;

  // set added flag to true when inside the objects
  let editingSelectionId = SelectionStore.getEditingSelectionId();
  if (obj.sMatHdrMatnr !== undefined) {
    let selectedMaterials;
    if (editingSelectionId === "") {
      selectedMaterials = SelectionStore.getMySelectedMaterials();
    } else {
      selectedMaterials = SelectionStore.getForeignSelectedMaterials();
    }

    selectedMaterials.forEach(selected => {
      if (selected.materialNumber === obj.sMatHdrMatnr) added = true;
    });
  }
  // override when the obj.added is provided (happens when manually adding / removing bottles)
  if (obj.added !== undefined) {
    added = obj.added;
  }

  return { added, editingSelectionId };
}

const ItemToggleSelection = createReactClass({
  mixins: [SelectionStore.mixin],

  statics: {
    getDerivedStateFromPropsaas(nextProps) {
      return returnSetStateObj({ sMatHdrMatnr: nextProps.sMatHdrMatnr });
    },
  },

  propTypes: {
    sMatHdrMatnr: PropTypes.string.isRequired,
    material: PropTypes.object.isRequired,
    isDetailTop: PropTypes.bool,
  },

  getInitialState: function () {
    return returnSetStateObj({ sMatHdrMatnr: this.props.sMatHdrMatnr });
  },

  storeDidChange: function () {
    this.setState(returnSetStateObj({ sMatHdrMatnr: this.props.sMatHdrMatnr }));
  },

  handleOnClickSelection: function (sMatHdrMatnr, added) {
    let selectionId = SelectionStore.getAffectedSelectionId();

    let material = MaterialsStore.getMaterialByNumberOrDefault(sMatHdrMatnr);
    let itemTitle = Translations.getItemTitle(material);

    let selectionSource = UrlHelpers.getSelectionSourceForServerRequest();

    let payload = {
      selectionId,
      materialNumbers: sMatHdrMatnr.split(" "),
      selectionSource,
    };

    if (added) {
      this.setState(function (prevState) {
        returnSetStateObj({
          added: !prevState.added,
        });
      });

      SelectionActions.removeMaterials({ payload });

      window._paq.push([
        "trackEvent",
        "selection",
        "remove",
        sMatHdrMatnr + " " + itemTitle + " ( " + selectionId + " )",
      ]);
    } else {
      this.setState(function (prevState) {
        returnSetStateObj({
          added: !prevState.added,
        });
      });

      SelectionActions.addMaterials({ payload });

      window._paq.push(["trackEvent", "selection", "add", sMatHdrMatnr + " " + itemTitle + " ( " + selectionId + " )"]);
    }
  },

  shouldComponentUpdate: function (nextProps, nextState) {
    return (
      this.state.added !== nextState.added ||
      this.props.sMatHdrMatnr !== nextProps.sMatHdrMatnr ||
      this.props.isDetailTop !== nextProps.isDetailTop ||
      this.state.editingSelectionId !== SelectionStore.getEditingSelectionId()
    );
  },

  render: function () {
    let sMatHdrMatnr = this.props.sMatHdrMatnr;
    let added = this.state.added;

    let tooltip;
    let className = "ficon";

    if (added) {
      className += " ficon__notepad-selected";
      tooltip = ElstrLangStore.text("REMOVE_FROM_SELECTION");
      if (SelectionStore.isEditing()) tooltip = ElstrLangStore.text("REMOVE_FROM_FOREIGN_SELECTION");
    } else {
      tooltip = ElstrLangStore.text("ADD_TO_SELECTION");
      if (SelectionStore.isEditing()) tooltip = ElstrLangStore.text("ADD_TO_FOREIGN_SELECTION");
      className += " ficon__notepad";
    }

    // different handling wen created for detailTop (in the top left corner of the detail page)
    let isDetailTop = false;
    if (this.props.isDetailTop === true) isDetailTop = this.props.isDetailTop;

    // assign classNames for detail page and every other page
    // for the items the classNames are different than for the details top page
    let align;
    let mainView = UrlHelpers.getMainView();
    if (!isDetailTop) {
      // assign first the compare view according to the current mainView
      let compareView;

      switch (mainView) {
        case MV.RESULT:
          compareView = ElstrUrlHashStore.get("resultView") || "grid";
          break;

        case MV.SELECTION:
          compareView = ElstrUrlHashStore.get("selectionView") || "compare";
          break;

        case MV.DETAIL:
          compareView = "compare";
          break;

        case MV.DOSSIER:
          compareView = "compare";
          break;
      }

      // according to compareView, the right classNames and tooltipAlign can be applied
      switch (compareView) {
        case "grid":
          align = TYPES.center;
          className += " item-grid__notepad";

          break;
        case "list":
          align = TYPES.left;
          className += " item-list__notepad";

          break;
        case "compare":
          align = TYPES.center;
          className += " item-compare__notepad";

          break;
      }
      // className for Detail top page
    } else {
      className += " detail-main-toolbar-item__notepad";
    }

    // RENDER INVISIBLE IN CASE USER IS EDITING A "CATALOGUE" SELECTION AND WANT'S TO ADD / REMOVE MATERIALS THAT ARE NOT ALLOWED THERE
    let visibility = "visible";
    if (Controllers.isSelectionHandlingNotAllowed(this.props.material)) visibility = "hidden";

    // ITEMS WILL APPEAR IN A DIFFERENT COLOR SIGNALIZING THAT CHANGES WILL BE EDITED TO A FOREIGN SELECTION
    let editingClassName = "";
    if (this.state.editingSelectionId !== "") {
      editingClassName += "editing";
      if (added || isDetailTop) {
        editingClassName += "-selected";
      }
    }

    // we don't render the item toggle for certain views
    if (UrlHelpers.isViewDossier()) return null;
    if (UrlHelpers.isViewPrintLabel()) return null;

    // return above for every item except detail page top
    if (!isDetailTop) {
      return (
        <span
          style={{ visibility }}
          className={`${className} ${editingClassName}`}
          key={`${className}-${sMatHdrMatnr}`}
          onMouseOver={TT.showTooltip.bind(this, tooltip, align, 15)}
          onMouseOut={TT.removeTooltip.bind(this, align)}
          onMouseDown={TT.removeTooltip.bind(this, align)}
          onClick={this.handleOnClickSelection.bind(this, sMatHdrMatnr, added)}
        />
      );
      // return for detail page top
    } else {
      return (
        <span style={{ visibility }} onClick={this.handleOnClickSelection.bind(this, sMatHdrMatnr, added)}>
          <span className={`${className} ${editingClassName}`} />
          <span className={`detail-main-toolbar-item__title phone-tablet--hidden ${editingClassName}`}>{tooltip}</span>
        </span>
      );
    }
  },
});

export default ItemToggleSelection;
