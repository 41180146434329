import { Link } from "react-router-dom";
import { MAIN_VIEW as MV } from "../../constants/MainView";
import React from "react";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import ElstrConfigStore from "elstr-jslib/src/scripts/stores/ElstrConfigStore";
import * as UrlHelpers from "../../libs/UrlHelpers";
import ElstrUserActions from "elstr-jslib/src/scripts/actions/ElstrUserActions";
import ElstrUserStore from "elstr-jslib/src/scripts/stores/ElstrUserStore";

const SwitchApp = createReactClass({
  getInitialState: function () {
    // decide if to show the switch button (depending on internIP or the environment for developers)
    let showSwitchApp = false;
    let clientIP = ElstrUserStore.getClientIp();
    let internalIpRange = ElstrConfigStore.option("internalIpRange");

    if (clientIP.startsWith(internalIpRange)) showSwitchApp = true;

    // overwrite showSwitchApp if env is development
    let env = ElstrConfigStore.getApplicationEnv();
    if (env === "development") showSwitchApp = true;

    return { showSwitchApp };
  },

  userLogout: function (e) {
    e.preventDefault();
    ElstrUserActions.logout();
  },

  propTypes: {
    renderedPlace: PropTypes.string.isRequired,
  },

  render: function () {
    // assign classNames
    let classNameTop = "";
    let classNameOverlay = "";
    let style = {};

    if (this.props.renderedPlace === "header") {
      classNameTop = "meta-nav__catalog-switch catalog-switch";
    } else if (this.props.renderedPlace === "overlayMenu") {
      classNameTop = "menu-overlay__catalog-switch catalog-switch";
      classNameOverlay = " menu-overlay-catalog-switch__label";
    } else if (this.props.renderedPlace === "detail") {
      classNameTop = "phone-tablet--hidden";
      style = {
        marginTop: 5,
        fontSize: "0.875em",
      };
    } else if (this.props.renderedPlace === "selection") {
      classNameTop = "phone-tablet--hidden";
      style = {
        marginTop: 5,
        fontSize: "0.875em",
        float: "right",
        marginRight: 100,
      };
    }

    if (this.state.showSwitchApp) {
      // def for catalogue
      let classNameHandleRight = "";
      let switchAppText = ElstrLangStore.text("SWITCH_TO_PISYS");

      // to determine the lang text and on which site the handle switch is
      let isPisysSite = ElstrConfigStore.option("isPisysSite");
      if (isPisysSite) {
        switchAppText = ElstrLangStore.text("SWITCH_TO_CATALOGUE");
        classNameHandleRight = "catalog-switch__handle--right";
      }

      const mainViewIsSettings = UrlHelpers.getMainView() === MV.SETTINGS;

      // userMenu
      const path = mainViewIsSettings ? UrlHelpers.getLinkPathForResult() : UrlHelpers.getLinkPathForSettings();
      const shouldMenuBeDisplayed =
        ElstrUserStore.forceAuthentication() === true &&
        ElstrUserStore.isAuth() === true &&
        this.props.renderedPlace !== MV.DETAIL &&
        this.props.renderedPlace !== MV.SELECTION &&
        LIBS.appName === "catalogue";

      const prePendString = mainViewIsSettings ? "" : " | ";

      const textOptions = mainViewIsSettings
        ? ElstrLangStore.text("BACK_TO_RESULTS")
        : ElstrLangStore.text("USER_SETTINGS");
      const userMenu = shouldMenuBeDisplayed ? (
        <span>
          <span className="pointer" onClick={this.userLogout}>
            {"".concat(prePendString, ElstrLangStore.text("USER_LOGOUT"))}
          </span>
          <Link to={path}>
            <span className="pointer">{"".concat(" | ", textOptions)}</span>
          </Link>
        </span>
      ) : null;

      // remove switch app for settings
      let switchAppSwitch = !mainViewIsSettings ? (
        <div className="catalog-switch__switch-box" onMouseDown={UrlHelpers.switchApp} onClick={UrlHelpers.switchApp}>
          <div
            className={`catalog-switch__handle ${classNameHandleRight}`}
            onMouseDown={UrlHelpers.switchApp}
            onClick={UrlHelpers.switchApp}
          />
        </div>
      ) : null;

      return (
        <div className={classNameTop} style={style}>
          {switchAppSwitch}
          <div className={`catalog-switch__label ${classNameOverlay}`}>
            {!mainViewIsSettings ? `${switchAppText}` : null}
            {userMenu}
          </div>
        </div>
      );
    } else {
      return <div className={classNameTop} />;
    }
  },
});

export default SwitchApp;
