import React from "react";
import createReactClass from "create-react-class";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import ElstrUserStore from "elstr-jslib/src/scripts/stores/ElstrUserStore";
import VetroUserHandler from "../user/VetroUserHandler";
import AppsStore from "../../stores/AppsStore";

const OverlayInfobox = createReactClass({
  mixins: [ElstrLangStore.mixin],

  storeDidChange: function () {
    this.forceUpdate();
  },

  render: function () {
    let selectionShare = "Select how to share your Selection";
    // let selectionShare = ElstrLangStore.text("LANG_TEXT_INFOBOX");
    // let textInfo = ElstrLangStore.text("LANG_TEXT_INFOBOX");

    // decide if to show the vetro user handler
    if (!ElstrUserStore.isAuth()) {
      return <VetroUserHandler />;
    } else if (AppsStore.getOverlayInfoboxOpen()) {
      // this is the content from the clickdummy
      // it won't be used at the moment, but remains for later usages here

      let shareMySelectionAsLink = "Share current selection as link";
      let shareMySelectionAsACopy = "Create a copy of the current selection and share this as a link";

      return (
        <div className="infobox-overlay">
          <div className="overlay__close-btn">
            <span className="ficon ficon__close-thin" />
          </div>
          <div className="infobox-overlay__content">
            <div className="infobox-overlay__text">{selectionShare}</div>
            <ul className="overlay-list">
              <li className="overlay-list__item">
                <a href={`mailto:${shareMySelectionAsLink}`}>
                  <span className="ficon ficon__mail support-list__mail-icon" />
                </a>
                {shareMySelectionAsLink}
              </li>
              <li className="overlay-list__item">
                <a href={`http://${shareMySelectionAsACopy}`}>
                  <span className="ficon ficon__mail support-list__mail-icon" />
                </a>
                {shareMySelectionAsACopy}
              </li>
            </ul>
          </div>
        </div>
      );
    } else {
      return <span />;
    }
  },
});

export default OverlayInfobox;
