import React from "react";
import PrintLabelIcon from "../../icon/PrintLabel";
import PageClose from "./PageClose";
import { getMainView } from "../../../libs/UrlHelpers";
import { MAIN_VIEW } from "../../../constants/MainView";
import DossierSVG from "../../svg/Dossier";
import { getTitle } from "../../../libs/Title";

export default function Header() {
  // defaults
  let icon = null;

  const mainView = getMainView();

  if (mainView === MAIN_VIEW.DOSSIER) {
    icon = <DossierSVG color="white" />;
  }

  if (mainView === MAIN_VIEW.PRINT_LABEL) {
    icon = <PrintLabelIcon />;
  }

  return (
    <div className="common-page__header phone--hidden">
      <div className="common-page-header__container">
        <span className="ficon common-page-header__icon">{icon}</span>
        <h1 className="common-page-header__title">{getTitle()}</h1>
        <PageClose />
      </div>
    </div>
  );
}
