import mcFly from "elstr-jslib/src/scripts/libs/mcFly.js";
import ElstrEditingStates from "elstr-jslib/src/scripts/constants/ElstrEditingStates";
import ElstrLoadingStates from "elstr-jslib/src/scripts/constants/ElstrLoadingStates";
import ElstrDisplayStore from "elstr-jslib/src/scripts/stores/ElstrDisplayStore";
import ElstrConfigStore from "elstr-jslib/src/scripts/stores/ElstrConfigStore";
import ElstrUserStore from "elstr-jslib/src/scripts/stores/ElstrUserStore";

const AppsStore = mcFly.createStore(
  {
    data: null,
    error: null,
    loadingState: ElstrLoadingStates.EMPTY,
    editingState: ElstrEditingStates.NORMAL,
    languageSelectionOpen: false,
    catalogueSelectionOpen: false,
    overlayMenuOpen: false,
    overlaySettingsOpen: false, // for Attributes.TYPES.result and Attributes.TYPES.mainDossier
    overlaySettingsOpenPackagingDossier: false, // for Attributes.TYPES.packagingDossier
    overlayFiltersOpen: false,
    overlayInfoboxOpen: false,
    overlayDetailContactOpen: false,
    layoutView: null, // can be ['desktop', 'tablet', 'phone']
    isMobileClient: false, // on iphone for example the onMouserOver event is triggered by two clicks. that's why in certain cases the app will behave different on mobile clients

    getData: function () {
      return this.data;
    },

    getError: function () {
      return this.error;
    },

    getEditingState: function () {
      return this.editingState;
    },

    getLayoutView: function () {
      return this.layoutView;
    },

    getLanguageSelectionOpen: function () {
      return this.languageSelectionOpen;
    },

    getCatalogueSelectionOpen: function () {
      return this.catalogueSelectionOpen;
    },

    getOverlayMenuOpen: function () {
      return this.overlayMenuOpen;
    },

    getOverlaySettingsOpen: function () {
      return this.overlaySettingsOpen;
    },

    getOverlaySettingsOpenPackagingDossier: function () {
      return this.overlaySettingsOpenPackagingDossier;
    },

    getOverlayFiltersOpen: function () {
      return this.overlayFiltersOpen;
    },

    getOverlayInfoboxOpen: function () {
      return this.overlayInfoboxOpen;
    },

    getOverlayDetailContactOpen: function () {
      return this.overlayDetailContactOpen;
    },

    getFormatedTelNr: function (cataloguePhone) {
      let cataloguePhoneFormated = cataloguePhone;

      // format telNr for link
      cataloguePhoneFormated = cataloguePhoneFormated.replace(/ /g, ""); // remove whitespace
      cataloguePhoneFormated = cataloguePhoneFormated.replace(/-/g, ""); // remove dash

      return cataloguePhoneFormated;
    },

    getIsMobileClient: function () {
      return AppsStore.isMobileClient;
    },

    getIsMobileLayoutView: function () {
      if (AppsStore.layoutView === "desktop") return false;

      if (AppsStore.layoutView === "tablet") return true;

      if (AppsStore.layoutView === "phone") return true;

      return false;
    },

    // toggle functions
    toggleLanguageOpen: function (e) {
      e.stopPropagation();

      AppsStore.languageSelectionOpen = !AppsStore.languageSelectionOpen;

      // close other overlays when self is about to open
      if (AppsStore.languageSelectionOpen) {
        AppsStore.catalogueSelectionOpen = false;
        AppsStore.overlaySettingsOpen = false;
      }

      AppsStore.emitChange();
    },

    toggleCatalogueSelectionOpen: function (e) {
      e.stopPropagation();

      AppsStore.catalogueSelectionOpen = !AppsStore.catalogueSelectionOpen;

      // close other overlays when self is about to open
      if (AppsStore.catalogueSelectionOpen) {
        AppsStore.languageSelectionOpen = false;
        AppsStore.overlaySettingsOpen = false;
      }

      AppsStore.emitChange();
    },

    toggleOverlayOpenMenu: function () {
      AppsStore.overlayMenuOpen = !AppsStore.overlayMenuOpen;

      AppsStore.emitChange();
    },

    toggleOverlaySettingsOpen: function (e) {
      e.stopPropagation();

      AppsStore.overlaySettingsOpen = !AppsStore.overlaySettingsOpen;

      if (AppsStore.overlaySettingsOpen) {
        AppsStore.languageSelectionOpen = false;
        AppsStore.catalogueSelectionOpen = false;
        AppsStore.overlaySettingsOpenPackagingDossier = false;
      }

      AppsStore.emitChange();
    },

    toggleOverlaySettingsOpenPackagingDossier: function (e) {
      e.stopPropagation();

      AppsStore.overlaySettingsOpenPackagingDossier = !AppsStore.overlaySettingsOpenPackagingDossier;

      if (AppsStore.overlaySettingsOpenPackagingDossier) {
        AppsStore.languageSelectionOpen = false;
        AppsStore.catalogueSelectionOpen = false;
        AppsStore.overlaySettingsOpen = false;
      }

      AppsStore.emitChange();
    },

    toggleOverlayFiltersOpen: function () {
      AppsStore.overlayFiltersOpen = !AppsStore.overlayFiltersOpen;

      AppsStore.emitChange();
    },

    toggleOverlayInfoboxOpen: function () {
      AppsStore.overlayInfoboxOpen = !AppsStore.overlayInfoboxOpen;

      AppsStore.emitChange();
    },

    toggleOverlayDetailContactOpen: function () {
      AppsStore.overlayDetailContactOpen = !AppsStore.overlayDetailContactOpen;

      AppsStore.emitChange();
    },

    // set function
    setLayoutViewOnResize: function () {
      let viewportWidth = ElstrDisplayStore.getViewportDimensions().width;
      let currentLayoutView = AppsStore.layoutView;

      if (viewportWidth > 1280) {
        AppsStore.layoutView = "desktop";
        // following settings will be set to false to close them, when switching from
        // a mobile view back to desktop. prevents styles errors
        AppsStore.overlayMenuOpen = false;
        AppsStore.overlayFiltersOpen = false;
        AppsStore.languageSelectionOpen = false;
        AppsStore.catalogueSelectionOpen = false;
      } else if (viewportWidth > 768) {
        AppsStore.layoutView = "tablet";
      } else {
        AppsStore.layoutView = "phone";
      }

      // only emit a change when the layoutView has to be upgraded
      if (AppsStore.layoutView !== currentLayoutView) {
        AppsStore.emitChange();
      }
    },

    // keep because otherwhise this functionality gets lost
    setOverlayInfoboxOpen: function (newBool) {
      AppsStore.overlayInfoboxOpen = newBool;

      AppsStore.emitChange();
    },

    setOverlaySettings: function (newBool) {
      AppsStore.overlaySettingsOpen = newBool;

      // close other overlays when self is about to open
      if (AppsStore.overlaySettingsOpen) {
        AppsStore.languageSelectionOpen = false;
        AppsStore.catalogueSelectionOpen = false;
        AppsStore.overlaySettingsOpenPackagingDossier = false;
      }

      AppsStore.emitChange();
    },

    setOverlaySettingsPackagingDossier: function (newBool) {
      AppsStore.overlaySettingsOpenPackagingDossier = newBool;

      // close other overlays when self is about to open
      if (AppsStore.overlaySettingsOpenPackagingDossier) {
        AppsStore.languageSelectionOpen = false;
        AppsStore.catalogueSelectionOpen = false;
        AppsStore.overlaySettingsOpen = false;
      }

      AppsStore.emitChange();
    },

    setCatalogueSettings: function (newBool) {
      AppsStore.catalogueSelectionOpen = newBool;

      AppsStore.emitChange();
    },

    setLanguageSettings: function (newBool) {
      AppsStore.languageSelectionOpen = newBool;

      AppsStore.emitChange();
    },

    setCloseAllMenus: function (e) {
      e.stopPropagation();

      // close every possible menu that could be open
      AppsStore.languageSelectionOpen = false;
      AppsStore.catalogueSelectionOpen = false;
      AppsStore.overlayMenuOpen = false;
      AppsStore.overlaySettingsOpen = false;
      AppsStore.overlayFiltersOpen = false;
      AppsStore.overlayInfoboxOpen = false;
      AppsStore.overlayDetailContactOpen = false;

      AppsStore.emitChange();
    },

    setIsMobileClient: function () {
      AppsStore.isMobileClient =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
          navigator.userAgent,
        );
    },

    shouldLoginBeRendered: function () {
      // showLoginDialog is only required when forceAuthentication is true and the user is not authorizied
      let showLoginDialog = false;
      if (ElstrUserStore.forceAuthentication() === true && ElstrUserStore.isAuth() === false) {
        showLoginDialog = true;
      }

      // login is only required for isPisysSite and will than be rendered
      let loginRequired = false;
      if (ElstrConfigStore.option("isPisysSite")) loginRequired = true;

      return loginRequired && showLoginDialog;
    },
  },
  function (payload) {
    switch (payload.actionType) {
    }
    return true;
  },
);

// windows resize event listener
window.onresize = AppsStore.setLayoutViewOnResize;
// to set default value **/
(function () {
  AppsStore.setLayoutViewOnResize();
})();

// initialize if application started in a mobile client
(function () {
  AppsStore.setIsMobileClient();
})();

export default AppsStore;
