import { MAIN_VIEW as MV } from "../../constants/MainView";
import * as Attributes from "../../libs/Attributes";
import React from "react";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import ElstrScrollStore from "elstr-jslib/src/scripts/stores/ElstrScrollStore";
import AttributesStore from "../../stores/AttributesStore";
import AppsStore from "../../stores/AppsStore";
import * as UrlHelpers from "../../libs/UrlHelpers";
import ItemList from "./ItemList";
import ItemListMore from "./ItemListMore";
import ItemListHeader from "./ItemListHeader";
import ItemListScroll from "./ItemListScroll";
import ItemListWidthStore from "../../stores/ItemListWidthStore";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";

const ClassChangerOnScroll = createReactClass({
  mixins: [AppsStore.mixin, ElstrScrollStore.mixin, ItemListWidthStore.mixin],

  storeDidChange: function () {
    this.forceUpdate();
  },

  componentDidMount: function () {
    let mainView = UrlHelpers.getMainView();

    if (mainView === MV.RESULT) {
      let currentLanguage = ElstrLangStore.getCurrentLanguage();
      window._paq.push(["trackPageView", "Katalog List ( " + currentLanguage + " )"]);
    } else if (mainView === MV.SELECTION) {
      let selectionId = ElstrUrlHashStore.get("selectionId");
      window._paq.push(["trackPageView", "mySelection List ( " + selectionId + " )"]);
    }
  },

  render: function () {
    let classNameItemList = "item-list__labels-row";
    let mainView = UrlHelpers.getMainView();

    if (mainView === MV.SELECTION) {
      classNameItemList += " my-selection-item-list__labels-row";
    }

    // style of the item list header according to the scroll position, display size and actual main view
    let scrollTop = ElstrScrollStore.getCurrentScroll();
    let scrollLeft = ElstrScrollStore.getCurrentHorizontalScroll();
    let layoutView = AppsStore.getLayoutView();

    let left = 0; // def
    let paddingLeft = 0; // def
    let width = "auto"; // reason is to make the label long enough so scrolling it won't shorten it

    if (mainView === MV.RESULT) {
      switch (layoutView) {
        case "desktop":
          paddingLeft = 36; // default if scrollTop is not on header Sticky

          if (scrollTop > 212) {
            paddingLeft = 396;
            width = ItemListWidthStore.getItemListWidth() + paddingLeft;
            left = 0 - scrollLeft;
          }
          break;

        case "tablet":
          paddingLeft = 40;
          width = ItemListWidthStore.getItemListWidth() + paddingLeft;
          if (scrollTop > 160) {
            left = 0 - scrollLeft;
          }

          break;

        case "phone":
          paddingLeft = 20;
          width = ItemListWidthStore.getItemListWidth() + paddingLeft;
          if (scrollTop > 160) {
            left = 0 - scrollLeft;
          }

          break;
      }
      // for mainView === selection
    } else if (mainView === MV.SELECTION) {
      width = ItemListWidthStore.getItemListWidth();

      switch (layoutView) {
        case "desktop":
          if (scrollTop > 200) {
            left = 65 - scrollLeft;
          }
          break;

        case "tablet":
          if (scrollTop > 200) {
            left = 40 - scrollLeft;
          }
          break;

        case "phone":
          left = 0 - scrollLeft;
          break;
      }
    }

    let styleItemList = { paddingLeft: paddingLeft, left: left, width: width };

    return (
      <div className={classNameItemList} style={styleItemList}>
        {this.props.children}
      </div>
    );
  },
});

const ContainerList = createReactClass({
  mixins: [AttributesStore.mixin],

  propTypes: {
    materialsToRender: PropTypes.array.isRequired,
    displayMore: PropTypes.bool,
    scrollActive: PropTypes.bool.isRequired,
  },

  storeDidChange: function () {
    this.forceUpdate();
  },

  render: function () {
    let attributesSorted = Attributes.getAttributesSortedAndByMainView();

    const counterForUpdate = AttributesStore.getCounterForUpdate();

    // start filling the content with listItems
    const listItems = this.props.materialsToRender.map((material, i) => {
      return (
        <ItemList
          key={material.sMatHdrMatnr}
          material={material}
          attributesSorted={attributesSorted}
          counterForUpdate={counterForUpdate}
          isFirstOne={i === 0}
        />
      );
    });

    return (
      <React.Fragment key="ContainerList">
        <ClassChangerOnScroll>
          <ItemListHeader attributesSorted={attributesSorted} />
        </ClassChangerOnScroll>
        {listItems}
        <ItemListMore
          displayMore={this.props.displayMore || false}
          numberOfResults={this.props.materialsToRender.length}
        />
        <ItemListScroll scrollActive={this.props.scrollActive} />
      </React.Fragment>
    );
  },
});

export default ContainerList;
