import React from "react";

const PisysTable = ({ thead, tbody }) => (
  <div className="tooltip__text table-restrictions">
    <table className="detail-pisys-info__product">
      {thead}
      {tbody}
    </table>
  </div>
);

export default PisysTable;
