import React from "react";
import CenterHover from "./eyekon/CenterHover";
import LeftHover from "./eyekon/LeftHover";
import CenterSticky from "./eyekon/CenterSticky";
import LeftSticky from "./eyekon/LeftSticky";

const ContainerTooltips = () => (
  <>
    <CenterHover />
    <LeftHover />
    <CenterSticky />
    <LeftSticky />
  </>
);

export default ContainerTooltips;
