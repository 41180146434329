import Translations from "../../libs/Translations";
import React from "react";
import * as Attributes from "../../libs/Attributes";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import * as UrlHelpers from "../../libs/UrlHelpers";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";
import ElstrConfigStore from "elstr-jslib/src/scripts/stores/ElstrConfigStore";

const SortOrderListbox = createReactClass({
  mixins: [ElstrUrlHashStore.mixin],

  propTypes: {
    renderNotFor: PropTypes.array.isRequired, // can be ['grid', 'list', 'compare']
  },

  storeDidChange: function () {
    this.forceUpdate();
  },

  selectChange: function (e) {
    let value = e.target.value;
    if (value !== "-") {
      UrlHelpers.setHashNoHistory({ sortByKey: value });
    } else {
      UrlHelpers.clearHash("sortByKey");
    }
  },

  render: function () {
    // check if component should rerender or return empty <i/>
    let resultView = ElstrUrlHashStore.get("resultView") || "grid";
    if (this.props.renderNotFor.includes(resultView)) {
      return <i />;
    } else {
      let textSortBy = ElstrLangStore.text("HEADER_STICKY_SORT_BY");

      let currentSortedBy = ElstrUrlHashStore.get("sortByKey");

      // options for grid are hardcoded according to FSP chapter 4.8
      // options for compare are defined by the configdata.json (attributes)
      let sortKeys = [];
      if (resultView === "grid") {
        // pisys has different sorting than eCat (eCat without sMatAtvUmriss)
        // https://basecamp.com/1769982/projects/12082841/todos/301763823
        if (ElstrConfigStore.option("isPisysSite")) {
          sortKeys = [
            "sMatAtvEcatSubgroup",
            "sMatAtvUmriss",
            "sMatAtvFuellvoll",
            "sMatAtvMuendungsgruppe",
            "sMatAtvFarbe",
          ];
        } else {
          sortKeys = ["sMatAtvEcatSubgroup", "sMatAtvFuellvoll", "sMatAtvMuendungsgruppe", "sMatAtvFarbe"];
        }
      } else if (resultView === "compare") {
        let attributesSorted = Attributes.getAttributesSortedAndByMainView();

        // push content
        attributesSorted.forEach(attribute => {
          if (attribute["compareDisplayDefault"] === true && attribute["compareDisplayAllowed"] === true) {
            sortKeys.push(attribute["dataAttributeKey"]);
          }
        });
      }

      // start pushing the content additionalOptions
      let additionalOptions = [];
      let defaultValue = null;

      sortKeys.forEach(sortKey => {
        // set default Value if according
        if (currentSortedBy === sortKey) defaultValue = sortKey;

        // push option
        additionalOptions.push(
          <option key={sortKey} value={sortKey}>
            {Translations.getLabel(sortKey) || ""}
          </option>,
        );
      });

      // create sort list
      let contentSortBy = (
        <select className="selectbox sort-by__select" defaultValue={defaultValue} onChange={this.selectChange}>
          <option value="-" onClick={UrlHelpers.clearHash.bind(this, "sortByKey")}>
            {textSortBy}
          </option>
          {additionalOptions}
        </select>
      );

      return <div className="main-header__sort-by">{contentSortBy}</div>;
    }
  },
});

export default SortOrderListbox;
