// from http://stackoverflow.com/a/4026828
// returns the differences from first array to second
export function diff(arraySelf, arrayToDiff) {
  return arraySelf.filter(i => {
    return arrayToDiff.indexOf(i) < 0;
  });
}

// returns arr of objs with key and value pair
export function findObjsInArray(arr, key, value) {
  return arr.filter(obj => {
    return obj[key] === value;
  });
}

// receives a json obj and returns the first key name
export function getFirstKeyNameOfJSON(jsonObj) {
  let firstProp;
  for (let key in jsonObj) {
    if (jsonObj.hasOwnProperty(key)) {
      firstProp = key;
      break;
    }
  }
  return firstProp;
}

export function setScrollToTop(timeout = 50) {
  setTimeout(function () {
    window.scrollTo(0, 0);
  }, timeout);
}

// checks if two json objects are equal
export function objectEquals(x, y) {
  // http://stackoverflow.com/a/26049428
  // if both are function
  if (x instanceof Function) {
    if (y instanceof Function) {
      return x.toString() === y.toString();
    }
    return false;
  }
  if (x === null || x === undefined || y === null || y === undefined) {
    return x === y;
  }
  if (x === y || x.valueOf() === y.valueOf()) {
    return true;
  }

  // if one of them is date, they must had equal valueOf
  if (x instanceof Date) {
    return false;
  }
  if (y instanceof Date) {
    return false;
  }

  // if they are not function or strictly equal, they both need to be Objects
  if (!(x instanceof Object)) {
    return false;
  }
  if (!(y instanceof Object)) {
    return false;
  }

  let p = Object.keys(x);
  return Object.keys(y).every(function (i) {
    return p.indexOf(i) !== -1;
  })
    ? p.every(function (i) {
        return objectEquals(x[i], y[i]);
      })
    : false;
}

// sorts an array of obj by key name
// http://stackoverflow.com/a/8175221
export function sortByKey(array, key) {
  return array.sort(function (a, b) {
    let x = a[key];
    let y = b[key];

    if (typeof x === "string") {
      x = x.toLowerCase();
    }
    if (typeof y === "string") {
      y = y.toLowerCase();
    }

    return x < y ? -1 : x > y ? 1 : 0;
  });
}
