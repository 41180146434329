import React, { useEffect, useState } from "react";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import DossierStore from "../../stores/DossierStore";

export default function DocumentsToggle() {
  const [toggleAZ, setToggleAZ] = useState(false);
  const [togglePP, setTogglePP] = useState(false);
  const [toggleMUZ, setToggleMUZ] = useState(false);
  const [toggleSP, setToggleSP] = useState(false);

  // when a user has used the DossierStore.toggleAllDocsChecked
  // we make sure that the specific toggles (AZ, PP...)
  // will trigger the opposite effect
  // this behaviour has a much better UX
  useEffect(
    function () {
      let state = !DossierStore.getToggleAllDocsState();
      setToggleAZ(state);
      setTogglePP(state);
      setToggleMUZ(state);
      setToggleSP(state);
    },
    [DossierStore.getToggleAllDocsState()],
  );

  function toggleAllAZ() {
    DossierStore.toggleDokarChecked("AZ", toggleAZ);
    setToggleAZ(!toggleAZ);
  }

  function toggleAllPP() {
    DossierStore.toggleDokarChecked("PP", togglePP);
    setTogglePP(!togglePP);
  }

  function toggleAllMUZ() {
    DossierStore.toggleDokarChecked("MUZ", toggleMUZ);
    setToggleMUZ(!toggleMUZ);
  }

  function toggleAllSP() {
    DossierStore.toggleDokarChecked("SP", toggleSP);
    setToggleSP(!toggleSP);
  }

  return (
    <React.Fragment>
      <div className="item-compare-label__row">
        <span className="item-compare-label__title clickable-attributes" onClick={toggleAllAZ}>
          {`${ElstrLangStore.text("DOSSIER_TOGGLE_SPECIFIC_DOCTYPE")} AZ`}
        </span>
      </div>
      <div className="item-compare-label__row hide-attributes-color">
        <span className="item-compare-label__title clickable-attributes" onClick={toggleAllPP}>
          {`${ElstrLangStore.text("DOSSIER_TOGGLE_SPECIFIC_DOCTYPE")} PP`}
        </span>
      </div>
      <div className="item-compare-label__row hide-attributes-color">
        <span className="item-compare-label__title clickable-attributes" onClick={toggleAllMUZ}>
          {`${ElstrLangStore.text("DOSSIER_TOGGLE_SPECIFIC_DOCTYPE")} MUZ`}
        </span>
      </div>
      <div className="item-compare-label__row hide-attributes-color">
        <span className="item-compare-label__title clickable-attributes" onClick={toggleAllSP}>
          {`${ElstrLangStore.text("DOSSIER_TOGGLE_SPECIFIC_DOCTYPE")} SP`}
        </span>
      </div>
    </React.Fragment>
  );
}
