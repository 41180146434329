import SelectionActions from "../actions/SelectionActions";
import SelectionStore from "../stores/SelectionStore";
import ElstrConfigStore from "elstr-jslib/src/scripts/stores/ElstrConfigStore";
import ElstrCache from "elstr-jslib/src/scripts/ElstrCache";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";
import * as UrlHelpers from "./UrlHelpers";

// dynamic load of pusher file because it is big
const script = document.createElement("script");
script.type = "text/javascript";
script.src = "https://js.pusher.com/4.3/pusher.min.js";
document.getElementsByTagName("head")[0].appendChild(script);
script.onload = pusherHasLoaded;
script.onerror = loadFromOwnServer;

function loadFromOwnServer() {
  const backup = document.createElement("script");
  backup.type = "text/javascript";
  backup.src = "scripts/pusher.min.js";
  document.getElementsByTagName("head")[0].appendChild(backup);
  backup.onload = pusherHasLoaded;
}

function pusherHasLoaded() {
  // Pusher.logToConsole = true;

  // Create Instance of Pusher
  const api_key = ElstrConfigStore.option("pusher", "api_key");
  const cluster = ElstrConfigStore.option("pusher", "cluster");
  const pusher = new Pusher(api_key, {
    cluster: cluster,
    encrypted: true,
  });

  // Initialize channel selection for selection-change and define the action
  const channel = pusher.subscribe("selection");
  channel.bind("selection-change", data => {
    let selectionId = data.selectionId;
    let selectionSource = UrlHelpers.getSelectionSourceForServerRequest();
    let selectionIdURL = ElstrUrlHashStore.get("selectionId");
    let editingSelectionId = SelectionStore.getEditingSelectionId();

    // CASE 1 TO UPDATE THE ONE YOU WE'RE EITHER:
    // * EDITING
    // * SEEING (WITH RENDERED SELECTION ID)
    // * OR IS IN THE HASH URL
    if (selectionId === editingSelectionId || selectionId === selectionIdURL) {
      let payload = { selectionId, selectionSource };
      SelectionActions.getMaterials({ payload });
      // exit
      return;
    }

    // CASE 2 TO UPDATE YOUR OWN SELECTION BECAUSE SOMEONE CHANGED IT
    if (selectionId === ElstrCache.get("mySelectionId")) {
      let payload = { selectionId, selectionSource: "local" };
      SelectionActions.getMaterials({ payload });
      // exit
    }
  });
}
