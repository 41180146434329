import React from "react";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";
import * as UrlHelpers from "../../libs/UrlHelpers";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import MaterialsStore from "../../stores/MaterialsStore";
import FilterStore from "../../stores/FilterStore";

const FilterRadiobox = createReactClass({
  mixins: [MaterialsStore.mixin],

  propTypes: {
    filter: PropTypes.object, // for every filter type
  },

  getInitialState: function () {
    return {
      filter: this.props.filter,
      hash: `filter_${this.props.filter.dataAttributeKey}`,
    };
  },

  storeDidChange: function () {
    this.forceUpdate();
  },

  render: function () {
    let dataAttributeKey = this.state.filter.dataAttributeKey;
    let filterValue = ElstrUrlHashStore.get(this.state.hash);
    let hash = this.state.hash;

    let sumsOfAttribute = MaterialsStore.getSumsOfAttributeValueFilters(dataAttributeKey);
    // sort attributes alphabethic
    let sumsOfAttributeSorted = Object.keys(sumsOfAttribute).sort();

    let content = [];

    // default radiobox
    let checked = !filterValue || filterValue === "any";
    content.push(
      <span key={dataAttributeKey}>
        <input
          type="radio"
          id={dataAttributeKey}
          checked={checked}
          onChange={UrlHelpers.clearSingleFilter.bind(this, dataAttributeKey)}
        />
        <label htmlFor={dataAttributeKey}>&nbsp;{ElstrLangStore.text("FILTER_TEXT_ANY")}</label>
      </span>,
    );

    // fill only content that exists, because of sumsOfAttributes
    sumsOfAttributeSorted.forEach(key => {
      // if there is no sum available, the content shouldn't not be added
      if (sumsOfAttribute[key]) {
        let text = FilterStore.getFilterText(key, dataAttributeKey);

        content.push(
          <div className="radiobox__item" key={key}>
            <input
              type="radio"
              id={key}
              checked={filterValue === key}
              onChange={UrlHelpers.setFilterValue.bind(this, hash, key)}
            />
            <label htmlFor={key}>&nbsp;{text}</label>
          </div>,
        );
      }
    });

    return <div className="filter-block__radiobox">{content}</div>;
  },
});

export default FilterRadiobox;
