import React from "react";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import PrintLabel from "../icon/PrintLabel";
import { useSvgColor } from "../../hooks/svg";

const DetailToolbarPrintLabel = () => {
  const [color, onMouseOver, onMouseOut] = useSvgColor();

  return (
    <li
      className="detail-main-toolbar__item inline-block--phone-tablet--hidden"
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      <PrintLabel className="detail-main-toolbar__item-non-standard" color={color} />
      <span className="detail-main-toolbar-item__title detail-main-toolbar-item__non-standard">
        {ElstrLangStore.text("PRINT_LABEL_TITLE")}
      </span>
    </li>
  );
};

export default DetailToolbarPrintLabel;
