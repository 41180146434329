import ElstrFlux from "elstr-jslib/src/scripts/libs/ElstrFlux.js";
import DetailsConstants from "../constants/actions/DetailsConstants";
import ElstrIo from "elstr-jslib/src/scripts/ElstrIo.js";

const className = "VETROPACK_WidgetServer_JSON_Detail";
const elstrIo = new ElstrIo({
  abortStaleRequests: true,
  maxTimeout: 60000 * 5, // 5 Min
});

const DetailsActions = ElstrFlux.createActions(
  [
    {
      className: className,
      methodName: "getDocuments",
      constantWill: DetailsConstants.GET_MATERIAL_DOCUMENTS_WILL_GET,
      constantDid: DetailsConstants.GET_MATERIAL_DOCUMENTS_DID_GET,
    },
    {
      className: className,
      methodName: "getStock",
      constantWill: DetailsConstants.GET_MATERIAL_STOCK_WILL_GET,
      constantDid: DetailsConstants.GET_MATERIAL_STOCK_DID_GET,
    },
    {
      className: className,
      methodName: "getSalesOrder",
      constantWill: DetailsConstants.GET_MATERIAL_SALES_ORDER_WILL_GET,
      constantDid: DetailsConstants.GET_MATERIAL_SALES_ORDER_DID_GET,
    },
    {
      className: className,
      methodName: "getProductionOrder",
      constantWill: DetailsConstants.GET_MATERIAL_PRODUCTION_WILL_GET,
      constantDid: DetailsConstants.GET_MATERIAL_PRODUCTION_DID_GET,
    },
    {
      className: className,
      methodName: "getCustomer",
      constantWill: DetailsConstants.GET_MATERIAL_CUSTOMER_WILL_GET,
      constantDid: DetailsConstants.GET_MATERIAL_CUSTOMER_DID_GET,
    },
    {
      className: className,
      methodName: "getBlowMoulds",
      constantWill: DetailsConstants.GET_MATERIAL_BLOW_MOULDS_WILL_GET,
      constantDid: DetailsConstants.GET_MATERIAL_BLOW_MOULDS_DID_GET,
    },
    {
      className: className,
      methodName: "getComplaints",
      constantWill: DetailsConstants.GET_MATERIAL_COMPLAINTS_WILL_GET,
      constantDid: DetailsConstants.GET_MATERIAL_COMPLAINTS_DID_GET,
    },
    {
      className: className,
      methodName: "getProdWF",
      constantWill: DetailsConstants.GET_MATERIAL_PROD_WF_WILL_GET,
      constantDid: DetailsConstants.GET_MATERIAL_PROD_WF_DID_GET,
    },
  ],
  {},
  elstrIo,
);

export default DetailsActions;
