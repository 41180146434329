import React from "react";
import AddDossier from "../svg/AddDossier";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import PrintLabelSVG from "../svg/PrintLabel";
import { useSvgColor } from "../../hooks/svg";

export const HEADER_ITEM_TYPE = {
  ADD_DOSSIER: "ADD_DOSSIER",
  PRINT_LABEL: "PRINT_LABEL",
};

const HeaderItem = ({ headerItemType }) => {
  const [color, onMouseOver, onMouseOut] = useSvgColor();

  let content = null;
  if (headerItemType === HEADER_ITEM_TYPE.ADD_DOSSIER) {
    content = (
      <>
        <AddDossier color={color} className="selection-header-list__add-dossier" />
        <span className="hide-text-phone">{ElstrLangStore.text("CREATE_DOSSIER")}</span>
      </>
    );
  }

  if (headerItemType === HEADER_ITEM_TYPE.PRINT_LABEL) {
    content = (
      <>
        <PrintLabelSVG color={color} className="selection-header-list__add-dossier" width="2em" height="2em" />
        <span className="hide-text-phone">{ElstrLangStore.text("PRINT_LABEL_TITLE")}</span>
      </>
    );
  }

  return (
    <li className="my-selection-menu__item" onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
      <span className="my-selection-menu-item__title">{content}</span>
    </li>
  );
};

export default HeaderItem;
