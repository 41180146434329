import React from "react";
import { Link } from "react-router-dom";
import vetroLogo from "../../assets/img/layout/vetropack-logo.svg";
import * as Sentry from "@sentry/browser";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.removeErr = this.removeErr.bind(this);
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    Sentry.captureException(error);
    Sentry.captureException(new Error(`info: ${JSON.stringify(info)}`));
  }

  removeErr() {
    this.setState({ hasError: false });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <React.Fragment>
          <div className="header-wrap" style={{ top: "0px" }}>
            <nav className="meta-nav phone-tablet--hidden">
              <h2 className="visually-hidden">Meta Navigation</h2>
              <div className="meta-nav__container" />
            </nav>
            <header className="header">
              <h2 className="visually-hidden">Header</h2>
              <div className="header__container">
                <Link to={"/catalogue/result/global/all#ph=1"} onClick={this.removeErr}>
                  <div className="header__logo--center">
                    <div className="header__logo">
                      <h2 className="visually-hidden">Vetropack Logo</h2>
                      <img className="header-logo__img" src={vetroLogo} alt="Vetropack Logo" />
                    </div>
                  </div>
                </Link>
              </div>
            </header>
          </div>
          <div className="emptypage-main" />
        </React.Fragment>
      );
    }
    return this.props.children;
  }
}
