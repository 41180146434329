import React from "react";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import DetailPisysStock from "./DetailPisysStock";
import DetailPisysSalesOrder from "./DetailPisysSalesOrder";
import DetailPisysProduction from "./DetailPisysProductionOrder";
import DetailPisysCustomer from "./DetailPisysCustomer";
import DetailPisysBlowMoulds from "./DetailPisysBlowMoulds";
import DetailPisysComplaints from "./DetailPisysComplaints";
import DetailPisysAddDocs from "./DetailPisysAddDocs";
import DetailPisysMain from "./DetailPisysMain";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";
import ElstrConfigStore from "elstr-jslib/src/scripts/stores/ElstrConfigStore";
import * as UrlHelpers from "../../libs/UrlHelpers";
import DetailPisysProdWF from "./DetailPisysProdWF";

const DetailPisys = createReactClass({
  propTypes: {
    material: PropTypes.object.isRequired,
    attributesSorted: PropTypes.array.isRequired,
  },

  selectChange: function (e) {
    let value = e.target.value;
    if (value !== "-") {
      UrlHelpers.setHashNoHistory({ pisysTab: value });

      window._paq.push(["trackEvent", "Detail", "pisysTab", value]);
    }
  },

  tabChange: function (tab) {
    UrlHelpers.setHashNoHistory({ pisysTab: tab });
    window._paq.push(["trackEvent", "Detail", "pisysTab", tab]);
  },

  render: function () {
    let material = this.props.material;
    let attributesSorted = this.props.attributesSorted;

    let pisysTab = ElstrUrlHashStore.get("pisysTab");
    // assign default when it's empty
    if (pisysTab === undefined) {
      let pisysTabs = ElstrConfigStore.option("pisysTabs");
      pisysTab = pisysTabs[0];
    }

    // push nav tabs for desktop/tablet (tabDesktopTablet) and for phone (tabPhone)
    let tabDesktopTablet = [];
    let tabPhone = [];
    let pisysTabs = ElstrConfigStore.option("pisysTabs");

    pisysTabs.forEach(tab => {
      let classNameDesktopTablet = "detail-pisys-info-nav-tab__item";
      let desktopTabletSelected = " detail-pisys-info-nav-tab__item--current";
      let classNamePhone = "";

      // apply selected class
      if (pisysTab === tab) {
        classNameDesktopTablet += desktopTabletSelected;
        classNamePhone = "detail-pisys-info-nav-selectbox__item--current";
      }

      // id is in translation in upper case
      let translationId = `DETAIL_TAB_${tab.toUpperCase()}`;

      // initially this was 'blowmoulds' and has changed to MOULD in order to support other types
      // introduced in phase v other types (mouldset, blow moulds, blank mould)
      if (tab === "blowMoulds") {
        translationId = `DETAIL_TAB_MOULDS`;
      }

      // nav tab desktop - tablet
      tabDesktopTablet.push(
        <li className={classNameDesktopTablet} key={`${tab} not-phone`} onClick={this.tabChange.bind(this, tab)}>
          {ElstrLangStore.text(translationId)}
        </li>,
      );

      // nav for phone view
      tabPhone.push(
        <option className={classNamePhone} key={`${tab} phone`} value={tab}>
          {ElstrLangStore.text(translationId)}
        </option>,
      );
    });

    // assign the content according to the selected pisysTab
    let content;
    switch (pisysTab) {
      case "main":
        content = <DetailPisysMain material={material} attributesSorted={attributesSorted} />;
        break;

      case "addDocs":
        content = <DetailPisysAddDocs sMatHdrMatnr={material["sMatHdrMatnr"]} />;

        break;

      case "stock":
        content = <DetailPisysStock material={material} />;

        break;
      case "salesOrder":
        content = <DetailPisysSalesOrder material={material} />;

        break;
      case "production":
        content = <DetailPisysProduction material={material} />;

        break;
      case "customer":
        content = <DetailPisysCustomer material={material} />;

        break;
      case "blowMoulds":
        content = <DetailPisysBlowMoulds material={material} />;

        break;
      case "complaints":
        content = <DetailPisysComplaints material={material} />;

        break;
      case "prodWF":
        content = <DetailPisysProdWF material={material} />;

        break;
    }

    return (
      <div className="detail-pisys-info" id="detail-pisys-info">
        <h2 className="detail-pisys-info__title">{ElstrLangStore.text("DETAIL_TEXT_PISYS_INFORMATIONS")}</h2>
        <ul className="detail-pisys-info__nav--tab phone--hidden">{tabDesktopTablet}</ul>
        <div className="detail-pisys-info__nav--selectbox tablet-desktop--hidden">
          <select
            className="selectbox detail-pisys-info-nav__selectbox"
            defaultValue={pisysTab}
            onChange={this.selectChange}
          >
            {tabPhone}
          </select>
        </div>
        <div className="detail-pisys-info__content-container">
          <div className="detail-pisys-info__content">{content}</div>
        </div>
        {/* <!-- // detail-pisys-info__content-container --> */}
      </div>
    );
  },
});

export default DetailPisys;
