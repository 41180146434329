import React from "react";
import * as UrlHelpers from "../../libs/UrlHelpers";
import { MAIN_VIEW as MV } from "../../constants/MainView";
import PrintLabelSVG from "../svg/PrintLabel";

const PrintLabel = props => {
  const mainView = UrlHelpers.getMainView();

  // defaults
  let width = "1em",
    height = "1em",
    color = "white";

  if (mainView === MV.DETAIL) {
    width = "2em";
    height = "2em";
    color = props.color;
  }

  return <PrintLabelSVG color={color} width={width} height={height} />;
};

export default PrintLabel;
