import React from "react";
import PropTypes from "prop-types";

const AddDossier = function (props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" {...props}>
      <g data-name="Group 4">
        <path data-name="Combined Shape" d="M11 14H0V0h6v5h5v9zm0-10H7V0l4 4z" fill={props.color} />
      </g>
      <path fill={props.color} d="M10 16h6v2h-6z" />
      <path data-name="Rectangle" fill={props.color} d="M12 14h2v6h-2z" />
    </svg>
  );
};

export default AddDossier;

AddDossier.propTypes = {
  color: PropTypes.string.isRequired,
};
