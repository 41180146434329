import React from "react";
import * as UrlHelpers from "../../../libs/UrlHelpers";
import { DOWNLOAD_TYPES } from "../FlexibleDataTable";

const ComplaintsBody = ({ dataRow }) => {
  let rows = [];
  let href = UrlHelpers.getHrefDBDocument();
  let { t_gos_hdr, qmnum } = dataRow;

  t_gos_hdr.forEach((matDoc, i) => {
    let filename = matDoc["filename"].split(" ").join("~");
    let attaId = matDoc["atta_id"];
    let fileType = matDoc["tech_type"].toLowerCase();
    let source = DOWNLOAD_TYPES.COMPLAINTS;

    // no download for txt fileType
    const download =
      fileType === "txt" ? (
        <td />
      ) : (
        <td>
          <a
            rel="noopener noreferrer"
            target="_blank"
            className="link--download"
            href={`${href}&fileType=${fileType}&filename=${filename}&atta_id=${attaId}&qmnum=${qmnum}&source=${source}`}
          >
            <span className="ficon ficon__download" />
          </a>
        </td>
      );

    rows.push(
      <tr key={i}>
        <td>{matDoc["cr_name"]}</td>
        <td>{matDoc["cr_date"]}</td>
        <td>{matDoc["cr_time"]}</td>
        <td>{matDoc["descr"]}</td>
        <td>{fileType}</td>
        <td>{matDoc["lang"]}</td>
        {download}
      </tr>,
    );
  });

  return <tbody>{rows}</tbody>;
};

export default ComplaintsBody;
