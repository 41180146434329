import { MAIN_VIEW as MV } from "../constants/MainView";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";
import ElstrCache from "elstr-jslib/src/scripts/ElstrCache";
import ElstrUrlHashActions from "elstr-jslib/src/scripts/actions/ElstrUrlHashActions";
import ElstrConfigStore from "elstr-jslib/src/scripts/stores/ElstrConfigStore";
import RouteHistoryStore from "../stores/RouteHistoryStore";
import SelectionStore from "../stores/SelectionStore";
import SelectionActions from "../actions/SelectionActions";

// ADDERS
export function addObjectToHashObject(obj) {
  let hash = Object.keys(obj);
  let val = obj[hash];
  let hashObject = ElstrUrlHashStore.get();
  hashObject[hash] = val;

  return hashObject;
}

export function addFilterToHashObject(dataAttributeKey, stringRange) {
  let hashObject = ElstrUrlHashStore.get();

  let hash = `filter_${dataAttributeKey}`;
  hashObject[hash] = stringRange;

  return hashObject;
}

export function addSelectionIdAndSourceToURL() {
  // adds the selectionId in the Url when missing
  let hashObj = ElstrUrlHashStore.get();
  let selectionIdURL = hashObj["selectionId"];
  let mySelectionId = ElstrCache.get("mySelectionId");

  let objToAdd = {};
  // IN CASE IT'S NOT IN THE URL
  if (selectionIdURL === undefined || !selectionIdURL.includes("-")) {
    objToAdd.selectionId = mySelectionId;
  }

  // IN CASE IT'S NOT IN THE URL
  let selectionSource = hashObj["selectionSource"];
  if (selectionSource === undefined || !(selectionSource === "pisys" || selectionSource === "catalogue")) {
    objToAdd.selectionSource = getSelectionSource();
  }

  if (Object.keys(objToAdd).length !== 0) {
    setHashes(objToAdd);
  }
}

// SWITCH APP
export function switchApp() {
  let isPisysSite = ElstrConfigStore.option("isPisysSite");

  let urlOrigin;
  // get first the origin
  if (isPisysSite) {
    urlOrigin = ElstrConfigStore.option("ecatAddress");
  } else {
    urlOrigin = ElstrConfigStore.option("pisysAddress");
  }

  let pathname = ElstrUrlHashStore.getPathname();

  // add the slash and appName at the end
  let urlOriginAndPathname = `${urlOrigin}${pathname}`;

  // get the hashes in the url to add at the end
  let hashes = getAllHashesExcept(["stacked", "switched", "selectionId", "selectionSource", "moveBackToResults", "ph"]);

  // * if a user switches from pisys to eCat while editing an eCat Selection
  // the editing mode should remain when switched
  // * if a user switches from pisys to eCat while editing a pisys Selection
  // the editing mode shouldn't remain when switched
  // * if a user switches from eCat to pisys while editing a eCat Selection
  // the editing mode should remain when switched
  // -----
  // therefore the first thing to check is if we're editing a selection and
  // it's source is from catalogue
  if (SelectionStore.checkIfEditingSelectionShouldBeKept()) {
    // Selection Rule 2)
    hashes += `&selectionId=${SelectionStore.getEditingSelectionId()}`;
    hashes += `&selectionSource=catalogue`;
    hashes += `&keepEditingAfterSwitch=true`;
  } else if (SelectionStore.toKeepSelectionIdAndSelectionSource()) {
    // Selection Rule 1)
    hashes += `&selectionId=${ElstrUrlHashStore.get()["selectionId"]}`;
    hashes += `&selectionSource=catalogue`;
  }

  window.location.href = `${urlOriginAndPathname}#ph=1&switched=true${hashes}`;
}

// CLEANERS
export function clearAllFiltersFromHashObject(hashObject) {
  let removedFilters = [];
  // Remove everything that starts with filter_ from the hash url
  for (let hashKey in hashObject) {
    if (hashKey.startsWith("filter_")) {
      removedFilters.push(hashKey);
      delete hashObject[hashKey];
    }
  }
  window._paq.push(["trackEvent", "Filter", "all removed"]);
  return hashObject;
}

export function clearAllFilters() {
  let hashObject = ElstrUrlHashStore.get();
  hashObject = clearAllFiltersFromHashObject(hashObject);

  ElstrUrlHashActions.replaceHashWithBasename(hashObject);
}

export function clearSingleFilter(key) {
  // expects an dataAttributeKey or similar string and will apply put filter_ at the start of the string
  let hashObject = ElstrUrlHashStore.get();

  let filter_name = key;
  key = `filter_${key}`;
  delete hashObject[key]; // delete url if there is no filter selected

  ElstrUrlHashActions.replaceHashWithBasename(hashObject);

  window._paq.push(["trackEvent", "Filter", filter_name, "[ removed ]"]);
}

export function clearHash(hash) {
  let hashObject = ElstrUrlHashStore.get();

  delete hashObject[hash]; // delete

  ElstrUrlHashActions.replaceHashWithBasename(hashObject);
}

export function clearHashes(hashes) {
  let hashObject = ElstrUrlHashStore.get();

  hashes.forEach(hash => {
    delete hashObject[hash]; // delete
  });

  ElstrUrlHashActions.replaceHashWithBasename(hashObject);
}

export function updateHashFromFilterUpdate(currentHash) {
  ElstrUrlHashActions.replaceHashWithBasename(currentHash);
}

// GETTERS
export function getHashArray(hash) {
  let filterValueStr = ElstrUrlHashStore.get(hash);
  let filterValueArr = [];
  if (filterValueStr) filterValueArr = filterValueStr.split("¦");

  return filterValueArr;
}

export function getHashRange(dataAttributeKey) {
  let hash = `filter_${dataAttributeKey}`;
  let filterValueStr = ElstrUrlHashStore.get(hash);
  let filterValueArr = {
    min: null,
    max: null,
  };

  if (filterValueStr) {
    let arrValues = filterValueStr.split("-");

    if (arrValues && arrValues.length > 1) {
      if (arrValues[0] !== null && arrValues[0] !== "") filterValueArr.min = parseFloat(arrValues[0]);
      if (arrValues[1] !== null && arrValues[1] !== "") filterValueArr.max = parseFloat(arrValues[1]);
    }
  }

  return filterValueArr;
}

export function getAllHashesExcept(hashKeysArray, removeFilters) {
  let hashObject = ElstrUrlHashStore.get();

  // remove all required entries
  hashKeysArray.forEach(hashKey => {
    delete hashObject[hashKey];
  });

  if (removeFilters) {
    hashObject = clearAllFiltersFromHashObject(hashObject);
  }

  let stringWithHashes = "";
  for (let key in hashObject) {
    stringWithHashes += `&${key}=${hashObject[key]}`;
  }

  return stringWithHashes;
}

function getTrimmedPathName() {
  let pathname = window.location.pathname;
  let pathnameLength = pathname.length;

  let endPos = pathname.search("catalogue");

  // ex // remove in detail view catalogue/detail/15171 from path (22 characters) (example with bottle number 15171)
  pathname = window.location.pathname.slice(0, -(pathnameLength - endPos));

  return pathname;
}

function getBasePath() {
  return `${window.location.origin}${getTrimmedPathName()}services/`;
}

export function getUrlCreateDossierPDF() {
  let selectionId = ElstrUrlHashStore.get()["selectionId"];
  let selectionSource = getSelectionSourceForDossierServerRequest();
  let eCatId = ElstrCache.get("eCatId") || "";

  return `${getBasePath()}VETROPACK_WidgetServer_Stream_Dossier?method=createDossier&selectionId=${selectionId}&selectionSource=${selectionSource}${
    eCatId !== "" ? `&eCatId=${eCatId}` : ""
  }`;
}

function getMode() {
  return ElstrConfigStore.simpleOptionOrDefault("getFileMode", "download");
}

export function getHrefMaterialDocument() {
  return `${getBasePath()}VETROPACK_WidgetServer_Stream_Detail?method=getFile&fileType=pdf&mode=${getMode()}`;
}

export function getHrefDBDocument() {
  return `${getBasePath()}VETROPACK_WidgetServer_Stream_Detail?method=getFileFromDB&mode=${getMode()}`;
}

export function getBaseURI() {
  // Workaround for IE 11
  // Based on: https://github.com/hypothesis/h/commit/b3a18e11af606c895b41f45315b07b46dc21ea84
  let baseURI = window.document.baseURI;
  if (baseURI === undefined) {
    let baseTags = document.getElementsByTagName("base");
    if (baseTags.length) {
      baseURI = baseTags[0].href;
    } else {
      baseURI = document.URL;
    }
  }
  return baseURI;
}

export function getBasename() {
  let baseURI = getBaseURI();

  let base = baseURI.split("/");
  // remove first 3 elements
  // ["http:", "", "example.com"]
  base.splice(0, 3);
  // remove top element which can be an empty string
  // or the name of the app
  base.pop();

  // create the string for the base
  // in case the base contains more prefixes, it needs to be joined
  // for example /elstrcustomername/public/
  // for an adress with only the "/" it doesn't
  if (base.length > 0) {
    base = `/${base.join("/")}/`;
  } else {
    base = "/";
  }

  return base;
}

export function getLinkPathForDetail(sMatHdrMatnr) {
  let hashesAdditional = getAllHashesExcept([
    "mainView",
    "ph",
    "stacked",
    "switched",
    "moveBackToResults",
    "mainGroup",
    "catalogue",
  ]);

  // for handling UrlHelpers.navigateBackOrToResult
  hashesAdditional += `&mainGroup=${RouteHistoryStore.getMaingroup()}`;
  hashesAdditional += `&catalogue=${RouteHistoryStore.getCatalogue()}`;
  hashesAdditional += returnStackedIfNeeded();

  let path = `/catalogue/${MV.DETAIL}/${sMatHdrMatnr}#ph=1${hashesAdditional}`;

  if (LIBS.appName === "intro") {
    return `${path}&moveBackToResults=true`;
  }

  return path;
}

export function getLinkPathForDossier() {
  let hashesAdditional = getAllHashesExcept([
    "mainView",
    "ph",
    "stacked",
    "switched",
    "moveBackToResults",
    "mainGroup",
    "catalogue",
  ]);

  // for handling UrlHelpers.navigateBackOrToResult
  hashesAdditional += `&mainGroup=${RouteHistoryStore.getMaingroup()}`;
  hashesAdditional += `&catalogue=${RouteHistoryStore.getCatalogue()}`;
  hashesAdditional += returnStackedIfNeeded();

  let path = `/catalogue/${MV.DOSSIER}#ph=1${hashesAdditional}`;

  return path;
}

export function getLinkPathForSelection() {
  let path = "";
  let hashesAdditional = getAllHashesExcept([
    "mainView",
    "ph",
    "selectionId",
    "stacked",
    "selectionSource",
    "switched",
    "moveBackToResults",
    "mainGroup",
    "catalogue",
  ]);

  // for handling UrlHelpers.navigateBackOrToResult
  hashesAdditional += `&mainGroup=${RouteHistoryStore.getMaingroup()}`;
  hashesAdditional += `&catalogue=${RouteHistoryStore.getCatalogue()}`;
  hashesAdditional += returnStackedIfNeeded();

  let editingSelectionId = SelectionStore.getEditingSelectionId();
  // behavior when not editing
  if (editingSelectionId === "") {
    let selectionId = ElstrCache.get("mySelectionId");
    let selectionSource = getSelectionSource();
    path = `/catalogue/${MV.SELECTION}#ph=1${hashesAdditional}&selectionId=${selectionId}&selectionSource=${selectionSource}`;
  } else {
    let selectionSource = SelectionStore.getEditingSelectionSource();
    path = `/catalogue/${MV.SELECTION}#ph=1${hashesAdditional}&selectionId=${editingSelectionId}&selectionSource=${selectionSource}`;
  }
  if (LIBS.appName === "intro") {
    return `${path}&moveBackToResults=true`;
  }

  return path;
}

export function getLinkPathForPrintLabel() {
  let hashesAdditional = getAllHashesExcept([
    "mainView",
    "ph",
    "stacked",
    "switched",
    "moveBackToResults",
    "mainGroup",
    "catalogue",
    "sMatHdrMatnr",
  ]);

  // for handling UrlHelpers.navigateBackOrToResult
  hashesAdditional += `&mainGroup=${RouteHistoryStore.getMaingroup()}`;
  hashesAdditional += `&catalogue=${RouteHistoryStore.getCatalogue()}`;
  hashesAdditional += returnStackedIfNeeded();

  if (isViewDetail()) {
    hashesAdditional += `&sMatHdrMatnr=${ElstrUrlHashStore.getRouteParam("sMatHdrMatnr")}`;
  }

  let path = `/catalogue/${MV.PRINT_LABEL}#ph=1${hashesAdditional}`;

  return path;
}

/**
 *
 * @return {string}
 */
export function getLinkPathForResult() {
  const hashesAdditional = getAllHashesExcept(["mainView", "ph", "stacked", "switched", "moveBackToResults"]);

  const mainGroup = RouteHistoryStore.getMaingroup();
  const catalogue = RouteHistoryStore.getCatalogue();

  return `/catalogue/${MV.RESULT}/${catalogue}/${mainGroup}#ph=1${hashesAdditional}`;
}

export function getLinkPathForSettings() {
  const hashesAdditional = getAllHashesExcept(["mainView", "ph", "stacked", "switched", "moveBackToResults"]);

  return `/catalogue/${MV.SETTINGS}#ph=1${hashesAdditional}`;
}

export function getStacked() {
  return ElstrUrlHashStore.get().stacked || false;
}

export function getMainView() {
  let pathname = document.location.pathname;
  let mainView = MV.RESULT; // default
  if (pathname.includes(MV.SELECTION)) mainView = MV.SELECTION;
  if (pathname.includes(MV.DETAIL)) mainView = MV.DETAIL;
  if (pathname.includes(MV.ERROR)) mainView = MV.ERROR;
  if (pathname.includes(MV.SETTINGS)) mainView = MV.SETTINGS;
  if (pathname.includes(MV.DOSSIER)) mainView = MV.DOSSIER;
  if (pathname.includes(MV.PRINT_LABEL)) mainView = MV.PRINT_LABEL;

  return mainView;
}

export function getPathToStartOfCatalogue() {
  return `/catalogue/${MV.RESULT}/global/all#ph=1`;
}

/**
 * Only relevant in case the param is not in the url
 * @returns {string}
 */
export function getSelectionSource() {
  let selectionSource = "pisys";
  if (!ElstrConfigStore.option("isPisysSite")) selectionSource = "catalogue";
  return selectionSource;
}

/**
 * Normally the DB to select is the local one
 * However, when editing a selection from catalogue in pisys the request must pass the other source
 * so the backend can handle it right
 * @returns {string}
 */
export function getSelectionSourceForServerRequest() {
  let selectionSource = "local";
  let editingSelectionSource = SelectionStore.getEditingSelectionSource();

  if (ElstrConfigStore.option("isPisysSite") && editingSelectionSource === "catalogue" && SelectionStore.isEditing())
    selectionSource = "catalogue";

  return selectionSource;
}

/**
 * For Request involving the dossier
 * @returns {string}
 */
export function getSelectionSourceForDossierServerRequest() {
  let selectionSource = ElstrUrlHashStore.get()["selectionSource"] || "local";

  if (selectionSource === "pisys") {
    // we normalize to 'local' to be consistent with other implementations
    selectionSource = "local";
  }

  return selectionSource;
}

/**
 * In certain cases, example after logging in or when Initializing the page, the Selection
 * From the SelectionId in the URL should be loaded.
 */
export function requestSelectionMaterialsWhenSelectionIdInUrl() {
  if (ElstrUrlHashStore.get()["selectionId"]) {
    let payload = {
      selectionId: ElstrUrlHashStore.get()["selectionId"],
      selectionSource: ElstrUrlHashStore.get()["selectionSource"] || getSelectionSourceForServerRequest(),
    };
    SelectionActions.getMaterials({ payload });
  }
}

// SETTERS
/**
 * will only set the provided object as hash parameters ignoring what's already present
 * @param hashObject
 */
export function setHashOverwrite(hashObject) {
  let updateHistory = true;
  let throwEvent = true;

  ElstrUrlHashActions.setWithOptions(hashObject, updateHistory, throwEvent);
}

export function setHashes(objects) {
  let hashObject = ElstrUrlHashStore.get();

  for (let key in objects) {
    hashObject[key] = objects[key];
  }

  let updateHistory = true;
  let throwEvent = true;

  ElstrUrlHashActions.setWithOptions(hashObject, updateHistory, throwEvent);
}

export function setHashNoHistory(obj) {
  let hashObject = addObjectToHashObject(obj);

  ElstrUrlHashActions.replaceHashWithBasename(hashObject);
}

export function setHashesNoHistory(objects) {
  let hashObject = ElstrUrlHashStore.get();

  for (let key in objects) {
    hashObject[key] = objects[key];
  }

  ElstrUrlHashActions.replaceHashWithBasename(hashObject);
}

export function setFilterArray(key, val, filterValueArr) {
  // Delete entry if already there
  let isNewVal = true;
  filterValueArr.every((filter, i, arr) => {
    if (filter === val) {
      arr.splice(i, 1); // delete entry
      isNewVal = false;
      return false;
    }
    return true;
  });
  // If the entry is new, add to the array
  if (isNewVal) filterValueArr.push(val); // push new value

  // Update the URL
  let hashObject = ElstrUrlHashStore.get();
  setUpdateHashObject(hashObject, filterValueArr, key);
  ElstrUrlHashActions.replaceHashWithBasename(hashObject);
}

/**
 * Will set or unset the filters which are predefined to direct, which materials are posted in eCat
 * @param cmd (can be "set" or "unset")
 */
export function setEcatFilters(cmd) {
  let hashObject = ElstrUrlHashStore.get();

  // initialize array of the filter
  let sMatAtvEinsatzart = hashObject["filter_sMatAtvEinsatzart"]
    ? hashObject["filter_sMatAtvEinsatzart"].split("¦")
    : [];
  let sMatHdrMstae = hashObject["filter_sMatHdrMstae"] ? hashObject["filter_sMatHdrMstae"].split("¦") : [];
  let sMatAtvPublikationscode = hashObject["filter_sMatAtvPublikationscode"]
    ? hashObject["filter_sMatAtvPublikationscode"].split("¦")
    : [];

  if (cmd === "set") {
    setEcatFilteresCaseSet(sMatAtvEinsatzart, sMatHdrMstae, sMatAtvPublikationscode);
  }

  if (cmd === "unset") {
    setEcatFilteresCaseUnset(sMatAtvEinsatzart, sMatHdrMstae, sMatAtvPublikationscode);
  }

  // we return only unique values, because previously they might be duplicated
  sMatAtvEinsatzart = returnDistinctValuesFromArray(sMatAtvEinsatzart);
  sMatHdrMstae = returnDistinctValuesFromArray(sMatHdrMstae);
  sMatAtvPublikationscode = returnDistinctValuesFromArray(sMatAtvPublikationscode);

  // update hashObject
  setUpdateHashObject(hashObject, sMatAtvEinsatzart, "filter_sMatAtvEinsatzart");
  setUpdateHashObject(hashObject, sMatHdrMstae, "filter_sMatHdrMstae");
  setUpdateHashObject(hashObject, sMatAtvPublikationscode, "filter_sMatAtvPublikationscode");

  ElstrUrlHashActions.replaceHashWithBasename(hashObject);
}

export function setEcatFilteresCaseSet(sMatAtvEinsatzart, sMatHdrMstae, sMatAtvPublikationscode) {
  sMatAtvEinsatzart.push("V_S");
  sMatHdrMstae.push("S3");
  sMatHdrMstae.push("S4");
  sMatAtvPublikationscode.push("W");
  sMatAtvPublikationscode.push("P");
}

export function setEcatFilteresCaseUnset(sMatAtvEinsatzart, sMatHdrMstae, sMatAtvPublikationscode) {
  // remove from list when exists
  let index = sMatAtvEinsatzart.indexOf("V_S");
  if (index !== -1) sMatAtvEinsatzart.splice(index, 1);
  index = sMatHdrMstae.indexOf("S3");
  if (index !== -1) sMatHdrMstae.splice(index, 1);
  index = sMatHdrMstae.indexOf("S4");
  if (index !== -1) sMatHdrMstae.splice(index, 1);
  index = sMatAtvPublikationscode.indexOf("W");
  if (index !== -1) sMatAtvPublikationscode.splice(index, 1);
  index = sMatAtvPublikationscode.indexOf("P");
  if (index !== -1) sMatAtvPublikationscode.splice(index, 1);
}

export function setFilterValue(hash, val) {
  let hashVal = ElstrUrlHashStore.get(hash);
  // Only do something when the hashVal is different than the provided val
  if (hashVal !== val) {
    let hashObject = ElstrUrlHashStore.get();
    hashObject[hash] = val;

    ElstrUrlHashActions.replaceHashWithBasename(hashObject);

    window._paq.push(["trackEvent", "Filter", hash, " [ " + val + " ]"]);
  }
}

/**
 * Will combine the filterArr and join it to the hashObj
 * or remove the entry from the hashObj in case it's empty
 * @param hashObject Object
 * @param filterArr array
 * @param key string
 * @return {*}
 */
export function setUpdateHashObject(hashObject, filterArr, key) {
  if (filterArr.length <= 0) {
    delete hashObject[key]; // delete url if there is no filter selected
  } else {
    hashObject[key] = filterArr.join("¦");
  }

  return hashObject;
}

export function setFilterRange(dataAttributeKey, stringRange) {
  if (stringRange !== "-") {
    let hashObject = addFilterToHashObject(dataAttributeKey, stringRange);

    ElstrUrlHashActions.replaceHashWithBasename(hashObject);

    window._paq.push(["trackEvent", "Filter", dataAttributeKey, " [ " + stringRange + " ]"]);
  } else {
    clearSingleFilter(dataAttributeKey);
  }
}

export function incResultMultip() {
  let hashObject = ElstrUrlHashStore.get();

  let resultMultip = hashObject["resultMultip"];

  if (resultMultip === undefined) {
    // initial set when there is none
    hashObject["resultMultip"] = 2;
  } else {
    hashObject["resultMultip"] = parseInt(resultMultip) + 1;
  }

  ElstrUrlHashActions.replaceHashWithBasename(hashObject);
}

// RETURNS
export function returnStackedIfNeeded() {
  // add the stacked in the link. this will ensure that additional pages will recieve the stacking style effects
  // see DetailPageStack or SelectionPageStack

  if (getMainView() !== MV.RESULT) {
    return "&stacked=true";
  } else {
    return "";
  }
}

export function returnDistinctValuesFromArray(arr) {
  return Array.from(new Set([...arr]));
}

export function isViewDossier() {
  return getMainView() === MV.DOSSIER;
}

export function isViewResult() {
  return getMainView() === MV.RESULT;
}

export function isViewMySelection() {
  return getMainView() === MV.SELECTION;
}

export function isViewDetail() {
  return getMainView() === MV.DETAIL;
}

export function isViewPrintLabel() {
  return getMainView() === MV.PRINT_LABEL;
}

// CONTROLLERS
export function controlIfRenderedMaterialNumber(sMatHdrMatnr) {
  let pathname = document.location.pathname;
  return pathname.includes(sMatHdrMatnr);
}

// NAVIGATORS
export function navigateBackOrToResult() {
  // switched will be in the hash when switch button has been clicked
  let switched = ElstrUrlHashStore.get("switched");
  // moveBackToResults will be activated when coming from intro or enter editing mode
  let moveBackToResults = ElstrUrlHashStore.get()["moveBackToResults"];

  // mainGroup and catalogue should be present in the hash when not in mainView "Result", so after an app switch, the app will keep
  // this information
  let mainGroup = ElstrUrlHashStore.get()["mainGroup"] || "all";
  let catalogue = ElstrUrlHashStore.get()["catalogue"] || "global";

  let length = window.history.length;
  let referrer = document.referrer;
  // (length === 1) when opening via a link or you choose to open a link in a new broser tab
  // (length === 2 && referrer === '') when opening a new tab and copy pasting the url in the browser

  // Navigate the user to the base page of the catalogue in the special cases. otherwise move back in history **/
  if (switched !== undefined || moveBackToResults !== undefined || length === 1 || (length === 2 && referrer === "")) {
    // prepare hash
    let hashes = getAllHashesExcept([
      "stacked",
      "switched",
      "selectionId",
      "selectionSource",
      "moveBackToResults",
      "mainGroup",
      "catalogue",
      "ph",
    ]);

    // navigate the user to the results page
    let historyObj = RouteHistoryStore.getHistoryObj();
    historyObj.push(`/${LIBS.appName}/result/${catalogue}/${mainGroup}#ph=1${hashes}`, {});
  } else {
    // default behavior -> history one back
    window.history.go(-1);
  }
}
