import mcFly from "elstr-jslib/src/scripts/libs/mcFly.js";
import ElstrLoadingStates from "elstr-jslib/src/scripts/constants/ElstrLoadingStates";
import DetailsConstants from "../constants/actions/DetailsConstants";

const DetailsStore = mcFly.createStore(
  {
    error: null,
    loadingStateAddDocs: ElstrLoadingStates.EMPTY,
    loadingStateFlexibleTable: ElstrLoadingStates.EMPTY,
    loadingStateFlexibleDataLoadMore: ElstrLoadingStates.EMPTY,

    documents: null,
    stock: null,
    salesOrder: null,
    salesOrderLoadMore: null,
    customer: null,
    production: null,
    productionOrderLoadMore: null,
    blowMoulds: null,
    complaints: null,
    prodWF: null,

    /** getters **/
    getError: function () {
      return this.error;
    },

    getLoadingStateAddDocs: function () {
      return this.loadingStateAddDocs;
    },

    getLoadingStateFlexibleTable: function () {
      return this.loadingStateFlexibleTable;
    },

    getLoadingStateFlexibleDataLoadMore: function () {
      return this.loadingStateFlexibleDataLoadMore;
    },

    getDocuments: function () {
      return DetailsStore.documents || [];
    },

    getStock: function () {
      return DetailsStore.stock || { data: [] };
    },

    getSalesOrder: function () {
      return DetailsStore.salesOrder || { data: [] };
    },

    getSalesOrderLoadMore: function () {
      return DetailsStore.salesOrderLoadMore || { data: [] };
    },

    getCustomer: function () {
      return DetailsStore.customer || { data: [] };
    },

    getProductionOrder: function () {
      return DetailsStore.production || { data: [] };
    },

    getProductionOrderLoadMore: function () {
      return DetailsStore.productionOrderLoadMore || { data: [] };
    },

    getBlowMoulds: function () {
      return DetailsStore.blowMoulds || {};
    },

    getComplaints: function () {
      return DetailsStore.complaints || { data: [] };
    },

    getProdWF: function () {
      return DetailsStore.prodWF || { data: [] };
    },

    requestData: function (dataHolder) {
      // we want to distinguish between documents and the rest of the requests
      if (dataHolder === "documents") {
        DetailsStore.loadingStateAddDocs = ElstrLoadingStates.EMPTY;
      } else {
        DetailsStore.loadingStateFlexibleTable = ElstrLoadingStates.EMPTY;
        DetailsStore.loadingStateFlexibleDataLoadMore = ElstrLoadingStates.EMPTY;
      }

      DetailsStore.emitChange();
    },

    getData: function (dataHolder, payload) {
      // we want to distinguish between documents and the rest of the requests
      if (dataHolder === "documents") {
        DetailsStore.loadingStateAddDocs = ElstrLoadingStates.LOADED;
      } else {
        if (payload.params.loadMore) {
          DetailsStore.loadingStateFlexibleDataLoadMore = ElstrLoadingStates.LOADED;
        } else {
          DetailsStore.loadingStateFlexibleTable = ElstrLoadingStates.LOADED;
        }
      }

      let error = payload.error;
      let messages = payload.messages;
      if (error && error !== "") {
        DetailsStore.error = error;
      } else if (messages && messages !== "" && messages.length > 0) {
        DetailsStore.error = messages;
      } else if (payload.data && payload.data.results) {
        DetailsStore[dataHolder] = payload.data.results;

        DetailsStore.error = null;
      } else {
        error = "NO DATA";
        DetailsStore.error = error;
      }
      DetailsStore.emitChange();
    },
  },

  function (payload) {
    switch (payload.actionType) {
      case DetailsConstants.GET_MATERIAL_DOCUMENTS_WILL_GET: {
        DetailsStore.documents = [];
        DetailsStore.requestData("documents");
        break;
      }
      case DetailsConstants.GET_MATERIAL_DOCUMENTS_DID_GET: {
        DetailsStore.getData("documents", payload);
        break;
      }

      case DetailsConstants.GET_MATERIAL_SALES_ORDER_WILL_GET: {
        DetailsStore.requestData("salesOrder");
        break;
      }
      case DetailsConstants.GET_MATERIAL_SALES_ORDER_DID_GET: {
        if (payload.params.loadMore) {
          DetailsStore.getData("salesOrderLoadMore", payload);
        } else {
          DetailsStore.getData("salesOrder", payload);
        }
        break;
      }

      case DetailsConstants.GET_MATERIAL_PRODUCTION_WILL_GET: {
        DetailsStore.requestData("production");
        break;
      }
      case DetailsConstants.GET_MATERIAL_PRODUCTION_DID_GET: {
        if (payload.params.loadMore) {
          DetailsStore.getData("productionOrderLoadMore", payload);
        } else {
          DetailsStore.getData("production", payload);
        }
        break;
      }

      case DetailsConstants.GET_MATERIAL_CUSTOMER_WILL_GET: {
        DetailsStore.requestData("customer");
        break;
      }
      case DetailsConstants.GET_MATERIAL_CUSTOMER_DID_GET: {
        DetailsStore.getData("customer", payload);
        break;
      }

      case DetailsConstants.GET_MATERIAL_STOCK_WILL_GET: {
        DetailsStore.requestData("stock");
        break;
      }
      case DetailsConstants.GET_MATERIAL_STOCK_DID_GET: {
        DetailsStore.getData("stock", payload);
        break;
      }

      case DetailsConstants.GET_MATERIAL_BLOW_MOULDS_WILL_GET: {
        DetailsStore.requestData("blowMoulds");
        break;
      }
      case DetailsConstants.GET_MATERIAL_BLOW_MOULDS_DID_GET: {
        DetailsStore.getData("blowMoulds", payload);
        break;
      }

      case DetailsConstants.GET_MATERIAL_COMPLAINTS_WILL_GET: {
        DetailsStore.requestData("complaints");
        break;
      }
      case DetailsConstants.GET_MATERIAL_COMPLAINTS_DID_GET: {
        DetailsStore.getData("complaints", payload);
        break;
      }

      case DetailsConstants.GET_MATERIAL_PROD_WF_WILL_GET: {
        DetailsStore.requestData("prodWF");
        break;
      }
      case DetailsConstants.GET_MATERIAL_PROD_WF_DID_GET: {
        DetailsStore.getData("prodWF", payload);
        break;
      }
    }

    return true;
  },
);

export default DetailsStore;
