import React from "react";
import { DossierContainerTop } from "./DossierContainerTop";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import createReactClass from "create-react-class";
import DossierCarrierBottom from "./DossierCarrierBottom";
import DossierStore from "../../stores/DossierStore";

const DossierInputMeta = createReactClass({
  mixins: [DossierStore.mixin],

  storeDidChange: function () {
    this.forceUpdate();
  },

  handleOnChange: function (e) {
    e.preventDefault();
    DossierStore.setTitle(e.target.value);
  },

  handleOnSubmit: function (e) {
    e.preventDefault();

    // used to hide the keyboard for mobile devices
    document.activeElement.blur();
  },

  render: function () {
    return (
      <div className="container-common-carrier">
        <DossierContainerTop step={1} />
        <div className="container-common container-common__middle" key="search-box" onSubmit={this.handleOnSubmit}>
          <div className="container-common-content">
            <div className="container-common-content-placer">
              <div className="container-common__middle__item">
                <h2>{ElstrLangStore.text("DOSSIER_INPUT_TITLE")}</h2>
                {this.getContent()}
              </div>
            </div>
          </div>
        </div>
        <DossierCarrierBottom step={1} />
      </div>
    );
  },

  getContent: function () {
    if (DossierStore.getActiveStep() === 1) {
      return (
        <form action="post">
          <div className="filter-block__search-box search-box__field-wrap">
            <input
              type="text"
              id="dossier title"
              className="search-box__field search-box__field__dossier"
              onChange={this.handleOnChange}
              autoComplete="on"
              value={DossierStore.getTitle()}
            />
          </div>
        </form>
      );
    }

    return (
      <span className="input-text-readonly-container">
        <span className="input-text-readonly">{DossierStore.getTitle()}</span>
      </span>
    );
  },
});

export default DossierInputMeta;
