import React from "react";
import createReactClass from "create-react-class";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import AppsStore from "../../stores/AppsStore";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";

const HeaderColRightSettingsDesktop = createReactClass({
  mixins: [ElstrLangStore.mixin, ElstrUrlHashStore.mixin],

  storeDidChange: function () {
    this.forceUpdate();
  },

  render: function () {
    // Set classNames
    // desktop
    let classNameUnlisted = "primary-nav__list";
    let classNameItem = "primary-nav__item";
    let classNameSpan = "";
    let classNameSelected = "primary-nav__item--active";

    // other views
    if (AppsStore.getLayoutView() !== "desktop") {
      classNameUnlisted = "menu-overlay-primary-nav__list";
      classNameItem = "menu-overlay-primary-nav__item";
      classNameSpan = "ficon ficon__arrow-primary-nav menu-overlay-primary-nav__icon";
      classNameSelected = "menu-overlay-primary-nav__item--active";
    }

    // NOTICE: in case os of settings in the future we might track this action
    // window._paq.push(["trackEvent", "Settings selected", "select", mySettings]);

    return (
      <ul className={classNameUnlisted}>
        <li key="subscription" className={`${classNameItem} ${classNameSelected}`}>
          <span className={classNameSpan} />
          {ElstrLangStore.text("SETTINGS_SUBSCRIPTION")}
        </li>
      </ul>
    );
  },
});

export default HeaderColRightSettingsDesktop;
