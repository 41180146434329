import { MAIN_VIEW as MV } from "../../constants/MainView";
import * as Attributes from "../../libs/Attributes";
import DossierStore from "../../stores/DossierStore";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";
import ItemCompareDossier from "./ItemCompareDossier";
import React from "react";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import ItemCompare from "./ItemCompare";
import ItemCompareMore from "./ItemCompareMore";
import ItemCompareLabel from "./ItemCompareLabel";
import ItemCompareScroll from "./ItemCompareScroll";
import AttributesStore from "../../stores/AttributesStore";
import * as UrlHelpers from "../../libs/UrlHelpers";

const ContainerCompare = createReactClass({
  propTypes: {
    materialsToRender: PropTypes.array.isRequired,
    displayMore: PropTypes.bool,
    scrollActive: PropTypes.bool.isRequired,
  },

  mixins: [AttributesStore.mixin],

  storeDidChange: function () {
    this.forceUpdate();
  },

  componentDidMount: function () {
    let mainView = UrlHelpers.getMainView();

    if (mainView === MV.RESULT) {
      let currentLanguage = ElstrLangStore.getCurrentLanguage();
      window._paq.push(["trackPageView", `Dossier Site ( lang:${currentLanguage} + " )`]);
    }

    if (mainView === MV.SELECTION) {
      let selectionId = ElstrUrlHashStore.get("selectionId");
      window._paq.push(["trackPageView", "mySelection Compare ( " + selectionId + " )"]);
    }
  },

  getClassName: () => {
    let className = `item-compare-wrap`;

    if (UrlHelpers.isViewDossier()) {
      className += ` ${className}__common`;
    }

    if (UrlHelpers.isViewPrintLabel()) {
      className += ` ${className}__common`;
    }

    return className;
  },

  render: function () {
    // className for frame
    let classNameCompareFrame = "item-compare-frame";
    const mainView = UrlHelpers.getMainView();

    if (mainView === MV.DETAIL) {
      classNameCompareFrame += " detail-related-content__item-compare-frame";
    }
    if (mainView === MV.SELECTION) {
      classNameCompareFrame += " my-selection-body-content__item-compare-frame";
    }
    if (mainView === MV.PRINT_LABEL) {
      classNameCompareFrame += " print-label-body-content__item-compare-frame";
    }
    if (mainView === MV.DOSSIER) {
      classNameCompareFrame += " dossier-body-content__item-compare-frame";
    }

    let attributesSorted = Attributes.getAttributesSortedAndByMainView();

    let counterForUpdate = AttributesStore.getCounterForUpdate();

    const compareItems = this.props.materialsToRender.map(material => {
      return (
        <ItemCompare
          key={material.sMatHdrMatnr}
          material={material}
          attributesSorted={attributesSorted}
          counterForUpdate={counterForUpdate}
        />
      );
    });

    let compareItemsDoc = null;
    if (UrlHelpers.isViewDossier()) {
      let attributesSorted = Attributes.getAttributesPackagingDossierSorted();

      compareItemsDoc = this.props.materialsToRender.map(material => {
        let docs = DossierStore.getDocsFromNumber(material.sMatHdrMatnr);
        return (
          <ItemCompareDossier
            key={material.sMatHdrMatnr}
            docs={docs}
            material={material}
            counterForUpdate={counterForUpdate}
            attributesSorted={attributesSorted}
            packPageChecked={DossierStore.getSpecificPackPageChecked(material.sMatHdrMatnr)}
          />
        );
      });
    }

    return (
      <span className={`${this.getClassName()}`}>
        <ItemCompareLabel attributesSorted={attributesSorted} />
        <div className={classNameCompareFrame}>
          <div className="item-compare-frame__content">
            {compareItems}
            <ItemCompareMore
              displayMore={this.props.displayMore || false}
              numberOfResults={this.props.materialsToRender.length}
            />
            <ItemCompareScroll scrollActive={this.props.scrollActive} />
          </div>
          <div className="item-compare-frame__content">{compareItemsDoc}</div>
        </div>
      </span>
    );
  },
});

export default ContainerCompare;
