import React from "react";
import createReactClass from "create-react-class";
import ElstrUserStore from "elstr-jslib/src/scripts/stores/ElstrUserStore";
import ElstrUserActions from "elstr-jslib/src/scripts/actions/ElstrUserActions";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";

const ElstrUserLoginDialog = createReactClass({
  username: React.createRef(),
  password: React.createRef(),
  mixins: [ElstrLangStore.mixin],

  onChange: function () {
    this.storeDidChange();
  },

  storeDidChange: function () {
    this.state.forceAuthentication = ElstrUserStore.forceAuthentication();
    this.setState(this.state);
  },

  getInitialState: function () {
    return {
      forceAuthentication: ElstrUserStore.forceAuthentication(),
      isAuth: this.props.isAuth,
      loading: this.props.loading,
      message: this.props.message,
    };
  },

  handleSubmit: function (e) {
    e.preventDefault();
    let username = this.username.current.value.trim();
    let password = this.password.current.value;

    // (responseAction, enterpriseApplicationData, isAdmin, isAuth, resourcesAllowed, memberOf, username, message)
    if (username === "") {
      ElstrUserActions.didLogin("fail", null, null, null, null, null, null, "USER_USERNAME_REQ");
    } else if (password === "") {
      ElstrUserActions.didLogin("fail", null, null, null, null, null, null, "USER_PASSWORD_REQ");
    } else {
      ElstrUserActions.login(username, password, "");
    }
  },

  componentDidMount: function () {
    this.username.current.focus();
  },

  render: function () {
    let message;
    if (this.props.message) {
      if (this.props.message.text && this.props.message.style) {
        // Non structured errors.
        message = <span>{ElstrLangStore.text(this.props.message.text)}</span>;
      }
    }

    let submitButton;
    if (this.props.loading === false) {
      submitButton = (
        <button type="submit" className="form--simple__button">
          {ElstrLangStore.text("USER_LOGIN")}
        </button>
      );
    } else {
      submitButton = (
        <button disabled className="form--simple__button">
          {ElstrLangStore.text("USER_CHECK_CREDENTIALS")}
        </button>
      );
    }

    return (
      <div className="elstrUserLoginDialog">
        <div className="static-modal">
          <form className="form--simple" onSubmit={this.handleSubmit}>
            {/*<span>{ElstrLangStore.text("USER_LOGIN")}</span>*/}
            <div className="form-group form--simple__group">
              <label className="form--simple__label" htmlFor="elstrUserLoginDialogInputUsername">
                {ElstrLangStore.text("USER_USERNAME")}
              </label>
              <input
                ref={this.username}
                type="text"
                className="form-control form--simple__input-text"
                id="elstrUserLoginDialogInputUsername"
                placeholder={ElstrLangStore.text("USER_USERNAME")}
              />
            </div>
            <div className="form-group form--simple__group">
              <label className="form--simple__label" htmlFor="elstrUserLoginDialogInputPassword">
                {ElstrLangStore.text("USER_PASSWORD")}
              </label>
              <input
                ref={this.password}
                type="password"
                className="form-control form--simple__input-text"
                id="elstrUserLoginDialogInputPassword"
                placeholder={ElstrLangStore.text("USER_PASSWORD")}
              />
            </div>
            {message}
            <br />
            <br />
            {submitButton}
          </form>
        </div>
      </div>
    );
  },
});

export default ElstrUserLoginDialog;
