import * as TT from "../../libs/Tooltip";
import { Link } from "react-router-dom";
import {
  addSelectionIdAndSourceToURL,
  getLinkPathForDossier,
  getLinkPathForPrintLabel,
  getSelectionSourceForServerRequest,
  navigateBackOrToResult,
} from "../../libs/UrlHelpers";
import React from "react";
import ContainerHeaderAdd from "../header/ContainerHeaderAdd";
import HeaderItem, { HEADER_ITEM_TYPE } from "../header/HeaderItem";
import createReactClass from "create-react-class";
import ContainerCompare from "../article/ContainerCompare";
import ContainerGrid from "../article/ContainerGrid";
import ContainerList from "../article/ContainerList";
import SelectionPageClose from "../selection/SelectionPageClose";
import SelectionPageStack from "../selection/SelectionPageStack";
import BackToResult from "../router/BackToResult";
import HeaderNavBar from "../header/HeaderNavBar";
import SelectionStore from "../../stores/SelectionStore";
import SelectionActions from "../../actions/SelectionActions";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import ElstrConfigStore from "elstr-jslib/src/scripts/stores/ElstrConfigStore";
import ElstrUrlHashActions from "elstr-jslib/src/scripts/actions/ElstrUrlHashActions";
import SwitchApp from "../header/SwitchApp";
import RouteHistoryStore from "../../stores/RouteHistoryStore";
import ElstrId from "elstr-jslib/src/scripts/ElstrId";
import { setDocTitle } from "../../libs/Meta";
import { TYPES } from "../../constants/Tooltip";

const Selection = createReactClass({
  mixins: [SelectionStore.mixin, ElstrUrlHashStore.mixin],

  statics: {
    getDerivedStateFromProps(newProps) {
      ElstrUrlHashActions.setRouteParams(newProps.location.pathname, newProps.match.params);
      return null;
    },
  },

  getInitialState: function () {
    return {
      openEmailLinkAfterAction: false,
      sharePersistentSelectionId: "",
      deletedMaterials: "",
    };
  },

  storeDidChange: function () {
    // The purpose of this part is to remove the tooltip when a bottle gets removed
    if (SelectionStore.isSelectionUrlMySelection()) {
      let selectionView = ElstrUrlHashStore.get("selectionView") || "grid";

      // to find out if center or left
      let tooltipAlign;
      switch (selectionView) {
        case "grid":
          tooltipAlign = TYPES.center;
          break;
        case "list":
          tooltipAlign = TYPES.left;
          break;
        case "compare":
          tooltipAlign = TYPES.center;
          break;
      }
      TT.removeTooltip(tooltipAlign);
    }

    this.forceUpdate();
  },

  componentDidMount: function () {
    addSelectionIdAndSourceToURL();

    window.scrollTo(0, 0);
  },

  sharePersistentSelection: function () {
    // Before sharing a selection, the selected bottles will be saved in a new selectionId
    // So there remains a persistence of this selection

    // gets rendered selected items as a list to prepare for the RPC
    let materialNumbers = SelectionStore.getRenderedMaterialNumbersAsList();

    // get a new random selection id
    this.state.sharePersistentSelectionId = ElstrId.create();
    this.state.openEmailLinkAfterAction = true; // set to open in the next rerender

    let selectionSource = getSelectionSourceForServerRequest();

    let payload = {
      selectionId: this.state.sharePersistentSelectionId,
      materialNumbers: materialNumbers,
      selectionSource,
    };

    // save all current bottles to the new id
    SelectionActions.addMaterials({ payload });

    let selectionId = this.state.sharePersistentSelectionId;
    //trackEvent(category, action, [name], [value])
    window._paq.push(["trackEvent", "conversion", "share", "email: selectionId = " + selectionId]);
  },

  openEmailLinkAfterAction: function (selectionView, titleText) {
    this.state.openEmailLinkAfterAction = false; // reset
    let persistentSelectionId = this.state.sharePersistentSelectionId;
    let selectionSource = ElstrUrlHashStore.get("selectionSource");

    // open the href with slightly delayed
    setTimeout(function () {
      // for code formatting with % http://stackoverflow.com/questions/5620324/mailto-with-html-body
      let partialStream = `%23selectionView%3D${selectionView}%26selectionId%3D${persistentSelectionId}%26selectionSource%3D${selectionSource}`;
      let bodyEmail = `${window.location.origin}${window.location.pathname}${partialStream}`;

      window.location.href = `mailto:email@address.com?subject=${titleText}&body=${bodyEmail}`;
    }, 1000);
  },

  handleOnClickPrint: function () {
    window.print();

    let selectionId = ElstrUrlHashStore.get("selectionId");
    window._paq.push(["trackEvent", "conversion", "print", "print: selectionId = " + selectionId]);
  },

  handleDelete: function () {
    let deletedMaterials = this.state.deletedMaterials;
    let selectionSource = getSelectionSourceForServerRequest();
    let selectionId = ElstrUrlHashStore.get()["selectionId"];

    if (deletedMaterials === "") {
      // REMOVE
      let materialsToDelete = SelectionStore.getRenderedMaterialNumbersAsList();
      this.state.deletedMaterials = materialsToDelete;

      let payload = {
        selectionId,
        materialNumbers: materialsToDelete,
        selectionSource,
      };

      SelectionActions.removeMaterials({ payload });
    } else {
      // UNDO THE REMOVE
      let payload = {
        selectionId,
        materialNumbers: deletedMaterials,
        selectionSource,
      };

      this.state.deletedMaterials = "";

      SelectionActions.addMaterials({ payload });
    }
  },

  render: function () {
    let selectionIdURL = ElstrUrlHashStore.get().selectionId;

    // set title and the selected materials (mySelection or foreignSelection when received share)
    let titleText = "";
    let materialsToRender = SelectionStore.getSortedRenderedMaterials();

    if (SelectionStore.isSelectionUrlMySelection() || selectionIdURL === undefined) {
      titleText = ElstrLangStore.text("SELECTION_MY_SELECTION");
    } else if (!SelectionStore.isSelectionUrlMySelection()) {
      titleText = ElstrLangStore.text("FOREIGN_MY_SELECTION");
    }

    // Hide the body when there are no selectedMaterials and change the title
    let visibility = "visible";
    if (materialsToRender.length <= 0) {
      visibility = "hidden";
      titleText = ElstrLangStore.text("SELECTION_IS_EMPTY");
    }

    // Set title for selection
    let isPisysSite = ElstrConfigStore.option("isPisysSite");
    let preTitle = ElstrLangStore.text("TITLE__EKATALOG");
    if (isPisysSite) preTitle = ElstrLangStore.text("TITLE__PISYS");
    setDocTitle(`${preTitle} ${titleText}`);

    let selectionView = ElstrUrlHashStore.get("selectionView") || "compare";

    let content;
    let classNameBodyContent = "my-selection-body__content";

    // Set classNames and content according to selectionView
    switch (selectionView) {
      case "grid":
        content = <ContainerGrid materialsToRender={materialsToRender} scrollActive={true} />;

        break;

      case "list":
        content = <ContainerList materialsToRender={materialsToRender} scrollActive={true} />;
        classNameBodyContent += " my-selection-body__content--list-view";

        break;

      case "compare":
        content = <ContainerCompare materialsToRender={materialsToRender} scrollActive={true} />;
        classNameBodyContent += " my-selection-body__content--item-compare";

        break;
    }

    // HANDLE THE EDIT COMPONENT
    let editComponent = null;
    // EDIT COMPONENT ONLY AVAILABLE IF IT'S NOT THE USERS OWN SELECTION
    if (!SelectionStore.isSelectionUrlMySelection()) {
      let editText = ElstrLangStore.text("SELECTION_EDIT_START");
      let classNameIcon = "ficon__edit";

      let editingStyle = null;
      let closeSign = null;

      // change style when in edit mode
      if (SelectionStore.isEditing()) {
        editText = ElstrLangStore.text("SELECTION_EDIT_FINISH");
        editingStyle = "editing-selected-ficon";
        closeSign = "ficon ficon__close";
      }

      editComponent = (
        <li className="my-selection-menu__item" onClick={SelectionStore.toggleEditMode}>
          <span className={editingStyle}>
            <span className={`ficon ${classNameIcon}`} />
            <span className="my-selection-menu-item__title">
              <span className="hide-text-phone">{editText}</span>
              <span className={closeSign} />
            </span>
          </span>
        </li>
      );
    }

    // HANDLE THE DELETE COMPONENT
    // ONLY WHEN EDITING OR IT'S THE USERS OWN SELECTION
    let deleteComponent = null;
    if (SelectionStore.isEditing() || SelectionStore.isSelectionUrlMySelection()) {
      // THE TEXT
      let deleteText = ElstrLangStore.text("SELECTION_DELETE_START");
      let classNameIcon = "ficon__delete";
      if (this.state.deletedMaterials !== "") {
        deleteText = ElstrLangStore.text("SELECTION_DELETE_UNDO");
        classNameIcon = "ficon__undo-delete";
      }

      deleteComponent = (
        <li className="my-selection-menu__item" onClick={this.handleDelete}>
          <span className={`ficon ${classNameIcon}`} />
          <span className="my-selection-menu-item__title">
            <span className="hide-text-phone">{deleteText}</span>
          </span>
        </li>
      );
    }

    // HANDLE THE DOSSIER COMPONENT
    // ONLY WHEN AT LEAST ONE ITEM IN SELECTION
    let dossierComponent = null;
    let printLabelComponent = null;
    let shareComponent = null;
    let printComponent = null;
    if (SelectionStore.getMaterialsToRender().length > 0) {
      dossierComponent = (
        <Link to={getLinkPathForDossier()}>
          <HeaderItem headerItemType={HEADER_ITEM_TYPE.ADD_DOSSIER} />
        </Link>
      );

      printLabelComponent = isPisysSite ? (
        <Link to={getLinkPathForPrintLabel()}>
          <HeaderItem headerItemType={HEADER_ITEM_TYPE.PRINT_LABEL} />
        </Link>
      ) : null;

      shareComponent = (
        <li className="my-selection-menu__item" onClick={this.sharePersistentSelection}>
          <span className="ficon ficon__external-link my-selection-menu__external-link" />
          <span className="my-selection-menu-item__title">
            <span className="hide-text-phone">{ElstrLangStore.text("SELECTION_SHARE")}</span>
          </span>
        </li>
      );

      printComponent = (
        <li className="my-selection-menu__item my-selection-menu__print">
          <span className="ficon ficon__printer my-selection-menu__printer" onClick={this.handleOnClickPrint} />
          <span className="my-selection-menu-item__title" onClick={this.handleOnClickPrint}>
            <span className="hide-text-phone">{ElstrLangStore.text("SELECTION_PRINT")}</span>
          </span>
        </li>
      );
    }

    // This Part will open a new mailto link to open email application with the the persistent share id
    if (this.state.openEmailLinkAfterAction) {
      this.openEmailLinkAfterAction(selectionView, titleText);
    }

    // show source of the selection when in pisys
    let sourceSelectionText = "";
    if (ElstrConfigStore.option("isPisysSite")) {
      sourceSelectionText =
        ElstrUrlHashStore.get()["selectionSource"] === "catalogue"
          ? ElstrLangStore.text("SOURCE_CATALOGUE")
          : ElstrLangStore.text("SOURCE_PISYS");
    }

    return (
      <React.Fragment>
        <BackToResult />
        <div className="my-selection-page my-selection-page--has-stack">
          <SelectionPageStack />
          <div className="my-selection-page__container">
            <div className="my-selection-page__header phone--hidden">
              <div className="my-selection-page-header__container">
                <span className="ficon ficon__my-selection my-selection-page-header__icon" />
                <h1 className="my-selection-page-header__title">{titleText}</h1>
                <h3 className="my-selection-page-header__title">{sourceSelectionText}</h3>
                <SwitchApp renderedPlace={"selection"} />
                <SelectionPageClose />
              </div>
            </div>
            <div className="my-selection-page__nav-bar tablet-desktop--hidden">
              <Link to={RouteHistoryStore.getBackToResultPath()} className="my-selection-nav-bar__left">
                <span className="ficon ficon__arrow-back" />
              </Link>
              <span className="my-selection-nav-bar__title">{titleText}</span>
              <span className="my-selection-nav-bar__right" onClick={navigateBackOrToResult}>
                <span className="ficon ficon__close-thin my-selection-page-header__close-icon" />
              </span>
            </div>
            <div className="my-selection-page__body">
              <div className="my-selection-page-body__container">
                <div className="my-selection-body__header">
                  <nav className="my-selection-body-header__col--left">
                    <ul className="my-selection-menu">
                      {dossierComponent}
                      {printLabelComponent}
                      {shareComponent}
                      {printComponent}
                      {editComponent}
                      {deleteComponent}
                    </ul>
                  </nav>
                  <nav className="my-selection-body-header__col--right" style={{ visibility: visibility }}>
                    <ContainerHeaderAdd />
                    <HeaderNavBar />
                  </nav>
                </div>

                <div className={classNameBodyContent} style={{ visibility: visibility }}>
                  {content}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="subpage-modal-overlay" />
      </React.Fragment>
    );
  },
});

export default Selection;
