import React from "react";

const DeliveryBody = ({ tableSource }) => {
  let rows = [];

  tableSource.forEach((row, i) => {
    rows.push(
      <tr key={i}>
        <td>{row["exidv"]}</td>
        <td>{row["erdat"]}</td>
        <td>{row["eruhr"]}</td>
        <td>{row["palno"]}</td>
        <td>{row["proddat"]}</td>
        <td>{row["linename"]}</td>
        <td>{row["aufnr"]}</td>
        <td>{row["charg_d"]}</td>
      </tr>,
    );
  });

  return <tbody>{rows}</tbody>;
};

export default DeliveryBody;
