import ContainerTooltips from "../components/common/tooltip/ContainerTooltips";
import { Route } from "react-router-dom";
import { MAIN_VIEW as MV } from "../constants/MainView";
import React from "react";
import createReactClass from "create-react-class";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";
import AppsStore from "../stores/AppsStore";
import StaticCacheVersionsStore from "../stores/StaticCacheVersionsStore";
import EnvInfoDisplay from "../components/common/EnvInfoDisplay.jsx";
import OverlayMenu from "../components/overlay/OverlayMenu";
import OverlayInfobox from "../components/overlay/OverlayInfobox";
import EmptyPage from "../components/view/EmptyPage";
import VetroUserLogin from "../components/user/VetroUserLogin";
import * as UrlHelpers from "../libs/UrlHelpers";
import ElstrUserStore from "elstr-jslib/src/scripts/stores/ElstrUserStore";
import Selection from "../components/view/Selection";
import Dossier from "../components/view/Dossier";
import PrintLabel from "../components/view/PrintLabel";
import Detail from "../components/view/Detail";
import Result from "../components/view/Result";
import Settings from "../components/view/Settings";
import ContainerFilters from "../components/filter/ContainerFilters";
import ClassChangerOnScrollApp from "../components/common/ClassChangerOnScrollApp";
import RouteHistoryStore from "../stores/RouteHistoryStore";
import ErrorBoundary from "../components/error/ErrorBoundary";

function _setStateApp(props) {
  let overlaySettingsOpen = AppsStore.getOverlaySettingsOpen();
  let overlayMenuOpen = AppsStore.getOverlayMenuOpen();
  let overlayFiltersOpen = AppsStore.getOverlayFiltersOpen();
  let overlayInfoboxOpen = AppsStore.getOverlayInfoboxOpen();
  let overlayDetailOpen = AppsStore.getOverlayDetailContactOpen();
  let isAuth = ElstrUserStore.isAuth();
  let layoutView = AppsStore.getLayoutView();
  let successCounter = StaticCacheVersionsStore.getSuccessCounter();
  let pathname = props.location.pathname;

  return {
    overlaySettingsOpen,
    overlayMenuOpen,
    overlayFiltersOpen,
    overlayInfoboxOpen,
    overlayDetailOpen,
    isAuth,
    layoutView,
    successCounter,
    pathname,
  };
}

const App = createReactClass({
  mixins: [AppsStore.mixin, ElstrUrlHashStore.mixin, ElstrUserStore.mixin, StaticCacheVersionsStore.mixin],

  getInitialState: function () {
    return _setStateApp(this.props);
  },

  statics: {
    getDerivedStateFromProps(newProps) {
      return _setStateApp(newProps);
    },
  },

  storeDidChange: function () {
    this.setState(_setStateApp(this.props));
  },

  componentDidMount: function () {
    // Give the history Obj from react-router to a global store
    RouteHistoryStore.setHistoryObj(this.props.history);
    // Set ph = "place holder" to abort default browser behavior when empty # is provided, it jumps up to the top of the page
    UrlHelpers.setHashNoHistory({ ph: 1 });
  },

  shouldComponentUpdate: function (nextProps, nextState) {
    return (
      this.state.isAuth !== nextState.isAuth ||
      this.state.overlaySettingsOpen !== nextState.overlaySettingsOpen ||
      this.state.overlayMenuOpen !== nextState.overlayMenuOpen ||
      this.state.overlayFiltersOpen !== nextState.overlayFiltersOpen ||
      this.state.overlayInfoboxOpen !== nextState.overlayInfoboxOpen ||
      this.state.layoutView !== nextState.layoutView ||
      this.state.successCounter !== nextState.successCounter ||
      this.state.pathname !== nextProps.location.pathname
    );
  },

  render: function () {
    if (AppsStore.shouldLoginBeRendered()) {
      return <VetroUserLogin />;
    }

    return (
      <ErrorBoundary>
        <ClassChangerOnScrollApp>
          <EnvInfoDisplay key="EnvInfoDisplay" />
          <Route path={`/catalogue/${MV.RESULT}/:catalogue/:mainGroup`} component={Result} />
          <Route path={`/catalogue/${MV.DETAIL}/:sMatHdrMatnr`} component={Detail} />
          <Route path={`/catalogue/${MV.SELECTION}`} component={Selection} />
          <Route path={`/catalogue/${MV.DOSSIER}`} component={Dossier} />
          <Route path={`/catalogue/${MV.PRINT_LABEL}`} component={PrintLabel} />
          <Route path={`/catalogue/${MV.SETTINGS}`} component={Settings} />
          <Route path={`/catalogue/${MV.ERROR}`} component={EmptyPage} />
          {/* OverlayMenu is displayed only in the mainView result on the presented clickdummy */}
          {/* In the real application it is used more often */}
          <OverlayMenu key="OverlayMenu" />
          <div className="modal-overlay" onClick={AppsStore.setCloseAllMenus} />
          <div className="filter-open-icon desktop--hidden" onClick={AppsStore.toggleOverlayFiltersOpen} />
          {/* Tooltip center -> middle arrow / Tooltip list -> left align arrow */}
          <ContainerTooltips />
          {/* If phone / mobile layoutView, the container is applied here */}
          <ContainerFilters key="ContainerFilters Desktop" renderNotFor={["desktop"]} />
          <OverlayInfobox />
          {/*<ErrorPage/>*/}
        </ClassChangerOnScrollApp>
      </ErrorBoundary>
    );
  },
});

export default App;
