import React from "react";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import MaterialsStore from "../../stores/MaterialsStore";
import Translations from "../../libs/Translations";
import Controllers from "../../libs/Controllers";
import * as Compress from "../../libs/Compress";
import { getBasename } from "../../libs/UrlHelpers";

function getQRCodeLink(material) {
  // Printing Labels that are not available on eCat will have a default URL
  // Others will link to the detail page of the according material
  const isMaterialNotAvailableOnECat = Controllers.isMaterialNotAvailableOnECat(material);

  if (isMaterialNotAvailableOnECat) {
    // an empty string will lead that no qr code is rendered
    return "";
  } else {
    return `https://katalog.vetropack.com/catalogue/detail/${material.sMatHdrMatnr}/#ph=1`;
  }
}

function getReqData(elementsAsArray) {
  const reqData = [];

  elementsAsArray.forEach(element => {
    const { id, value } = element;

    const sMatHdrMatnr = id;
    // default to 1 when falsy
    const nrOfLabels = Number.parseInt(value) || 1;

    const material = MaterialsStore.getMaterialByMatnr(sMatHdrMatnr);

    reqData.push({
      sMatHdrMatnr,
      nrOfLabels,
      title: Translations.getItemTitle(material),
      height: `${material.sMatAtvHoehe}`,
      diameter: `${material.sMatAtvDurchmesser}`,
      fillingLine: `${material.sMatAtvFillingLine}`,
      colour: Translations.getValue("sMatAtvFarbe", material.sMatAtvFarbe),
      QRLink: getQRCodeLink(material),
    });
  });

  return reqData;
}

export default function Bottom() {
  function onClick() {
    // https://stackoverflow.com/questions/222841/most-efficient-way-to-convert-an-htmlcollection-to-an-array#222847
    const htmlCollectionLabels = window.document.getElementsByClassName("input-number-labels");
    const elementsAsArray = Array.prototype.slice.call(htmlCollectionLabels);

    const reqData = getReqData(elementsAsArray);
    const compressed = Compress.fromJSON(reqData);

    const basename = getBasename();

    window.open(
      `${basename}services/VETROPACK_WidgetServer_Stream_Print?method=getPrintLabels&reqData=${compressed}`,
      "_blank",
    );
  }

  return (
    <div className="container-common-carrier">
      <div className="container-common container-common__bottom">
        <div className="container-common-content">
          <div className="container-common-content-placer">
            <span className="container-common-content-placer-left" />
            <span className="container-common-content-placer-right">
              <button className="btn-common-blue" onClick={onClick}>
                {ElstrLangStore.text("PRINT_LABEL_BUTTON_CREATE_LABELS")}
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
