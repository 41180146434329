import ElstrFlux from "elstr-jslib/src/scripts/libs/ElstrFlux.js";
import FilterConstants from "../constants/actions/FilterConstants";

const className = "VETROPACK_WidgetServer_JSON_Filter";
const constantWill = FilterConstants.FILTER_GET_MATERIALS_FROM_SERVER_WILL;
const constantDid = FilterConstants.FILTER_GET_MATERIALS_FROM_SERVER_DID;

const FilterActions = ElstrFlux.createActions(
  [
    {
      className,
      methodName: "customer",
      constantWill,
      constantDid,
    },
    {
      className,
      methodName: "complaintsPriority",
      constantWill,
      constantDid,
    },
    {
      className,
      methodName: "complaintsDefectType",
      constantWill,
      constantDid,
    },
    {
      className,
      methodName: "complaintsDefectLocation",
      constantWill,
      constantDid,
    },
    {
      className,
      methodName: "production",
      constantWill,
      constantDid,
    },
    {
      className,
      methodName: "plannedOrders",
      constantWill,
      constantDid,
    },
  ],
  {},
);

export default FilterActions;
