import React from "react";
import BeaconPoints from "../../svg/BeaconPoints";

export default function StepIndicator() {
  return (
    <span className="points-wrapper">
      <div className="points-holder">
        <BeaconPoints />
      </div>
    </span>
  );
}
