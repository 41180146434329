import React from "react";
import PropTypes from "prop-types";

const PrintLabel = function (props) {
  return (
    <svg viewBox="0 0 512 512" {...props}>
      <path
        fill={props.color}
        d="M152.455 103.545L0 256l152.455 152.455H512v-304.91H152.455zm319.774 265.139H168.928L56.244 256l112.684-112.684h303.301v225.368z"
      />
      <path
        fill={props.color}
        d="M120.916 236.12h39.771v39.771h-39.771zM233.6 196.349h159.083v39.771H233.6zM233.6 275.891h159.083v39.771H233.6z"
      />
    </svg>
  );
};

export default PrintLabel;

PrintLabel.propTypes = {
  color: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};
