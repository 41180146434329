import React from "react";
import createReactClass from "create-react-class";
import ElstrUserStore from "elstr-jslib/src/scripts/stores/ElstrUserStore";
import ElstrLog from "elstr-jslib/src/scripts/ElstrLog";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import ElstrConfigStore from "elstr-jslib/src/scripts/stores/ElstrConfigStore";
import VetroUserLoginDialog from "./VetroUserLoginDialog";
import * as Sentry from "@sentry/browser";

const VetroUserHandler = createReactClass({
  mixins: [ElstrLangStore.mixin, ElstrUserStore.mixin],

  storeDidChange: function () {
    try {
      if (ElstrUserStore.isAuth() === true) {
        this.state.showLoginDialog = false;
      } else {
        if (ElstrUserStore.forceAuthentication() === true) {
          this.state.showLoginDialog = true;
        }
      }
      this.state.username = ElstrUserStore.getUsername();
      this.state.isAuth = ElstrUserStore.isAuth();
      this.state.isAdmin = ElstrUserStore.isAdmin();
      this.setState(this.state);
    } catch (err) {
      Sentry.captureException(new Error(`err on VetroUserHandler on storeDidChange with err ${err}`));
      throw err;
    }
  },

  getInitialState: function () {
    let showLoginDialog = false;
    if (ElstrUserStore.forceAuthentication() === true && ElstrUserStore.isAuth() === false) {
      showLoginDialog = true;
    }

    // login is only required for isPisysSite and will than be rendered
    let loginRequired = false;
    if (ElstrConfigStore.option("isPisysSite")) loginRequired = true;

    return {
      username: ElstrUserStore.getUsername(),
      isAuth: ElstrUserStore.isAuth(),
      isAdmin: ElstrUserStore.isAdmin(),
      showLoginDialog: showLoginDialog,
      loginRequired,
    };
  },

  showLoginDialog: function (e) {
    e.preventDefault();
    ElstrLog.trace("ElstrUserHandler.showLoginDialog");
    this.state.showLoginDialog = true;
    this.setState(this.state);
  },

  hideLoginDialog: function () {
    ElstrLog.trace("ElstrUserHandler.hideLoginDialog");

    if (ElstrUserStore.forceAuthentication() === false) {
      this.state.showLoginDialog = false;
    }
    this.setState(this.state);
  },

  render: function () {
    let loginDialog;
    let errorMessage = {};

    if (ElstrUserStore.getMessage()) {
      errorMessage.text = ElstrUserStore.getMessage();
      errorMessage.style = "danger";
    }

    if (this.state.showLoginDialog) {
      loginDialog = (
        <VetroUserLoginDialog
          hideLoginDialog={this.hideLoginDialog}
          isAuth={ElstrUserStore.isAuth()}
          loading={ElstrUserStore.isLoading()}
          message={errorMessage}
        />
      );
    }

    if (this.state.showLoginDialog && this.state.loginRequired) {
      return (
        <div className="emptypage-layer">
          <div className="emptypage-layer__content">
            <div className="emptypage-layer__title">{ElstrLangStore.text("USER_LOGIN")}</div>
            <div className="emptypage-layer__block">{loginDialog}</div>
          </div>
        </div>
      );
    } else {
      return <i />;
    }
  },
});

export default VetroUserHandler;
