import React, { useEffect, useState } from "react";
import { preventNonNumeric } from "../../libs/Numbers";

const ItemCompareRowPrintLabel = function ({ material, defaultNrOfLabels = 1 }) {
  const [nrOfLabels, setNrOfLabels] = useState(0);

  useEffect(
    function updateFromDefaultLabels() {
      setNrOfLabels(defaultNrOfLabels);
    },
    [defaultNrOfLabels],
  );

  const min = 1;

  function onChange(e) {
    const { value } = e.target;
    const parsedValue = Number.parseInt(value);

    if (isNaN(parsedValue)) {
      setNrOfLabels(0);
      return;
    }

    if (parsedValue < min) {
      setNrOfLabels(min);
      return;
    }

    setNrOfLabels(parsedValue);
  }

  let sMatHdrMatnr = "";
  if (material && material.sMatHdrMatnr) {
    sMatHdrMatnr = material.sMatHdrMatnr;
  }

  return (
    <div className="item-compare__row">
      <label htmlFor={sMatHdrMatnr} />
      <input
        className="input-number-labels"
        type="number"
        id={sMatHdrMatnr}
        name={sMatHdrMatnr}
        min={min}
        step={1}
        value={Number.parseInt(nrOfLabels) || ""}
        placeholder={1}
        onKeyPress={preventNonNumeric}
        onChange={onChange}
      />
    </div>
  );
};

export default ItemCompareRowPrintLabel;
