import React from "react";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import * as UrlHelpers from "../../libs/UrlHelpers";
import MaterialsStore from "../../stores/MaterialsStore";
import FilterStore from "../../stores/FilterStore";
import FilterListboxOptions from "./FilterListboxOptions";
import { ATTRIBUTE_SOURCE } from "../../libs/Filters";
import { FILTER, FILTER_TYPE } from "../../constants/Filter";

const FilterListboxTwoStep = createReactClass({
  mixins: [MaterialsStore.mixin],

  propTypes: {
    filter: PropTypes.object, // for every filter type
  },

  getInitialState: function () {
    return {
      filter: this.props.filter,
      hash: `filter_${this.props.filter.dataAttributeKey}`,
    };
  },

  storeDidChange: function () {
    this.forceUpdate();
  },

  selectChangeStepOne: function (e) {
    let value = e.target.value;
    if (value !== "-") {
      UrlHelpers.setFilterValue(this.state.hash, value);
    }
  },

  selectChangeStepTwo: function (filterValueArr, e) {
    let value = e.target.value;
    if (value !== "-") {
      UrlHelpers.setFilterArray(this.state.hash, value, filterValueArr);
    }
  },

  render: function () {
    let { dataAttributeKey, keyStepOne, keyStepTwo } = this.state.filter;
    let filterValueArr = UrlHelpers.getHashArray(this.state.hash);
    let hash = this.state.hash;

    // Content for already selected filters
    let selectedFilters = filterValueArr.map((key, i) => {
      // the first one removes the whole filter since all following are depended on the first one
      if (i === 0) {
        let text = FilterStore.getFilterText(key, keyStepOne);
        return (
          <div
            key={key}
            className="btn-filter-color"
            onClick={UrlHelpers.clearSingleFilter.bind(this, dataAttributeKey)}
          >
            {text}
            <span className="ficon ficon__close btn-filter__close" />
          </div>
        );
      }

      // all other selected filters after the first one
      let text = FilterStore.getFilterText(key, keyStepTwo);
      return (
        <div
          key={key}
          className="btn-filter-color"
          onClick={UrlHelpers.setFilterArray.bind(this, hash, key, filterValueArr)}
        >
          {text}
          <span className="ficon ficon__close btn-filter__close" />
        </div>
      );
    });

    // yourSelection will only be shown when there is data to show
    let yourSelection = null;
    if (selectedFilters.length > 0) {
      yourSelection = (
        <span className="filter-block__sub-content">
          <div className="filter-block__sub-title">{ElstrLangStore.text("FILTER_TEXT_YOURSELECTION")}</div>
          {selectedFilters}
        </span>
      );
    }

    let filterValueArrWithoutFirst = [...filterValueArr];
    filterValueArrWithoutFirst.shift();

    return (
      <div className="filter-block__selectbox">
        <select onChange={this.selectChangeStepOne} value="-">
          <FilterListboxOptions
            filterValueArr={[filterValueArr[0]]}
            dataAttributeKey={FILTER.PRODUCTION}
            attributeSource={ATTRIBUTE_SOURCE.TRANSLATIONS}
            keyStepOne={keyStepOne}
            keyStepTwo={keyStepTwo}
            step={1}
            filterType={FILTER_TYPE.LISTBOX_TWO_STEP}
          />
        </select>
        {filterValueArr[0] && (
          <select style={{ marginTop: "2em" }} onChange={this.selectChangeStepTwo.bind(this, filterValueArr)} value="-">
            <FilterListboxOptions
              filterValueArr={filterValueArrWithoutFirst}
              dataAttributeKey={FILTER.PRODUCTION}
              attributeSource={ATTRIBUTE_SOURCE.TRANSLATIONS}
              keyStepOne={keyStepOne}
              keyStepTwo={keyStepTwo}
              step={2}
              filterType={FILTER_TYPE.LISTBOX_TWO_STEP}
            />
          </select>
        )}
        {yourSelection}
      </div>
    );
  },
});

export default FilterListboxTwoStep;
