import * as TT from "../../libs/Tooltip";
import React, { useEffect, useState } from "react";
import { MAIN_VIEW as MV } from "../../constants/MainView";
import Controllers from "../../libs/Controllers";
import * as UrlHelpers from "../../libs/UrlHelpers";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import AppsStore from "../../stores/AppsStore";
import SelectionStore from "../../stores/SelectionStore";
import SelectionActions from "../../actions/SelectionActions";
import MaterialsStore from "../../stores/MaterialsStore";
import ElstrConfigStore from "elstr-jslib/src/scripts/stores/ElstrConfigStore";
import { TYPES } from "../../constants/Tooltip";

const addIcon = "ficon__notepad";
const removeIcon = "ficon__notepad-selected";
const editingClass = "editing";
const editingSelected = "editing-selected";

const maxNrToAdd = Number(ElstrConfigStore.simpleOptionOrDefault("maxResultsAddToSelection", 150));

function isDisabled() {
  // -1 means unlimited therefore never disabled
  if (maxNrToAdd === -1) {
    return false;
  }
  return MaterialsStore.getFilteredMaterialsResultNotSpliced().length > maxNrToAdd;
}

export default function HeaderAddAllResults() {
  let [addedNumbers, setAddedNumbers] = useState([]);
  let [added, setAdded] = useState(false);
  let [icon, setIcon] = useState(addIcon);
  let [editing, setEditing] = useState(editingClass);

  // to handle icon appearance (either remove or add)
  // also change color with state editing if editing a foreign selection
  useEffect(() => {
    if (added) {
      setIcon(removeIcon);
      setEditing(editingSelected);
    } else {
      setIcon(addIcon);
      setEditing(editingClass);
    }
  }, [added]);

  // to set "added" back to initial state -> false when certain conditions are met
  useEffect(() => {
    if (!added) {
      return;
    }

    // we want to reset that something has been added after the user has new results

    // we compare if different length equals that results have changed
    if (MaterialsStore.getFilteredMaterialResultNumbers().length !== addedNumbers.length) {
      setAdded(false);
      return;
    }

    // comparing if there are the same results, so in case they haven't the same inputs anymore
    if (!arraysHaveSameInput(MaterialsStore.getFilteredMaterialResultNumbers(), addedNumbers)) {
      setAdded(false);
    }
  }, [MaterialsStore.getFilteredMaterialResultNumbers().length]);

  function arraysHaveSameInput(arr1, arr2) {
    return arr1.sort().join(",") === arr2.sort().join(",");
  }

  function addToSelection() {
    let selectionId = SelectionStore.getAffectedSelectionId();
    let materialNumbers = MaterialsStore.getFilteredMaterialResultNumbers();
    let selectionNumbers = SelectionStore.getRenderedMaterialNumbersAsList();
    let selectionSource = UrlHelpers.getSelectionSourceForServerRequest();

    // we filter materials that are not allowed to be added (ex:when editing a eCat selection
    // and trying to add numbers that are not allowed in the eCat part)
    materialNumbers = materialNumbers.filter(sMatHdrMatnr => {
      let material = MaterialsStore.getMaterialByMatnr(sMatHdrMatnr);
      return !Controllers.isSelectionHandlingNotAllowed(material);
    });

    // we filter the items to only add the ones that are currently not in the selection
    let addedNumbers = materialNumbers.filter(sMatHdrMatnr => !selectionNumbers.includes(sMatHdrMatnr));

    let payload = {
      selectionId,
      materialNumbers: addedNumbers,
      selectionSource,
    };

    SelectionActions.addMaterials({ payload });

    setAddedNumbers(addedNumbers);
    setAdded(true);
  }

  function removeFromSelection() {
    let selectionId = SelectionStore.getAffectedSelectionId();
    let selectionSource = UrlHelpers.getSelectionSourceForServerRequest();

    let payload = {
      selectionId,
      materialNumbers: addedNumbers,
      selectionSource,
    };

    SelectionActions.removeMaterials({ payload });

    setAdded(false);
  }

  function handleClick() {
    if (isDisabled()) {
      return;
    }

    if (added) {
      removeFromSelection();
    } else {
      addToSelection();
    }
  }

  function getTooltip() {
    if (isDisabled()) {
      return `${ElstrLangStore.text("DISABLED_FOR_MORE_RESULTS_THAN")} ${maxNrToAdd}`;
    }

    let tooltip = ElstrLangStore.text("ADD_ALL_RESULTS_TO_MY_SELECTION");
    if (SelectionStore.getEditingSelectionId()) {
      tooltip = ElstrLangStore.text("ADD_ALL_RESULTS_TO_FOREIGN_SELECTION");
    }

    if (added) {
      tooltip = ElstrLangStore.text("REMOVE_ADDED_FROM_SELECTION_AGAIN");
    }

    return tooltip;
  }

  // not render for main view selection and phone layout
  if (UrlHelpers.getMainView() === MV.SELECTION || AppsStore.getLayoutView() === "phone") return <i />;

  return (
    <li className={`view-nav__item view-nav__item--add-item ${isDisabled() ? "disabled-item" : ""}`}>
      <span
        className={`ficon ${icon} view-nav-item__icon view-nav-item__icon__notepad ${
          isDisabled() ? "disabled-item" : ""
        } ${SelectionStore.isEditing() ? editing : ""}`}
        onClick={handleClick}
        onMouseOver={TT.showTooltip.bind(this, getTooltip(), TYPES.center, 15)}
        onMouseOut={TT.removeTooltip.bind(this, TYPES.center)}
        onMouseDown={TT.removeTooltip.bind(this, TYPES.center)}
      />
    </li>
  );
}
