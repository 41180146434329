import Translations from "../../libs/Translations";
import React from "react";
import * as Attributes from "../../libs/Attributes";
import { isViewDossier } from "../../libs/UrlHelpers";
import AttributesStore from "../../stores/AttributesStore";
import createReactClass from "create-react-class";
// example here: https://github.com/clauderic/react-sortable-hoc/blob/master/examples/drag-handle.js
import { arrayMove, SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import PropTypes from "prop-types";

const DragHandle = SortableHandle(
  createReactClass({
    render: function () {
      return <span className="ficon ficon__table-option item-setting-list__option" />;
    },
  }),
);

function toDisableCheckbox(dataAttributeKey) {
  // we disable the attribute "sMatHdrTdline" since it is redundant in the dossier functionality
  return dataAttributeKey === "sMatHdrTdline" && isViewDossier();
}

const SortableItem = SortableElement(
  createReactClass({
    render: function () {
      let classNameCheckbox = "item-setting-list__item";
      let dataAttributeKey = this.props.value;
      let label = Translations.getLabel(dataAttributeKey) || "";

      if (this.props.checked) classNameCheckbox += " item-setting-list__item--checked";
      return (
        <li className={classNameCheckbox}>
          <input
            className="item-setting-list__checkbox"
            type="checkbox"
            name={this.props.value}
            checked={this.props.checked}
            disabled={toDisableCheckbox(dataAttributeKey)}
            onChange={AttributesStore.toggleAttributes.bind(
              this,
              this.props.checked,
              this.props.position,
              this.props.type,
            )}
          />
          <label htmlFor={"setting--" + this.props.value}>{label}</label>
          <DragHandle />
        </li>
      );
    },
  }),
);

const SortableList = SortableContainer(function ({ items, checked, allowed, type }) {
  function getClassName(type) {
    let className = `item-list__setting-layer`;
    if (type === Attributes.TYPES.packagingDossier) {
      className = `item-list__setting-layer-packaging-dossier`;
    }

    if (type !== Attributes.TYPES.result) {
      className = `${className} ${className}__dossier`;
    }
    return className;
  }

  let sortableContent = [];
  items.forEach((value, index) => {
    if (allowed[index] === true) {
      sortableContent.push(
        <SortableItem
          key={`item-${index}`}
          index={index}
          position={index}
          value={value}
          checked={checked[index]}
          type={type}
        />,
      );
    }
  });

  return (
    <div className={getClassName(type)}>
      <ul className="item-setting__list">{sortableContent}</ul>
    </div>
  );
});

const ItemListOverlaySettings = createReactClass({
  mixins: [AttributesStore.mixin, ElstrLangStore.mixin],

  propTypes: {
    type: PropTypes.string.isRequired, // Attributes.TYPE
  },

  _setState: function () {
    let attributes = Attributes.getAttributesByType(this.props.type);

    let dataAttributeKeys = [];
    let listDisplayDefaults = [];
    let listDisplayAllowed = [];

    attributes.forEach(attribute => {
      dataAttributeKeys.push(attribute["dataAttributeKey"]);
      listDisplayDefaults.push(attribute["listDisplayDefault"]);
      listDisplayAllowed.push(attribute["listDisplayAllowed"]);
    });

    return {
      items: dataAttributeKeys,
      checked: listDisplayDefaults,
      allowed: listDisplayAllowed,
    };
  },

  getInitialState: function () {
    return this._setState();
  },

  storeDidChange: function () {
    this.setState(this._setState());
  },

  onSortEnd: function (that, indexes) {
    let { type } = this.props;

    let attributes = Attributes.getAttributesByType(type);

    let newAttributesOrder = arrayMove(attributes, indexes.oldIndex, indexes.newIndex);

    AttributesStore.setAttributesByType(newAttributesOrder, type);
  },

  render: function () {
    let that = this;

    return (
      <SortableList
        items={this.state.items}
        onSortEnd={this.onSortEnd.bind(this, that)}
        checked={this.state.checked}
        allowed={this.state.allowed}
        type={this.props.type}
      />
    );
  },
});

export default ItemListOverlaySettings;
