import { Link } from "react-router-dom";
import { setScrollToTop } from "../../libs/Utils";
import * as UrlHelpers from "../../libs/UrlHelpers";
import React, { useState } from "react";
import PropTypes from "prop-types";
import TouchStore from "../../stores/TouchStore";
import { TYPES } from "../../constants/Tooltip";

ItemColor.propTypes = {
  sMatHdrMatnr: PropTypes.string.isRequired,
  mainSMatHdrMatnr: PropTypes.string.isRequired, // as a reference where to the touch overlay
  showTooltip: PropTypes.func,
  removeTooltip: PropTypes.func,
  colorKey: PropTypes.string,
  fullClassStyle: PropTypes.string,
  noLink: PropTypes.bool,
  isOverlay: PropTypes.bool,
  tooltip: PropTypes.string,
};

export default function ItemColor({
  sMatHdrMatnr,
  mainSMatHdrMatnr,
  showTooltip,
  removeTooltip,
  colorKey,
  fullClassStyle,
  children,
  noLink,
  isOverlay,
  tooltip,
}) {
  const [path, setPath] = useState(UrlHelpers.getLinkPathForDetail(sMatHdrMatnr));

  function handleShowTooltip(e) {
    showTooltip(tooltip, TYPES.center, 12, e);
  }

  function handleRemoveTooltip(e) {
    removeTooltip(TYPES.center, e);
  }

  function linkForwardOrShowOverlay(e) {
    e.stopPropagation();

    if (e.type === "touchend" || e.type === "MSPointerDown" || e.type === "pointerdown" || e.type === "touchstart") {
      e.preventDefault();
      // set the number that will be stored and is allowed to show the overlay
      TouchStore.setShowColorsOverlayForMaterial(mainSMatHdrMatnr);
    } else {
      setScrollToTop();
    }
  }

  function handleTouchLink(e) {
    e.stopPropagation();
    setScrollToTop(150);
  }

  function setPathDetail() {
    const newPath = UrlHelpers.getLinkPathForDetail(sMatHdrMatnr);
    if (newPath !== path) setPath(newPath);
  }

  function handleOnMouseOver(e) {
    setPathDetail();
    handleShowTooltip(e);
  }

  if (isOverlay) {
    return (
      <Link
        key={`${colorKey} ${sMatHdrMatnr}`}
        to={path}
        onMouseOver={setPathDetail}
        onFocus={setPathDetail}
        onTouchStart={handleTouchLink}
        onTouchEnd={handleTouchLink}
        onClick={handleTouchLink}
        className={fullClassStyle}
      >
        {children}
      </Link>
    );
  } else if (noLink) {
    return (
      <div
        key={`${colorKey} ${sMatHdrMatnr}`}
        className={fullClassStyle}
        onFocus={setPathDetail}
        onClick={linkForwardOrShowOverlay}
        onTouchStart={linkForwardOrShowOverlay}
        onTouchEnd={linkForwardOrShowOverlay}
        onMouseOver={handleOnMouseOver}
        onMouseOut={handleRemoveTooltip}
        onMouseDown={handleRemoveTooltip}
      >
        {children}
      </div>
    );
  } else {
    return (
      <Link
        className={fullClassStyle}
        key={`${colorKey} ${sMatHdrMatnr}`}
        to={path}
        onFocus={setPathDetail}
        onClick={linkForwardOrShowOverlay}
        onTouchStart={linkForwardOrShowOverlay}
        onTouchEnd={linkForwardOrShowOverlay}
        onMouseOver={handleOnMouseOver}
        onMouseOut={handleRemoveTooltip}
        onMouseDown={handleRemoveTooltip}
      >
        {children}
      </Link>
    );
  }
}
