import React from "react";
import createReactClass from "create-react-class";
import HeaderWrap from "../header/HeaderWrap";
import SettingsNewZvgb from "../settings/SettingsNewZvgb";
import ElstrUrlHashActions from "elstr-jslib/src/scripts/actions/ElstrUrlHashActions";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import ElstrConfigStore from "elstr-jslib/src/scripts/stores/ElstrConfigStore";
import AppsStore from "../../stores/AppsStore";
import SettingsStore from "../../stores/SettingsStore";
import SettingsActions from "../../actions/SettingsActions";
import { setDocTitle } from "../../libs/Meta";

function _setState() {
  let currentLang = ElstrLangStore.getCurrentLanguage();

  return { currentLang };
}

const Settings = createReactClass({
  mixins: [ElstrLangStore.mixin, AppsStore.mixin, SettingsStore.mixin],

  getInitialState: function () {
    return _setState();
  },

  statics: {
    getDerivedStateFromProps(newProps) {
      ElstrUrlHashActions.setRouteParams(newProps.location.pathname, newProps.match.params);
      return null;
    },
  },

  storeDidChange: function () {
    this.setState(_setState());
  },

  componentDidMount: function () {
    SettingsActions.getSubscriptionNewZvgb();

    // set title
    let isPisysSite = ElstrConfigStore.option("isPisysSite");
    let title = ElstrLangStore.text("TITLE__EKATALOG");
    if (isPisysSite) title = ElstrLangStore.text("TITLE__PISYS");
    setDocTitle(`${title}`);
  },

  render: function () {
    return (
      <React.Fragment>
        <HeaderWrap />
        <div className="main">
          <h2 className="visually-hidden">Main</h2>
          <div className="main__body-settings">
            <aside className="main-body__aside">
              {" "}
              <div className="settings-sub settings-sub--phone-tablet">
                <div className="setting-sub-block--pisys">
                  <h2 className="setting-sub-block__title">
                    {ElstrLangStore.text("SETTINGS_SUBSCRIPTION_SUB_NEWZVGB")}
                  </h2>
                </div>
                {/*<div className="setting-sub-block--pisys">*/}
                {/*<h2 className="setting-sub-block__title">More future settings</h2>*/}
                {/*</div>*/}
              </div>
              {/* dont put aside in ContainerFilters, because in mobile view, ContainerFilters aren't aside */}
            </aside>

            <div className="main-body__center">
              <h3>{ElstrLangStore.text("SETTINGS_SUBSCRIPTION_SUB_NEWZVGB_DESCRIPTION")}</h3>
              <SettingsNewZvgb />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  },
});

export default Settings;
