import React from "react";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import Controllers from "../../libs/Controllers";

const ItemLabelTop = createReactClass({
  mixins: [ElstrLangStore.mixin],

  propTypes: {
    material: PropTypes.object.isRequired,
    detailTop: PropTypes.bool,
  },

  storeDidChange: function () {
    this.forceUpdate();
  },

  /**
   * render
   */
  render: function () {
    // defaults
    let material = this.props.material;
    let applyLabel = false;
    let textLabel;
    let classNameLabel = null;
    /**
     * check for criteria:
     * 1) confidential
     * 2) new Bottle
     */

    let classNameText;

    let isNewBottle;
    // 1) confidential
    if (Controllers.isConfidential(material)) {
      if (this.props.detailTop === true) {
        classNameLabel = "label-confidential--right";
        classNameText = "label-confidential--right__text";
      } else {
        classNameLabel = "label-confidential--top";
        classNameText = "label-confidential--top__text";
      }

      textLabel = ElstrLangStore.text("CONFIDENTIAL");
      applyLabel = true;
      // 2) new Bottle
      // } else if (material.sMatHdrMstae === newCriteria) {
      // Check for undefined first (old material data will not have isNew)
    } else if (typeof material.isNew !== "undefined") {
      // check for new
      if (material.isNew === 1) {
        // OLD IMPLEMENTATION BEFORE THE NEW CRITERIA HANDLING MOVED TO THE BACKEND PREPARATION OF THE MATERIALS
        // let dateAppRendering = AppsStore.getDateAppRendering();
        // let diffInDays = ((dateAppRendering - Date.parse(material.sMatHdrMstde)) / 86400000);

        // if (diffInDays < diffDaysCriteria) {
        if (this.props.detailTop === true) {
          classNameLabel = "label-new--left";
          classNameText = "label-new--left__text";
        } else {
          classNameLabel = "label-new--top";
          classNameText = "label-new--top__text";
          // }
        }

        textLabel = ElstrLangStore.text("NEW_BOTTLE");
        applyLabel = true;
        isNewBottle = true;
        //}
      }
    }

    // check if app is intro and the new criteria is matched
    // than the label won't be applied
    // confidential label should appliy for pisys intro
    let introNewBottle = false;
    if (LIBS.appName === "intro" && isNewBottle) introNewBottle = true;

    /**
     * only if applyLabel is true, the div gets returned
     * otherwhise an empty span gets returned
     * this is to prevent style errors, when returnig an empty div
     *
     * also no label on the top if the app is an introNewBottle since in the intro the label is at
     * the bottom of the grid and in the itemGrid component
     */

    if (applyLabel && !introNewBottle) {
      return (
        <div className={classNameLabel}>
          <span className={classNameText}>{textLabel}</span>
        </div>
      );
    } else {
      return <span />;
    }
  },
});

export default ItemLabelTop;
