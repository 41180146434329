import { MAIN_VIEW as MV } from "../constants/MainView";
import mcFly from "elstr-jslib/src/scripts/libs/mcFly.js";
import ElstrEditingStates from "elstr-jslib/src/scripts/constants/ElstrEditingStates";
import ElstrLoadingStates from "elstr-jslib/src/scripts/constants/ElstrLoadingStates";
import ElstrUrlHashConstants from "elstr-jslib/src/scripts/constants/ElstrUrlHashConstants";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";
import * as UrlHelpers from "../libs/UrlHelpers";

const RouteHistoryStore = mcFly.createStore(
  {
    data: null,
    error: null,
    loadingState: ElstrLoadingStates.EMPTY,
    editingState: ElstrEditingStates.NORMAL,
    historyObj: {}, // will contain the history obj that has been created by react-router
    backToResultPath: "/catalogue/result/global/all#ph=1", // default as fallback
    mainGroup: "all", // default as fallback
    catalogue: "global", // default as fallback

    /** get functions **/
    getData: function () {
      return this.data;
    },

    getError: function () {
      return this.error;
    },

    getLoadingState: function () {
      if (this.loadingState === ElstrLoadingStates.EMPTY) {
        let cachedData = this.getData();
        if (cachedData) {
          return ElstrLoadingStates.CACHED;
        }
      }
      return this.loadingState;
    },

    getEditingState: function () {
      return RouteHistoryStore.editingState;
    },

    getHistoryObj: () => {
      return RouteHistoryStore.historyObj;
    },

    getBackToResultPath: () => {
      return RouteHistoryStore.backToResultPath;
    },

    getMaingroup: () => {
      return RouteHistoryStore.mainGroup;
    },

    getCatalogue: () => {
      return RouteHistoryStore.catalogue;
    },

    setHistoryObj: historyObj => {
      RouteHistoryStore.historyObj = historyObj;
    },

    setMaingroup: mainGroup => {
      RouteHistoryStore.mainGroup = mainGroup;
    },

    setCatalogue: catalogue => {
      RouteHistoryStore.catalogue = catalogue;
    },

    setBackToResultPath: path => {
      RouteHistoryStore.backToResultPath = path;
    },

    shouldBackToResultURLBeSet: () => {
      let mainView = UrlHelpers.getMainView();
      if (mainView === MV.RESULT) {
        let lengthToCut = UrlHelpers.getBaseURI().length;
        let href = document.location.href;
        let path = href.substr(lengthToCut, href.length);
        // ex Path will be now: 'catalogue/result/global/WI#filter_sMatAtvFarbe=VG&filter_sMatAtvEcatSubgroup=WI_OTHER'

        RouteHistoryStore.setBackToResultPath(`/${path}`);
      }
    },
  },
  function (payload) {
    switch (payload.actionType) {
      case ElstrUrlHashConstants.URL_HASH_CHANGE: {
        mcFly.dispatcher.waitFor([ElstrUrlHashStore.dispatcherID]);
        RouteHistoryStore.shouldBackToResultURLBeSet();

        break;
      }

      case ElstrUrlHashConstants.URL_ROUTE_CHANGE: {
        mcFly.dispatcher.waitFor([ElstrUrlHashStore.dispatcherID]);
        RouteHistoryStore.shouldBackToResultURLBeSet();

        break;
      }
    }
    return true;
  },
);

export default RouteHistoryStore;
