let keyMirror = require("keymirror");

module.exports = keyMirror({
  SUBSCRIPTION_NEW_ZVGB_WILL_GET: null,
  SUBSCRIPTION_NEW_ZVGB_DID_GET: null,

  SUBSCRIPTION_NEW_ZVGB_WILL_ADD: null,
  SUBSCRIPTION_NEW_ZVGB_DID_ADD: null,

  SUBSCRIPTION_NEW_ZVGB_WILL_REMOVE: null,
  SUBSCRIPTION_NEW_ZVGB_DID_REMOVE: null,
});
