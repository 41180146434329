let keyMirror = require("keymirror");

module.exports = keyMirror({
  GET_PRODINFO_EXCEL_DOWNLOAD_LINK_WILL_GET: null,
  GET_PRODINFO_EXCEL_DOWNLOAD_LINK_DID_GET: null,

  GET_MATERIAL_DOCUMENTS_WILL_GET: null,
  GET_MATERIAL_DOCUMENTS_DID_GET: null,

  GET_MATERIAL_STOCK_WILL_GET: null,
  GET_MATERIAL_STOCK_DID_GET: null,

  GET_MATERIAL_SALES_ORDER_WILL_GET: null,
  GET_MATERIAL_SALES_ORDER_DID_GET: null,

  GET_MATERIAL_PRODUCTION_WILL_GET: null,
  GET_MATERIAL_PRODUCTION_DID_GET: null,

  GET_MATERIAL_CUSTOMER_WILL_GET: null,
  GET_MATERIAL_CUSTOMER_DID_GET: null,

  GET_MATERIAL_BLOW_MOULDS_WILL_GET: null,
  GET_MATERIAL_BLOW_MOULDS_DID_GET: null,

  GET_MATERIAL_COMPLAINTS_WILL_GET: null,
  GET_MATERIAL_COMPLAINTS_DID_GET: null,

  GET_MATERIAL_PROD_WF_WILL_GET: null,
  GET_MATERIAL_PROD_WF_DID_GET: null,
});
