import * as TT from "../../libs/Tooltip";
import ScrollToHOC from "../common/ScrollToHOC";
import Translations from "../../libs/Translations";
import React from "react";
import ContainerHeaderAdd from "./ContainerHeaderAdd";
import createReactClass from "create-react-class";
import SortOrderListbox from "../article/SortOrderListbox";
import HeaderStickySearchBox from "./HeaderStickySearchBox";
import HeaderNavBar from "./HeaderNavBar";
import HeaderStickyMaingroupMobile from "./HeaderStickyMaingroupMobile";
import * as UrlHelpers from "../../libs/UrlHelpers";
import AppsStore from "../../stores/AppsStore";
import ElstrScrollStore from "elstr-jslib/src/scripts/stores/ElstrScrollStore";
import MaterialsStore from "../../stores/MaterialsStore";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";
import FilterStore from "../../stores/FilterStore";
import Loader from "../loader/HeaderSticky";
import { isPisysSite } from "../../libs/Controllers";
import { getFilterType, getTextTwoStep, getValuesFrom } from "../../libs/Filters";
import { FILTER_TYPE } from "../../constants/Filter";
import { TYPES } from "../../constants/Tooltip";

const ClassChangerOnScroll = createReactClass({
  mixins: [AppsStore.mixin, ElstrScrollStore.mixin],

  storeDidChange: function () {
    this.forceUpdate();
  },

  render: function () {
    let resultView = ElstrUrlHashStore.get("resultView") || "grid";

    let offsetTop = "auto";
    if (AppsStore.getLayoutView() === "desktop") {
      let scrollTop = ElstrScrollStore.getCurrentScroll();
      offsetTop = 8;
      if (scrollTop < 212) offsetTop = 212 - scrollTop; // 212px is the value from the clickDummy
    }

    // Header className
    let classNameHeader = "main__header";
    if (resultView === "grid") classNameHeader += " main__header--grid-view";
    if (resultView === "list") classNameHeader += " main__header--list-view";
    if (resultView === "compare") classNameHeader += " main__header--compare-view";

    return (
      <header key={classNameHeader} className={classNameHeader} style={{ top: offsetTop }}>
        {this.props.children}
      </header>
    );
  },
});

const HeaderSticky = createReactClass({
  mixins: [MaterialsStore.mixin],

  storeDidChange: function () {
    this.forceUpdate();
  },

  getNumberOfActiveFilters: function (hashObject) {
    let numberOfActiveFilters = 0;
    let hashKeys = Object.keys(hashObject);

    hashKeys.forEach(hashStr => {
      if (hashStr.startsWith("filter_")) {
        let dataAttributeKey = hashStr.slice(7);
        if (!MaterialsStore.isFilterIsInactive(dataAttributeKey)) numberOfActiveFilters++;
      }
    });
    return numberOfActiveFilters;
  },

  render: function () {
    // count number of active filters
    let hashObject = ElstrUrlHashStore.get();
    let numberOfActiveFilters = this.getNumberOfActiveFilters(hashObject);

    // prepare the text be shown for the number of results in the header sticky (desktop and mobile)
    let numberOfResults = MaterialsStore.getNumberOfResults();
    let textResults = ElstrLangStore.text("HEADER_STICKY_RESULTS");
    let textResultsDesktop = `${numberOfResults} ${textResults}`;
    let textResultsMobile = textResultsDesktop;

    // more text is applied when there are filters applied
    if (numberOfActiveFilters > 0) {
      textResultsDesktop = `${textResultsDesktop}, ${ElstrLangStore.text("HEADER_STICKY_RESULTS_FILTERED_BY")}`;
      textResultsMobile = `${textResultsMobile}, ${numberOfActiveFilters} ${ElstrLangStore.text(
        "HEADER_STICKY_RESULTS_FILTERS_IN_USE",
      )}`;
    }

    let numberOfMaterialsFilteredByDesktop = (
      <h3 className="main-header__title phone-tablet--hidden">{textResultsDesktop}</h3>
    );
    let numberOfMaterialsFilteredByMobile = (
      <h3 className="main-header__title main-header__title--phone-tablet desktop--hidden">{textResultsMobile}</h3>
    );

    // fill the contents of filters that are active which will be displayed
    let contentActiveFilters = [];
    if (numberOfActiveFilters > 0) {
      // copy for altering contents
      let _hashObject = { ...hashObject };
      // not relevant for eCat
      if (!isPisysSite()) {
        delete _hashObject["filter_dateFrom"];
        delete _hashObject["filter_dateTo"];
      }

      let hashKeys = Object.keys(_hashObject);

      hashKeys.forEach(hash => {
        if (hash.startsWith("filter_")) {
          let dataAttributeKey = hash.substring(7);

          const translFilter = Translations.getLabel(dataAttributeKey) || dataAttributeKey;

          const filterText =
            dataAttributeKey === "searchFor" ? ElstrLangStore.text("HEADER_STICKY_SEARCH") : translFilter;

          // set background grey for inactive filters
          const inactive = MaterialsStore.isFilterIsInactive(dataAttributeKey) ? "btn-inactive" : null;

          const tooltipText = getTooltipText(dataAttributeKey, hashObject, hash);

          contentActiveFilters.push(
            <React.Fragment key={dataAttributeKey}>
              <ScrollToHOC dataAttributeKey={dataAttributeKey}>
                <div
                  key={dataAttributeKey}
                  className={`btn-filter ${inactive}`}
                  onMouseOver={TT.showTooltip.bind(this, tooltipText, TYPES.left, 12)}
                  onMouseOut={TT.removeTooltip.bind(this, TYPES.left)}
                  onMouseDown={TT.removeTooltip.bind(this, TYPES.left)}
                >
                  {filterText}
                </div>
              </ScrollToHOC>
              <RenderLoader dataAttributeKey={dataAttributeKey} />
              <span
                className={`ficon ficon__close btn-filter__close__outside ${inactive}`}
                onClick={UrlHelpers.clearSingleFilter.bind(this, dataAttributeKey)}
              />
            </React.Fragment>,
          );
        }
      });
      // add the remove all filters box
      contentActiveFilters.push(
        <div
          key="clear-all-filters"
          className="btn-filter btn-filter--clear-all"
          onClick={UrlHelpers.clearAllFilters.bind(this)}
        >
          {ElstrLangStore.text("HEADER_STICKY_CLEAR_FILTERS")}
          <span className="ficon ficon__close btn-filter__close" />
        </div>,
      );
    }

    return (
      <ClassChangerOnScroll>
        <div key="main-header__col--left" className="main-header__col--left">
          <div className="main-header__selectbox-field-unit">
            <HeaderStickyMaingroupMobile />
            <HeaderStickySearchBox key="HeaderStickySearchBox" />
          </div>
        </div>
        <div key="main-header__col--right" className="main-header__col--right">
          <div className="main-header__filter-link desktop--hidden" onClick={AppsStore.toggleOverlayFiltersOpen}>
            <span className="ficon ficon__filter filter-link__icon" />
            {ElstrLangStore.text("FILTER_TEXT_FILTERS")}
          </div>

          <SortOrderListbox renderNotFor={["list"]} />
          {numberOfMaterialsFilteredByDesktop}
          {numberOfMaterialsFilteredByMobile}

          <div className="main-header__btn-filters">{contentActiveFilters}</div>
          <nav className="main-header__view-nav">
            <ContainerHeaderAdd />
            <HeaderNavBar />
          </nav>
        </div>
      </ClassChangerOnScroll>
    );
  },
});

function getTooltipText(dataAttributeKey, hashObject, hash) {
  const filterValue = hashObject[hash].split("¦");
  const translated = [];

  filterValue.forEach((value, i) => {
    if (getFilterType(dataAttributeKey) === FILTER_TYPE.LISTBOX_TWO_STEP) {
      let step = i === 0 ? 1 : 2;
      translated.push(getTextTwoStep(dataAttributeKey, value, step));
      return;
    }

    const valuesFrom = getValuesFrom(dataAttributeKey);
    if (valuesFrom) {
      translated.push(MaterialsStore.getValueColumn(valuesFrom, value));
      return;
    }

    translated.push(MaterialsStore.getValueColumn(dataAttributeKey, value));
  });

  return translated.join("<br>");
}

export default HeaderSticky;

const RenderLoader = createReactClass({
  mixins: [FilterStore.mixin],

  storeDidChange: function () {
    this.forceUpdate();
  },

  render: function () {
    const { dataAttributeKey = "" } = this.props;
    return <React.Fragment>{FilterStore.getFilterIsLoading(dataAttributeKey) ? <Loader /> : null}</React.Fragment>;
  },
});
