import mcFly from "elstr-jslib/src/scripts/libs/mcFly.js";
import ElstrEditingStates from "elstr-jslib/src/scripts/constants/ElstrEditingStates";
import ElstrLoadingStates from "elstr-jslib/src/scripts/constants/ElstrLoadingStates";

const ItemListWidthStore = mcFly.createStore(
  {
    data: null,
    error: null,
    loadingState: ElstrLoadingStates.EMPTY,
    editingState: ElstrEditingStates.NORMAL,

    itemListWidth: 0,

    /** get functions **/
    getItemListWidth: function () {
      return this.itemListWidth;
    },

    /** set function **/
    setItemListWidth: function (width) {
      ItemListWidthStore.itemListWidth = width;

      ItemListWidthStore.emitChange();
    },
  },
  function (payload) {
    switch (payload.actionType) {
    }

    return true;
  },
);

export default ItemListWidthStore;
