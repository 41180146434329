import React, { useState } from "react";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import { getUrlCreateDossierPDF } from "../../libs/UrlHelpers";
import { isPisysSite } from "../../libs/Controllers";
import DossierStore from "../../stores/DossierStore";

export default function BottomStep2() {
  let [togglePackPages, setTogglePackPages] = useState(false);

  function toggleAllDocs() {
    DossierStore.toggleAllDocsChecked();
  }

  function toggleAllPackPages() {
    DossierStore.toggleAllPackPagesChecked(togglePackPages);
    setTogglePackPages(!togglePackPages);
  }

  function openCreateDossierTab() {
    window.open(getUrlCreateDossierPDF(), "_blank");
  }

  let allDocsLoaded = DossierStore.getAllDocsAreLoaded();

  return (
    <React.Fragment>
      <span className="container-common-content-placer-left">
        {isPisysSite() ? (
          <button
            disabled={!allDocsLoaded}
            className={`btn-common-white ${allDocsLoaded ? "" : "disabled-item"}`}
            onClick={toggleAllPackPages}
          >
            {ElstrLangStore.text("DOSSIER_BUTTON_TOGGLE_PACKAGING_ATTRIBUTES")}
          </button>
        ) : null}
        <button
          disabled={!allDocsLoaded}
          className={`btn-common-white ${allDocsLoaded ? "" : "disabled-item"}`}
          onClick={toggleAllDocs}
        >
          {ElstrLangStore.text("DOSSIER_BUTTON_TOGGLE_DOCUMENTS_SELECTION")}
        </button>
      </span>
      <span className="container-common-content-placer-right">
        <button
          disabled={!allDocsLoaded}
          className={`btn-common-blue ${allDocsLoaded ? "" : "disabled-item"}`}
          onClick={openCreateDossierTab}
        >
          {ElstrLangStore.text("DOSSIER_BUTTON_CREATE_PDF")}
        </button>
      </span>
    </React.Fragment>
  );
}
