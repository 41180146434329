import { DOWNLOAD_TYPES, FlexibleDataTable } from "./FlexibleDataTable";
import React from "react";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import DetailsActions from "../../actions/DetailsActions";
import DetailsStore from "../../stores/DetailsStore";
import ElstrLoadingStates from "elstr-jslib/src/scripts/constants/ElstrLoadingStates";
import DetailPisysTableMessage from "./DetailPisysTableMessage";

const DetailPisysComplaints = createReactClass({
  propTypes: {
    material: PropTypes.object.isRequired,
  },

  getInitialState() {
    const { material } = this.props;
    return { material };
  },

  statics: {
    getDerivedStateFromProps(newProps, prevState) {
      if (newProps.material.sMatHdrMatnr !== prevState.renderedMaterialNumber) {
        DetailsActions.getComplaints({
          materialNumber: newProps.material.sMatHdrMatnr,
        });
        return { renderedMaterialNumber: newProps.material.sMatHdrMatnr };
      }
      return null;
    },
  },

  render: function () {
    const complaints = DetailsStore.getComplaints();

    // show the user the loading state of the app
    let loadingState = DetailsStore.getLoadingStateFlexibleTable();
    let error = DetailsStore.getError();

    if (error == null && (complaints == null || complaints.dataDefintions == null || complaints.data == null)) {
      error = ElstrLangStore.text("DETAIL_TEXT_NODATA");
    }

    // case the request data is empty the arrow also should be displayed
    if (complaints.data !== undefined) {
      if (complaints.data.length <= 0) {
        error = ElstrLangStore.text("DETAIL_TEXT_NODATA");
      }
    }

    // return text loading when the loading state is EMPTY
    if (loadingState === ElstrLoadingStates.EMPTY) {
      return <DetailPisysTableMessage isLoading />;
    } else if (error !== null) {
      return <DetailPisysTableMessage>{error}</DetailPisysTableMessage>;
    } else {
      return (
        <FlexibleDataTable
          dataDefintions={complaints.dataDefintions}
          data={complaints.data}
          downloadType={DOWNLOAD_TYPES.COMPLAINTS}
        />
      );
    }
  },
});

export default DetailPisysComplaints;
