import Translations from "../../libs/Translations";
import React from "react";
import { MAIN_VIEW as MV } from "../../constants/MainView";
import * as UrlHelpers from "../../libs/UrlHelpers";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import MetaNavLanguage from "./MetaNavLanguage";
import MetaNavCatalogue from "./MetaNavCatalogue";
import HeaderWrapContent from "./HeaderWrapContent";
import SwitchApp from "./SwitchApp";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import ElstrScrollStore from "elstr-jslib/src/scripts/stores/ElstrScrollStore";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";
import AppsStore from "../../stores/AppsStore";

const ClassChangerOnScroll = createReactClass({
  mixins: [AppsStore.mixin, ElstrScrollStore.mixin],

  storeDidChange: function () {
    this.forceUpdate();
  },

  // Render
  render: function () {
    // scroll handling
    let offsetTop = "auto";
    // tmp deactivated scroll changes

    if (AppsStore.getLayoutView() === "desktop") {
      let scrollTop = ElstrScrollStore.getCurrentScroll();
      offsetTop = -212;
      if (scrollTop < 212) offsetTop = 0 - scrollTop;
    }

    return (
      <div key="header-wrap" className="header-wrap" style={{ top: offsetTop }}>
        {this.props.children}
      </div>
    );
  },
});

const HeaderWrap = createReactClass({
  mixins: [AppsStore.mixin, ElstrUrlHashStore.mixin, ElstrLangStore.mixin],

  propTypes: {
    // emptypage is for pages like login / error where certain elements won't appear
    emptypage: PropTypes.bool,
  },

  storeDidChange: function () {
    this.forceUpdate();
  },

  // Render
  render: function () {
    let classNameLanguageDesktop = "meta-nav__item";
    if (AppsStore.getLanguageSelectionOpen()) {
      classNameLanguageDesktop += " meta-nav__list--open";
    }

    let classNameCountryDesktop = "meta-nav__item";
    if (AppsStore.getCatalogueSelectionOpen()) {
      classNameCountryDesktop += " meta-nav__list--open";
    }

    let labelCatalogue = Translations.getLabelCatalogue();
    let currentCatText = Translations.getCurrentCatText();

    let visibility = "visible";
    if (this.props.emptypage === true) visibility = "hidden";
    if (UrlHelpers.getMainView() === MV.SETTINGS) visibility = "hidden";

    return (
      <ClassChangerOnScroll>
        <nav key="meta-nav phone-tablet--hidden" className="meta-nav phone-tablet--hidden">
          <h2 className="visually-hidden">Meta Navigation</h2>
          <div className="meta-nav__container">
            <div className={classNameCountryDesktop} style={{ visibility }}>
              <h2 className="visually-hidden">Select Country</h2>
              <div className="meta-nav__label" onClick={AppsStore.toggleCatalogueSelectionOpen}>
                {labelCatalogue}:
              </div>
              <div className="meta-nav__content" onClick={AppsStore.toggleCatalogueSelectionOpen}>
                <span className="ficon ficon__arrow-down-bold meta-nav__arrow-down" />
                <span className="meta-nav__current">{currentCatText}</span>
                <div className="meta-nav__list-layer">
                  <ul className="meta-nav__list">
                    <MetaNavCatalogue />
                  </ul>
                </div>
              </div>
            </div>
            <div className={classNameLanguageDesktop} style={{ visibility }}>
              <h2 className="visually-hidden">Select language</h2>
              <div className="meta-nav__label" onClick={AppsStore.toggleLanguageOpen}>
                {ElstrLangStore.text("LANG_TEXT_LANGUAGE")}
              </div>
              <div className="meta-nav__content" onClick={AppsStore.toggleLanguageOpen}>
                <span className="ficon ficon__arrow-down-bold meta-nav__arrow-down" />
                <span className="meta-nav__current">{ElstrLangStore.getCurrentLanguage()}</span>
                <div className="meta-nav__list-layer">
                  <ul className="meta-nav__list">
                    <MetaNavLanguage />
                  </ul>
                </div>
              </div>
            </div>
            <SwitchApp renderedPlace="header" />
          </div>
        </nav>
        <HeaderWrapContent emptypage={this.props.emptypage} />
      </ClassChangerOnScroll>
    );
  },
});

export default HeaderWrap;
