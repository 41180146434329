import { MAIN_VIEW as MV } from "../../constants/MainView";
import React from "react";
import createReactClass from "create-react-class";
import * as UrlHelpers from "../../libs/UrlHelpers";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";

function _setState() {
  let resultView = ElstrUrlHashStore.get("resultView") || "grid";
  let selectionView = ElstrUrlHashStore.get("selectionView") || "compare";

  return { resultView, selectionView };
}

const HeaderNavBar = createReactClass({
  mixins: [ElstrUrlHashStore.mixin],

  getInitialState: function () {
    return _setState();
  },

  storeDidChange: function () {
    this.setState(_setState());
  },

  shouldComponentUpdate: function (nextProps, nextState) {
    return this.state.resultView !== nextState.resultView || this.state.selectionView !== nextState.selectionView;
  },

  render: function () {
    let classNameNavGrid = "view-nav__item view-nav__item--grid-view";
    let classNameNavList = "view-nav__item view-nav__item--list-view phone--hidden";
    let classNameNavCompare = "view-nav__item view-nav__item--compare-view";

    // relevantView can be either resultView when in result view or selectionView when inside the selection
    let relevantView;
    let key;
    let mainView = UrlHelpers.getMainView();
    if (mainView === MV.SELECTION) {
      key = "selectionView";
      relevantView = this.state.selectionView;
    } else {
      key = "resultView";
      relevantView = this.state.resultView;
    }

    // Handle which nav item is active
    let active = " view-nav__item--active";
    switch (relevantView) {
      case "grid":
        classNameNavGrid += active;
        break;
      case "list":
        classNameNavList += active;
        break;
      case "compare":
        classNameNavCompare += active;
        break;
    }

    let hashGrid = {};
    let hashList = {};
    let hashCompare = {};

    hashGrid[key] = "grid";
    hashList[key] = "list";
    hashCompare[key] = "compare";

    return (
      <ul className="view-nav-list">
        <li className={classNameNavGrid} onClick={UrlHelpers.setHashNoHistory.bind(this, hashGrid)}>
          <span className="ficon ficon__grid-view view-nav-item__icon" />
        </li>
        <li className={classNameNavList} onClick={UrlHelpers.setHashNoHistory.bind(this, hashList)}>
          <span className="ficon ficon__list-view view-nav-item__icon" />
        </li>
        <li className={classNameNavCompare} onClick={UrlHelpers.setHashNoHistory.bind(this, hashCompare)}>
          <span className="ficon ficon__compare-view view-nav-item__icon" />
        </li>
      </ul>
    );
  },
});

export default HeaderNavBar;
