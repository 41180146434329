import { Redirect } from "react-router-dom";
import Translations from "../../libs/Translations";
import React from "react";
import createReactClass from "create-react-class";
import * as UrlHelpers from "../../libs/UrlHelpers";
import RouteHistoryStore from "../../stores/RouteHistoryStore";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";

const HeaderStickyMaingroupMobile = createReactClass({
  getInitialState: function () {
    return { redirectTo: undefined };
  },

  selectChange: function (e) {
    let mainGroup = "all"; // default

    // can contain the new maingGroup if not = '-'
    let value = e.target.value;

    //let objects;
    if (value !== "-") {
      mainGroup = value;
    }

    this.setState({ redirectTo: mainGroup });
  },

  handleRedirections: function () {
    let mainGroup = this.state.redirectTo;
    // reset
    this.state.redirectTo = undefined;

    /** prepare path **/
    let hashesToRemain = UrlHelpers.getAllHashesExcept(["mainView", "catalogue", "mainGroup", "ph", "switched"], false);

    let catalogue = RouteHistoryStore.getCatalogue();

    let path = `/catalogue/result/${catalogue}/${mainGroup}/#ph=1${hashesToRemain}`;

    return <Redirect to={path} />;
  },

  render: function () {
    let maingroups = VETROPACK_STATIC_CACHE.navigation[0]["maingroups"];

    //Start filling the content of the maingroup selectbox
    let additionalMaingroups = [];
    let defaultValue = "-";

    let mainGroupURL = ElstrUrlHashStore.getRouteParam("mainGroup");
    if (mainGroupURL === undefined && LIBS["appName"] === "catalogue") mainGroupURL = "all"; // set default
    if (LIBS["appName"] === "intro") mainGroupURL = ""; // overwrite if in the intro app and there is some value in the url

    maingroups.forEach(mainGroup => {
      // set default Value if according
      if (mainGroupURL === mainGroup) defaultValue = mainGroup;

      // push option
      additionalMaingroups.push(
        <option key={mainGroup} value={mainGroup}>
          {Translations.getValue("sMatAtvEcatMaingroup", mainGroup)}
        </option>,
      );
    });

    // create mainGroup selectbox
    let content = (
      <select className="main-header__primary-nav-selectbox" value={defaultValue} onChange={this.selectChange}>
        <option value="all" onClick={UrlHelpers.clearHash.bind(this, "mainGroup")}>
          {ElstrLangStore.text("HEADER_MAINGROUP_ALL")}
        </option>
        {additionalMaingroups}
      </select>
    );

    // handle url redirections when necessary
    let redirect = null;
    if (this.state.redirectTo) {
      redirect = this.handleRedirections();
    }

    return (
      <div className="main-header__primary-nav-selectbox-wrap desktop--hidden">
        {content}
        {redirect}
      </div>
    );
  },
});

export default HeaderStickyMaingroupMobile;
