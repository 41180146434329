import ElstrFlux from "elstr-jslib/src/scripts/libs/ElstrFlux.js";
import SettingsConstants from "../constants/actions/SettingsConstants";

const classNameSubscription = "VETROPACK_WidgetServer_JSON_Subscription";

const SettingsActions = ElstrFlux.createActions(
  [
    {
      className: classNameSubscription,
      methodName: "getSubscriptionNewZvgb",
      constantWill: SettingsConstants.SUBSCRIPTION_NEW_ZVGB_WILL_GET,
      constantDid: SettingsConstants.SUBSCRIPTION_NEW_ZVGB_DID_GET,
    },
    {
      className: classNameSubscription,
      methodName: "addSubscriptionNewZvgb",
      constantWill: SettingsConstants.SUBSCRIPTION_NEW_ZVGB_WILL_ADD,
      constantDid: SettingsConstants.SUBSCRIPTION_NEW_ZVGB_DID_ADD,
    },
    {
      className: classNameSubscription,
      methodName: "removeSubscriptionNewZvgb",
      constantWill: SettingsConstants.SUBSCRIPTION_NEW_ZVGB_WILL_REMOVE,
      constantDid: SettingsConstants.SUBSCRIPTION_NEW_ZVGB_DID_REMOVE,
    },
  ],
  {},
);

export default SettingsActions;
