import { Redirect, Route } from "react-router-dom";
import React from "react";
import createReactClass from "create-react-class";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";

// REDIRECTS FOR OLD PAGE URLs:
// ex1) https://katalog.vetropack.com/catalogue#mainView=selection&selectionId=3D1E4B0F-845B-4166-90E2-47514796902D&ph=1
// ex2) https://katalog.vetropack.com/catalogue#mainView=detail&sMatHdrMatnr=18351#ph=1
const Redirects = createReactClass({
  render: function () {
    // old page had an hash parameter mainView in the URL
    let mainViewOld = ElstrUrlHashStore.get("mainView");

    // REDIRECTS FOR DETAIL
    if (mainViewOld === "detail") {
      let sMatHdrMatnr = ElstrUrlHashStore.get("sMatHdrMatnr");
      let path = `/catalogue/detail/${sMatHdrMatnr}`;
      return <Redirect to={path} />;
    }

    // REDIRECTS FOR SELECTION
    if (mainViewOld === "selection") {
      let selectionId = ElstrUrlHashStore.get("selectionId");
      let path = `/catalogue/selection#selectionId=${selectionId}`;
      return <Redirect to={path} />;
    }

    // EXPLANATION OF THE ph=1 part
    // add a hash in the url if there is none, this is to prevent the application jumping to the start when
    // the application starts with an empty #, than you scroll down the page, apply a filter and remove it again
    // in this case an empty # remains which lets the page jump up
    return <Route exact path="/catalogue" render={() => <Redirect to="catalogue/result/global/all#ph=1" />} />;
  },
});

export default Redirects;
