import { MAIN_VIEW as MV } from "../constants/MainView";
import { getMainView } from "./UrlHelpers";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";

export function getTitle() {
  let titleText = "No Title for this View";

  const mainView = getMainView();

  if (mainView === MV.DOSSIER) {
    titleText = ElstrLangStore.text("DOSSIER_TITLE");
  }

  if (mainView === MV.PRINT_LABEL) {
    titleText = ElstrLangStore.text("PRINT_LABEL_TITLE");
  }

  return titleText;
}
