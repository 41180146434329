import Translations from "../../libs/Translations";
import React from "react";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import MaterialsStore from "../../stores/MaterialsStore";
import FilterStore from "../../stores/FilterStore";
import { ATTRIBUTE_SOURCE } from "../../libs/Filters";
import { FILTER, FILTER_TYPE } from "../../constants/Filter";
import * as UrlHelpers from "../../libs/UrlHelpers";

function sanitizeSumsOfAttributeForWerks(sumsOfAttribute) {
  // attribute werks following entries are only experimental and therefore not needed
  delete sumsOfAttribute[1210];
}

const FilterListboxOptions = createReactClass({
  propTypes: {
    filterValueArr: PropTypes.array.isRequired,
    dataAttributeKey: PropTypes.string.isRequired,
    attributeSource: PropTypes.string.isRequired,
    keyStepOne: PropTypes.string,
    keyStepTwo: PropTypes.string,
    step: PropTypes.number,
    filterType: PropTypes.string,
    valuesFrom: PropTypes.string,
  },

  render: function () {
    let options = [];
    let { filterValueArr, dataAttributeKey, attributeSource, keyStepOne, keyStepTwo, step, filterType, valuesFrom } =
      this.props;

    let sumsOfAttribute = {};
    let sumsOfAttributeSorted = [];

    // typeof undefined as fallback mechanism
    if (attributeSource === ATTRIBUTE_SOURCE.MATERIALS || typeof attributeSource === "undefined") {
      sumsOfAttribute = MaterialsStore.getSumsOfAttributeValueFilters(dataAttributeKey);
      // sort attributes alphabetic
      sumsOfAttributeSorted = Object.keys(sumsOfAttribute).sort();
    }

    if (attributeSource === ATTRIBUTE_SOURCE.TRANSLATIONS) {
      sumsOfAttribute = Object.assign({}, Translations.getValues(dataAttributeKey)) || {};
      // sort attributes alphabetic
      sumsOfAttributeSorted = Object.keys(sumsOfAttribute).sort();

      if (dataAttributeKey === FILTER.DEFECT_TYPE) {
        // this part is kept simple in order to be better understandable (KISS)
        // removing options that start with 1,2,3 when main defect type is selected
        // ex: 100 is selected, therefore user inputs like 101 are obsolete since they are included in 100
        let skipAll100 = filterValueArr.includes("100");
        let skipAll200 = filterValueArr.includes("200");
        let skipALl300 = filterValueArr.includes("300");

        sumsOfAttributeSorted.forEach(key => {
          let removeKey = false;
          if (skipAll100 && key.startsWith("1")) {
            removeKey = true;
          }
          if (skipAll200 && key.startsWith("2")) {
            removeKey = true;
          }
          if (skipALl300 && key.startsWith("3")) {
            removeKey = true;
          }

          if (removeKey) {
            delete sumsOfAttribute[key];
          }
        });
      }

      if (dataAttributeKey === FILTER.PRODUCTION) {
        if (step === 1) {
          sumsOfAttribute = Object.assign({}, Translations.getValues(keyStepOne)) || {};
          // sort attributes alphabetic
          sumsOfAttributeSorted = Object.keys(sumsOfAttribute).sort();
          sanitizeSumsOfAttributeForWerks(sumsOfAttribute);
        }

        if (step === 2) {
          let filterValueStepOne = UrlHelpers.getHashArray(`filter_${FILTER.PRODUCTION}`)[0];
          sumsOfAttribute = Object.assign({}, Translations.getValues(keyStepTwo)[filterValueStepOne]) || {};
          sumsOfAttributeSorted = Object.keys(sumsOfAttribute).sort();
        }
      }

      if (valuesFrom) {
        sumsOfAttribute = Object.assign({}, Translations.getValues(valuesFrom)) || {};
        // sort attributes alphabetic
        sumsOfAttributeSorted = Object.keys(sumsOfAttribute).sort();

        if (dataAttributeKey === FILTER.PLANNED_ORDERS) {
          sanitizeSumsOfAttributeForWerks(sumsOfAttribute);
        }
      }
    }

    // fill only options that exists, because of sumsOfAttributes
    sumsOfAttributeSorted.forEach(key => {
      // if there is no sum available, the options shouldn't not be added
      // key can also be an empty string on TEST Env
      if (sumsOfAttribute[key] && key !== "") {
        const text = FilterStore.getFilterText(key, dataAttributeKey, step);
        const checked = filterValueArr.includes(key);

        // only push options when listbox is not selected
        if (!checked) {
          options.push(
            <option key={key} value={key}>
              {text}
            </option>,
          );
        }
      }
    });

    // text shown first in listbox
    let textListbox = `${ElstrLangStore.text("FILTER_TEXT_CHOOSE")} ${Translations.getLabel(dataAttributeKey)}`;

    if (valuesFrom) {
      textListbox = `${ElstrLangStore.text("FILTER_TEXT_CHOOSE")} ${Translations.getLabel(valuesFrom)}`;
    }

    if (filterType === FILTER_TYPE.LISTBOX_TWO_STEP && step === 1) {
      textListbox = `${ElstrLangStore.text("FILTER_TEXT_CHOOSE")} ${Translations.getLabel(keyStepOne)}`;
    }

    if (filterType === FILTER_TYPE.LISTBOX_TWO_STEP && step === 2) {
      textListbox = `${ElstrLangStore.text("FILTER_TEXT_CHOOSE")} ${Translations.getLabel(keyStepTwo)}`;
    }

    return (
      <>
        <option value="-">{textListbox}</option>
        {options}
      </>
    );
  },
});

export default FilterListboxOptions;
