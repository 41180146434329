import React from "react";
import createReactClass from "create-react-class";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";
import AppsStore from "../../stores/AppsStore";

const Footer = createReactClass({
  mixins: [ElstrLangStore.mixin],

  storeDidChange: function () {
    this.forceUpdate();
  },

  render: function () {
    /** assign the right source element where tel-nr and email data are located **/
    let currentCat = ElstrUrlHashStore.getRouteParam("catalogue") || "global"; // fallback VPS = Switzerland
    let navigation = VETROPACK_STATIC_CACHE["navigation"];

    /** assign the right source element where tel-nr and email data are located **/
    let source = {};
    navigation.forEach(navElem => {
      if (navElem["catalogue"][currentCat]) source = navElem["catalogue"][currentCat];
    });

    /** get email **/
    let catalogueMail = source["catalogueMail"] || "no email defined";

    /** get number **/
    let cataloguePhone = source["cataloguePhone"] || "no phone defined";
    // format cataloguePhone for link
    let cataloguePhoneFormated = AppsStore.getFormatedTelNr(cataloguePhone);

    /** decide if to overwrite eMail address for global and hiding the phone number **/
    let visibility = "inline";
    if (currentCat === "global") {
      // no tel. number for global
      visibility = "none";
      // fix email for global
      catalogueMail = "productinfo@vetropack.com";
    }

    /** assign href to according language **/
    let currentLanguage = ElstrLangStore.getCurrentLanguage();
    // set defaults
    let hrefLegal = "http://www.vetropack.ch/en/legal/privacy-policy/";
    let hrefImprint = "http://www.vetropack.com/en/legal/imprint-catalogue/";
    // overwrite with language
    switch (currentLanguage) {
      case "en":
        hrefLegal = "http://www.vetropack.ch/en/legal/privacy-policy/";
        hrefImprint = "http://www.vetropack.com/en/legal/imprint-catalogue/";
        break;
      case "de":
        hrefLegal = "http://www.vetropack.ch/de/legal/datenschutzerklaerung/";
        hrefImprint = "http://www.vetropack.com/de/legal/impressum-katalog/";
        break;
      case "fr":
        hrefLegal = "http://www.vetropack.ch/fr/legal/protection-de-donnees/";
        hrefImprint = "http://www.vetropack.com/fr/legal/impressum-catalogue/";
        break;
      case "it":
        hrefLegal = "http://www.vetropack.it/it/legal/informativa-sulla-privacy/";
        hrefImprint = "http://www.vetropack.com/it/legal/stampa-catalogo/";
        break;
      case "hr":
        hrefLegal = "http://www.vetropack.hr/hr/legal/izjava-o-zastiti-privatnosti/";
        hrefImprint = "http://www.vetropack.hr/hr/legal/imprint-katalog/";
        break;
      case "cs":
        hrefLegal = "http://www.vetropack.cz/cz/legal/zasady-ochrany-osobnich-udaju/";
        hrefImprint = "http://www.vetropack.cz/en/legal/imprint-catalogue/";
        break;
      case "sk":
        hrefLegal = "http://www.vetropack.sk/sk/legal/zasady-ochrany-osobnych-udajov/";
        hrefImprint = "http://www.vetropack.sk/en/legal/imprint-catalogue/";
        break;
      case "uk":
        hrefLegal = "http://www.vetropack.ua/uk/legal/politika-konfidenciinosti/";
        hrefImprint = "http://www.vetropack.ua/uk/legal/vikhidni-dani-katalog/";
        break;
      case "ru":
        hrefLegal = "http://www.vetropack.ua/ru/legal/politika-konfidencialnosti/";
        hrefImprint = "http://www.vetropack.ua/ru/legal/vykhodnye-dannye-katalog/";
        break;
    }

    return (
      <div className="main-content__footer">
        <h3 className="main-content-footer__title">{ElstrLangStore.text("DETAIL_CONTACT_US")}</h3>
        <div className="main-content-footer__tel" style={{ display: visibility }}>
          <a href={`tel:${cataloguePhoneFormated}`}>
            <span className="ficon ficon__tel main-content-footer__tel-icon" />
            {cataloguePhone}
          </a>
        </div>
        <div className="main-content-footer__mail">
          <a href={`mailto:${catalogueMail}`}>
            <span className="ficon ficon__mail main-content-footer__mail-icon" />
            {catalogueMail}
          </a>
        </div>
        <div className="main-content-footer__right-item">
          <a rel="noopener noreferrer" target="_blank" href={hrefImprint}>
            <div className="main-content-footer__link--intern">{ElstrLangStore.text("TEXT_IMPRESSUM")}</div>
          </a>
          <div className="main-content-footer__separator">&nbsp;|&nbsp;</div>
          <a rel="noopener noreferrer" target="_blank" href={hrefLegal}>
            <div className="main-content-footer__link--intern">{ElstrLangStore.text("TEXT_PRIVACY_POLICY")}</div>
          </a>
        </div>
      </div>
    );
  },
});

export default Footer;
