import mcFly from "elstr-jslib/src/scripts/libs/mcFly.js";
import loadjs from "loadjs";
import ElstrUserConstants from "elstr-jslib/src/scripts/constants/ElstrUserConstants";
import ElstrLog from "elstr-jslib/src/scripts/ElstrLog";
import AppsConstants from "../constants/actions/AppsConstants";

const StaticCacheVersionsStore = mcFly.createStore(
  {
    error: null,

    staticCacheVersionsLoaded: VETROPACK_STATIC_CACHE.versions,
    staticCacheVersionsCurrent: VETROPACK_STATIC_CACHE.versions,

    successCounter: 0,

    getStaticCacheVersionsLoaded: function () {
      return this.staticCacheVersionsLoaded;
    },

    getError: function () {
      return this.error;
    },

    getSuccessCounter: () => {
      return StaticCacheVersionsStore.successCounter;
    },
  },
  function (payload) {
    let tmpError;
    switch (payload.actionType) {
      case ElstrUserConstants.ELSTR_USER_DID_LOGOUT:
        //StaticCacheVersionsStore.staticCacheVersionsLoaded = null;
        //StaticCacheVersionsStore.staticCacheVersionsCurrent = null;
        StaticCacheVersionsStore.error = null;

        StaticCacheVersionsStore.emitChange();
        break;

      case AppsConstants.STATIC_CACHE_VERSIONS_WILL_GET:
        //ElstrLog.log("AppsConstants.STATIC_CACHE_VERSIONS_WILL_GET ", payload);
        break;

      case AppsConstants.STATIC_CACHE_VERSIONS_DID_GET:
        //ElstrLog.log("AppsConstants.STATIC_CACHE_VERSIONS_DID_GET ", payload);

        tmpError = payload.error;
        if (tmpError && tmpError !== "") {
          StaticCacheVersionsStore.error = tmpError;

          StaticCacheVersionsStore.emitChange();
        } else {
          StaticCacheVersionsStore.error = null;
          StaticCacheVersionsStore.staticCacheVersionsCurrent = payload.data.staticCacheVersions;

          for (let staticCacheVersionType in StaticCacheVersionsStore.staticCacheVersionsLoaded) {
            if (StaticCacheVersionsStore.staticCacheVersionsLoaded.hasOwnProperty(staticCacheVersionType)) {
              if (
                StaticCacheVersionsStore.staticCacheVersionsCurrent[staticCacheVersionType] !==
                StaticCacheVersionsStore.staticCacheVersionsLoaded[staticCacheVersionType]
              ) {
                let jsResource =
                  "static_cache/" +
                  staticCacheVersionType +
                  "." +
                  StaticCacheVersionsStore.staticCacheVersionsCurrent[staticCacheVersionType] +
                  ".js";
                loadjs(jsResource, {
                  success: function (staticCacheVersionType) {
                    StaticCacheVersionsStore.staticCacheVersionsLoaded[staticCacheVersionType] =
                      StaticCacheVersionsStore.staticCacheVersionsCurrent[staticCacheVersionType];

                    StaticCacheVersionsStore.successCounter += 1;

                    ElstrLog.info("New JS resource loaded: " + jsResource);
                    ElstrLog.info(
                      "getStaticCacheVersionsLoaded(): ",
                      StaticCacheVersionsStore.getStaticCacheVersionsLoaded(),
                    );

                    StaticCacheVersionsStore.emitChange();
                  }.bind(this, staticCacheVersionType),
                });
              }
            }
          }
        }
        break;
    }
    return true;
  },
);

export default StaticCacheVersionsStore;
