import * as TT from "../../libs/Tooltip";
import React from "react";
import ItemCompareRows from "./ItemCompareRows";
import { isPisysSite } from "../../libs/Controllers";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import DossierStore from "../../stores/DossierStore";
import * as UrlHelpers from "../../libs/UrlHelpers";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import { TYPES } from "../../constants/Tooltip";

const ItemCompareDossier = createReactClass({
  mixins: [DossierStore.mixin],

  propTypes: {
    material: PropTypes.object.isRequired,
    docs: PropTypes.array.isRequired,
    attributesSorted: PropTypes.array.isRequired,
    counterForUpdate: PropTypes.number, // increases when a user changes attributes settings (displaying / order)
    packPageChecked: PropTypes.bool,
  },

  storeDidChange: function () {
    this.setState(this._setState());
  },

  _setState() {
    const { sMatHdrMatnr } = this.props.material;
    const isDocsLoadedFromMaterial = DossierStore.isDocsLoadedFromMaterial(sMatHdrMatnr);
    const comment = DossierStore.comments[sMatHdrMatnr] || "";

    return { isDocsLoadedFromMaterial, comment };
  },

  getInitialState() {
    return this._setState({});
  },

  shouldComponentUpdate: function (nextProps, nextState) {
    return (
      this.props.material.sMatHdrMatnr !== nextProps.material.sMatHdrMatnr ||
      JSON.stringify(this.props.docs) !== JSON.stringify(nextProps.docs) ||
      this.state.isDocsLoadedFromMaterial !== nextState.isDocsLoadedFromMaterial ||
      this.props.counterForUpdate !== nextProps.counterForUpdate ||
      this.props.packPageChecked !== nextProps.packPageChecked ||
      this.state.comment !== nextState.comment
    );
  },

  toggleCheckedDoc(doknr, dokvr, prevChecked) {
    // toggle
    let newChecked = !prevChecked;
    DossierStore.setCheckedDoc(doknr, dokvr, newChecked);
  },

  toggleCheckedPackPage() {
    DossierStore.toggleCheckedPackPage(this.props.material.sMatHdrMatnr);
  },

  render: function () {
    let { docs, attributesSorted, material, packPageChecked } = this.props;
    let sMatHdrMatnr = material.sMatHdrMatnr;

    let documents = [];
    let currLang = ElstrLangStore.getCurrentLanguage();
    let hrefDoc = UrlHelpers.getHrefMaterialDocument();

    docs.forEach(doc => {
      documents.push(
        <div key={`${doc.doknr}-${doc.dokvr}`}>
          <dt>
            <input
              onChange={this.toggleCheckedDoc.bind(this, doc.doknr, doc.dokvr, doc.checked)}
              checked={doc.checked}
              type="checkbox"
              id={doc.doknr}
            />{" "}
            <label className="pointer" htmlFor={doc.doknr}>
              <a
                className="link--underline"
                onMouseOver={TT.showTooltip.bind(this, doc.dktxt[currLang], TYPES.left, 15)}
                onMouseOut={TT.removeTooltip.bind(this, TYPES.left)}
                onMouseDown={TT.removeTooltip.bind(this, TYPES.left)}
                rel="noopener noreferrer"
                target="_blank"
                href={`${hrefDoc}&doknr=${doc.doknr}&materialNumber=${sMatHdrMatnr}`}
              >
                {`${doc.dokar} - ${doc.doknr}`}
              </a>
            </label>
          </dt>
        </div>,
      );
    });

    if (docs.length === 0) {
      documents = ElstrLangStore.text("DETAIL_TEXT_LOADING");

      // if loading state says document are loaded
      // and the docs.length is 0
      // then it means there are no documents for this doc
      // ex: material
      if (this.state.isDocsLoadedFromMaterial) {
        documents = ElstrLangStore.text("NO_DOCUMENTS_FOR_THIS_MATERIAL");
      }
    }

    return (
      <article className="item-compare">
        {shouldRenderPackagingAttributes(attributesSorted, material)}

        {isPisysSite() ? (
          <div className="item-compare__row">
            <div className="filter-block__checkbox">
              <dl>
                <dt>
                  <input onChange={this.toggleCheckedPackPage} type="checkbox" checked={packPageChecked} />{" "}
                  <label className="pointer" htmlFor={"pp"}>
                    {ElstrLangStore.text("DOSSIER_ATTRIBUTES_PACKAGING")}
                  </label>
                </dt>
              </dl>
            </div>
          </div>
        ) : null}

        <div className="item-compare__dossier_last_item">
          <div className="item-compare__top-box">
            <div className="item-compare-top-box__content">
              <div className="filter-block__content filter-block__dossier">
                <div className="filter-block__checkbox">
                  <dl>{documents}</dl>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isPisysSite() ? (
          <div className="item-compare__dossier_last_item">
            <div className="item-compare__top-box">
              <div className="item-compare-top-box__content">
                <textarea
                  style={{ width: "100%", height: "100%", border: 0, resize: "none" }}
                  maxLength={300}
                  placeholder={`${ElstrLangStore.text("DOSSIER_COMMENT")}...`}
                  value={this.state.comments}
                  onChange={e => DossierStore.updateComment(sMatHdrMatnr, e.target.value)}
                />
              </div>
            </div>
          </div>
        ) : null}
      </article>
    );

    function shouldRenderPackagingAttributes(attributesSorted, material) {
      // for pisys we'll show the actual selected packaging attributes
      if (isPisysSite()) {
        return <ItemCompareRows material={material} attributesSorted={attributesSorted} />;
      }
      // in eCat this is not necessary but for design / style purposes we need an row
      return <div className="item-compare__row common__row" />;
    }
  },
});

export default ItemCompareDossier;
