import React from "react";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import DossierStore from "../../stores/DossierStore";

export default function BottomStep1() {
  let activeStep = DossierStore.getActiveStep();

  let button = null;

  function setActiveStepTo1() {
    DossierStore.setActiveStep(1);
  }

  function isEmptyTitle() {
    return DossierStore.getTitle() === "";
  }

  function getClassName() {
    if (isEmptyTitle()) {
      return "btn-common-white";
    }
    return "btn-common-blue";
  }

  function getDisabled() {
    if (isEmptyTitle()) {
      return true;
    }
    return false;
  }

  if (activeStep === 1) {
    button = (
      <button
        disabled={getDisabled()}
        className={`${getClassName()} ${isEmptyTitle() ? "disabled-item" : ""}`}
        onClick={DossierStore.incActiveStep}
      >
        {ElstrLangStore.text("DOSSIER_BUTTON_NEXT")}
      </button>
    );
  }

  if (activeStep > 1) {
    button = (
      <button className="btn-common-white" onClick={setActiveStepTo1}>
        {ElstrLangStore.text("DOSSIER_BUTTON_MODIFY")}
      </button>
    );
  }

  return <React.Fragment>{button}</React.Fragment>;
}
