import { DOWNLOAD_TYPES, FlexibleDataTable } from "./FlexibleDataTable";
import React from "react";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import DetailsActions from "../../actions/DetailsActions";
import DetailsStore from "../../stores/DetailsStore";
import ElstrLoadingStates from "elstr-jslib/src/scripts/constants/ElstrLoadingStates";
import DetailPisysTableMessage from "./DetailPisysTableMessage";

const DetailPisysBlowMoulds = createReactClass({
  propTypes: {
    material: PropTypes.object.isRequired,
  },

  getInitialState() {
    const { material } = this.props;
    return { material };
  },

  statics: {
    getDerivedStateFromProps(newProps, prevState) {
      if (newProps.material.sMatHdrMatnr !== prevState.renderedMaterialNumber) {
        DetailsActions.getBlowMoulds({
          materialNumber: newProps.material.sMatHdrMatnr,
        });
        return { renderedMaterialNumber: newProps.material.sMatHdrMatnr };
      }
      return null;
    },
  },

  render: function () {
    let blowMoulds = DetailsStore.getBlowMoulds();

    // show the user the loading state of the app
    let loadingState = DetailsStore.getLoadingStateFlexibleTable();
    let error = DetailsStore.getError();

    if (error == null && (blowMoulds == null || blowMoulds.dataDefintions == null || blowMoulds.data == null)) {
      error = ElstrLangStore.text("DETAIL_TEXT_NODATA");
    }

    // case the request data is empty the arrow also should be displayed
    if (blowMoulds.data !== undefined) {
      if (
        blowMoulds.data["MOULD_MOULDSETS"].length === 0 &&
        blowMoulds.data["MOULD_BLOWMOULDS"].length === 0 &&
        blowMoulds.data["MOULD_BLANKMOULDS"].length === 0
      ) {
        error = ElstrLangStore.text("DETAIL_TEXT_NODATA");
      }
    }

    // show loading -> return text loading when the loading state is EMPTY
    if (loadingState === ElstrLoadingStates.EMPTY) {
      return <DetailPisysTableMessage isLoading />;
    } else if (error !== null) {
      return <DetailPisysTableMessage>{error}</DetailPisysTableMessage>;
    } else {
      return (
        <>
          <h2 className="pisys-tab-h2">{ElstrLangStore.text("DETAIL_TAB_MOULDSETS")}</h2>
          <FlexibleDataTable
            dataDefintions={blowMoulds.dataDefintions["MOULD_MOULDSETS"]}
            data={blowMoulds.data["MOULD_MOULDSETS"]}
            downloadType={DOWNLOAD_TYPES.BLOW_MOULDS}
          />
          <h2 className="pisys-tab-h2">{ElstrLangStore.text("DETAIL_TAB_BLOWMOULDS")}</h2>
          <FlexibleDataTable
            dataDefintions={blowMoulds.dataDefintions["MOULD_BLOWMOULDS"]}
            data={blowMoulds.data["MOULD_BLOWMOULDS"]}
            downloadType={DOWNLOAD_TYPES.BLOW_MOULDS}
          />
          <h2 className="pisys-tab-h2">{ElstrLangStore.text("DETAIL_TAB_BLANKMOULDS")}</h2>
          <FlexibleDataTable
            dataDefintions={blowMoulds.dataDefintions["MOULD_BLANKMOULDS"]}
            data={blowMoulds.data["MOULD_BLANKMOULDS"]}
            downloadType={DOWNLOAD_TYPES.BLOW_MOULDS}
          />
        </>
      );
    }
  },
});

export default DetailPisysBlowMoulds;
