import React from "react";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import * as UrlHelpers from "../../libs/UrlHelpers";
import MaterialsStore from "../../stores/MaterialsStore";
import ItemListWidthStore from "../../stores/ItemListWidthStore";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";

function _setState() {
  let width = ItemListWidthStore.getItemListWidth();
  let currentLanguage = ElstrLangStore.getCurrentLanguage();

  return { width, currentLanguage };
}

const ItemListMore = createReactClass({
  mixins: [ItemListWidthStore.mixin, ElstrLangStore.mixin],

  propTypes: {
    displayMore: PropTypes.bool.isRequired,
    numberOfResults: PropTypes.number.isRequired,
  },

  getInitialState: function () {
    return _setState();
  },

  storeDidChange: function () {
    this.setState(_setState());
  },

  shouldComponentUpdate: function (nextProps, nextState) {
    return (
      this.state.width !== nextState.width ||
      this.state.currentLanguage !== nextState.currentLanguage ||
      this.props.displayMore !== nextProps.displayMore ||
      this.props.numberOfResults !== nextProps.numberOfResults
    );
  },

  render: function () {
    let width = this.state.width;

    if (this.props.displayMore && width > 0) {
      let numbOfTotalResults = MaterialsStore.getNumberOfResults();
      let numberOfResults = this.props.numberOfResults;
      let positionText = `${ElstrLangStore.text("ITEM_SHOW_MORE_SHOWING")} ${numberOfResults} ${ElstrLangStore.text(
        "ITEM_SHOW_MORE_FROM",
      )} ${numbOfTotalResults}`;
      let loadMoreText = `${ElstrLangStore.text("ITEM_SHOW_MORE")}`;

      return (
        <article className="item-list load-more" style={{ width: width }} onClick={UrlHelpers.incResultMultip}>
          <div className="btn-filter item-list__load-more-btn">
            <span className="load-more__position">{positionText}</span>
            <span className="load-more__text">
              {loadMoreText}
              <span className="ficon ficon__close btn-filter__close load-more__ficon" />
            </span>
          </div>
        </article>
      );
    } else {
      return <i />;
    }
  },
});

export default ItemListMore;
