import FilterStore from "../stores/FilterStore";

export const ATTRIBUTE_SOURCE = {
  MATERIALS: "materials",
  TRANSLATIONS: "translations",
  CUSTOMERS: "customers",
};

const filtersByKey = {};

export function init() {
  const filters = getFilters();
  filters.forEach(filter => {
    filtersByKey[filter.dataAttributeKey] = filter;
  });
}

function getFilters() {
  return VETROPACK_STATIC_CACHE.filters;
}

/**
 * will return the type of the filter or inactive in case the filter doesn't exist
 * @param dataAttributeKey string
 * @returns {string}
 */
export function getFilterType(dataAttributeKey) {
  let type = "inactive";

  // searchFor gets his type assigned here
  if (dataAttributeKey === "searchFor") {
    return "searchFor";
  }

  if (filtersByKey[dataAttributeKey] && filtersByKey[dataAttributeKey].type) {
    type = filtersByKey[dataAttributeKey].type;
  }

  return type;
}

export function getValuesFrom(dataAttributeKey) {
  if (filtersByKey[dataAttributeKey]) {
    if (filtersByKey[dataAttributeKey].valuesFrom) {
      return filtersByKey[dataAttributeKey].valuesFrom;
    }
  }

  // fallback
  return "";
}

/**
 * @param dataAttributeKey string
 * @returns {string}
 */
export function getAttributeSource(dataAttributeKey) {
  let attributeSource = ATTRIBUTE_SOURCE.MATERIALS;

  if (filtersByKey[dataAttributeKey] && filtersByKey[dataAttributeKey].attributeSource) {
    attributeSource = filtersByKey[dataAttributeKey].attributeSource;
  }

  return attributeSource;
}

export function getTextTwoStep(dataAttributeKey, value, step = 1) {
  const { keyStepOne, keyStepTwo } = filtersByKey[dataAttributeKey];

  if (step === 1) return FilterStore.getFilterText(value, keyStepOne);
  if (step === 2) return FilterStore.getFilterText(value, keyStepTwo);

  return value;
}
