import React from "react";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import * as UrlHelpers from "../../libs/UrlHelpers";
import MaterialsStore from "../../stores/MaterialsStore";
import FilterStore from "../../stores/FilterStore";
import FilterListboxOptions from "./FilterListboxOptions";

const FilterListbox = createReactClass({
  mixins: [MaterialsStore.mixin],

  propTypes: {
    filter: PropTypes.object, // for every filter type
  },

  getInitialState: function () {
    return {
      filter: this.props.filter,
      hash: `filter_${this.props.filter.dataAttributeKey}`,
    };
  },

  storeDidChange: function () {
    this.forceUpdate();
  },

  selectChange: function (filterValueArr, e) {
    let value = e.target.value;
    if (value !== "-") {
      UrlHelpers.setFilterArray(this.state.hash, value, filterValueArr);
    }
  },

  render: function () {
    let dataAttributeKey = this.state.filter.dataAttributeKey;
    let filterValueArr = UrlHelpers.getHashArray(this.state.hash);
    let hash = this.state.hash;
    let { filter } = this.props;

    // fill only content that exists, because of sumsOfAttributes

    // Content for already selected filters
    let selectedFilters = filterValueArr.map(key => {
      // set the text
      let text = FilterStore.getFilterText(key, dataAttributeKey);

      return (
        <div
          key={key}
          className="btn-filter-color"
          onClick={UrlHelpers.setFilterArray.bind(this, hash, key, filterValueArr)}
        >
          {text}
          <span className="ficon ficon__close btn-filter__close" />
        </div>
      );
    });

    // yourSelection will only be shown when there is data to show
    let yourSelection = null;
    if (selectedFilters.length > 0) {
      yourSelection = (
        <span className="filter-block__sub-content">
          <div className="filter-block__sub-title">{ElstrLangStore.text("FILTER_TEXT_YOURSELECTION")}</div>
          {selectedFilters}
        </span>
      );
    }

    return (
      <div className="filter-block__selectbox">
        <select onChange={this.selectChange.bind(this, filterValueArr)} value="-">
          <FilterListboxOptions
            filterValueArr={filterValueArr}
            dataAttributeKey={filter.dataAttributeKey}
            attributeSource={filter.attributeSource}
            valuesFrom={filter.valuesFrom}
          />
        </select>
        {yourSelection}
      </div>
    );
  },
});

export default FilterListbox;
