import React from "react";
import HeaderAddExcel from "./HeaderAddExcel";
import HeaderAddAllResults from "./HeaderAddAllResults";

export default function ContainerHeaderAdd() {
  return (
    <React.Fragment>
      <ul className="view-nav-list view-nav-list__additional">
        <HeaderAddAllResults />
        <HeaderAddExcel />
      </ul>
    </React.Fragment>
  );
}
