import { Link } from "react-router-dom";
import Translations from "../../libs/Translations";
import React from "react";
import AppsStore from "../../stores/AppsStore";
import createReactClass from "create-react-class";
import MetaNavLanguage from "../header/MetaNavLanguage";
import MetaNavCatalogue from "../header/MetaNavCatalogue";
import HeaderMaingroupDesktop from "../header/HeaderMaingroupDesktop";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import SwitchApp from "../header/SwitchApp";
import * as UrlHelpers from "../../libs/UrlHelpers";

const OverlayMenu = createReactClass({
  mixins: [AppsStore.mixin, ElstrLangStore.mixin],

  storeDidChange: function () {
    this.forceUpdate();
  },

  render: function () {
    let classNameCountryOverlay = "menu-overlay__sub-layer menu-overlay-sub-layer--country";
    if (AppsStore.getCatalogueSelectionOpen()) {
      classNameCountryOverlay += " sub-layer-country--open";
    }
    let classNameLanguageOverlay = "menu-overlay__sub-layer menu-overlay-sub-layer--language";
    if (AppsStore.getLanguageSelectionOpen()) {
      classNameLanguageOverlay += " sub-layer-language--open";
    }

    return (
      <div className="menu-overlay desktop--hidden">
        <div className="menu-overlay__close-btn">
          <span className="ficon ficon__close menu-overlay__close-icon" onClick={AppsStore.toggleOverlayOpenMenu} />
        </div>
        <Link to={UrlHelpers.getPathToStartOfCatalogue()}>
          <div className="menu-overlay__home-link" onClick={AppsStore.toggleOverlayOpenMenu}>
            {ElstrLangStore.text("LANG_TEXT_HOME")}
          </div>
        </Link>
        <nav className="menu-overlay__primary-nav">
          <h2 className="visually-hidden">Main navigation</h2>
          <HeaderMaingroupDesktop />
        </nav>
        <nav className="menu-overlay__meta-nav">
          <h2 className="visually-hidden">Meta Navigation</h2>
          <div
            className="menu-overlay__meta-nav-item open-sub-layer-country"
            onClick={AppsStore.toggleCatalogueSelectionOpen}
          >
            <h2 className="visually-hidden">Select Country</h2>
            <div className="menu-overlay-meta-nav__label">{Translations.getLabelCatalogue()}:</div>
            <div className="menu-overlay-meta-nav__content">
              <span className="menu-overlay-meta-nav__current">
                {Translations.getCurrentCatText()}
                <span className="ficon ficon__arrow menu-overlay-meta-nav__icon--arrow" />
              </span>
            </div>
          </div>
          <div className="menu-overlay__meta-nav-item open-sub-layer-language" onClick={AppsStore.toggleLanguageOpen}>
            <div>
              {/*<h2 className="visually-hidden">Select language</h2>*/}
              <div className="menu-overlay-meta-nav__label">{ElstrLangStore.text("LANG_TEXT_LANGUAGE")}</div>
              <div className="menu-overlay-meta-nav__content">
                <span className="menu-overlay-meta-nav__current">
                  {ElstrLangStore.getCurrentLanguage()}
                  <span className="ficon ficon__arrow menu-overlay-meta-nav__icon--arrow" />
                </span>
              </div>
            </div>
          </div>
          <SwitchApp renderedPlace={"overlayMenu"} />
        </nav>
        <div className={classNameLanguageOverlay}>
          <div className="overlay__nav-bar">
            <span className="overlay-nav-bar__title">{ElstrLangStore.text("LANG_TEXT_LANGUAGE")}</span>
            <span className="overlay-nav-bar__right close-sub-layer-language" onClick={AppsStore.toggleLanguageOpen}>
              {ElstrLangStore.text("LANG_TEXT_DONE")}
            </span>
          </div>
          <ul className="sub-layer__list">
            <MetaNavLanguage />
          </ul>
        </div>
        <div className={classNameCountryOverlay}>
          <div className="overlay__nav-bar">
            <span className="overlay-nav-bar__title">{`${Translations.getLabelCatalogue()}:`}</span>
            <span
              className="overlay-nav-bar__right close-sub-layer-country"
              onClick={AppsStore.toggleCatalogueSelectionOpen}
            >
              {ElstrLangStore.text("LANG_TEXT_DONE")}
            </span>
          </div>
          <ul className="sub-layer__list">
            <MetaNavCatalogue />
          </ul>
        </div>
      </div>
    );
  },
});

export default OverlayMenu;
