import DossierInputMeta from "../dossier/DossierInputMeta";
import { addSelectionIdAndSourceToURL } from "../../libs/UrlHelpers";
import React from "react";
import DossierActions from "../../actions/DossierActions";
import createReactClass from "create-react-class";
import BackToResult from "../router/BackToResult";
import SelectionStore from "../../stores/SelectionStore";
import DossierStore from "../../stores/DossierStore";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import ElstrConfigStore from "elstr-jslib/src/scripts/stores/ElstrConfigStore";
import ElstrUrlHashActions from "elstr-jslib/src/scripts/actions/ElstrUrlHashActions";
import { setDocTitle } from "../../libs/Meta";
import Header from "../common/view/Header";
import PhoneNav from "../common/view/PhoneNav";
import StepIndicator from "../common/view/StepIndicator";
import DossierOutputSettings from "./DossierOutputSettings";

const Dossier = createReactClass({
  mixins: [SelectionStore.mixin, ElstrUrlHashStore.mixin, DossierStore.mixin, ElstrLangStore.mixin],

  statics: {
    getDerivedStateFromProps(newProps) {
      ElstrUrlHashActions.setRouteParams(newProps.location.pathname, newProps.match.params);
      return null;
    },
  },

  _setState: function () {
    let lastNumbOfMaterials = this.state.lastNumbOfMaterials;
    let numbOfMaterials = SelectionStore.getSortedRenderedMaterials().length;

    // this change can occur when a user has a tab open with this view
    // and in another tab he adds / removes materials (or someone else who is editing this selection)
    if (lastNumbOfMaterials !== numbOfMaterials) {
      lastNumbOfMaterials = numbOfMaterials;
      DossierActions.initOnDb();
    }

    return { lastNumbOfMaterials };
  },

  getInitialState: function () {
    return { lastNumbOfMaterials: 0 };
  },

  storeDidChange: function () {
    this.setState(this._setState());
  },

  componentDidMount: function () {
    addSelectionIdAndSourceToURL();
    window.scrollTo(0, 0);
  },

  isHidden: function () {
    if (DossierStore.getActiveStep() !== 2) {
      return "hide-step";
    }

    return "";
  },

  render: function () {
    let isPisysSite = ElstrConfigStore.option("isPisysSite");

    let titleText = ElstrLangStore.text("DOSSIER_TITLE");
    let materialsToRender = SelectionStore.getSortedRenderedMaterials();

    // Hide the body when there are no selectedMaterials and change the title
    let visibility = "visible";
    if (materialsToRender.length <= 0) {
      visibility = "hidden";
      titleText = ElstrLangStore.text("SELECTION_IS_EMPTY");
    }

    // Set title for selection
    let preTitle = ElstrLangStore.text("TITLE__EKATALOG");
    if (isPisysSite) preTitle = ElstrLangStore.text("TITLE__PISYS");
    setDocTitle(`${preTitle} ${titleText}`);

    DossierStore.initMissingDocs();

    return (
      <React.Fragment>
        <BackToResult />
        <div className="common-page">
          <div className="common-page__container">
            <Header />
            <PhoneNav />
            <div className="common-page__body">
              <div className="common-page-body__container">
                <div className="common-page-body__header phone--hidden" />
                <div style={{ visibility: visibility }}>
                  <DossierInputMeta />
                  <StepIndicator />
                  <DossierOutputSettings />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="subpage-modal-overlay" />
      </React.Fragment>
    );
  },
});

export default Dossier;
