import React from "react";
import createReactClass from "create-react-class";
import * as UrlHelpers from "../../libs/UrlHelpers";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";

const FilterSetEcat = createReactClass({
  render: function () {
    return (
      <div className="filter-block__additional-filters--pisys">
        <div onClick={UrlHelpers.setEcatFilters.bind(this, "set")} className="additional-filter--pisys">
          {ElstrLangStore.text("FILTER_SET_ECAT_FILTERS")}
        </div>
        <div onClick={UrlHelpers.setEcatFilters.bind(this, "unset")} className="additional-filter--pisys">
          {ElstrLangStore.text("FILTER_UNSET_ECAT_FILTERS")}
        </div>
      </div>
    );
  },
});

export default FilterSetEcat;
