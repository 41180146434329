import Translations from "../../libs/Translations";
import React from "react";
import * as Attributes from "../../libs/Attributes";
import { isPisysSite } from "../../libs/Controllers";
import DokarToggle from "../dossier/DokarToggle";
import DossierStore from "../../stores/DossierStore";
import * as UrlHelpers from "../../libs/UrlHelpers";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import AppsStore from "../../stores/AppsStore";
import ItemListOverlaySettings from "./ItemListOverlaySettings";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";

const ItemCompareLabel = createReactClass({
  propTypes: {
    attributesSorted: PropTypes.array.isRequired,
  },

  handleSettingsMain: e => {
    if (isPisysSite()) {
      AppsStore.toggleOverlaySettingsOpen(e);
    }
  },

  handleSettingsPackaging: e => {
    if (isPisysSite()) {
      AppsStore.toggleOverlaySettingsOpenPackagingDossier(e);
    }
  },

  includeLabelForAttributes: (itemCompareLabelContent, attributesSorted) => {
    attributesSorted.forEach((attribute, i) => {
      if (attribute["compareDisplayDefault"] === true && attribute["compareDisplayAllowed"] === true) {
        let dataAttributeKey = attribute["dataAttributeKey"];

        let classNameRow = "item-compare-label__row";

        // add double lines if configured
        if (attribute["compareDisplaySize"] === 2) classNameRow += " double-lines";

        // one additional className for color
        if (dataAttributeKey === "sMatAtvFarbe") classNameRow += " item-compare-label__row--colors";

        // textName before changing dataAttributeKey
        let textName = Translations.getLabel(dataAttributeKey);

        dataAttributeKey = Attributes.getRealDataAttributeKey(dataAttributeKey);

        itemCompareLabelContent.push(
          <div className={classNameRow} key={dataAttributeKey + i}>
            <span className="item-compare-label__title">{textName}</span>
          </div>,
        );
      }
    });

    return itemCompareLabelContent;
  },

  render: function () {
    let attributesSorted = this.props.attributesSorted;

    let itemCompareLabelContent = [];
    // if the site is not pisys, the leading attribute is 'compareDisplayDefault' / in PisysSite it's 'listDisplayDefault'

    if (UrlHelpers.isViewDossier()) {
      itemCompareLabelContent.push(
        <div className="item-compare-label__row common__row dossier-settings" key="attributes selection">
          <div className="item-list__label-col-setting item-list__label-col-setting__dossier dossier-settings-icon">
            <span
              className={`ficon ficon__setting item-list__setting-icon ${isPisysSite() ? "" : "hide"}`}
              onClick={this.handleSettingsMain}
            />
            <ItemListOverlaySettings type={Attributes.TYPES.mainDossier} />
          </div>
          <span className="item-compare-label__title">
            {isPisysSite() ? ElstrLangStore.text("DOSSIER_ATTRIBUTES_MAIN") : ""}
          </span>
        </div>,
      );
    }

    itemCompareLabelContent = this.includeLabelForAttributes(itemCompareLabelContent, attributesSorted);

    if (UrlHelpers.isViewDossier()) {
      itemCompareLabelContent.push(
        <div
          className="item-compare-label__row common__row dossier-settings packaging-settings"
          key="packaging attributes selection"
        >
          <div className="item-list__label-col-setting item-list__label-col-setting__dossier dossier-settings-icon">
            <span
              className={`ficon ficon__setting item-list__setting-icon ${isPisysSite() ? "" : "hide"}`}
              onClick={this.handleSettingsPackaging}
            />
            <ItemListOverlaySettings type={Attributes.TYPES.packagingDossier} />
          </div>
          <span className="item-compare-label__title">
            {" "}
            {isPisysSite() ? ElstrLangStore.text("DOSSIER_ATTRIBUTES_PACKAGING") : ""}
          </span>
        </div>,
      );

      if (isPisysSite()) {
        let packagingAttributesSorted = Attributes.getAttributesPackagingDossierSorted();
        itemCompareLabelContent = this.includeLabelForAttributes(itemCompareLabelContent, packagingAttributesSorted);
      }

      itemCompareLabelContent.push(
        <div className="item-compare-label__row" key="add docs">
          <span className="item-compare-label__title">{ElstrLangStore.text("DOSSIER_DOCUMENT_SELECTION")}</span>
        </div>,
      );

      if (isPisysSite() && DossierStore.getAllDocsAreLoaded()) {
        itemCompareLabelContent.push(<DokarToggle key="documents toggle" />);
      }
    }

    // overwrite when print-label
    if (UrlHelpers.isViewPrintLabel()) {
      const translation = ElstrLangStore.text("PRINT_LABEL_NUMBER_TO_PRINT");

      itemCompareLabelContent = (
        <div className="item-compare-label__row">
          <span className="item-compare-label__title">{translation}</span>
        </div>
      );
    }

    let className = `item-compare-label-col ${UrlHelpers.isViewDossier() ? `dossier-compare-label-col` : ``}`;

    return <div className={className}>{itemCompareLabelContent}</div>;
  },
});

export default ItemCompareLabel;
