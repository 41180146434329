import * as Attributes from "./Attributes";
import { MAIN_VIEW as MV } from "../constants/MainView";
import Filesaver from "file-saver";
import MaterialsStore from "../stores/MaterialsStore";
import * as UrlHelpers from "./UrlHelpers";
import SelectionStore from "../stores/SelectionStore";

// dynamic load of xlsx file because it has a size around 1 MB
const script = document.createElement("script");
script.type = "text/javascript";
script.src = "https://unpkg.com/xlsx@0.14.0/dist/xlsx.full.min.js";
document.getElementsByTagName("head")[0].appendChild(script);
script.onerror = loadFromOwnServer;

function loadFromOwnServer() {
  const backup = document.createElement("script");
  backup.type = "text/javascript";
  backup.src = "scripts/xlsx.full.min.js";
  document.getElementsByTagName("head")[0].appendChild(backup);
}

const XlsxExport = {
  exportMaterials: () => {
    let wb = {
      SheetNames: [],
      Sheets: {},
    };

    let mainView = UrlHelpers.getMainView();

    let ws_name = mainView;
    // CREATE THE WORKSHEET DATA ARRAY //
    let ws_data = [];

    let attributesSorted = Attributes.getAttributesSortedAndByMainView();

    let headers = [];

    // CREATE THE HEADER DATA //
    attributesSorted.forEach(attribute => {
      if (attribute.listDisplayDefault === true && attribute.listDisplayAllowed === true) {
        headers.push(MaterialsStore.getHeaderTextWithUnit(attribute["dataAttributeKey"], attribute["unit"]));
      }
    });
    ws_data.push(headers);

    // PUSH ROWS TO WORKSHEET //
    let materialsToAdd = [];
    if (mainView === MV.RESULT) {
      materialsToAdd = MaterialsStore.getFilteredMaterialsResultNotSpliced();
    }
    if (mainView === MV.SELECTION) {
      materialsToAdd = SelectionStore.getMaterialsToRender();
    }

    materialsToAdd.forEach(material => {
      let column = [];
      attributesSorted.forEach(attribute => {
        if (attribute.listDisplayDefault === true && attribute.listDisplayAllowed === true) {
          let dataAttributeKey = Attributes.getRealDataAttributeKey(attribute["dataAttributeKey"]);

          // fill valueColumn content
          // unit will be provided as an empty string here so the rest of the logic
          // can remain the same
          let valueColumn = MaterialsStore.getValueColumn(dataAttributeKey, material[dataAttributeKey], false);

          column.push(valueColumn);
        }
      });
      ws_data.push(column);
    });

    let ws = XLSX.utils.aoa_to_sheet(ws_data);

    /* Add the sheet name to the list */
    wb.SheetNames.push(ws_name);

    /* Load the worksheet object */
    wb.Sheets[ws_name] = ws;

    let wopts = { bookType: "xlsx", bookSST: false, type: "array" };

    let wbout = XLSX.write(wb, wopts);

    // let filename = `${mainView}_${Date.now()}.xlsx`;
    let filename = `${mainView}_${new Date()}.xlsx`;

    /* the saveAs call downloads a file on the local machine */
    Filesaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), filename);
  },
};

export default XlsxExport;
