import { MAIN_VIEW as MV } from "../../constants/MainView";
import React from "react";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import ItemGrid from "./ItemGrid";
import ItemGridMore from "./ItemGridMore";
import ItemGridScroll from "./ItemGridScroll";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import * as UrlHelpers from "../../libs/UrlHelpers";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";

const ContainerGrid = createReactClass({
  propTypes: {
    materialsToRender: PropTypes.array.isRequired,
    displayMore: PropTypes.bool,
    scrollActive: PropTypes.bool.isRequired,
  },

  componentDidMount: function () {
    let mainView = UrlHelpers.getMainView();

    if (mainView === MV.RESULT) {
      let currentLanguage = ElstrLangStore.getCurrentLanguage();
      window._paq.push(["trackPageView", "Katalog Grid ( " + currentLanguage + " )"]);
    } else if (mainView === MV.SELECTION) {
      let selectionId = ElstrUrlHashStore.get("selectionId");
      window._paq.push(["trackPageView", "mySelection Grid ( " + selectionId + " )"]);
    }
  },

  render: function () {
    const grids = this.props.materialsToRender.map(material => {
      return <ItemGrid key={material.sMatHdrMatnr} material={material} />;
    });

    return (
      <React.Fragment key="ContainerGrid">
        {grids}
        <ItemGridMore
          displayMore={this.props.displayMore || false}
          numberOfResults={this.props.materialsToRender.length}
        />
        <ItemGridScroll scrollActive={this.props.scrollActive} />
      </React.Fragment>
    );
  },
});

export default ContainerGrid;
