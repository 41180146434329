import React, { useEffect, useState } from "react";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";
import * as UrlHelpers from "../../libs/UrlHelpers";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import DatePicker from "react-date-picker";
import FilterStore from "../../stores/FilterStore";
import AppsStore from "../../stores/AppsStore";

export const TYPE_DATE_RANGE = "TYPE_DATE_RANGE";
export const TYPE_TWO_DATE_PICKER = "TYPE_TWO_DATE_PICKER";

const FilterDate = ({ type }) => {
  // CASE: TYPE_DATE_RANGE
  // dates is used in conjunction with the library component DateRangePicker
  // as far as tested it can be in those four states:
  // 1) null -> no dates are set
  // 2) [Date, undefined] -> only from is set
  // 3) [null, Date] -> only to is set
  // 4) [Date, Date] -> from and to are set
  const [dates, setDates] = useState(null);
  // CASE: TYPE_TWO_DATE_PICKER
  // dateFrom and dateTo are used with react-date-picker
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  function updateDateFrom(newDate) {
    // set or clear from in hash url, will trigger useEffect change
    if (!newDate) {
      UrlHelpers.clearSingleFilter("dateFrom");
      return;
    }

    UrlHelpers.setFilterValue("filter_dateFrom", FilterStore.parseDateToISO8601(newDate));
  }

  function updateDateTo(newDate) {
    // set or clear from in hash url, will trigger useEffect change
    if (!newDate) {
      UrlHelpers.clearSingleFilter("dateTo");
      return;
    }

    UrlHelpers.setFilterValue("filter_dateTo", FilterStore.parseDateToISO8601(newDate));
  }

  function adjustUrlToChangeRange(newDates) {
    // set or clear from in hash url, will trigger useEffect change
    if (!newDates) {
      UrlHelpers.clearSingleFilter("dateFrom");
      UrlHelpers.clearSingleFilter("dateTo");
      return;
    }

    // case when date to and date from are set
    if (newDates[0] && newDates[1]) {
      UrlHelpers.setHashesNoHistory({
        filter_dateFrom: FilterStore.parseDateToISO8601(newDates[0]),
        filter_dateTo: FilterStore.parseDateToISO8601(newDates[1]),
      });
      return;
    }

    // case when date from but not date to is set
    if (newDates[0]) {
      UrlHelpers.setFilterValue("filter_dateFrom", FilterStore.parseDateToISO8601(newDates[0]));
    }

    // case when date from to not date from is set
    if (newDates[1]) {
      UrlHelpers.setFilterValue("filter_dateTo", FilterStore.parseDateToISO8601(newDates[1]));
    }
  }

  function parseUrlToDate(urlDate) {
    const dateParts = urlDate.split("-");
    return new Date(`${dateParts[0]}-${dateParts[1]}-${dateParts[2]}`);
  }

  function adjustStateAccordingToUrl() {
    const dateFrom = ElstrUrlHashStore.get("filter_dateFrom");
    const dateTo = ElstrUrlHashStore.get("filter_dateTo");

    if (!dateFrom && !dateTo) {
      setDates(null);
      setDateFrom(null);
      setDateTo(null);
    }

    if (dateFrom && !dateTo) {
      setDates([parseUrlToDate(dateFrom), undefined]);
      setDateFrom(parseUrlToDate(dateFrom));
      setDateTo(null);
    }

    if (!dateFrom && dateTo) {
      setDates([null, parseUrlToDate(dateTo)]);
      setDateFrom(null);
      setDateTo(parseUrlToDate(dateTo));
    }

    if (dateFrom && dateTo) {
      setDates([parseUrlToDate(dateFrom), parseUrlToDate(dateTo)]);
      setDateFrom(parseUrlToDate(dateFrom));
      setDateTo(parseUrlToDate(dateTo));
    }
  }

  useEffect(() => {
    adjustStateAccordingToUrl();
  }, [ElstrUrlHashStore.get("filter_dateFrom"), ElstrUrlHashStore.get("filter_dateTo")]);

  const isMobileLayout = AppsStore.getIsMobileLayoutView();

  if (type === TYPE_TWO_DATE_PICKER) {
    return (
      <React.Fragment>
        <h2 className="filter-block__title filter-block__title__date">{ElstrLangStore.text("FILTER_DATE_RANGE")}</h2>

        <div className="filter-block__content">
          <div className="filter-block__search-box search-box__field-wrap">
            <div className="two-date-range-picker">
              <div className={`date-from${isMobileLayout ? "__mobile" : ""}`}>
                <DatePicker
                  onChange={updateDateFrom}
                  locale={ElstrLangStore.getCurrentLanguage()}
                  format={"d/M/y"}
                  value={dateFrom}
                  clearIcon={null}
                  calendarIcon={null}
                  showLeadingZeros={false}
                />
              </div>
              <div className="date-separator">—</div>
              <div className={`date-to${isMobileLayout ? "__mobile" : ""}`}>
                <DatePicker
                  onChange={updateDateTo}
                  locale={ElstrLangStore.getCurrentLanguage()}
                  format={"d/M/y"}
                  value={dateTo}
                  clearIcon={null}
                  calendarIcon={null}
                  showLeadingZeros={false}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  if (type === TYPE_DATE_RANGE) {
    return (
      <React.Fragment>
        <h2 className="filter-block__title filter-block__title__date">{ElstrLangStore.text("FILTER_DATE_RANGE")}</h2>

        <div className="filter-block__content">
          <div className="filter-block__search-box search-box__field-wrap">
            <DateRangePicker
              onChange={adjustUrlToChangeRange}
              locale={ElstrLangStore.getCurrentLanguage()}
              value={dates}
              format={"d/M/y"}
              clearIcon={null}
              calendarIcon={null}
              showLeadingZeros={false}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }

  return null;
};

export default FilterDate;
