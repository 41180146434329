import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";

const Translations = {
  getLabelCatalogue: function () {
    return Translations.getLabel("sMatAtvKatalog");
  },

  getCurrentCatText: function () {
    let currentCat = ElstrUrlHashStore.getRouteParam("catalogue");
    return currentCat !== "global" && currentCat
      ? Translations.getValue("sMatAtvKatalog", currentCat)
      : ElstrLangStore.text("GLOBAL_CATALOGUE");
  },

  getTranslations: () => {
    return VETROPACK_STATIC_CACHE.translations;
  },

  getLabel: key => {
    if (!key) return null;

    const currentLang = ElstrLangStore.getCurrentLanguage();
    const translations = Translations.getTranslations();

    if (!translations[key]) return null;
    if (!translations[key].label) return null;
    if (!translations[key].label[currentLang]) return null;

    return translations[key].label[currentLang];
  },

  getLabelDetailColumn: key => {
    // For the Label in Detail we want to remove prependLabelTranslation from Attributes to shorten the column
    const toReplace = `${ElstrLangStore.text("DETAIL_TAB_COMPLAINTS")} `;
    const label = Translations.getLabel(key) ? Translations.getLabel(key) : "";
    return label.replace(toReplace, "");
  },

  getValue: (key, value) => {
    if (!key) return null;
    if (!value) return null;
    const currentLang = ElstrLangStore.getCurrentLanguage();
    const translations = Translations.getTranslations();
    if (!translations[key]) return null;
    if (!translations[key].values) return null;
    if (!translations[key].values[value]) return null;
    if (!translations[key].values[value][currentLang]) return null;

    return translations[key].values[value][currentLang];
  },

  getValues: key => {
    if (!key) return null;
    const translations = Translations.getTranslations();
    if (!translations[key]) return null;
    if (!translations[key].values) return null;

    return translations[key].values;
  },

  getFuellVollAndMuendung: material => {
    let textFuellvoll = `${material["sMatAtvFuellvoll"]} ml`; // ex: 1030 ml

    const muendungsgruppe = Translations.getValue("sMatAtvMuendungsgruppe", material["sMatAtvMuendungsgruppe"]);
    const textMuendungsgruppe = !muendungsgruppe ? "" : muendungsgruppe;

    return `${textFuellvoll}, ${textMuendungsgruppe}`;
  },

  getItemTitle: material => {
    let currentLanguage = ElstrLangStore.getCurrentLanguage();
    let translKey = `sMatHdrTdline__${currentLanguage}`; // ex: sMatHdrTdline__de
    let itemTitle = material[translKey];

    return itemTitle;
  },

  getTextArtNrAndMatnr: sMatHdrMatnr => {
    let textArtNr = ElstrLangStore.text("GRID_TEXT_ARTIKEL_NUMBER"); // ex: Art-Nr.
    let textArtNrAndMatnr = `${textArtNr} ${sMatHdrMatnr}`; // ex: Art-Nr. 10049

    return textArtNrAndMatnr;
  },

  getNoLabelFor: key => `no label defined for ${key}`,
};

export default Translations;
