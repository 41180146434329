import Translations from "../../libs/Translations";
import React from "react";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";
import MaterialsStore from "../../stores/MaterialsStore";
import Controllers, { isPisysSite } from "../../libs/Controllers";

export default function DetailMainHeader() {
  const material = MaterialsStore.getMaterialByNumberOrDefault();
  const itemTitle = Translations.getItemTitle(material);

  // textArtNrAndMatnr
  const sMatHdrMatnr = ElstrUrlHashStore.getRouteParam("sMatHdrMatnr");
  const textArtNrAndMatnr = Translations.getTextArtNrAndMatnr(sMatHdrMatnr);

  let techNo = "";
  if (isPisysSite()) {
    const key = "sMatHdrZeinr";
    techNo = `${Translations.getLabel(key)} ${material[key]}`;
  }

  // in case the sMatAtvPublikationscode is W, an additional text gets applied (according to FSP)
  let textRestricted = "";
  if (material["sMatAtvPublikationscode"] === "W") textRestricted = ElstrLangStore.text("DETAIL_SOLD_RESTRICTED_ONLY");

  let inactiveClass = "";
  if (Controllers.isMsteaInactive(material["sMatHdrMstae"])) inactiveClass = "blackAndDashed";

  return (
    <div className="detail-main__header">
      <h1 itemProp="name" className={`detail-main-header__title ${inactiveClass}`}>
        {itemTitle}
      </h1>
      <div itemProp="productID" content={sMatHdrMatnr}>
        <div className="detail-main-header__key-info detail-main-header-matnr">{textArtNrAndMatnr}</div>
        <span className="detail-main-header__key-info mobile--hidden">{techNo}</span>
      </div>
      <span className="detail-main-header__key-info desktop--hidden">{techNo}</span>
      {textRestricted && (
        <span className="detail-main-header__key-info" style={{ color: "#ff0000 " }}>
          {textRestricted}
        </span>
      )}
    </div>
  );
}
