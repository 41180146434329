import React from "react";
import createReactClass from "create-react-class";
import * as UrlHelpers from "../../libs/UrlHelpers";

const DetailPageStack = createReactClass({
  render: () => {
    // decide if the stacking page should appear
    let stacked = UrlHelpers.getStacked();

    if (stacked) {
      return <div className="detail-page__stack" key="stacking-detail" />;
    } else {
      return <i key="no stacking detail" />;
    }
  },
});

export default DetailPageStack;
