import mcFly from "elstr-jslib/src/scripts/libs/mcFly.js";
import ElstrEditingStates from "elstr-jslib/src/scripts/constants/ElstrEditingStates";
import ElstrLoadingStates from "elstr-jslib/src/scripts/constants/ElstrLoadingStates";
import ElstrUrlHashConstants from "elstr-jslib/src/scripts/constants/ElstrUrlHashConstants";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";

const TouchStore = mcFly.createStore(
  {
    showColorsOverlayForMaterial: null,

    error: null,
    loadingState: ElstrLoadingStates.EMPTY,
    editingState: ElstrEditingStates.NORMAL,

    /** GETTERS **/
    getLoadingState: function () {
      return this.loadingState;
    },

    getEditingState: function () {
      return this.editingState;
    },

    getShowColorsOverlayForMaterial: function () {
      return TouchStore.showColorsOverlayForMaterial;
    },

    setShowColorsOverlayForMaterial: function (sMatHdrMatnr) {
      // set the material Number
      // if sMatHdrMatner === null means the overlay is reset
      TouchStore.showColorsOverlayForMaterial = sMatHdrMatnr;
      TouchStore.emitChange();
    },

    // DECIDES IF THE OVERLAY SHOULD BE RESETTED
    handleReset: () => {
      if (TouchStore.getShowColorsOverlayForMaterial() !== null) {
        TouchStore.setShowColorsOverlayForMaterial(null);
      }
    },
  },
  function (payload) {
    switch (payload.actionType) {
      case ElstrUrlHashConstants.URL_HASH_CHANGE: {
        mcFly.dispatcher.waitFor([ElstrUrlHashStore.dispatcherID]);
        TouchStore.handleReset();

        break;
      }

      case ElstrUrlHashConstants.URL_ROUTE_CHANGE: {
        mcFly.dispatcher.waitFor([ElstrUrlHashStore.dispatcherID]);
        TouchStore.handleReset();

        break;
      }
    }
    return true;
  },
);

export default TouchStore;
