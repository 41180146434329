import React from "react";

const BeaconPoints = props => (
  <svg data-name="Beacon Points" width="2em" height="2em" viewBox="0 0 6 28" {...props}>
    <circle cx={3} cy={3} r={3} fill="#d8d8d8" />
    <circle data-name="Oval Copy" cx={3} cy={3} r={3} transform="translate(0 11)" fill="#d8d8d8" />
    <circle data-name="Beacon Points" cx={3} cy={3} r={3} transform="translate(0 22)" fill="#d8d8d8" />
  </svg>
);

export default BeaconPoints;
