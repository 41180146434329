import React from "react";
import createReactClass from "create-react-class";
import * as UrlHelpers from "../../libs/UrlHelpers";
import MaterialsStore from "../../stores/MaterialsStore";
import AppsStore from "../../stores/AppsStore";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";
import ElstrConfigStore from "elstr-jslib/src/scripts/stores/ElstrConfigStore";

const HeaderStickySearchBox = createReactClass({
  searchbox: React.createRef(),

  counter: 0,

  mixins: [ElstrLangStore.mixin, ElstrUrlHashStore.mixin],

  getInitialState: function () {
    return {
      autocompleteMaterials: [],
      input: "",
    };
  },

  storeDidChange: function () {
    let searchVal = ElstrUrlHashStore.get("filter_searchFor") || "";

    this.setState({
      input: String(searchVal), // take url value or empty
      active: false, // close window
      mouseIsOverAutocomplete: false, // close window
      selectedItemNr: -1, // set to initial
    });
  },

  componentDidMount: function () {
    let autocompleteMaterials = [];
    let input = ElstrUrlHashStore.get("filter_searchFor") || "";
    let active = false;
    let mouseIsOverAutocomplete = false;

    // turn autoFocus on for desktop client while off for mobile client
    let autoFocus = !AppsStore.getIsMobileClient();

    this.setState({
      autocompleteMaterials, // the materials that will appear in for the autocomplete suggestions
      input, // the input value
      active, // true when the input field is selected
      mouseIsOverAutocomplete, // true when mouse is over the autocomplete suggestions
      selectedItemNr: -1, // which item will be displayed as selected
      autoFocus,
    });
  },

  handleOnSubmit: function (e) {
    e.preventDefault();

    let selectedItemNr = this.state.selectedItemNr;

    /** take the selectedItemNr for >= 0
     * or the value that the user has written for -1
     */
    let searchVal = null;
    if (selectedItemNr >= 0) {
      const id = `autocomplete__item-${selectedItemNr}`;
      searchVal = document.getElementById(id).value;
    } else if (selectedItemNr < 0) {
      searchVal = this.searchbox.current.value;
    }

    // value from the <li> items in autocomplete are numbers
    searchVal = String(searchVal);

    /** apply value or remove the filter if there is no valid value **/
    // if (searchVal.length > 0 || !isNaN(searchVal)) {
    if (searchVal.length > 0) {
      UrlHelpers.setFilterValue("filter_searchFor", searchVal);
    } else {
      UrlHelpers.clearSingleFilter("searchFor");
    }

    // used to hide the keyboard for mobile devices
    document.activeElement.blur();
  },

  handleOnChange: function () {
    const searchVal = this.searchbox.current.value || "";

    let autocompleteMaterials = [];

    if (searchVal !== "") {
      // first get initial materials to appear in the autocomplete box
      autocompleteMaterials = MaterialsStore.getFilteredMainMaterials();

      // now filter with empty filters
      // tmp disabled until proofed to be correct
      // autocompleteMaterials = MaterialsStore.filterEmptyFilterSettings(autocompleteMaterials);

      // now filter with the input of the filter search for box
      autocompleteMaterials = MaterialsStore.filterSearchFor(searchVal, autocompleteMaterials);

      // limit to 10 items
      if (autocompleteMaterials.length > 10) {
        autocompleteMaterials.splice(10);
      }
    }

    this.setState({
      autocompleteMaterials,
      input: String(searchVal),
      active: true,
      selectedItemNr: -1,
    });
  },

  handleOnBlur: function () {
    // only set to false when the mouse is not over the autocomplete items
    // this is to prevent that active: false will set when useing onClick on an autocomplete item
    if (this.state.mouseIsOverAutocomplete === false) {
      this.setState({ active: false });
    }
  },

  handleOnFocus: function () {
    this.handleOnChange();
  },

  handleOnMouseOver: function () {
    this.setState({ mouseIsOverAutocomplete: true });
  },

  handleOnMouseOut: function () {
    this.setState({
      mouseIsOverAutocomplete: false,
      selectedItemNr: -1,
    });
  },

  setSelectedItemNr: function (index) {
    this.setState({ selectedItemNr: index });
  },

  strReplace: function (string, start, end, word) {
    return string.replace(new RegExp("^(.{" + start + "}).{" + (end - start) + "}"), "$1" + word);
  },

  handleOnKeyDown: function (e) {
    // ARROW UP
    if (e.keyCode === 38) {
      this.setState(function (prevState) {
        let selectedItemNr = -1;
        if (prevState.selectedItemNr >= 0) {
          selectedItemNr = prevState.selectedItemNr - 1;
        }
        return { selectedItemNr };
      });
      // ARROW DOWN
    } else if (e.keyCode === 40) {
      this.setState(function (prevState) {
        let selectedItemNr = prevState.selectedItemNr + 1;
        if (selectedItemNr > prevState.autocompleteMaterials.length - 1) {
          selectedItemNr = prevState.autocompleteMaterials.length - 1;
        }
        return { selectedItemNr };
      });
    }
  },

  render: function () {
    let autocompleteMaterials = this.state.autocompleteMaterials;
    let displayAutocomplete = "none";
    let currentLanguage = ElstrLangStore.getCurrentLanguage();
    let classNameSelected = "autocomplete__item-selected";

    let autocompleteContent = [];
    if (autocompleteMaterials && autocompleteMaterials.length > 0) {
      displayAutocomplete = "block";
      let sMatHdrTdline__xx = `sMatHdrTdline__${currentLanguage}`; // apply the lang

      autocompleteMaterials.forEach((material, i) => {
        let selected = "";
        if (this.state.selectedItemNr === i) selected = classNameSelected;

        let sMatHdrMatnr = material.sMatHdrMatnr;
        let sMatHdrTdline = material[sMatHdrTdline__xx];
        let sMatHdrZeinr = "";
        // pisys will show additional attribute sMatHdrZeinr
        if (ElstrConfigStore.option("isPisysSite")) {
          sMatHdrZeinr = ` - ${material.sMatHdrZeinr}`;
        }

        autocompleteContent.push(
          <li
            className={`autocomplete__item ${selected}`}
            key={i}
            id={`autocomplete__item-${i}`}
            value={material.sMatHdrMatnr}
            onClick={this.handleOnSubmit}
            onMouseOver={this.setSelectedItemNr.bind(this, i)}
          >
            {`${sMatHdrMatnr} - ${sMatHdrTdline}${sMatHdrZeinr}`}
          </li>,
        );
      });
    }

    /** different overwrite possibilities for hiding the autocomplete box **/
    if (autocompleteMaterials.length < 1) displayAutocomplete = "none"; // don't display when there are no items to display
    if (this.state.input === "") displayAutocomplete = "none"; // don't display when input field is empty
    if (this.state.active === false) displayAutocomplete = "none"; // don't display when field is not active

    /** this will ensure that autoFocus will be true in the initial loading of the app and than never again **/
    let autoFocus;
    if (this.counter > 1) {
      this.state.autoFocus = false;
    }
    autoFocus = this.state.autoFocus;
    this.counter += 1;

    return (
      <div className="search-box" key="searchboxdiv" onSubmit={this.handleOnSubmit}>
        <h3 className="search-box__title">{ElstrLangStore.text("HEADER_STICKY_SEARCH")}</h3>
        <form action="post">
          <div className="search-box__field-wrap">
            <input
              type="text"
              ref={this.searchbox}
              placeholder={ElstrLangStore.text("HEADER_STICKY_PLACEHOLDER_SEARCHBOX")}
              autoComplete="off"
              className="search-box__field"
              onChange={this.handleOnChange}
              autoFocus={autoFocus}
              onBlur={this.handleOnBlur}
              onFocus={this.handleOnFocus}
              onInput={this.handleOnChange}
              onKeyDown={this.handleOnKeyDown}
              onMouseUp={this.handleOnChange}
              value={this.state.input}
            />
            <span className="ficon ficon__lupe search-box__icon-lupe" onClick={this.handleOnSubmit} />
            <div
              className="autocomplete"
              style={{ display: displayAutocomplete }}
              onMouseOver={this.handleOnMouseOver}
              onMouseOut={this.handleOnMouseOut}
            >
              <ul>{autocompleteContent}</ul>
            </div>
          </div>
        </form>
      </div>
    );
  },
});

export default HeaderStickySearchBox;
