import React from "react";
import { navigateBackOrToResult } from "../../../libs/UrlHelpers";
import { Link } from "react-router-dom";
import RouteHistoryStore from "../../../stores/RouteHistoryStore";
import { getTitle } from "../../../libs/Title";

export default function PhoneNav() {
  return (
    <div className="common-page__nav-bar tablet-desktop--hidden">
      <Link to={RouteHistoryStore.getBackToResultPath()} className="common-page-nav-bar__left">
        <span className="ficon ficon__arrow-back" />
      </Link>
      <span className="common-page-nav-bar__title">{getTitle()}</span>
      <span className="common-page-nav-bar__right" onClick={navigateBackOrToResult}>
        <span className="ficon ficon__close-thin" />
      </span>
    </div>
  );
}
