import React from "react";
import PropTypes from "prop-types";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import Step from "../svg/DossierStep";
import DossierStore from "../../stores/DossierStore";
import SelectionStore from "../../stores/SelectionStore";

export const DossierContainerTop = ({ step }) => {
  return (
    <div className="container-common container-common__top">
      <div className="container-common-content">
        <div className="container-common-content-placer">{getContent()}</div>
      </div>
    </div>
  );

  function getFill() {
    // default blue
    let fill = "#0061ad";

    // grey for inactive
    if (step !== DossierStore.getActiveStep()) {
      fill = "#d8d8d8";
    }

    return fill;
  }

  function getContent() {
    let content = null;

    if (step === 1) {
      content = (
        <h2>
          <Step fill={getFill()} step={step} className="dossier-step" />
          <Text>{ElstrLangStore.text("DOSSIER_COVER")}</Text>
        </h2>
      );
    }

    if (step === 2) {
      content = [];
      content.push(
        <h2 key="1">
          <Step fill={getFill()} step={step} className="dossier-step" />
          <Text>{ElstrLangStore.text("DOSSIER_ATTRIBUTES_DOCUMENT_SELECTION")}</Text>
        </h2>,
      );
      content.push(
        <span className="dossier-materials-counter" key="2">
          {SelectionStore.getRenderedMaterialNumbersAsList().length}
        </span>,
      );
    }

    return content;
  }
};

function Text({ children }) {
  return <span className="hide-text-phone">{children}</span>;
}

DossierContainerTop.propTypes = {
  step: PropTypes.number.isRequired,
};
