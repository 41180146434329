import * as Attributes from "../../libs/Attributes";
import Translations from "../../libs/Translations";
import Controllers from "../../libs/Controllers";
import React from "react";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import FilterListbox from "./FilterListbox";
import FilterAdditional from "./FilterAdditional";
import FilterSlider from "./FilterSlider";
import FilterCheckbox from "./FilterCheckbox";
import FilterListboxTwoStep from "./FilterListboxTwoStep";
import FilterRadiobox from "./FilterRadiobox";
import FilterColor from "./FilterColor";
import FilterSearchbox from "./FilterSearchbox";
import FilterSetEcat from "./FilterSetEcat";
import FilterStore from "../../stores/FilterStore";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";
import FilterDate, { TYPE_TWO_DATE_PICKER } from "./FilterDate";
import { FILTER_TYPE } from "../../constants/Filter";

const FilterBlock = createReactClass({
  mixins: [FilterStore.mixin, ElstrUrlHashStore.mixin],

  storeDidChange: function () {
    this.forceUpdate();
  },

  propTypes: {
    filter: PropTypes.object, // used for filter values, exception is additional filter (meaning the block that surrounds all additional filters which are not display. found at the bottom of the page
    additional: PropTypes.string, // ecat, pisys
    overlayFiltersIsOpen: PropTypes.bool, // so the component knows if the menu is open
  },

  getInitialState: function () {
    return { filterBlockOpen: false };
  },

  toggleFilterBlockDisplayedOpen: function () {
    this.setState(function (prevState) {
      return { filterBlockOpen: !prevState.filterBlockOpen };
    });
  },

  render: function () {
    let classNameFilterBlock = "filter-block";

    let filterContent, filter;
    // if a filter is provided, it is not the additional filter block
    if (this.props.filter) filter = this.props.filter; // additional boxes have no filter.props

    // empty except filter.type = slider and not for the filter block additional available
    let titleUnit = !this.props.additional ? Attributes.getUnit(filter.dataAttributeKey) : "";

    let closeFilterBlock;

    let hintMessage = null;

    if (filter) {
      // add className for pisys block
      if (filter.isPisys) {
        classNameFilterBlock += " filter-block--pisys";
      }

      // show open in tablet and phone view
      let overlayFiiltersIsOpen = false;
      // this was necessary to solve an visual error
      // when a filter block was open, and a user rescaled he window and reopened
      // which made the filter slider to appear visual bugged
      const filterBlockOpen = this.state.filterBlockOpen;
      if (this.props.overlayFiltersIsOpen) overlayFiiltersIsOpen = true;
      if (filterBlockOpen && overlayFiiltersIsOpen) {
        classNameFilterBlock += " filter-block--open";
      }

      // if isAdditional filter block can be closed

      if (filter.isAdditional) {
        closeFilterBlock = (
          <div className="filter-block__close">
            <span
              className="ficon ficon__close filter-block__close-icon"
              onClick={FilterStore.toggleDisplayFilter.bind(this, filter.dataAttributeKey)}
            />
          </div>
        );
      }

      // handle the hint for empty filter
      // first check if there is empty filtering for this filter
      if (filter.emptyFilter !== "") {
        let filterAlreadyApplied = ElstrUrlHashStore.get()[`filter_${filter.dataAttributeKey}`];
        let applyEmptyFilter = Controllers.applyEmptyFilter();

        // Only do something when the filter hasn't be applied
        // And is should be applied according to business rules
        if (!filterAlreadyApplied && applyEmptyFilter) {
          hintMessage = (
            <h3 className="filter-block__title" style={{ marginTop: 10 }}>
              {`${ElstrLangStore.text(`${filter.hintText}`)} ${filter.emptyFilter}`}
            </h3>
          );
        }
      }

      // Determine the content type of the filter
      switch (filter.type) {
        case "slider":
          // this is a workaround for issue https://github.com/mpowaga/react-slider/issues/136
          // the intention is to create a new component here by assigning
          // a different key so a new one gets mounted
          filterContent = <FilterSlider key={`${filterBlockOpen}`} filter={filter} />;

          break;
        case "listbox":
          filterContent = <FilterListbox filter={filter} />;

          break;
        case FILTER_TYPE.LISTBOX_TWO_STEP:
          filterContent = <FilterListboxTwoStep filter={filter} />;

          break;
        case "checkbox":
          filterContent = <FilterCheckbox filter={filter} />;

          break;
        case "radiobox":
          filterContent = <FilterRadiobox filter={filter} />;

          break;
        case "searchbox":
          filterContent = <FilterSearchbox filter={filter} />;
          // in case the current dataAttributeKey is an autocomplete open
          if (FilterStore.getAutocompleteOpen() === filter.dataAttributeKey) {
            classNameFilterBlock += " autocomplete--open";
          }

          break;

        case "color":
          filterContent = <FilterColor filter={filter} />;

          break;

        case "button":
          if (filter.dataAttributeKey === "setEcatFilters") {
            filterContent = <FilterSetEcat filter={filter} />;
          }

          break;
      }
    }

    let blockTitle = "no title defined";
    // for the additional content ecat
    if (this.props.additional === "ecat") {
      blockTitle = ElstrLangStore.text("FILTER_TEXT_ADDITIONAL_ECAT");
      filterContent = <FilterAdditional additional="ecat" />;
      classNameFilterBlock += " filter-block--keep-open";
    }
    // for the additional content pisys
    if (this.props.additional === "pisys") {
      blockTitle = ElstrLangStore.text("FILTER_TEXT_ADDITIONAL_PISYS");
      filterContent = <FilterAdditional additional="pisys" />;
      classNameFilterBlock += " filter-block--keep-open";
      classNameFilterBlock += " filter-block--pisys";
    }

    // Title for non Additional Filters
    if (this.props.filter !== undefined) {
      let key = filter.dataAttributeKey;
      blockTitle = Translations.getLabel(key) || Translations.getNoLabelFor(key);
    }

    return (
      <div
        className={classNameFilterBlock}
        id={`filterBlock${filter ? filter.dataAttributeKey : this.props.additional}`}
      >
        <div className="filter-block__toogle-arrow desktop--hidden" onClick={this.toggleFilterBlockDisplayedOpen}>
          <span className="ficon ficon__arrow-down filter-block__toogle-arrow-icon" />
        </div>
        {closeFilterBlock}
        <h2 className="filter-block__title" onClick={this.toggleFilterBlockDisplayedOpen}>
          {blockTitle}
          <span className="filter-block__title--unit">{titleUnit}</span>
        </h2>
        <div className="filter-block__content">{filterContent}</div>
        {hintMessage}
        {filter && !!filter.withDate ? <FilterDate type={TYPE_TWO_DATE_PICKER} /> : null}
      </div>
    );
  },
});

export default FilterBlock;
