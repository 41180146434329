import { Link } from "react-router-dom";
import * as Attributes from "../../libs/Attributes";
import Translations from "../../libs/Translations";
import React from "react";
import createReactClass from "create-react-class";
import DetailPisys from "../detail/DetailPisys";
import DetailTableTop from "../detail/DetailTableTop";
import DetailAside from "../detail/DetailAside";
import DetailAlternative from "../detail/DetailAlternative";
import DetailContact from "../detail/DetailContact";
import DetailPageStack from "../detail/DetailPageStack";
import DetailPageClose from "../detail/DetailPageClose";
import BackToResult from "../router/BackToResult";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import ElstrConfigStore from "elstr-jslib/src/scripts/stores/ElstrConfigStore";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";
import ElstrUrlHashActions from "elstr-jslib/src/scripts/actions/ElstrUrlHashActions";
import MaterialsStore from "../../stores/MaterialsStore";
import SelectionStore from "../../stores/SelectionStore";
import AppsStore from "../../stores/AppsStore";
import DetailsStore from "../../stores/DetailsStore";
import ItemToggleSelection from "../article/ItemToggleSelection";
import ItemLabelTop from "../article/ItemLabelTop";
import HeaderWrapContent from "../header/HeaderWrapContent";
import * as UrlHelpers from "../../libs/UrlHelpers";
import SwitchApp from "../header/SwitchApp";
import { setDocTitle } from "../../libs/Meta";
import DetailToolbarPrintLabel from "../detail/DetailToolbarPrintLabel";
import DetailMainHeader from "../detail/DetailMainHeader";

const Detail = createReactClass({
  mixins: [MaterialsStore.mixin, DetailsStore.mixin],

  getInitialState() {
    return {};
  },

  statics: {
    getDerivedStateFromProps(newProps) {
      ElstrUrlHashActions.setRouteParams(newProps.location.pathname, newProps.match.params);
      return null;
    },
  },

  storeDidChange: function () {
    this.forceUpdate();
  },

  componentDidMount: function () {
    let sMatHdrMatnr = this.props.match.params.sMatHdrMatnr;
    let material = MaterialsStore.getMaterialByNumberOrDefault(sMatHdrMatnr);

    if (material) {
      window._paq.push([
        "trackPageView",
        "Katalog Detail - " + sMatHdrMatnr + " : " + Translations.getItemTitle(material),
      ]);
    }
  },

  render: function () {
    const material = MaterialsStore.getMaterialByNumberOrDefault();

    // fallback if material is an empty object (can happen when switching from PISYS to eCat in the Detail view)
    if (!Object.keys(material).length) {
      return (
        <React.Fragment>
          <div className="header-wrap desktop--hidden">
            <HeaderWrapContent emptypage={false} />
          </div>
          <div className="detail-page-top desktop--hidden">
            {/* This Part has been temporary removed to avoid further implementation. It may be used in the future and left therefore here
                      <span className="ficon ficon__arrow-back detail-page-back-to-list__icon"/>Back to list */}
          </div>
          <BackToResult />
          <div className="detail-page">
            <DetailPageStack />
            <DetailPageClose />
            <div className="detail-page__container">
              <div className="detail-main">
                <div itemScope itemType="http://schema.org/Product" className="detail-main__container">
                  <div className="detail-main__header">
                    <h1 itemProp="name" className={`detail-main-header__title`}>
                      {ElstrLangStore.text("ITEM_NOT_FOUND")}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      // get img path
      let sMatAtvMatnrZvgb = `${material.sMatAtvMatnrZvgb}`;
      let ImagePath = MaterialsStore.getImagePath(sMatAtvMatnrZvgb, "detail");

      let itemTitle = Translations.getItemTitle(material);

      // textArtNrAndMatnr
      let sMatHdrMatnr = ElstrUrlHashStore.getRouteParam("sMatHdrMatnr");

      // sort the attributes according to configuration
      let attributesSorted = Attributes.getAttributesSortedAndByMainView("detailsOrderDefault");

      // push content for pisys information page
      let isPisysSite = ElstrConfigStore.option("isPisysSite");
      let contentPisys = null;
      if (isPisysSite) {
        contentPisys = <DetailPisys material={material} attributesSorted={attributesSorted} />;
      }

      // change document title to current bottle
      setDocTitle(`${material.sMatHdrMatnr} ${itemTitle}`);

      // ITEMS WILL APPEAR IN A DIFFERENT COLOR SIGNALIZING THAT CHANGES WILL BE EDITED TO A FOREIGN SELECTION
      let editingClassName = "";
      if (SelectionStore.isEditing()) editingClassName = "editing-selected";

      // APPLY SELECTION TEXT
      let selectionText = SelectionStore.isEditing()
        ? ElstrLangStore.text("FOREIGN_MY_SELECTION")
        : ElstrLangStore.text("SELECTION_MY_SELECTION");

      return (
        <React.Fragment>
          <div className="header-wrap desktop--hidden">
            <HeaderWrapContent emptypage={false} />
          </div>
          <div className="detail-page-top desktop--hidden">
            {/* This Part has been temporary removed to avoid further implementation. It may be used in the future and left therefore here
                          <span className="ficon ficon__arrow-back detail-page-back-to-list__icon"/>Back to list */}
          </div>
          <BackToResult />
          <div className="detail-page">
            <DetailPageStack />
            <DetailPageClose />
            <div className="detail-page__container">
              <ItemLabelTop material={material} detailTop={true} />
              <div className="detail-main">
                <div itemScope itemType="http://schema.org/Product" className="detail-main__container">
                  <div className="detail-main__toolbar">
                    <ul>
                      <li className="detail-main-toolbar__item">
                        <ItemToggleSelection
                          isDetailTop={true}
                          sMatHdrMatnr={material["sMatHdrMatnr"]}
                          material={material}
                        />
                      </li>
                      <Link to={UrlHelpers.getLinkPathForSelection()}>
                        <li className="detail-main-toolbar__item inline-block--phone-tablet--hidden">
                          <span
                            className={`ficon ficon__my-selection detail-main-toolbar-item__my-selection ${editingClassName}`}
                          />
                          <span className={`detail-main-toolbar-item__title ${editingClassName}`}>{selectionText}</span>
                        </li>
                      </Link>
                      {isPisysSite ? (
                        <Link to={UrlHelpers.getLinkPathForPrintLabel()}>
                          <DetailToolbarPrintLabel />
                        </Link>
                      ) : null}
                      <li className="detail-main-toolbar__item detail-main-toolbar__contact inline-block--desktop--hidden">
                        <span
                          className="ficon ficon__contact detail-main-toolbar-item__contact"
                          onClick={AppsStore.toggleOverlayDetailContactOpen}
                        />
                      </li>
                      <li className="detail-main-toolbar__item">
                        {/* <!-- meta-nav__catalog-switch --> */}
                        <SwitchApp renderedPlace={"detail"} />
                        {/* <!-- // meta-nav__catalog-switch --> */}
                      </li>
                    </ul>
                  </div>
                  {/* <!-- // detail-main__toolbar --> */}
                  <DetailMainHeader />
                  <div className="detail-main__img-wrap">
                    <img itemProp="image" src={ImagePath} alt="glas img" className="detail-main__img" />
                  </div>
                  <div className="detail-main__body">
                    <div className="detail-main-body__content">
                      <DetailTableTop material={material} attributesSorted={attributesSorted} />
                    </div>
                    {/* <!-- // detail-main-body__content --> */}
                    <DetailAside material={material} />
                  </div>
                  {/* <!-- // detail-main__body --> */}
                </div>
                {/* <!-- // detail-main__container --> */}
              </div>
              {/* <!-- // detail-main --> */}
              {contentPisys}
              <DetailAlternative sMatHdrMatnr={sMatHdrMatnr} />
            </div>
            {/* <!-- // detail-page__container --> */}
          </div>
          {/* <!-- // detail-page --> */}
          <DetailContact material={material} />
          <div className="subpage-modal-overlay" />
        </React.Fragment>
      );
    }
  },
});

export default Detail;
