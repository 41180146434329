import Translations from "../../libs/Translations";
import React from "react";
import * as Attributes from "../../libs/Attributes";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import MaterialsStore from "../../stores/MaterialsStore";

const DetailPisysMain = createReactClass({
  propTypes: {
    material: PropTypes.object.isRequired,
    attributesSorted: PropTypes.array.isRequired,
  },

  render: function () {
    let material = this.props.material;

    let attributesSorted = this.props.attributesSorted;
    let contentTable = [];

    // push content for the table
    attributesSorted.forEach(attribute => {
      if (attribute["detailsDisplayTop"] === false && attribute["detailsDisplayAllowed"]) {
        let dataAttributeKey = attribute["dataAttributeKey"];

        // assign labelColumn !Before overwriting dataAttributeKey!
        let labelColumn = Translations.getLabel(dataAttributeKey);

        dataAttributeKey = Attributes.getRealDataAttributeKey(dataAttributeKey);

        // fill valueColumn
        let valueColumn = MaterialsStore.getValueColumn(dataAttributeKey, material[dataAttributeKey]);

        contentTable.push(
          <tr key={`${dataAttributeKey}-main`}>
            <th>{labelColumn}</th>
            <td>{valueColumn}</td>
          </tr>,
        );
      }
    });

    return (
      <table className="table-detail detail-pisys-info__main">
        <thead>{contentTable}</thead>
      </table>
    );
  },
});

export default DetailPisysMain;
