import { Link } from "react-router-dom";
import { setScrollToTop } from "../../libs/Utils";
import React, { useState } from "react";
import PropTypes from "prop-types";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import * as UrlHelpers from "../../libs/UrlHelpers";

LinkToDetail.propTypes = {
  material: PropTypes.object.isRequired,
  renderedFrom: PropTypes.string,
};

export default function LinkToDetail({ children, material, renderedFrom }) {
  const [path, setPath] = useState(UrlHelpers.getLinkPathForDetail(material.sMatHdrMatnr));

  function handleClick() {
    const currentLanguage = ElstrLangStore.getCurrentLanguage();
    const translKey = `sMatHdrTdline__${currentLanguage}`; // ex: sMatHdrTdline__de
    const itemTitle = material[translKey];
    const sMatHdrMatnr = material.sMatHdrMatnr;

    window._paq.push(["trackEvent", `Product ${renderedFrom || "Detail"}`, "select", sMatHdrMatnr + " : " + itemTitle]);

    setScrollToTop();
  }

  function setPathDetail() {
    const newPath = UrlHelpers.getLinkPathForDetail(material.sMatHdrMatnr);
    if (newPath !== path) setPath(newPath);
  }

  return (
    <Link onMouseOver={setPathDetail} onFocus={setPathDetail} to={path} onClick={handleClick}>
      {children}
    </Link>
  );
}
