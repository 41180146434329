import * as Attributes from "../libs/Attributes";
import Translations from "../libs/Translations";
import { getSelectionSourceForDossierServerRequest, isViewDossier } from "../libs/UrlHelpers";
import * as ElstrLanguageStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import ElstrCache from "elstr-jslib/src/scripts/ElstrCache";
import { isPisysSite } from "../libs/Controllers";
import MaterialStore from "./MaterialsStore";
import SelectionStore from "./SelectionStore";
import ElstrDelayedFunctions from "elstr-jslib/src/scripts/libs/ElstrDelayedFunctions";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";
import DetailsConstants from "../constants/actions/DetailsConstants";
import DossierConstants from "../constants/actions/DossierConstants";
import DossierActions from "../actions/DossierActions";
import mcFly from "elstr-jslib/src/scripts/libs/mcFly.js";
import ElstrEditingStates from "elstr-jslib/src/scripts/constants/ElstrEditingStates";
import ElstrLoadingStates from "elstr-jslib/src/scripts/constants/ElstrLoadingStates";
import * as Sentry from "@sentry/browser";

const DossierStore = mcFly.createStore(
  {
    /*
        will contain all the gathered docs with the sMatHdrMatnr as key
        ex:
        {
        "10000": [{checked: true, doknr: "", dktkx: {}, dokar: "", dokvr: "", doktl_d: "", dokst: "", dostx: {}}]
        "19437": [{checked: false, doknr: "", dktkx: {}, dokar: "", dokvr: "", doktl_d: "", dokst: "", dostx: {}}]
        }
         */
    docs: {},

    /*
        will contain the loading docs state docs with the sMatHdrMatnr as key
        ex:
        {
        "10000": ElstrLoadingStates.EMPTY  // still loading
        "19437": ElstrLoadingStates.LOADED // loaded
        }
         */
    loadingStatePerDoc: {},

    /*
        will contain an index of the doc.doknr with the used sMatHdrMatnr because
        a doc.doknr might be used in similar articles
        { doc.doknr-doc.dokvr -> [sMatHdrMatnr] }
        {
        "4000395-00": [30268, 32372]
        "4001322-01": [25681, 30405]
        }
         */
    indexDoknr: {},

    /*
        will contain an index of the doc.doknr with the current checked bool status
        { doc.doknr-doc.dokvr -> boolean }
        {
        "4000395-00": true
        "4001322-01": false
        }
         */
    doknrChecked: {},

    /*
        will contain an index of the sMatHdrMatnr and if the packaging page should be included
        { sMatHdrMatnr -> boolean }
        {
          "19437": true
          "31869": false
        }
        */
    packPageChecked: {},

    /*
        will contain all comments from a sMatHdrMatnr and with sMatHdrMatnr as key
        ex:
        {
        "10000": "This article is our best seller",
        "19437": "",
        }
         */
    comments: {},

    error: null,
    title: "",
    activeStep: 1,
    toggleAllDocsState: true,
    editingState: ElstrEditingStates.NORMAL,

    // getter
    getDocs: function () {
      return this.docs;
    },

    getError: function () {
      return this.error;
    },

    getAllDocsAreLoaded: function () {
      let allDocsLoaded = true;
      let state = DossierStore.loadingStatePerDoc;

      // when empty nothing is loaded
      if (Object.entries(state).length === 0 && state.constructor === Object) {
        allDocsLoaded = false;
      }

      // if one has the loading state not everything is loaded
      for (let key in state) {
        if (state[key] === ElstrLoadingStates.EMPTY) {
          allDocsLoaded = false;
        }
      }
      return allDocsLoaded;
    },

    getEditingState: function () {
      return DossierStore.editingState;
    },

    getDocsFromNumber: sMatHdrMatnr => {
      let docs = DossierStore.getDocs();
      if (!docs[sMatHdrMatnr]) {
        return [];
      }
      return docs[sMatHdrMatnr];
    },

    getTitle: () => {
      return DossierStore.title;
    },

    getPayloadForDossierRequest: () => {
      let selectionId = ElstrUrlHashStore.get("selectionId");
      let selectionSource = getSelectionSourceForDossierServerRequest();
      let eCatId = ElstrCache.get("eCatId") || "";

      let payload = {
        selectionId,
        selectionSource,
        eCatId,
      };
      return payload;
    },

    getDoknrChecked: (doknr, dokvr) => {
      // default is checked true
      if (DossierStore.doknrChecked[`${doknr}-${dokvr}`] === undefined) {
        return true;
      }
      return DossierStore.doknrChecked[`${doknr}-${dokvr}`];
    },

    getPackPageChecked: () => {
      return DossierStore.packPageChecked;
    },

    getSpecificPackPageChecked: sMatHdrMatnr => {
      return DossierStore.packPageChecked.hasOwnProperty(sMatHdrMatnr)
        ? DossierStore.packPageChecked[sMatHdrMatnr]
        : true;
    },

    getActiveStep: () => {
      return DossierStore.activeStep;
    },

    getToggleAllDocsState: () => {
      return DossierStore.toggleAllDocsState;
    },

    setDoknrChecked: (doknr, dokvr, bool) => {
      DossierStore.doknrChecked[`${doknr}-${dokvr}`] = bool;
    },

    setCheckedDoc: (doknr, dokvr, checked) => {
      // set the right doknr checked and reassign the new list
      // a doknr might be used in different sMatHdrMatnr

      let allsMatHdrMatnrFromDoknr = DossierStore.indexDoknr[`${doknr}-${dokvr}`];
      allsMatHdrMatnrFromDoknr.forEach(sMatHdrMatnr => {
        let oldDocs = DossierStore.getDocsFromNumber(sMatHdrMatnr);

        let newDocs = oldDocs.map(doc => {
          let copyDoc = Object.assign({}, doc);
          // check if the same document
          if (copyDoc.doknr === doknr && copyDoc.dokvr === dokvr) {
            copyDoc.checked = checked;
          }
          return copyDoc;
        });

        DossierStore.docs[sMatHdrMatnr] = newDocs;
      });

      DossierStore.setDoknrChecked(doknr, dokvr, checked);

      DossierStore.setDocs();
      DossierStore.emitChange();
    },

    toggleCheckedPackPage: sMatHdrMatnr => {
      if (DossierStore.packPageChecked.hasOwnProperty(sMatHdrMatnr)) {
        DossierStore.packPageChecked[sMatHdrMatnr] = !DossierStore.packPageChecked[sMatHdrMatnr];
      } else {
        Sentry.captureException(
          new Error(
            `Tried to toggle PackPage DossierStore.toggleCheckedPackPage
            on DossierStore.packPageChecked[${sMatHdrMatnr}] which doesn't exist`,
          ),
        );
      }

      DossierStore.setDossier();
      DossierStore.emitChange();
    },

    toggleAllDocsChecked: () => {
      let newState = !DossierStore.toggleAllDocsState;

      let allOldDocs = DossierStore.getDocs();

      for (let sMatHdrMatnr in allOldDocs) {
        let newDocs = allOldDocs[sMatHdrMatnr].map(doc => {
          let copyDoc = Object.assign({}, doc);
          copyDoc.checked = newState;
          return copyDoc;
        });
        DossierStore.docs[sMatHdrMatnr] = newDocs;
      }

      DossierStore.toggleAllDocsState = newState;

      DossierStore.setDocs();
      DossierStore.emitChange();
    },

    toggleDokarChecked: (dokar, bool) => {
      let allOldDocs = DossierStore.getDocs();

      for (let sMatHdrMatnr in allOldDocs) {
        let newDocs = allOldDocs[sMatHdrMatnr].map(doc => {
          let copyDoc = Object.assign({}, doc);
          if (copyDoc.dokar.startsWith(dokar)) {
            copyDoc.checked = bool;
          }
          return copyDoc;
        });
        DossierStore.docs[sMatHdrMatnr] = newDocs;
      }

      DossierStore.setDocs();
      DossierStore.emitChange();
    },

    toggleAllPackPagesChecked: bool => {
      let allPackPageNumbers = DossierStore.getPackPageChecked();

      for (let sMatHdrMatnr in allPackPageNumbers) {
        DossierStore.packPageChecked[sMatHdrMatnr] = bool;
      }

      DossierStore.setDossier();
      DossierStore.emitChange();
    },

    setTitle: value => {
      DossierStore.title = value;

      // update db
      var executeBeforeUnload = false;
      ElstrDelayedFunctions.delay("setDossier", DossierStore.sendDossierDelayed, 2000, executeBeforeUnload);

      DossierStore.emitChange();
    },

    updateComment: (sMatHdrMatnr, comment) => {
      DossierStore.comments[sMatHdrMatnr] = comment;

      // update db
      var executeBeforeUnload = false;
      ElstrDelayedFunctions.delay(
        "setAttributes",
        DossierStore.sendAttributesForCommentDelayed,
        2000,
        executeBeforeUnload,
      );

      DossierStore.emitChange();
    },

    setActiveStep: value => {
      DossierStore.activeStep = value;
      DossierStore.emitChange();
    },

    incActiveStep: () => {
      DossierStore.setActiveStep(DossierStore.getActiveStep() + 1);
    },

    getMaterialsSorted: () => {
      let materialsSorted = [];
      let sortedRenderedMaterials = SelectionStore.getSortedRenderedMaterials();

      sortedRenderedMaterials.forEach(material => {
        // in eCat the packPageSelected is always false with the goal not include it in the dossier
        let packPageSelected = isPisysSite() ? DossierStore.getSpecificPackPageChecked(material.sMatHdrMatnr) : false;

        let pos = {
          sMatHdrMatnr: material.sMatHdrMatnr,
          sMatAtvMatnrZvgb: material.sMatAtvMatnrZvgb,
          sMatHdrTdline: Translations.getItemTitle(material),
          fuellVollAndMuendung: Translations.getFuellVollAndMuendung(material),
          textArtNrAndMatnr: Translations.getTextArtNrAndMatnr(material.sMatHdrMatnr),
          packPageSelected,
        };

        materialsSorted.push(pos);
      });

      return materialsSorted;
    },

    setDossier: () => {
      let payload = DossierStore.getPayloadForDossierRequest();
      payload.meta = {
        title: DossierStore.getTitle(),
        materialsSorted: DossierStore.getMaterialsSorted(),
      };

      DossierActions.setDossier(payload);
    },

    sendDossierDelayed: () => {
      DossierStore.setDossier();
    },

    sendAttributesForCommentDelayed: () => {
      DossierStore.setAttributes(Attributes.TYPES.mainDossier);
    },

    setDocs: () => {
      let allsMatHdrMatnrs = SelectionStore.getSortedRenderedMaterialNumbersAsList();
      let _docs = DossierStore.getDocs();
      let docsToSend = [];
      let currentLang = ElstrLanguageStore.getCurrentLanguage();
      allsMatHdrMatnrs.forEach(sMatHdrMatnr => {
        if (_docs[sMatHdrMatnr] === undefined) {
          return;
        }

        let docPosition = [];
        _docs[sMatHdrMatnr].forEach(doc => {
          if (!doc.checked) {
            return;
          }

          docPosition.push({ doknr: doc.doknr, dokar: doc.dokar, dktxt: doc.dktxt[currentLang] });
        });
        docsToSend.push(docPosition);
      });

      let payload = DossierStore.getPayloadForDossierRequest();
      payload.docs = docsToSend;

      DossierActions.setDocs(payload);
    },

    setAttributes: type => {
      const _attributes = Attributes.getAttributesByType(type);
      const selectedMaterials = SelectionStore.getRenderedMaterialNumbersAsList();

      const attributes = _attributes
        // we remove the attribute "sMatHdrTdline" since it is redundant in the dossier functionality
        .filter(attr => attr.dataAttributeKey !== "sMatHdrTdline")
        .reduce((acc, attr) => {
          if (attr.compareDisplayDefault) {
            let valueObj = {};
            selectedMaterials.forEach(sMatHdrMatnr => {
              let material = MaterialStore.getMaterialByMatnr(sMatHdrMatnr);
              let dataAttributeKey = attr.dataAttributeKey;
              valueObj[sMatHdrMatnr] = MaterialStore.getValueColumn(dataAttributeKey, material[dataAttributeKey]);
            });

            acc.push({
              labelText: Translations.getLabel(attr.dataAttributeKey),
              valuesText: valueObj,
              compareDisplaySize: attr.compareDisplaySize,
            });
          }
          return acc;
        }, []);

      const payload = DossierStore.getPayloadForDossierRequest();
      payload.type = type;
      payload.attributes = attributes;
      payload.comments = type === Attributes.TYPES.mainDossier ? DossierStore.comments : {};

      DossierActions.setAttributes(payload);
    },

    cleanAndEditDocs: (docs, sMatHdrMatnr) => {
      let preparedDocs = [];
      docs.forEach(doc => {
        // no SP0 docs
        if (doc.dokar === "SP0") {
          return;
        }

        // only active docs
        if (!["A1", "S1"].includes(doc.dokst)) {
          return;
        }

        // we assign the same doknr checked status like the others with the same doknr
        let doknrChcked = DossierStore.getDoknrChecked(doc.doknr, doc.dokvr);
        doc.checked = doknrChcked;

        preparedDocs.push(doc);

        // add doc index
        if (!DossierStore.indexDoknr[`${doc.doknr}-${doc.dokvr}`]) {
          DossierStore.indexDoknr[`${doc.doknr}-${doc.dokvr}`] = [];
        }
        DossierStore.indexDoknr[`${doc.doknr}-${doc.dokvr}`].push(sMatHdrMatnr);
      });
      DossierStore.docs[sMatHdrMatnr] = preparedDocs;
    },

    initPackPage: sMatHdrMatnr => {
      DossierStore.packPageChecked[sMatHdrMatnr] = DossierStore.getSpecificPackPageChecked(sMatHdrMatnr);
    },

    initMissingDocs: () => {
      let materialsToRender = SelectionStore.getSortedRenderedMaterials();

      // Approach of one request to get all Docs
      // request documents that aren't already cached in DossierStore
      let missingDocsFromsMatHdrMatnr = [];
      materialsToRender.forEach(mat => {
        let sMatHdrMatnr = mat.sMatHdrMatnr;
        if (!DossierStore.loadingStatePerDoc[sMatHdrMatnr]) {
          missingDocsFromsMatHdrMatnr.push(sMatHdrMatnr);
          // when a materialNumber is unknown, assign loading state
          DossierStore.loadingStatePerDoc[sMatHdrMatnr] = ElstrLoadingStates.EMPTY;
        }
      });

      if (missingDocsFromsMatHdrMatnr.length > 0) {
        DossierActions.getDocumentsFromNumbers({
          materialNumbers: missingDocsFromsMatHdrMatnr,
        });
      }
    },

    isDocsLoadedFromMaterial: sMatHdrMatnr => {
      if (!DossierStore.loadingStatePerDoc[sMatHdrMatnr]) {
        return false;
      }

      return DossierStore.loadingStatePerDoc[sMatHdrMatnr] === ElstrLoadingStates.LOADED;
    },
  },
  function (payload) {
    switch (payload.actionType) {
      case DetailsConstants.GET_MATERIAL_DOCUMENTS_WILL_GET: {
        // when a materialNumber is unknown, assign loading state
        let materialNumber = payload.materialNumber;
        if (!DossierStore.loadingStatePerDoc[materialNumber]) {
          DossierStore.loadingStatePerDoc[materialNumber] = ElstrLoadingStates.EMPTY;
        }

        break;
      }

      case DetailsConstants.GET_MATERIAL_DOCUMENTS_DID_GET: {
        let error = payload.error;
        let messages = payload.messages;
        let emitChange = true;

        if (error && error !== null && error !== "") {
          DossierStore.error = error;
          break;
        }
        if (messages && messages !== null && messages !== "" && messages.length > 0) {
          DossierStore.error = messages;
          break;
        }
        if (payload.data && payload.data.results) {
          if (payload.data.results[0]) {
            if (payload.params.materialNumber) {
              let materialNumber = payload.params.materialNumber;
              DossierStore.loadingStatePerDoc[materialNumber] = ElstrLoadingStates.LOADED;

              if (!DossierStore.docs[materialNumber]) {
                let docs = payload.data.results;
                DossierStore.cleanAndEditDocs(docs, materialNumber);
                DossierStore.initPackPage(materialNumber);
              } else {
                emitChange = false;
              }
            }
          }

          DossierStore.error = null;
        } else {
          error = "NO DATA";
          DossierStore.error = error;
        }

        if (emitChange) {
          DossierStore.emitChange();
        }

        // since a user might already have checked / unchecked docs we send them when all
        // are loaded automatically
        if (DossierStore.getAllDocsAreLoaded() && isViewDossier()) {
          DossierStore.setDocs();
        }

        break;
      }

      case DossierConstants.DOSSIER_GET_DOCUMENTS_FROM_NUMBERS_WILL: {
        // when a materialNumber is unknown, assign loading state
        let materialNumbers = payload.materialNumbers;
        let emitChange = false;

        materialNumbers.forEach(materialNumber => {
          if (!DossierStore.loadingStatePerDoc[materialNumber]) {
            DossierStore.loadingStatePerDoc[materialNumber] = ElstrLoadingStates.EMPTY;

            emitChange = true;
          }
        });
        if (emitChange) {
          DossierStore.emitChange();
        }
        break;
      }

      case DossierConstants.DOSSIER_GET_DOCUMENTS_FROM_NUMBERS_DID: {
        let error = payload.error;
        let messages = payload.messages;
        let emitChange = true;

        if (error && error !== null && error !== "") {
          DossierStore.error = error;
          break;
        }
        if (messages && messages !== null && messages !== "" && messages.length > 0) {
          DossierStore.error = messages;
          break;
        }
        if (payload.data && payload.data.data) {
          let data = payload.data.data;

          for (let materialNumber in data) {
            DossierStore.loadingStatePerDoc[materialNumber] = ElstrLoadingStates.LOADED;

            if (data[materialNumber]) {
              if (!DossierStore.docs[materialNumber]) {
                let docs = data[materialNumber];
                DossierStore.cleanAndEditDocs(docs, materialNumber);
                DossierStore.initPackPage(materialNumber);
              } else {
                emitChange = false;
              }
            }
          }

          DossierStore.error = null;
        } else {
          error = "NO DATA";
          DossierStore.error = error;
        }

        if (emitChange) {
          DossierStore.emitChange();
        }

        DossierActions.initOnDb();

        break;
      }

      case DossierConstants.DOSSIER_INIT_ON_DB: {
        mcFly.dispatcher.waitFor([ElstrUrlHashStore.dispatcherID]);
        mcFly.dispatcher.waitFor([SelectionStore.dispatcherID]);

        // After Initializing the Dossier Page we update certain elements to the DB
        DossierStore.setDocs();
        DossierStore.setDossier();
        DossierStore.setAttributes(Attributes.TYPES.mainDossier);
        DossierStore.setAttributes(Attributes.TYPES.packagingDossier);

        break;
      }
    }
    return true;
  },
);

export default DossierStore;
