import Translations from "../../libs/Translations";
import React from "react";
import * as Attributes from "../../libs/Attributes";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import ItemColors from "../article/ItemColors";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import MaterialsStore from "../../stores/MaterialsStore";
import TouchStore from "../../stores/TouchStore";
import * as UrlHelpers from "../../libs/UrlHelpers";
import DetailControllers from "../../libs/DetailControllers";
import DetailsStore from "../../stores/DetailsStore";

const DetailTableTop = createReactClass({
  mixins: [TouchStore.mixin],

  propTypes: {
    material: PropTypes.object.isRequired,
    attributesSorted: PropTypes.array.isRequired,
  },

  getInitialState: function () {
    return { showColorsOverlaySelf: false };
  },

  storeDidChange: function () {
    // this will reset showing the overlay open for the component itself
    this.state.showColorsOverlaySelf =
      TouchStore.getShowColorsOverlayForMaterial() === this.props.material.sMatHdrMatnr;

    this.forceUpdate();
  },

  render: function () {
    let { material, attributesSorted } = this.props;
    let currentLanguage = ElstrLangStore.getCurrentLanguage();
    let contentTable = [];

    //  push content for the table
    attributesSorted.forEach(attribute => {
      if (attribute["detailsDisplayTop"]) {
        let dataAttributeKey = attribute["dataAttributeKey"];

        // assign labelColumn !Before overwriting dataAttributeKey!
        let labelColumn = Translations.getLabel(dataAttributeKey);

        dataAttributeKey = Attributes.getRealDataAttributeKey(dataAttributeKey);

        // fill valueColumn
        let valueColumn = MaterialsStore.getValueColumn(dataAttributeKey, material[dataAttributeKey]);

        if (dataAttributeKey === "sMatAtvFarbe") {
          let colorsOverlay = null;
          if (this.state.showColorsOverlaySelf) {
            colorsOverlay = (
              <ItemColors
                colorSelf={material.sMatAtvFarbe}
                colorsAlternate={material.alternativeColors}
                sMatHdrMatnr={material.sMatHdrMatnr}
                isOverlay={true}
              />
            );
          }

          contentTable.push(
            <tr className="table-detail__color" key={`${dataAttributeKey}-top`}>
              <th>{labelColumn}</th>
              <td className="td__color">
                <ItemColors
                  colorSelf={material.sMatAtvFarbe}
                  colorsAlternate={material.alternativeColors}
                  sMatHdrMatnr={material.sMatHdrMatnr}
                  isOverlay={false}
                />
                {colorsOverlay}
              </td>
            </tr>,
          );
        } else {
          contentTable.push(
            <tr key={`${dataAttributeKey}-top`}>
              <th>{labelColumn}</th>
              <td>{valueColumn}</td>
            </tr>,
          );
        }
      }
    });

    // push content at the end for the documents which appear in mobile view (dekstop / tablet)
    let documentItems = [];
    let materialDocuments = DetailsStore.getDocuments();

    let sMatHdrMatnr = material["sMatHdrMatnr"];
    let hrefDoc = UrlHelpers.getHrefMaterialDocument();

    //ex: materialDocuments = [{doknr: 1, dktxt: {en: 'asdf1'}}, {doknr: 2, dktxt: {en: 'asdf2'}}, {doknr: 3, dktxt: {en: 'asdf3'}}]
    materialDocuments.forEach(matDoc => {
      if (DetailControllers.allowedToPostDocument(matDoc)) {
        let doknr = matDoc["doknr"];
        documentItems.push(
          <a
            className="link--underline"
            key={doknr}
            rel="noopener noreferrer"
            target="_blank"
            href={`${hrefDoc}&doknr=${doknr}&materialNumber=${sMatHdrMatnr}`}
          >
            <span key={matDoc["doknr"]}>
              {matDoc["dktxt"][currentLanguage]}
              <br />
            </span>
          </a>,
        );
      }
    });

    return (
      <table className="table-detail">
        <tbody>
          {contentTable}
          <tr className="tr--desktop--hidden">
            <th>{ElstrLangStore.text("DETAIL_TEXT_DOCUMENTS")}</th>
            <td>{documentItems}</td>
          </tr>
        </tbody>
      </table>
    );
  },
});

export default DetailTableTop;
