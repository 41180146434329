import React, { useEffect, useState } from "react";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import Translations from "../../libs/Translations";
import { ExportToExcel } from "./ExportToExcel";

const PisysHeaderMenu = ({ exportPisysTable, filterProps }) => {
  return (
    <>
      <div className="pisys-additional-menubar">
        <ExportToExcel exportPisysTable={exportPisysTable} />
      </div>
      <SecondFilters filterProps={filterProps} />
    </>
  );
};

export default PisysHeaderMenu;

const SECOND_PARAMS = {
  chargD: `chargD`,
  palnoFrom: `palnoFrom`,
  palnoTo: `palnoTo`,
  exidv: `exidv`,
};

export const PREFIX_SECOND_FILTER = "SecondFilter";

const SECOND_PARAMS_FULL = {
  [SECOND_PARAMS.chargD]: `${PREFIX_SECOND_FILTER}_${SECOND_PARAMS.chargD}`,
  [SECOND_PARAMS.palnoFrom]: `${PREFIX_SECOND_FILTER}_${SECOND_PARAMS.palnoFrom}`,
  [SECOND_PARAMS.palnoTo]: `${PREFIX_SECOND_FILTER}_${SECOND_PARAMS.palnoTo}`,
  [SECOND_PARAMS.exidv]: `${PREFIX_SECOND_FILTER}_${SECOND_PARAMS.exidv}`,
};

export const FILTER_MECHANISM = {
  INDEX_OF: "indexOf",
  GREATER_THEN_EQUALS: "gte",
  LESS_THEN_EQUALS: "lte",
};

const ATTRIBUTE_SOURCE = {
  t_at_ord_sscc: "t_at_ord_sscc",
};

export const SECOND_PARAMS_FILTER_META = {
  [SECOND_PARAMS_FULL[SECOND_PARAMS.chargD]]: {
    attrSource: ATTRIBUTE_SOURCE.t_at_ord_sscc,
    param: "charg_d",
    filterMechanism: FILTER_MECHANISM.INDEX_OF,
  },
  [SECOND_PARAMS_FULL[SECOND_PARAMS.palnoFrom]]: {
    attrSource: ATTRIBUTE_SOURCE.t_at_ord_sscc,
    param: "palno",
    filterMechanism: FILTER_MECHANISM.GREATER_THEN_EQUALS,
  },
  [SECOND_PARAMS_FULL[SECOND_PARAMS.palnoTo]]: {
    attrSource: ATTRIBUTE_SOURCE.t_at_ord_sscc,
    param: "palno",
    filterMechanism: FILTER_MECHANISM.LESS_THEN_EQUALS,
  },
  [SECOND_PARAMS_FULL[SECOND_PARAMS.exidv]]: {
    attrSource: ATTRIBUTE_SOURCE.t_at_ord_sscc,
    param: "exidv",
    filterMechanism: FILTER_MECHANISM.INDEX_OF,
  },
};

const SECOND_FILTER_SIZE = {
  small: "small",
};

const SecondFilters = ({ filterProps }) => {
  // rendering additional filter for sale order delivery-doc
  if (ElstrUrlHashStore.get("pisysTab") === "salesOrder") {
    return (
      <div className="pisys-additional-menubar pisys-additional-menubar__filter">
        <span className="filter-title-sales-order">{`${ElstrLangStore.text("DETAIL_TAB_VBELV")}: `}</span>
        <SecondFilter
          label={Translations.getLabelDetailColumn(SECOND_PARAMS.chargD)}
          param={SECOND_PARAMS_FULL[SECOND_PARAMS.chargD]}
          filterProps={filterProps}
          size={SECOND_FILTER_SIZE.small}
        />
        <SecondFilter
          label={`${Translations.getLabelDetailColumn("palno")} ${ElstrLangStore.text("LABEL_FROM")}`}
          param={SECOND_PARAMS_FULL[SECOND_PARAMS.palnoFrom]}
          filterProps={filterProps}
          size={SECOND_FILTER_SIZE.small}
        />
        <SecondFilter
          label={`${Translations.getLabelDetailColumn("palno")} ${ElstrLangStore.text("LABEL_TO")}`}
          param={SECOND_PARAMS_FULL[SECOND_PARAMS.palnoTo]}
          filterProps={filterProps}
          size={SECOND_FILTER_SIZE.small}
        />
        <SecondFilter
          label={Translations.getLabelDetailColumn(SECOND_PARAMS.exidv)}
          param={SECOND_PARAMS_FULL[SECOND_PARAMS.exidv]}
          filterProps={filterProps}
        />
        <ClearSecondFilter filterProps={filterProps} attrSource={ATTRIBUTE_SOURCE.t_at_ord_sscc} />
      </div>
    );
  }

  return null;
};

const ClearSecondFilter = ({ filterProps, attrSource = ATTRIBUTE_SOURCE.t_at_ord_sscc }) => {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    let newShouldRender = false;

    const keys = Object.keys(Object.assign(filterProps.filters)) || [];

    keys.forEach(key => {
      if (!SECOND_PARAMS_FILTER_META[key]) {
        return;
      }

      // adding button only when a second filter is set
      if (SECOND_PARAMS_FILTER_META[key].attrSource === attrSource) {
        newShouldRender = true;
      }
    });

    setShouldRender(newShouldRender);
  }, [filterProps]);

  function removeSecondFilters() {
    filterProps.removeAllSecondFilter();
  }

  if (!shouldRender) {
    return null;
  }

  return (
    <div className="btn-filter btn-filter--clear-all pisys-additional-button" onClick={removeSecondFilters}>
      {ElstrLangStore.text("HEADER_STICKY_CLEAR_FILTERS")}
      <span className="ficon ficon__close btn-filter__close" />
    </div>
  );
};

const SecondFilter = ({ label, param, filterProps, size }) => {
  const [value, setValue] = useState("");

  useEffect(
    function updateFilter() {
      if (!filterProps.filters[param]) {
        setValue("");
      } else {
        setValue(filterProps.filters[param]);
      }
    },
    [filterProps.filters[param]],
  );

  const classNameInput = `input-second-filter ${size === SECOND_FILTER_SIZE.small ? "input-second-filter-small" : ""}`;

  function onChange(e) {
    filterProps.updateFilter(param, e);
    setValue(e.target.value);
  }

  return (
    <span className="filter-group">
      <span>{`${label} :`}</span>
      <span className="input-filter">
        <input
          className={classNameInput}
          type="text"
          placeholder={ElstrLangStore.text("DETAIL_TEXT_FILTERING")}
          value={value || ""}
          onKeyDown={e => filterProps.handleOnKeyDown(param, e)}
          onChange={onChange}
        />
        <span
          className="ficon ficon__filter pointer second-filter"
          onClick={e => filterProps.handleOnClick(param, e)}
        />
      </span>
    </span>
  );
};
