import ElstrConfigStore from "elstr-jslib/src/scripts/stores/ElstrConfigStore";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";
import SelectionStore from "../stores/SelectionStore";

const Controllers = {
  isMsteaInactive: mstea => {
    let inactiveMstea = ElstrConfigStore.option("inactiveMstae");
    return inactiveMstea.includes(mstea);
  },

  isSelectionHandlingNotAllowed: material => {
    // first we check if the selectionSource is catalogue. Only then it's required to make changes
    let editingSelectionSource = SelectionStore.getEditingSelectionSource();
    if (editingSelectionSource === "catalogue") {
      Controllers.isMaterialNotAvailableOnECat(material);
    } else {
      return false;
    }
  },

  isMaterialNotAvailableOnECat: material => {
    // check is if all configured attributes are allowed
    let editCatalogueConditions = ElstrConfigStore.option("editCatalogue")["conditions"];
    let notAllowed = false;
    for (let key in editCatalogueConditions) {
      // IF THE PROVIDED MATERIAL HAS NOT INCLUDED THE KEYS CONFIGURED IN CONFIG.INI - IT WILL NOT BE ALLOWED
      if (!editCatalogueConditions[key].includes(material[key])) notAllowed = true;
    }

    return notAllowed;
  },

  isConfidential: material => {
    let confidentialCriteria = ElstrConfigStore.option("watermark")["conditions"]["sMatAtvEinsatzart"];

    return material.sMatAtvEinsatzart === confidentialCriteria;
  },

  applyEmptyFilter: () => {
    // When the filter_serachFor isn't applied allow the empty Filter settings
    let searchForApplied = ElstrUrlHashStore.get()["filter_searchFor"];
    return !searchForApplied;
  },

  /**
   * The Filter sMatAtvEcatSubgroup has special business rules
   * In case there is no mainGroup selected it shouldn't be shown
   * This means also when switching from a mainGroup (eg. from WINE to ALL) the filter should be removed
   * This case is used in multiple places to complete this specification
   * @param dataAttributeKey
   * @returns {boolean}
   */
  caseEcatSubgroupMainGroupAll: dataAttributeKey => {
    const pathname = window.location.pathname;
    return dataAttributeKey === "sMatAtvEcatSubgroup" && pathname.includes("/all");
  },
};

export function isPisysSite() {
  let isPisysSite = ElstrConfigStore.option("isPisysSite");
  return !!isPisysSite;
}

export default Controllers;
