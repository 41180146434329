import { MAIN_VIEW as MV } from "../../constants/MainView";
import React from "react";
import createReactClass from "create-react-class";
import ElstrConfigStore from "elstr-jslib/src/scripts/stores/ElstrConfigStore";
import ElstrUserStore from "elstr-jslib/src/scripts/stores/ElstrUserStore";
import ElstrEnvStore from "elstr-jslib/src/scripts/stores/ElstrEnvStore";
import ElstrLog from "elstr-jslib/src/scripts/ElstrLog";
import MaterialsStore from "../../stores/MaterialsStore";
import SelectionStore from "../../stores/SelectionStore";
import AppsStore from "../../stores/AppsStore";
import * as UrlHelpers from "../../libs/UrlHelpers";

const EnvInfoDisplay = createReactClass({
  // Purpose of this function is to better debug or show material attributes according to what is currently displayed
  showFilteredMaterials: function () {
    if (LIBS.appName === "intro") {
      ElstrLog.log(MaterialsStore.getFilteredMaterialsRandomIntro());
    } else {
      switch (UrlHelpers.getMainView()) {
        case MV.RESULT:
          ElstrLog.log(MaterialsStore.getFilteredMaterialsResult());

          break;

        case MV.SELECTION:
          ElstrLog.log("My Selection: ", SelectionStore.getMySelectedMaterials());
          ElstrLog.log("Foreign Selection: ", SelectionStore.getForeignSelectedMaterials());

          break;

        case MV.DETAIL:
          ElstrLog.log("Detail Material: ", MaterialsStore.getMaterialByNumberOrDefault());
          ElstrLog.log("Alternative Material(s): ", MaterialsStore.getAlternativeMaterials());

          break;
      }
    }
  },

  getInitialState: function () {
    const applicationType = ElstrConfigStore.option("isPisysSite") ? "PISYS" : "ECAT";
    const applicationEnv = ElstrConfigStore.getApplicationEnv();
    const browserInfo = ElstrEnvStore.getBrowserInfo();
    const sapSystem = ElstrConfigStore.simpleOptionOrDefault("sapSystem", "unkown");

    return { visibility: "visible", browserInfo, applicationType, applicationEnv, sapSystem };
  },

  hide: function () {
    this.setState({ visibility: "hidden" });
  },

  render: function () {
    if (this.state.applicationEnv === "production") {
      return <section />;
    }

    let visibleForDevs = "none";
    if (ElstrConfigStore.simpleOptionOrDefault("visibleForDevs", false) === "true") {
      visibleForDevs = "inline";
    }

    return (
      <div className="containerEnvInfo">
        <section
          style={{ visibility: this.state.visibility }}
          className="envInfoDisplay"
          onClick={this.showFilteredMaterials}
        >
          <span>{`${this.state.applicationEnv}`}</span>
          <span> | </span>
          <span>{LIBS.appVersion}</span>
          <span> | </span>
          <span>{this.state.browserInfo.name}</span>
          <span>&nbsp;</span>
          <span>{this.state.browserInfo.version}</span>
          <span> | </span>
          <span>{ElstrUserStore.getClientIp()}</span>
          <span> | </span>
          <span>{this.state.applicationType}</span>
          <span> | </span>
          <span>SAP: {this.state.sapSystem}</span>
          <span> | </span>
          <span className="hide_envinfo" onClick={this.hide}>
            Hide
          </span>
          <span style={{ display: visibleForDevs }}>
            <span> | </span>
            <button className="clickable link--download" onClick={AppsStore.toggleOverlayInfoboxOpen}>
              Overlay
            </button>
          </span>
          <span> | </span>
          <span style={{ animation: "blinker 5s linear 1s infinite" }}>
            {ElstrConfigStore.simpleOptionOrDefault("devMode", false)
              ? "devMode is on - No real data for many server requests"
              : ""}
          </span>
        </section>
      </div>
    );
  },
});

export default EnvInfoDisplay;
