import React from "react";
import PropTypes from "prop-types";

export default function Step(props) {
  return (
    <svg viewBox="0 0 24 32" width="2em" height="2em" {...props}>
      <path d="M24 32H0V0h13v11h11v21zm0-23h-9V0h.4L24 8.6V9z" fill={props.fill} />
      <text transform="translate(8 21)" fontSize={12} fontFamily="Futura PT W08 Medium" fill="#fff">
        {props.step}
      </text>
    </svg>
  );
}

Step.propTypes = {
  fill: PropTypes.string.isRequired,
  step: PropTypes.number.isRequired,
};
