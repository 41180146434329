import AppsStore from "../../stores/AppsStore";
import React from "react";
import createReactClass from "create-react-class";
import UserActions from "../../actions/UserActions";
import ElstrLangActions from "elstr-jslib/src/scripts/actions/ElstrLangActions";
import ElstrConfigStore from "elstr-jslib/src/scripts/stores/ElstrConfigStore";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";

const MetaNavLanguage = createReactClass({
  mixins: [AppsStore.mixin],

  storeDidChange: function () {
    this.forceUpdate();
  },

  getInitialState: function () {
    let supportedLanguages = ElstrConfigStore.simpleOptionOrDefault("supportedLanguages");
    let availableLanguages = supportedLanguages.map(lang => {
      return {
        key: lang,
        text: ElstrLangStore.text("LANG_TEXT_" + lang),
      };
    });
    return { availableLanguages: availableLanguages };
  },

  changeLanguage: function (lang, e) {
    AppsStore.setCloseAllMenus(e);
    ElstrLangActions.load(lang);
    this.setLangBE(lang);
    window._paq.push(["trackEvent", "interface", "change", "language: " + lang]);
  },

  setLangBE: function (lang) {
    const isPisys = ElstrConfigStore.option("isPisysSite");
    if (isPisys) {
      UserActions.setLang({ lang });
    }
  },

  /**
   * Render
   */
  render: function () {
    /** class names **/
    let selected = "";
    // for desktop
    let classNameNavItem = "meta-nav-list__item";
    let classNameSelected = "meta-nav-list__item--selected for selected";
    // mobile and phone
    if (AppsStore.getLayoutView() !== "desktop") {
      classNameNavItem = "sub-layer-list__item";
      classNameSelected = "sub-layer-list__item--selected";
    }

    /** create languageList **/
    let currentLanguage = ElstrLangStore.getCurrentLanguage();
    let availableLanguages = this.state.availableLanguages;

    let languageList = availableLanguages.map(obj => {
      selected = "";
      let lang = obj.key;

      if (lang === currentLanguage) selected = classNameSelected;

      return (
        <li
          key={lang + "languageList"}
          className={`${classNameNavItem} ${selected}`}
          onClick={this.changeLanguage.bind(this, lang)}
        >
          {ElstrLangStore.text(`LANG_TEXT_${lang}`)}
        </li>
      );
    });

    return <span>{languageList}</span>;
  },
});

export default MetaNavLanguage;
