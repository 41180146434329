import React from "react";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
// https://www.npmjs.com/package/react-slider for doc
import ReactSlider from "react-slider";
import * as UrlHelpers from "../../libs/UrlHelpers";
import MaterialsStore from "../../stores/MaterialsStore";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";

function _setState(props) {
  let mainGroup = ElstrUrlHashStore.getRouteParam("mainGroup"); // defines with which mainGroup the component was created
  let catalogue = ElstrUrlHashStore.getRouteParam("catalogue"); // defines with which catalogue the component was created

  let dataAttributeKey = props.filter["dataAttributeKey"];
  let hashValues = UrlHelpers.getHashRange(dataAttributeKey); // [100,400] for filter_range=100-400
  let range = MaterialsStore.getMinMaxRange(dataAttributeKey);
  let values = [];

  values[0] = range[0];
  values[1] = range[1];

  // overwrites values
  if (hashValues.min !== null) values[0] = hashValues.min;
  if (hashValues.max !== null) values[1] = hashValues.max;

  return {
    values,
    range,
    mainGroup,
    catalogue,
  };
}

const FilterSlider = createReactClass({
  mixins: [MaterialsStore.mixin],

  propTypes: {
    filter: PropTypes.object, // for every filter type
  },

  getInitialState: function () {
    return _setState(this.props);
  },

  storeDidChange: function () {
    let mainGroup = ElstrUrlHashStore.getRouteParam("mainGroup");
    let catalogue = ElstrUrlHashStore.getRouteParam("catalogue");
    let dataAttributeKey = this.props.filter["dataAttributeKey"];
    let hashValues = UrlHelpers.getHashRange(dataAttributeKey);

    // in case mainGroup or catalogue has changed
    if (
      mainGroup !== this.state.mainGroup ||
      catalogue !== this.state.catalogue ||
      hashValues.min !== this.state.values.min ||
      hashValues.max !== this.state.values.max
    ) {
      // to reset the values and set the current mainGroup and catalogue state

      this.setState(_setState(this.props));
    }
  },

  componentDidMount: function () {
    // Workaround for react 16. See: https://github.com/mpowaga/react-slider/issues/115
    setTimeout(() => this.setState(_setState(this.props)), 10);
  },

  handleOnAfterChange: function (dataAttributeKey, valuesEvent) {
    let stringRange = `${valuesEvent[0]}-${valuesEvent[1]}`;
    UrlHelpers.setFilterRange(dataAttributeKey, stringRange);
  },

  handleOnChange: function (values) {
    this.setState({ values });
  },

  handleOnChangeInput: function (arrPos, dataAttributeKey, e) {
    let values = this.state.values;
    values[arrPos] = parseFloat(e.target.value);
    this.setState({ values });
  },

  handleOnKeyDown: function (arrPos, dataAttributeKey, e) {
    if (e.keyCode === 9 || e.keyCode === 13 || (e.shiftKey && e.keyCode === 9)) {
      // 13 = Enter-Key / 9 = TAB-Key
      let values = this.state.values;
      let stringRange = `${values[0]}-${values[1]}`;

      UrlHelpers.setFilterRange(dataAttributeKey, stringRange);

      // to remove keyboard in mobile
      e.target.blur();
      // to prevent opening the url tab in mobile
      e.preventDefault();
    }
  },

  highlightField: function (e) {
    // will highlight the input field
    e.target.select();
  },

  // Render
  render: function () {
    let dataAttributeKey = this.props.filter.dataAttributeKey;
    let values = this.state.values;
    let range = this.state.range;

    return (
      <div key={dataAttributeKey} className="filter-block__range-slider">
        {/* first index = minimum --- second index = maximum */}
        <ReactSlider
          className="horizontal-slider"
          onAfterChange={this.handleOnAfterChange.bind(this, dataAttributeKey)}
          onChange={this.handleOnChange}
          min={range[0]}
          max={range[1]}
          value={[values[0], values[1]]}
          defaultValue={[range[0], range[1]]}
          withBars
          step={0.1}
        />
        {/* Input Range */}
        <div className="range-slider__inputs-wrap">
          <input
            className="range-slider__input"
            type="number"
            min={range[0]}
            max={values[1]}
            placeholder={range[0]}
            value={values[0]}
            onFocus={this.highlightField}
            onChange={this.handleOnChangeInput.bind(this, 0, dataAttributeKey)}
            onKeyDown={this.handleOnKeyDown.bind(this, 0, dataAttributeKey)}
          />
          <span className="range-slider__input-separator">—</span>
          <input
            className="range-slider__input"
            type="number"
            min={values[0]}
            max={range[1]}
            placeholder={range[1]}
            value={values[1]}
            onFocus={this.highlightField}
            onChange={this.handleOnChangeInput.bind(this, 1, dataAttributeKey)}
            onKeyDown={this.handleOnKeyDown.bind(this, 0, dataAttributeKey)}
          />
        </div>
      </div>
    );
  },
});

export default FilterSlider;
