import * as Attributes from "../../libs/Attributes";
import React from "react";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import MaterialsStore from "../../stores/MaterialsStore";
import * as UrlHelpers from "../../libs/UrlHelpers";
import ElstrUrlHashStore from "elstr-jslib/src/scripts/stores/ElstrUrlHashStore";
import FilterStore from "../../stores/FilterStore";
import { FILTER } from "../../constants/Filter";

const FilterSearchbox = createReactClass({
  mixins: [MaterialsStore.mixin],

  propTypes: {
    filter: PropTypes.object, // for every filter type
  },

  getInitialState: function () {
    return {
      autocompleteItems: [],
      input: "",
    };
  },

  storeDidChange: function () {
    let searchVal = ElstrUrlHashStore.get(this.state.hash) || "";

    this.setState({
      input: String(searchVal), // take url value or empty
      active: false, // close window
      mouseIsOverAutocomplete: false, // close window
      selectedItemNr: -1, // set to initial
    });
  },

  componentDidMount: function () {
    let autocompleteItems = [];

    let dataAttributeKey = this.props.filter["dataAttributeKey"];
    let hash = `filter_${dataAttributeKey}`;
    let input = ElstrUrlHashStore.get(hash) || "";

    let active = false;
    let mouseIsOverAutocomplete = false;

    this.setState({
      autocompleteItems, // the materials that will appear in for the autocomplete suggestions
      input, // the input value
      active, // true when the input field is selected
      mouseIsOverAutocomplete, // true when mouse is over the autocomplete suggestions
      selectedItemNr: -1, // which item will be displayed as selected
      filter: this.props.filter,
      hash,
      dataAttributeKey,
    });
  },

  handleOnSubmit: function (e) {
    e.preventDefault();

    let selectedItemNr = this.state.selectedItemNr;

    // take the selectedItemNr for >= 0
    // or the value that the user has written for -1
    let searchVal = null;
    if (selectedItemNr >= 0) {
      const id = `autocomplete__item-${this.state.hash}-${selectedItemNr}`;
      searchVal = document.getElementById(id).title;
    } else if (selectedItemNr < 0) {
      searchVal = document.getElementById(this.state.hash).value;
    }

    // value from the <li> items in autocomplete are numbers
    searchVal = String(searchVal);

    // apply value or remove the filter if there is no valid value
    if (searchVal.length > 0) {
      UrlHelpers.setFilterValue(this.state.hash, searchVal);
    } else {
      let dataAttributeKey = this.state.hash.slice(7);
      UrlHelpers.clearSingleFilter(dataAttributeKey);
    }

    // used to hide the keyboard for mobile devices
    document.activeElement.blur();
  },

  handleOnChange: function () {
    let materialsToSearch = MaterialsStore.getFilteredMainMaterials();
    let autocompleteItems = [];

    const searchVal = document.getElementById(this.state.hash).value || "";

    if (searchVal !== "") {
      // get Items that fullfill the search criteria to show them in the auto-complete box
      if (this.state.dataAttributeKey === FILTER.CUSTOMER) {
        autocompleteItems = MaterialsStore.getCustomersFromFilterValueForAutocomplete(searchVal, materialsToSearch);
      } else {
        autocompleteItems = MaterialsStore.filterSearchBox(searchVal, this.state.dataAttributeKey, materialsToSearch);
      }

      // return only the value of the relevant dataAttributeKey
      let dataAttributeKey = Attributes.getRealDataAttributeKey(this.state.dataAttributeKey);
      if (this.state.dataAttributeKey === FILTER.CUSTOMER) {
        autocompleteItems = autocompleteItems.map(
          item => `${item.kunnr} - ${item.name1} - ${item.ort01} - ${item.land1}`,
        );
      } else {
        autocompleteItems = autocompleteItems.map(obj => obj[dataAttributeKey]);
      }

      autocompleteItems = [...new Set(autocompleteItems)];

      // limit to 10 items
      if (autocompleteItems.length > 10) {
        autocompleteItems.splice(10);
      }

      autocompleteItems.sort();
    }

    this.setState({
      autocompleteItems,
      input: searchVal,
      active: true,
      selectedItemNr: -1,
    });
  },

  handleOnBlur: function () {
    // only set to false when the mouse is not over the autocomplete items
    // this is to prevent that active set to false when using onClick on an autocomplete item
    if (this.state.mouseIsOverAutocomplete === false) {
      this.setState({ active: false });
    }
  },

  handleOnFocus: function () {
    FilterStore.setAutocompleteOpen(this.state.dataAttributeKey);
    this.handleOnChange();
  },

  handleOnMouseOver: function () {
    this.setState({ mouseIsOverAutocomplete: true });
  },

  handleOnMouseOut: function () {
    this.setState({
      mouseIsOverAutocomplete: false,
      selectedItemNr: -1,
    });
  },

  setSelectedItemNr: function (index) {
    this.setState({ selectedItemNr: index });
  },

  handleOnKeyDown: function (e) {
    // ARROW UP
    if (e.keyCode === 38) {
      this.setState(function (prevState) {
        let selectedItemNr = -1;
        if (prevState.selectedItemNr >= 0) {
          selectedItemNr = prevState.selectedItemNr - 1;
        }
        return { selectedItemNr };
      });
      // ARROW DOWN
    } else if (e.keyCode === 40) {
      this.setState(function (prevState) {
        let selectedItemNr = prevState.selectedItemNr + 1;
        if (selectedItemNr > prevState.autocompleteItems.length - 1) {
          selectedItemNr = prevState.autocompleteItems.length - 1;
        }
        return { selectedItemNr };
      });
    }
  },

  render: function () {
    let autocompleteItems = this.state.autocompleteItems;
    let displayAutocomplete = "none";
    let classNameSelected = "autocomplete__item-selected";

    /** fill autocompleteContent **/
    let autocompleteContent = [];
    if (autocompleteItems && autocompleteItems.length > 0) {
      displayAutocomplete = "block";

      autocompleteItems.forEach((item, i) => {
        let selected = "";
        if (this.state.selectedItemNr === i) selected = classNameSelected;

        autocompleteContent.push(
          <li
            className={`autocomplete__item ${selected}`}
            key={i}
            id={`autocomplete__item-${this.state.hash}-${i}`}
            value={item}
            title={item}
            onClick={this.handleOnSubmit}
            onMouseOver={this.setSelectedItemNr.bind(this, i)}
          >
            {item}
          </li>,
        );
      });
    }

    // different overwrite possibilities for hiding the autocomplete box
    if (autocompleteItems.length < 1) displayAutocomplete = "none"; // don't display when there are no items to display
    if (this.state.input === "") displayAutocomplete = "none"; // don't display when input field is empty
    if (this.state.active === false) displayAutocomplete = "none"; // don't display when field is not active

    return (
      <div className="search-box" key="search-box" onSubmit={this.handleOnSubmit}>
        <form action="post">
          <div className="filter-block__search-box search-box__field-wrap">
            <input
              type="text"
              id={this.state.hash}
              className="search-box__field"
              onChange={this.handleOnChange}
              autoComplete="off"
              onBlur={this.handleOnBlur}
              onFocus={this.handleOnFocus}
              onKeyDown={this.handleOnKeyDown}
              value={this.state.input}
            />
            <span className="ficon ficon__lupe search-box__icon-lupe" onClick={this.handleOnSubmit} />
            <div
              className="autocomplete"
              style={{ display: displayAutocomplete }}
              onMouseOver={this.handleOnMouseOver}
              onMouseOut={this.handleOnMouseOut}
            >
              <ul>{autocompleteContent}</ul>
            </div>
          </div>
        </form>
      </div>
    );
  },
});

export default FilterSearchbox;
