import * as TT from "../../libs/Tooltip";
import React from "react";
import * as Attributes from "../../libs/Attributes";
import * as UrlHelpers from "../../libs/UrlHelpers";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import ItemColors from "./ItemColors";
import MaterialsStore from "../../stores/MaterialsStore";
import TouchStore from "../../stores/TouchStore";
import ItemCompareRowPrintLabel from "./ItemCompareRowPrintLabel";
import PrintLabelStore from "../../stores/PrintLabelStore";
import { TYPES } from "../../constants/Tooltip";

const ItemCompareRows = createReactClass({
  mixins: [TouchStore.mixin, PrintLabelStore.mixin],

  propTypes: {
    material: PropTypes.object.isRequired,
    attributesSorted: PropTypes.array.isRequired,
  },

  getInitialState: function () {
    return { showColorsOverlaySelf: false };
  },

  storeDidChange: function () {
    // this will reset showing the overlay open for the component itself
    this.state.showColorsOverlaySelf =
      TouchStore.getShowColorsOverlayForMaterial() === this.props.material.sMatHdrMatnr;

    this.forceUpdate();
  },

  render: function () {
    // defaults
    let material = this.props.material;

    // overwrite when print-label
    if (UrlHelpers.isViewPrintLabel()) {
      return (
        <ItemCompareRowPrintLabel defaultNrOfLabels={PrintLabelStore.getDefaultNrOfLabels()} material={material} />
      );
    }

    // start fill content
    let attributesSorted = this.props.attributesSorted;

    let itemCompareRows = [];
    // if the site is not pisys, the leading attribute is 'compareDisplayDefault' / in PisysSite it's 'listDisplayDefault'

    if (UrlHelpers.isViewDossier()) {
      itemCompareRows.push(<div className="item-compare__row common__row" key="empty line attribute" />);
    }

    attributesSorted.forEach((attribute, i) => {
      if (attribute["compareDisplayDefault"] === true && attribute["compareDisplayAllowed"] === true) {
        let dataAttributeKey = Attributes.getRealDataAttributeKey(attribute["dataAttributeKey"]);

        let classNameRow = "item-compare__row";
        if (attribute["compareDisplaySize"] === 2) classNameRow += " double-lines";

        let valueColumn = MaterialsStore.getValueColumn(dataAttributeKey, material[dataAttributeKey]);

        // in case the dataAttributeKey === 'sMatAtvKatalog', the content will be rewritten
        if (dataAttributeKey === "sMatAtvKatalog") {
          if (Object.prototype.toString.call(material[dataAttributeKey]) === "[object Array]") {
            let textShown = "";
            material[dataAttributeKey].forEach((value, i) => {
              // value ex: VPI
              if (i !== 0) textShown += `, `; // add coma and space if more than one value in the array
              textShown += `${value}`;
            });

            // rewrite the valueColumn
            valueColumn = (
              <span
                onMouseOver={TT.showTooltip.bind(this, valueColumn, TYPES.left, 15)}
                onMouseOut={TT.removeTooltip.bind(this, TYPES.left)}
                onMouseDown={TT.removeTooltip.bind(this, TYPES.left)}
              >
                {textShown}
              </span>
            );
          }
        }

        if (dataAttributeKey === "sMatAtvFarbe") {
          let colorsOverlay = null;
          if (this.state.showColorsOverlaySelf) {
            colorsOverlay = (
              <ItemColors
                colorSelf={material.sMatAtvFarbe}
                colorsAlternate={material.alternativeColors}
                sMatHdrMatnr={material.sMatHdrMatnr}
                isOverlay={true}
              />
            );
          }

          classNameRow += " item-compare__row--colors";
          itemCompareRows.push(
            <div className={classNameRow} key={dataAttributeKey + i}>
              <ItemColors
                colorSelf={material.sMatAtvFarbe}
                colorsAlternate={material.alternativeColors}
                sMatHdrMatnr={material.sMatHdrMatnr}
                isOverlay={false}
              />
              {colorsOverlay}
            </div>,
          );
        } else {
          itemCompareRows.push(
            <div className={classNameRow} key={dataAttributeKey + i}>
              {valueColumn}
            </div>,
          );
        }
      }
    });

    return itemCompareRows;
  },
});

export default ItemCompareRows;
