import * as Utils from "./Utils";
import { isPisysSite } from "./Controllers";
import DossierStore from "../stores/DossierStore";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import ElstrLog from "elstr-jslib/src/scripts/ElstrLog";
import ElstrCache from "elstr-jslib/src/scripts/ElstrCache";
import query from "array-query";
import * as UrlHelpers from "./UrlHelpers";

export const TYPES = {
  result: "result",
  mainDossier: "mainDossier",
  packagingDossier: "packagingDossier",
};

let attributesByKey = {};

export function init() {
  const attributes = getAttributes();
  attributes.forEach(attribute => {
    attributesByKey[attribute.dataAttributeKey] = attribute;
  });
  initUserAttributes();
}

function getAttributes() {
  return VETROPACK_STATIC_CACHE.attributes;
}

export function getUnit(dataAttributeKey) {
  return attributesByKey[dataAttributeKey] ? attributesByKey[dataAttributeKey].unit : "";
}

export function getMultilangType(dataAttributeKey) {
  return attributesByKey[dataAttributeKey] ? attributesByKey[dataAttributeKey].multilangType : 0;
}

export function getDataType(dataAttributeKey) {
  return attributesByKey[dataAttributeKey] ? attributesByKey[dataAttributeKey].dataType : "";
}

export function getRealDataAttributeKey(dataAttributeKey) {
  // attributes with multilLangType === 2 have also a language tag attached to access them
  // ex: sMatHdrTdline will be sMatHdrTdline__de sMatHdrTdline__cs etc..
  if (getMultilangType(dataAttributeKey) === 2) {
    dataAttributeKey = `${dataAttributeKey}__${ElstrLangStore.getCurrentLanguage()}`;
  }
  return dataAttributeKey;
}

function getAttributesResult() {
  return ElstrCache.get("attributes");
}

function getAttributesMainDossier() {
  return ElstrCache.get("attributesMainDossier");
}

function getAttributesPackagingDossier() {
  return ElstrCache.get("attributesPackagingDossier");
}

function setAttributesResult(attributes) {
  ElstrCache.set("attributes", attributes);
}

function setAttributesMainDossier(attributes) {
  ElstrCache.set("attributesMainDossier", attributes);
}

function setAttributesPackagingDossier(attributes) {
  ElstrCache.set("attributesPackagingDossier", attributes);
}

export function getAttributesByType(type) {
  if (type === TYPES.result) {
    return getAttributesResult();
  }

  if (type === TYPES.mainDossier) {
    return getAttributesMainDossier();
  }

  if (type === TYPES.packagingDossier) {
    return getAttributesPackagingDossier();
  }
}

export function setAttributesByType(attributes, type) {
  if (type === TYPES.result) {
    setAttributesResult(attributes);
  }

  if (type === TYPES.mainDossier) {
    setAttributesMainDossier(attributes);
    DossierStore.setAttributes(type);
  }

  if (type === TYPES.packagingDossier) {
    setAttributesPackagingDossier(attributes);
    DossierStore.setAttributes(type);
  }
}

export function getAttributesSortedAndByMainView(key) {
  let attributesSorted = getAttributesSorted(key);
  if (UrlHelpers.isViewDossier()) {
    attributesSorted = getAttributesMainDossierSorted(key);
  }
  return attributesSorted;
}

function getAttributesSorted(key) {
  let attributes = getAttributesResult();
  return getSortedAttributes(key, attributes);
}

function getAttributesMainDossierSorted(key) {
  let attributes = getAttributesMainDossier();
  return getSortedAttributes(key, attributes);
}

export function getAttributesPackagingDossierSorted(key) {
  let attributes = getAttributesPackagingDossier();
  return getSortedAttributes(key, attributes);
}

function getSortedAttributes(key, attributes) {
  // the sorting of the attributes are as they are stored in the cache file
  // if a key is provided, the attributes will be sorted according to it
  if (!key) {
    return attributes;
  }

  return query().sort(key).on(attributes);
}

function updateStoredAttributes(attributesToUpdate) {
  //
  // update (attributes if they exist already)
  // does following:
  // - adding new attributes
  // - deleting deprecated attributes
  // - overwriting certain key values
  //

  // assign all dataAttributeKeys from the VSC (template)
  let attributesFromVSC = getAttributes();
  let dataAttributeKeysFromVSC = [];
  attributesFromVSC.forEach(attributeObjVSC => {
    dataAttributeKeysFromVSC.push(attributeObjVSC["dataAttributeKey"]);
  });

  // assign all dataAttributeKeys from the LS
  let dataAttributeKeysFromLS = [];
  attributesToUpdate.forEach(attributeObjLS => {
    dataAttributeKeysFromLS.push(attributeObjLS["dataAttributeKey"]);
  });

  // get diffs to add and diffs to remove
  let attributesToAdd = Utils.diff(dataAttributeKeysFromVSC, dataAttributeKeysFromLS);
  let attributesToRemove = Utils.diff(dataAttributeKeysFromLS, dataAttributeKeysFromVSC);

  // adding new attributes
  // add new attributes ojb if there are diffs
  if (attributesToAdd.length > 0) {
    attributesToAdd.forEach(attributeKeyToAdd => {
      ElstrLog.info(`attribute: ${attributeKeyToAdd} will be added`);
      let objToAdd = Utils.findObjsInArray(attributesFromVSC, "dataAttributeKey", attributeKeyToAdd);
      attributesToUpdate.push(objToAdd[0]);
    });
  }

  // deleting deprecated attributes
  // remove attribute obj if there are diffs
  if (attributesToRemove.length > 0) {
    attributesToRemove.forEach(attributeKeyToRemove => {
      ElstrLog.info(`attribute: ${attributeKeyToRemove} will be removed`);
      attributesToUpdate.forEach((attributesFromLS, i, originObj) => {
        if (attributesFromLS["dataAttributeKey"] === attributeKeyToRemove) {
          originObj.splice(i, 1);
        }
      });
    });
  }

  // overwriting certain key values
  // following array defines the attributes which will always be overwritten to assure, that the LS cache
  let columnsToOverwrite = [
    "listDisplayAllowed",
    "compareDisplayAllowed",
    "detailsDisplayAllowed",
    "detailsOrderDefault",
    "detailsDisplayTop",
    "compareDisplaySize",
    "listDisplaySize",
    "multilangType",
    "unit",
  ];

  // overwrite LS data according to VSC settings
  columnsToOverwrite.forEach(keyToOverwrite => {
    attributesToUpdate.forEach((attributeLS, i, originObj) => {
      let value = Utils.findObjsInArray(attributesFromVSC, "dataAttributeKey", attributeLS["dataAttributeKey"]);

      // overwrite from original array the value in VSC
      originObj[i][keyToOverwrite] = value[0][keyToOverwrite];
    });
  });

  return attributesToUpdate;
}

function getPackagingInitialAttributes() {
  let attributes = getAttributes();

  // this is the list of dataAttributeKeys we want to push first before the standard default order
  let initialOrder = [
    "sMatHdrUmrez",
    "sMatAtvPackagingLayers",
    "sMatHdrGrossWt",
    "calculatedLaengBreitHoehe",
    "sMatAtvPackagingPalletType",
    "sMatAtvPackagingType",
  ];

  // create the attributes without the ones for the initial Order
  let packagingInitialAttributes = attributes.filter(attr => !initialOrder.includes(attr.dataAttributeKey));

  // we deactivate the checkbox indicator (listDisplayDefault) and (compareDisplayDefault)
  packagingInitialAttributes = packagingInitialAttributes.map(attr => {
    let copy = Object.assign({}, attr);
    copy.listDisplayDefault = false;
    copy.compareDisplayDefault = false;
    return copy;
  });

  // we push the initialOrder in "reverse" order on the top with unshift to have the right order in the end
  initialOrder.reverse().forEach(key => {
    let data = attributes.filter(attr => attr.dataAttributeKey === key);

    if (data[0]) {
      let copy = Object.assign({}, data[0]);
      // we activate the checkbox indicator (listDisplayDefault) and (compareDisplayDefault)
      copy.listDisplayDefault = true;
      copy.compareDisplayDefault = true;
      packagingInitialAttributes.unshift(copy);
    }
  });
  return packagingInitialAttributes;
}

function getMainDossierInitialAttributes() {
  let attributes = getAttributes();

  // create copies for mutable purposes
  let _attributes = attributes.map(attr => {
    let copy = Object.assign({}, attr);
    return copy;
  });

  // in eCat we leave it like the default
  if (!isPisysSite()) {
    return _attributes;
  }

  // we remove packaging attributes for PISYS in the default page
  // this is the list of dataAttributeKeys we want to push first before the standard default order
  let attributesNotInitialDisplayed = [
    "sMatHdrUmrez",
    "sMatAtvPackagingLayers",
    "sMatHdrGrossWt",
    "calculatedLaengBreitHoehe",
    "sMatAtvPackagingPalletType",
    "sMatAtvPackagingType",
  ];

  // we simply set the default to not show them at the begin
  let __attributes = _attributes.map(attr => {
    if (attributesNotInitialDisplayed.includes(attr.dataAttributeKey)) {
      attr.listDisplayDefault = false;
      attr.compareDisplayDefault = false;
    }

    return attr;
  });

  return __attributes;
}

// initialize or upgrade which attributes a user will see when he loads the app
export function initUserAttributes() {
  let attributesResult = getAttributesResult(); // attributes used for results page
  let attributesMainDossier = getAttributesMainDossier(); // attributes used for main dossier but also changeable in selection page
  let attributesPackagingDossier = getAttributesPackagingDossier(); // attributes used dossier for the additional packaging page

  // initialize
  if (attributesResult === undefined) {
    setAttributesResult(getAttributes());
    // update
  } else {
    setAttributesResult(updateStoredAttributes(attributesResult));
  }

  // initialize
  if (attributesMainDossier === undefined) {
    setAttributesMainDossier(getMainDossierInitialAttributes());
    // update
  } else {
    setAttributesMainDossier(updateStoredAttributes(attributesMainDossier));
  }

  // initialize
  if (attributesPackagingDossier === undefined) {
    setAttributesPackagingDossier(getPackagingInitialAttributes());
    // update
  } else {
    setAttributesPackagingDossier(updateStoredAttributes(attributesPackagingDossier));
  }
}

export function toggleAttributesChecked(checked, pos, type) {
  let attributes = getAttributesByType(type);

  // toggle attributes
  attributes[pos]["listDisplayDefault"] = !checked;
  attributes[pos]["compareDisplayDefault"] = !checked;

  setAttributesByType(attributes, type);
}

/*
VETROPACK_STATIC_CACHE.attributes can have:

compareDisplayAllowed: false
compareDisplayDefault: false
compareDisplaySize: 2
compareOrderDefault: 10
convert0ToNull: false
dataAttributeKey: "sMatHdrTdline"
dataAttributeKeySap: "s_mat_hdr-tdline"
dataType: "string"
detailsDisplayAllowed: false
detailsDisplayTop: false
detailsOrderDefault: 200
listDisplayAllowed: true
listDisplayDefault: true
listDisplaySize: 1
listOrderDefault: 10
multilangType: 2
overrideLabelTranslation: ""
sortOrderDefault: 100
source: "sap"
unit: ""
 */
