import React from "react";
import * as UrlHelpers from "../../../libs/UrlHelpers";

export default function PageClose() {
  return (
    <div className="common-page-header__close-btn" onClick={UrlHelpers.navigateBackOrToResult}>
      <span className="ficon ficon__close-thin common-page-header__close-icon" />
    </div>
  );
}
