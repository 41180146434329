import * as TT from "../../libs/Tooltip";
import Translations from "../../libs/Translations";
import React from "react";
import * as Attributes from "../../libs/Attributes";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import * as UrlHelpers from "../../libs/UrlHelpers";
import MaterialsStore from "../../stores/MaterialsStore";
import ElstrLangStore from "elstr-jslib/src/scripts/stores/ElstrLangStore";
import ElstrConfigStore from "elstr-jslib/src/scripts/stores/ElstrConfigStore";
import { TYPES } from "../../constants/Tooltip";

const FilterColor = createReactClass({
  mixins: [MaterialsStore.mixin],

  propTypes: {
    filter: PropTypes.object, // for every filter type
  },

  getInitialState: function () {
    return {
      filter: this.props.filter,
      hash: `filter_${this.props.filter.dataAttributeKey}`,
    };
  },

  storeDidChange: function () {
    this.forceUpdate();
  },

  selectChange: function (filterValueArr, e) {
    let value = e.target.value;
    if (value !== "-") {
      UrlHelpers.setFilterArray(this.state.hash, value, filterValueArr);
    }
  },

  selectColorOption: function (value, colorHumanName, filterValueArr) {
    let isSelected = filterValueArr.indexOf(value) !== -1;
    if (!isSelected) {
      return (
        <option key={value} value={value}>
          {colorHumanName}
        </option>
      );
    }
  },

  drawColorOption: function (value, className, colorHumanName, filterValueArr) {
    // If the filter contains our value, then is selected
    let isSelected = filterValueArr.indexOf(value) !== -1;
    let selectedClass = "";

    if (isSelected) {
      selectedClass = " product-color--selected";
    }

    return (
      <span
        key={value}
        className={"product-color " + className + selectedClass}
        onMouseOver={TT.showTooltip.bind(this, colorHumanName, TYPES.center, 12)}
        onMouseOut={TT.removeTooltip.bind(this, TYPES.center)}
        onMouseDown={TT.removeTooltip.bind(this, TYPES.center)}
        onClick={this.handleStandardColor.bind(this, this.state.hash, value, filterValueArr)}
      />
    );
  },

  handleStandardColor: function (key, val, filterValueArr) {
    // handle url change
    UrlHelpers.setFilterArray(key, val, filterValueArr);
    // hide focus
    document.activeElement.blur();
  },

  getColorText: function (color, dataAttributeKey, multiLangType) {
    let colorText = "";

    // multiLangType should be 3 in pisys but not in ekat as requested by customer
    if (multiLangType === 3) {
      // adds the color code to the text eg: GR
      colorText += `${color} `;
    }

    // example for GR Green
    // pisys: "GR Green"
    // ekat: "Green"
    colorText += Translations.getValue(dataAttributeKey, color) || "";

    return colorText;
  },

  // if there is no bottle available with that color, the color should not be rendered
  filterNonAvailableColors(color) {
    const sumsOfAttribute = MaterialsStore.getSumsOfAttributeValueFilters("sMatAtvFarbe");
    // will be undefined if not existing
    return sumsOfAttribute[color];
  },

  render: function () {
    let filterValueArr = UrlHelpers.getHashArray(this.state.hash);
    let dataAttributeKey = this.state.filter["dataAttributeKey"];
    let multiLangType = Attributes.getMultilangType(dataAttributeKey);

    // for standard colors
    // aggregate circleOptions
    // aggregate colorOptionsComponents
    let standardColours = ElstrConfigStore.simpleOptionOrDefault("standardColours");
    let circleOptions = []; // color options visually shown by a color circle
    let colorOptionsComponents = [];

    standardColours.filter(this.filterNonAvailableColors).forEach(color => {
      // if there is no bottle available with that color, the color should not be rendered
      const textTooltip = this.getColorText(color, dataAttributeKey, multiLangType);
      const classNameColor = `product-color--${color}`;
      circleOptions.push(this.selectColorOption(color, textTooltip, filterValueArr));
      colorOptionsComponents.push(this.drawColorOption(color, classNameColor, textTooltip, filterValueArr));
    });

    // Content for already selected filters
    let allColors = Translations.getValues(dataAttributeKey);
    let allRelevantColorKeys = Object.keys(allColors).filter(this.filterNonAvailableColors);

    // aggregate selectedFilters
    const selectedFilters = allRelevantColorKeys
      // filter for only selected values
      .filter(color => filterValueArr.indexOf(color) !== -1)
      .map(color => (
        <div key={color} className="btn-filter-color">
          {this.getColorText(color, dataAttributeKey, multiLangType)}
          <span
            className="ficon ficon__close btn-filter__close"
            onClick={UrlHelpers.setFilterArray.bind(this, this.state.hash, color, filterValueArr)}
          />
        </div>
      ));

    // aggregate nonStandardColorOptions for non standard colors
    const nonStandardColorOptions = allRelevantColorKeys
      // prevent adding standard colors to additional nonStandardColorOptions
      // standard colours are included in the var circleOptions at the top of the select options
      .filter(color => !standardColours.includes(color))
      .map(color => {
        const colorText = this.getColorText(color, dataAttributeKey, multiLangType);

        // prevent adding standard colors to additional nonStandardColorOptions
        // standard colours are included in the var circleOptions at the top of the select options
        return this.selectColorOption(color, colorText, filterValueArr);
      });

    // selectedColors will only be shown when there is data to show
    let selectedColors = null;
    if (selectedFilters.length > 0) {
      selectedColors = (
        <React.Fragment>
          <div className="filter-block__sub-title">{ElstrLangStore.text("FILTER_TEXT_YOURSELECTION")}</div>
          {selectedFilters}
        </React.Fragment>
      );
    }

    let colorTranslated = Translations.getLabel(dataAttributeKey) || "";
    let textChooseColor = `${ElstrLangStore.text("FILTER_TEXT_CHOOSE")} ${colorTranslated}`;

    return (
      <div className="filter-block__content">
        {colorOptionsComponents}

        <div className="filter-block__color-selection">
          <div className="filter-block__selectbox">
            <select onChange={this.selectChange.bind(this, filterValueArr)} value="-">
              <option value="-">{textChooseColor}</option>
              {circleOptions}
              {nonStandardColorOptions}
            </select>
          </div>
          {selectedColors}
        </div>
      </div>
    );
  },
});

export default FilterColor;
