import * as Attributes from "../libs/Attributes";
import mcFly from "elstr-jslib/src/scripts/libs/mcFly.js";

const AttributesStore = mcFly.createStore(
  {
    counterForUpdate: 0, // updates every time a user changes attributes order or de/activates one (only relevant in list view)

    getCounterForUpdate: function () {
      return AttributesStore.counterForUpdate;
    },

    toggleAttributes: function (checked, pos, type) {
      Attributes.toggleAttributesChecked(checked, pos, type);
      AttributesStore.incrementCounterForUpdate();
      AttributesStore.emitChange();
    },

    setAttributesByType: function (newAttributesOrder, type) {
      Attributes.setAttributesByType(newAttributesOrder, type);
      AttributesStore.incrementCounterForUpdate();
      AttributesStore.emitChange();
    },

    // will trigger a rerender for shouldComponentUpdate with counterForUpdate implementation
    incrementCounterForUpdate: function () {
      AttributesStore.counterForUpdate += 1;
    },
  },
  function (payload) {
    return true;
  },
);

export default AttributesStore;
